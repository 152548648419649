import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import './storeStepper.scss';
// Components
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Stepper, Step, StepLabel, StepConnector } from '@material-ui/core';
import Check from '@material-ui/icons/Check';
import { LOCALES } from 'i18n';

const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  active: {
    '& $line': {
      borderColor: 'var(--color-primary)',
    },
  },
  completed: {
    '& $line': {
      borderColor: 'var(--color-primary)',
    },
  },
  line: {
    borderColor: '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector);

const useQontoStepIconStyles = makeStyles({
  root: {
    color: '#eaeaf0',
    display: 'flex',
    height: 24,
    alignItems: 'center',
  },
  active: {
    color: 'var(--color-primary)',
  },
  circle: {
    width: 20,
    height: 20,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
  completed: {
    color: 'var(--color-primary)',
    zIndex: 1,
    fontSize: 24,
  },
});

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}>
      {completed ? <Check className={classes.completed} /> : <div className={classes.circle} />}
    </div>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
};

function getSteps() {
  return ['Bolsa de Compras', 'Despacho', 'Pago'];
}

function getEnSteps() {
  return ['Shopcart', 'Dispatch', 'Payment'];
}

function StoreStepper(props) {
  const { activeStep = 0, locale } = props;
  const steps = locale === LOCALES.ENGLISH ? getEnSteps() : getSteps();

  return (
    <div className="store-stepper">
      <Stepper
        alternativeLabel
        activeStep={activeStep}
        connector={<QontoConnector />}
        className="nui-background-body">
        {steps.map((label, index) => (
          <Step key={index}>
            <StepLabel StepIconComponent={QontoStepIcon}>
              <span className="nui-font-size-5">{label}</span>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
}

StoreStepper.propTypes = {
  activeStep: PropTypes.oneOf([0, 1, 2, 3]),
};

export default StoreStepper;
