import configService from './config';
import fetchApi from './fetchApi';
import * as METHODS from './methods';

const baseUrl = configService.apiUrlAdmin;
const sucursalService = {};

sucursalService.list = async function (productRateId) {
    const { response } = await fetchApi({
      url: baseUrl + '/sucursales',
      options: METHODS.GET(),
      dataType: {},
      message: {
        success: 'Datos de las sucursales obtenidas con éxito.',
        error: 'No se pudo obtener los datos de las sucursales.',
      },
    });
  
    return { ...response };
  };
  

  export default sucursalService;