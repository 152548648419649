import React, { useState, useEffect, useContext } from 'react';
import { blogService } from 'services';
import { useNotification } from 'hooks';
import './blogDetail.scss';
// Components
import { Notification, CatePaper } from 'components';
import { Container, Chip } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { Context } from 'context';
import { LOCALES } from 'i18n';
import { LocalSee } from '@material-ui/icons';

function BlogDetail(props) {
  const blogSlug = props.match.params.slug;
  const { notification, createMessage, closeMessage } = useNotification();
  const { locale } = useContext(Context);
  const [loading, setLoading] = useState(true);
  const [blogData, setBlogData] = useState({});

  const fetchShowBlog = async () => {
    setLoading(true);
    const res = await blogService.showBySlug(blogSlug);
    if (!res.info.error) {
      setBlogData(res.data);
    } else {
      createMessage.error(res.info.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchShowBlog();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blogSlug]);

  return (
    <div className="cate-blog-detail">
      <div className="blog-detail-wrap">
        <Container maxWidth={false} className="blog-detail-content">
          <CatePaper disableElevation className="blog-detail-content__card">
            <div className="blog-detail-content__title">
              <h1>{locale === LOCALES.ENGLISH ? blogData.title_en : blogData.title}</h1>
            </div>
            {!loading ? (
              <div
                className="nui-content-post"
                dangerouslySetInnerHTML={{
                  __html:
                    locale === LOCALES.ENGLISH ? blogData.description_en : blogData.description,
                }}
              />
            ) : (
              <div>
                <Skeleton variant="text" height={64} width="80%" style={{ marginBottom: 18 }} />
                <Skeleton height={24} />
                <Skeleton height={24} />
                <Skeleton height={24} />
                <Skeleton height={24} />
                <Skeleton height={24} width="40%" />
                <Skeleton variant="rect" height={312} style={{ margin: '16px 0' }} />
                <Skeleton height={24} />
                <Skeleton height={24} />
                <Skeleton height={24} width="25%" />
              </div>
            )}
            <div className="blog-detail-content__categories">
              {blogData.category && (
                <Chip
                  label={
                    locale === LOCALES.ENGLISH
                      ? blogData.category.category_en
                      : blogData.category.category
                  }
                />
              )}
            </div>
            <div className="blog-detail-content__extra-data">
              <div className="blog-author-list">
                <div className="blog-author-item">
                  <span className="author-item-wrap">
                    <div className="author-item-image">
                      <AccountCircleIcon />
                    </div>
                    <div className="author-item-info">
                      <div className="author-item-info__subtitle">
                        <p>{locale === LOCALES.ENGLISH ? 'Written by' : 'Escrito por'}</p>
                      </div>
                      <div className="author-item-info__name">
                        <h3>{blogData.author}</h3>
                      </div>
                    </div>
                  </span>
                </div>
              </div>
            </div>
          </CatePaper>
        </Container>
      </div>
      {notification.state && <Notification onClose={closeMessage} {...notification} />}
    </div>
  );
}

export default BlogDetail;
