import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
// Components
import { CatePaper, RedButton } from 'components';
import Traslate from 'i18n/traslate';
import ELEMENTS from 'constants/elementsForTranslate';
function MercadoPagoSubscriptCheckout(props) {
  const { isStorePickup, subTotal, deliveryPrice, handleSuccess } = props;

  /* Zona useEffect */
  useEffect(() => {}, []);

  /* ***Zona useEffect */

  return (
    <CatePaper disableElevation className="payment-overview">
      <div>
        <div className="overview-title">
          <h3>{Traslate(ELEMENTS.SHOPCART.SUMMARY)}</h3>
        </div>
        <div className="overview-body">
          <div className="price-group">
            <dl>
              <dt>Subtotal</dt>
              <dd>S/. {subTotal}</dd>
            </dl>
          </div>
          <div className="price-group">
            <dl>
              <dt>Método de envío</dt>
              {isStorePickup ? <dd>Retiro en tienda</dd> : <dd>S/. {deliveryPrice}</dd>}
            </dl>
          </div>
        </div>
        <div className="overview-total">
          <dl>
            <dt>Total</dt>
            {isStorePickup ? <dd>S/. {subTotal}</dd> : <dd>S/. {subTotal + deliveryPrice}</dd>}
          </dl>
        </div>
        <div className="overview-btn">
          <RedButton
            fullWidth
            disableElevation
            variant="contained"
            color="primary"
            onClick={handleSuccess}>
            {Traslate(ELEMENTS.SHIPPING.PAY)}
          </RedButton>
        </div>
      </div>
    </CatePaper>
  );
}

MercadoPagoSubscriptCheckout.propTypes = {
  subTotal: PropTypes.number,
  deliveryPrice: PropTypes.number,
  payment: PropTypes.number,
  handleSuccess: PropTypes.func.isRequired,
};

export default MercadoPagoSubscriptCheckout;
