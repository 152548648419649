// import palette from './palette';

const typography = {
  h1: {
    // color: palette.text.primary,
    fontWeight: 500,
    fontSize: '3.5rem',
    fontFamily: 'Montserrat',
    letterSpacing: '-0.24px',
    lineHeight: '40px',
  },
  h2: {
    // color: palette.text.primary,
    fontWeight: 500,
    fontSize: '2.9rem',
    fontFamily: 'Montserrat',
    letterSpacing: '-0.24px',
    lineHeight: '32px',
  },
  h3: {
    // color: palette.text.primary,
    fontWeight: 500,
    fontSize: '2.4rem',
    fontFamily: 'Montserrat',
    letterSpacing: '-0.06px',
    lineHeight: '28px',
  },
  h4: {
    // color: palette.text.primary,
    fontWeight: 500,
    fontSize: '2rem',
    fontFamily: 'Montserrat',
    letterSpacing: '-0.06px',
    lineHeight: '24px',
  },
  h5: {
    // color: palette.text.primary,
    fontWeight: 500,
    fontSize: '1.6rem',
    fontFamily: 'Montserrat',
    letterSpacing: '-0.05px',
    lineHeight: '20px',
  },
  h6: {
    // color: palette.text.primary,
    fontWeight: 500,
    fontSize: '1.4rem',
    fontFamily: 'Montserrat',
    letterSpacing: '-0.05px',
    lineHeight: '20px',
  },
  button: {
    // color: palette.text.primary,
    fontFamily: 'Montserrat',
    fontSize: '1.4rem',
  },
};

export default typography;
