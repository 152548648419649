import React, { useState, useEffect, useContext } from 'react';
import { userService, locationService, subscriptionService } from 'services';
import { useHistory, useLocation } from 'react-router-dom';
import { useNotification } from 'hooks';
import { Notification } from 'components';

// import { validate, required } from 'utils';
import { Context } from 'context';
import * as ROUTES from 'constants/routes';
import * as VARIABLES from 'constants/variables';
import './shipping.scss';
// Components
import { CatePaper, StoreStepper, RedButton } from 'components';
import { Container, Grid, TextField, Tab, Tabs, MenuItem } from '@material-ui/core';
import LocalShippingOutlinedIcon from '@material-ui/icons/LocalShippingOutlined';
import StoreMallDirectoryOutlinedIcon from '@material-ui/icons/StoreMallDirectoryOutlined';
import Skeleton from '@material-ui/lab/Skeleton';
import Traslate from 'i18n/traslate';
import ELEMENTS from 'constants/elementsForTranslate';

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`shipping-tabpanel-${index}`}
      aria-labelledby={`shipping-tab-${index}`}
      {...other}>
      {value === index && children}
    </div>
  );
};

const a11yProps = (index) => {
  return {
    id: `shipping-tab-${index}`,
    'aria-controls': `shipping-tabpanel-${index}`,
  };
};

const SelectRow = (props) => {
  const {
    id,
    text,
    type,
    select,
    menuData = [],
    value,
    onChange,
    required,
    disabled,
    error,
  } = props;
  let msg_err = 'Error en  campo';
  if (id === 'ruc') {
    if (value.trim().length > 0) {
      msg_err = 'RUC inválido';
    } else {
      msg_err = 'Ingrese RUC.';
    }
  }

  if (id === 'business_name') {
    msg_err = 'Debe ingresar una Razón Social.';
  }

  if (id === 'business_address') {
    msg_err = 'Debe ingresar una dirección de entrega.';
  }

  return (
    <div className="input-container">
      <label htmlFor={id}>{text}</label>
      <div className="input-row">
        <TextField
          select={select}
          required={required}
          disabled={disabled}
          variant="outlined"
          type={type}
          id={id}
          name={id}
          value={value}
          onChange={onChange}>
          {menuData.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.name}
            </MenuItem>
          ))}
        </TextField>
      </div>
      {error ? <label key={'error_' + id}>{msg_err}</label> : <></>}
    </div>
  );
};

const locationSchema = {
  department: (value) => isNaN(value),
  provincia: (value) => isNaN(value),
  district: (value) => isNaN(value),
  address: (value) => isNaN(value),
};
locationSchema.department.required = true;
locationSchema.provincia.required = true;
locationSchema.district.required = true;
locationSchema.address.required = true;

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

function Shipping(props) {
  let query = useQuery();
  let membershipId = query.get('plan_id');
  let experiencepId = query.get('experience_id');
  const history = useHistory();
  const { notification, createMessage, closeMessage } = useNotification();
  const [deliveryPriceList, setDeliveryPriceList] = useState([]);

  const {
    id,
    total,
    membership,
    experience,
    deliveryPrice,
    isStorePickup,
    locale,
    setShipping,
    setCheckout,
    addStorePickup,
    setStorePickup,
    addInvoice,
  } = useContext(Context);

  const [tabValue, setTabValue] = useState(0);
  const [loading, setLoading] = useState(false);
  const [userLocation, setUserLocation] = useState({
    department: '',
    provincia: '',
    district: '',
    address: '',
  });

  const [pickupData, setPickupData] = useState({
    datePickup: '',
    timePickup: '',
  });
  const [departments, setDepartments] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [invoice, setInvoice] = useState({
    is_invoice: 0,
    ruc: ' ',
    business_name: '',
    business_address: '',
  });

  const [errorRuc, setErrorRuc] = useState(false);
  const [errorRazo, setErrorRazo] = useState(false);
  const [errorDirEmp, setErrorDirEmp] = useState(false);
  let newPrice = null;

  if (membershipId) {
    newPrice = membership.price;
  }

  if (experiencepId) {
    newPrice = experience.price;
  }

  const handleTabChange = (event, newValue) => {
    setStorePickup(newValue === 1 ? true : false);
    setTabValue(newValue);
  };

  const handleAdressChange = (e) => {
    setUserLocation({
      ...userLocation,
      address: e.target.value,
    });

    setShipping(
      userLocation.department,
      userLocation.provincia,
      userLocation.district,
      e.target.value,
      deliveryPrice,
    );
    localStorage.setItem('address_products', JSON.stringify(e.target.value));
  };

  const handleUserLocationInputChange = (e) => {
    setUserLocation({
      ...userLocation,
      [e.target.name]: e.target.value,
    });
    if (e.target.name === 'district') {
      onDistrictsChange(e.target.value);
      /*fetchDistrictPriceByName(
        userLocation.department,
        userLocation.provincia,
        e.target.value,
        userLocation.address,
      );*/
    }
    if (e.target.name === 'address') {
      setShipping(
        userLocation.department,
        userLocation.provincia,
        userLocation.district,
        e.target.value,
        deliveryPrice,
      );
      localStorage.setItem('address_products', JSON.stringify(e.target.value));
    }
  };

  const handlePickupDataInputChange = (e) => {
    setPickupData({
      ...pickupData,
      [e.target.name]: e.target.value,
    });
  };

  /*
  function validarRuc(data) {
    //Elimina cualquier caracter espacio o signos habituales y comprueba validez
      var ruc       = data.replace(/[-.,[\]()\s]+/g,"")
    // Devuelve un booleano si es un RUC válido
    // (deben ser 11 dígitos sin otro caracter en el medio)
    //11 dígitos y empieza en 10,15,16,17 o 20
      if (!(ruc >= 1e10 && ruc < 11e9
         || ruc >= 15e9 && ruc < 18e9
         || ruc >= 2e10 && ruc < 21e9))
          return false;  
      for (var suma = -(ruc%10<2), i = 0; i<11; i++, ruc = ruc/10|0)
          suma += (ruc % 10) * (i % 7 + (i/7|0) + 1);
      return suma % 11 === 0;
  }
  */

  // Devuelve un booleano si es un RUC válido
  // (deben ser 11 dígitos sin otro caracter en el medio)
  function rucValido(ruc) {
    //11 dígitos y empieza en 10,15,16,17 o 20
    /* eslint-disable */
    if (
      !(
        (ruc >= 1e10 && ruc < 11e9) ||
        (ruc >= 15e9 && ruc < 18e9) ||
        (ruc >= 2e10 && ruc < 21e9)
      )
    )
      return false;
    /* eslint-enable */

    for (var suma = -(ruc % 10 < 2), i = 0; i < 11; i++, ruc = (ruc / 10) | 0)
      suma += (ruc % 10) * ((i % 7) + ((i / 7) | 0) + 1);
    return suma % 11 === 0;
  }

  function business_name_valid(business_name) {
    if (business_name.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  function business_address_valid(business_address) {
    if (business_address.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  const handleInvoiceDataInputChange = (e) => {
    if (e.target.name === 'ruc') {
      let rucN = Number(e.target.value);
      if (rucValido(rucN)) {
        setErrorRuc(false);
      } else {
        setErrorRuc(true);
      }
    }

    if (e.target.name === 'business_name') {
      if (business_name_valid(e.target.value)) {
        setErrorRazo(false);
      } else {
        setErrorRazo(true);
      }
    }

    if (e.target.name === 'business_address') {
      if (business_address_valid(e.target.value)) {
        setErrorDirEmp(false);
      } else {
        setErrorDirEmp(true);
      }
    }

    setInvoice({
      ...invoice,
      [e.target.name]: e.target.value,
    });
  };

  const handleActionClick = () => {
    // if (!validate(userLocation, locationSchema).status) {
    //   console.log('error de ubicacion');
    //   return;
    // }
    // if (!required(userLocation, locationSchema).status) {
    //   console.log('error de ubicacion 2');
    //   return;
    // }

    if (invoice.is_invoice === 1) {
      if (business_name_valid(invoice.business_name)) {
        setErrorRazo(false);
      } else {
        createMessage.error('Ingrese una razón social válida.');
        setErrorRazo(true);
      }

      if (business_address_valid(invoice.business_address)) {
        setErrorDirEmp(false);
      } else {
        createMessage.error('Ingrese una dirección válida.');
        setErrorDirEmp(true);
        return;
      }
    }

    //todos estos datos se van guardando en el elstorage
    if (isStorePickup) {
      if (!pickupData.datePickup) {
        createMessage.error('Ingrese una fecha de recojo válida.');
        return;
      }
      addStorePickup(pickupData.datePickup, pickupData.timePickup);
    } else {
      const [currentDepartment] = departments.filter(
        (department) => department.id === userLocation.department,
      );

      const [currentProvince] = provinces.filter(
        (province) => province.id === userLocation.provincia,
      );

      const [currentDistrict] = districts.filter(
        (district) => district.id === userLocation.district,
      );

      const { name: districtName } = currentDistrict;
      const { name: departmentName } = currentDepartment;
      const { name: provinceName } = currentProvince;

      localStorage.setItem('precio_envio_productos', JSON.stringify(deliveryPrice));
      localStorage.setItem('department_products', JSON.stringify(departmentName));
      localStorage.setItem('province_products', JSON.stringify(provinceName));
      localStorage.setItem('district_products', JSON.stringify(districtName));
      localStorage.setItem('address_products', JSON.stringify(userLocation.address));
    }

    addInvoice(
      invoice.is_invoice,
      invoice.ruc,
      invoice.business_name,
      invoice.business_address,
    );
    setCheckout(true);
    if (membershipId) {
      history.push(ROUTES.PAYMENT + '?plan_id=' + membershipId);
    } else if (experiencepId) {
      history.push(ROUTES.PAYMENT + '?experience_id=' + experiencepId);
    } else {
      history.push(ROUTES.PAYMENT);
    }
  };

  // const onDepartmentChange = (value) => {
  //   const depItem = departments.find((item) => item.name === value);
  //   if (depItem) {
  //     fetchProvinceList(depItem.id);
  //   }
  // };
  const onDepartmentChange = (value) => {
    setUserLocation({
      ...userLocation,
      ['department']: value.target.value,
      ['provincia']: '',
      ['district']: '',
    });
    setShipping(value.target.value, '', '', userLocation.address, '');
    loadProvinceList(value.target.value);
  };

  const onProvinceChange = (value) => {
    setUserLocation({
      ...userLocation,
      ['provincia']: value.target.value,
      ['district']: '',
    });
    setShipping(userLocation.department, value.target.value, '', userLocation.address, '');
    loadDistrictList(userLocation.department, value.target.value);
  };

  const onProvincesChange = (value) => {
    const prvItem = provinces.find((item) => item.name === value);
    if (prvItem) {
      fetchDistrictList(prvItem.id);
    }
  };

  const onDistrictChange = (value) => {
    const [currentDistrict] = districts.filter(
      (district) => district.id === value.target.value,
    );

    const { price } = currentDistrict;

    setShipping(
      userLocation.department,
      userLocation.provincia,
      value.target.value,
      userLocation.address,
      price,
    );

    setUserLocation({
      ...userLocation,
      ['district']: value.target.value,
    });
  };

  const onDistrictsChange = (value) => {
    fetchDistrictPriceByName(
      userLocation.department,
      userLocation.provincia,
      value,
      userLocation.address,
    );
  };

  const fetchUserData = async () => {
    setLoading(true);
    const res = await userService.show(id);
    if (!res.info.error) {
      if (res.data.user_detail) {
        // const newLocation = res.data.user_detail;
        setUserLocation(res.data.user_detail);
        if (res.data.user_detail.department) {
          onDepartmentChange(res.data.user_detail.department);
        }
        if (res.data.user_detail.provincia) {
          onProvincesChange(res.data.user_detail.provincia);
        }
        if (res.data.user_detail.district) {
          onDistrictsChange(res.data.user_detail.district);
          /*fetchDistrictPriceByName(
            res.data.user_detail.department,
            res.data.user_detail.provincia,
            res.data.user_detail.district,
            res.data.user_detail.addrress,
          );*/
        }
      }
    }
    setLoading(false);
  };

  const fetchDepartmentsList = async () => {
    const res = await locationService.departmentsList();
    // console.log(res);
    if (!res.info.error) {
      setDepartments(res.data);
    } else {
      setDepartments([]);
    }
  };

  const fetchProvinceList = async (departmentId) => {
    const res = await locationService.provinceList(departmentId);
    // console.log(res);
    if (!res.info.error) {
      setProvinces(res.data);
    } else {
      setProvinces([]);
    }
  };

  const fetchDistrictList = async (provinceId) => {
    const res = await locationService.districtList(provinceId);
    if (!res.info.error) {
      setDistricts(res.data);
    } else {
      setDistricts([]);
    }
  };

  const fetchDistrictPriceByName = async (department, province, district, address) => {
    const res = await locationService.getdistrictPriceByName(district);
    if (!res.info.error) {
      setShipping(department, province, district, address, res.data.price);
      localStorage.setItem('precio_envio_productos', JSON.stringify(res.data.price));
      localStorage.setItem('department_products', JSON.stringify(department));
      localStorage.setItem('province_products', JSON.stringify(province));
      localStorage.setItem('district_products', JSON.stringify(district));
      localStorage.setItem('address_products', JSON.stringify(address));
    }
  };

  const loadDepartmentsList = async (deliveryList) => {
    if (departments.length > 0) {
      return;
    }

    let departmentList = [];
    if (deliveryList !== undefined && deliveryList !== null) {
      Object.values(deliveryList).forEach((item) => {
        departmentList.push({
          id: item.department_id,
          name: item.department_name,
        });
      });
    }

    setDepartments(departmentList);
  };

  const loadProvinceList = async (departmentId) => {
    let provincesList = [];
    Object.values(deliveryPriceList[departmentId].provinces).forEach((item) => {
      provincesList.push({
        id: item.province_id,
        name: item.province_name,
      });
    });
    setProvinces(provincesList);
  };

  const loadDistrictList = async (departmentId, provinceId) => {
    let districtList = [];
    Object.values(deliveryPriceList[departmentId].provinces[provinceId].districts).forEach(
      (item) => {
        districtList.push({
          id: item.ditrict_id,
          name: item.district_name,
          price: item.price,
        });
      },
    );
    setDistricts(districtList);
  };

  const fetchDeliveryPriceList = async () => {
    subscriptionService
      .listDeliveryPrices()
      .then((response) => {
        if (!response.info.error) {
          localStorage.setItem(
            VARIABLES.LOCAL_STORAGE.DELIVERY_PRICE_LIST,
            JSON.stringify(response.data),
          );
          localStorage.setItem(
            VARIABLES.LOCAL_STORAGE.DELIVERY_PRICE_LIST_DATE_LOADED,
            new Date().toString(),
          );
          loadDepartmentsList(response.data);
          setDeliveryPriceList(response.data);
        } else {
          createMessage.error(response.info.message);
          setDeliveryPriceList([]);
        }
      })
      .catch((reason) => {
        console.log(reason);
        setDeliveryPriceList([]);
      });
  };

  useEffect(() => {
    // fetchUserData();
    fetchDeliveryPriceList();
    loadDepartmentsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   fetchDistrictList(1501);
  // }, []);

  // useEffect(() => {
  //   if (userLocation.department && departments.length > 0) {
  //     onDepartmentChange(userLocation.department);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [userLocation, departments]);

  // useEffect(() => {
  //   if (userLocation.provincia && provinces.length > 0) {
  //     onProvincesChange(userLocation.provincia);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [userLocation, provinces]);

  /*useEffect(() => {
    if (userLocation.district && districts.length > 0) {
      onDistrictsChange(userLocation.district);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userLocation, provinces]);
  */

  return (
    <div className="cate-shipping">
      <Container maxWidth="lg">
        <div>
          <StoreStepper activeStep={1} locale={locale} />
          <Grid container spacing={3}>
            <Grid item xs={12} md={8}>
              <CatePaper disableElevation className="shipping-location shipping-card">
                <h3 className="location-title">{Traslate(ELEMENTS.SHIPPING.CHOOSE)}</h3>
                <div className="location-tabs">
                  <Tabs
                    aria-label="shipping tabs"
                    indicatorColor="primary"
                    variant="fullWidth"
                    value={tabValue}
                    onChange={handleTabChange}>
                    <Tab
                      label={
                        <>
                          <LocalShippingOutlinedIcon />
                          <span>{Traslate(ELEMENTS.SHIPPING.DISPATCH)}</span>
                        </>
                      }
                      {...a11yProps(0)}
                    />
                    {!newPrice && (
                      <Tab
                        label={
                          <>
                            <StoreMallDirectoryOutlinedIcon />
                            <span>{Traslate(ELEMENTS.SHIPPING.RETIRE)}</span>
                          </>
                        }
                        {...a11yProps(1)}
                      />
                    )}
                  </Tabs>
                </div>
                <TabPanel value={tabValue} index={0}>
                  <div className="location-summary">
                    <span>{Traslate(ELEMENTS.SHIPPING.CHOOSE_DISTRICT)}</span>
                  </div>
                  {!loading ? (
                    <div className="location-form">
                      <SelectRow
                        select
                        id="department"
                        text={Traslate(ELEMENTS.SHIPPING.DEPARTMENT)}
                        menuData={departments}
                        value={userLocation.department}
                        onChange={onDepartmentChange}
                      />
                      {provinces.length > 0 ? (
                        <SelectRow
                          select
                          id="provincia"
                          text={Traslate(ELEMENTS.SHIPPING.PROVINCE)}
                          menuData={provinces}
                          value={userLocation.provincia}
                          onChange={onProvinceChange}
                        />
                      ) : (
                        <SelectRow
                          disabled
                          id="provincia"
                          text={Traslate(ELEMENTS.SHIPPING.PROVINCE)}
                        />
                      )}
                      {districts.length > 0 ? (
                        <SelectRow
                          select
                          id="district"
                          text={Traslate(ELEMENTS.SHIPPING.DISTRICT)}
                          menuData={districts}
                          value={userLocation.district}
                          onChange={onDistrictChange}
                          error={false}
                        />
                      ) : (
                        <SelectRow
                          disabled
                          id="district"
                          text={Traslate(ELEMENTS.SHIPPING.DISTRICT)}
                        />
                      )}
                      <SelectRow
                        id="address"
                        text={Traslate(ELEMENTS.SHIPPING.ADDRESS)}
                        value={userLocation.address}
                        onChange={handleAdressChange}
                        error={false}
                      />
                    </div>
                  ) : (
                    <div className="location-form">
                      {Array.from(new Array(4)).map((data, index) => {
                        return (
                          <div key={index} className="input-container">
                            <Skeleton variant="text" width="35%" height={24} />
                            <Skeleton className="input-row " variant="text" height={42} />
                          </div>
                        );
                      })}
                    </div>
                  )}
                </TabPanel>
                {!newPrice && (
                  <TabPanel value={tabValue} index={1}>
                    <div className="location-summary">
                      <span>{Traslate(ELEMENTS.SHIPPING.CONFIRM_DATE)}</span>
                    </div>
                    <div className="location-form">
                      <SelectRow
                        id="datePickup"
                        text={Traslate(ELEMENTS.SHIPPING.DATE)}
                        type="date"
                        value={pickupData.datePickup}
                        onChange={handlePickupDataInputChange}
                      />
                      <SelectRow
                        select
                        id="timePickup"
                        text={Traslate(ELEMENTS.SHIPPING.HOUR)}
                        menuData={VARIABLES.TIME_ARRAY}
                        value={pickupData.timePickup}
                        onChange={handlePickupDataInputChange}
                      />
                    </div>
                  </TabPanel>
                )}
                <div className="location-form">
                  <div className="input-container">
                    <label htmlFor="is_invoice">
                      {Traslate(ELEMENTS.SHIPPING.PAYMENT_RECEIPT)}
                    </label>
                    <div className="input-row">
                      <TextField
                        select
                        variant="outlined"
                        id="is_invoice"
                        name="is_invoice"
                        value={invoice.is_invoice}
                        onChange={handleInvoiceDataInputChange}>
                        {VARIABLES.INVOICE_ARRAY.map((option, index) => (
                          <MenuItem key={index} value={option.value}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </div>
                  </div>
                  {invoice.is_invoice === 1 && (
                    <>
                      <SelectRow
                        id="ruc"
                        text="RUC:"
                        type="text"
                        value={invoice.ruc.trim()}
                        onChange={handleInvoiceDataInputChange}
                        error={errorRuc}
                      />
                      <SelectRow
                        id="business_name"
                        text="Razon Social:"
                        type="text"
                        value={invoice.business_name}
                        onChange={handleInvoiceDataInputChange}
                        error={errorRazo}
                      />
                      <SelectRow
                        id="business_address"
                        text="Dirección de la empresa:"
                        type="text"
                        value={invoice.business_address}
                        onChange={handleInvoiceDataInputChange}
                        error={errorDirEmp}
                      />
                    </>
                  )}
                </div>
              </CatePaper>
            </Grid>
            <Grid item xs={12} md={4}>
              <CatePaper disableElevation className="price-overview shipping-card">
                <div>
                  <h3 className="overview-title">{Traslate(ELEMENTS.SHOPCART.SUMMARY)}</h3>
                  <div className="overview-body">
                    <div className="price-group">
                      <dl>
                        <dt>Subtotal</dt>
                        {newPrice ? (
                          <dd>S/. {parseFloat(newPrice).toFixed(2)}</dd>
                        ) : (
                          <dd>S/. {parseFloat(total).toFixed(2)}</dd>
                        )}
                      </dl>
                    </div>
                    {!isStorePickup && (
                      <div className="price-group">
                        <dl>
                          <dt>Costo del despacho</dt>
                          <dd>S/. {deliveryPrice ? deliveryPrice.toFixed(2) : '...'}</dd>
                        </dl>
                      </div>
                    )}
                  </div>
                  <div className="overview-btn-order">
                    <dl>
                      <dt>Total</dt>
                      {isStorePickup ? (
                        <dd>S/. {parseFloat(total).toFixed(2)}</dd>
                      ) : (
                        <>
                          {deliveryPrice ? (
                            <>
                              {newPrice ? (
                                <dd>S/. {(parseFloat(newPrice) + deliveryPrice).toFixed(2)}</dd>
                              ) : (
                                <dd>S/. {(parseFloat(total) + deliveryPrice).toFixed(2)}</dd>
                              )}
                            </>
                          ) : (
                            <dd>...</dd>
                          )}
                        </>
                      )}
                    </dl>
                  </div>
                  <div className="btn-action">
                    {isStorePickup ? (
                      <RedButton
                        fullWidth
                        disableElevation
                        disabled={
                          pickupData?.datePickup === '' || pickupData?.timePickup === ''
                            ? true
                            : false
                        }
                        variant="contained"
                        color="primary"
                        onClick={handleActionClick}>
                        PROCEDER AL PAGO
                      </RedButton>
                    ) : (
                      <RedButton
                        fullWidth
                        disableElevation
                        disabled={!userLocation.district || !userLocation.address}
                        // disabled={
                        //   userLocation?.address?.length > 5 ? false : true || !deliveryPrice
                        // }
                        variant="contained"
                        color="primary"
                        onClick={handleActionClick}>
                        {Traslate(ELEMENTS.SHIPPING.PAY)}
                      </RedButton>
                    )}
                  </div>
                </div>
              </CatePaper>
            </Grid>
          </Grid>
        </div>
      </Container>
      {notification.state && <Notification onClose={closeMessage} {...notification} />}
    </div>
  );
}

export default Shipping;
