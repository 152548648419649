import React from 'react';
import { CookiesProvider } from 'react-cookie';
import { ThemeProvider } from '@material-ui/core';

import Routes from 'router/Routes';
import Context from 'context';
import theme from 'theme';
import { I18nProvider } from './i18n';

function App() {
  return (
    <CookiesProvider>
      <Context.Provider>
        <ThemeProvider theme={theme}>
          <I18nProvider>
            <Routes />
          </I18nProvider>
        </ThemeProvider>
      </Context.Provider>
    </CookiesProvider>
  );
}


export default App;
