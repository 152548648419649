import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { replacePathParams, isNewProduct } from 'utils';
import * as VARIABLES from 'constants/variables';
import * as ROUTES from 'constants/routes';
import PropTypes from 'prop-types';
import './cardGrid.scss';
// components
import { CatePaper } from 'components';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Button } from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import Skeleton from '@material-ui/lab/Skeleton';

const layout = { xs: 12, sm: 6, md: 4, lg: 3 };

const ColorButton = withStyles((theme) => ({
  root: {
    color: red[500],
    border: '1px solid' + red[500],
    '&:hover': {
      backgroundColor: red[50],
    },
  },
}))(Button);

function CardGrid(props) {
  const { loading = true, data } = props;

  return (
    <div className="products-list-grid">
      <Grid container spacing={3}>
        {loading ? (
          <>
            {Array.from(new Array(15)).map((data, index) => (
              <Grid item key={index} {...layout}>
                <CatePaper disableElevation className="product-card">
                  <Skeleton animation="wave" variant="rect" className="product-card__image" />
                  <div className="product-card__body">
                    <div className="product-card__body__wrap">
                      <div className="card-body-top">
                        <div className="body-brand">
                          <Skeleton animation="wave" width="60%" height={20} />
                        </div>
                        <div className="body-title">
                          <Skeleton animation="wave" height={20} />
                        </div>
                      </div>
                      <div className="card-body-bottom">
                        <div className="body-price"></div>
                        <div className="body-action">
                          <Skeleton animation="wave" width={32} height={24} />
                        </div>
                      </div>
                    </div>
                  </div>
                </CatePaper>
              </Grid>
            ))}
          </>
        ) : (
          <>
            {data.map((item, index) => (
              <React.Fragment key={index}>
                <Grid item {...layout}>
                  <CatePaper disableElevation className="product-card">
                    <RouterLink
                      className="nui-no-decoration nui-block"
                      to={replacePathParams(ROUTES.PRODUCTSB2B_BY_SLUG, { slug: item.slug })}>
                      <div
                        className={`product-card__image ${
                          item.stock <= 0 ? 'cover-disable' : ''
                        }`}>
                        <img
                          src={
                            item.images[0]
                              ? VARIABLES.IMAGE_PATH + item.images[0].image_path
                              : VARIABLES.IMAGE_FALLBACK
                          }
                          alt={item.label}
                        />
                        {isNewProduct(item.created_at) && (
                          <div className="badge-new">
                            <span>Nuevo</span>
                          </div>
                        )}
                        {item.stock <= 0 && (
                          <div className="badge-stock">
                            <p>PRODUCTO</p>
                            <p>AGOTADO</p>
                          </div>
                        )}
                      </div>
                    </RouterLink>
                    <div className="product-card__body">
                      <div className="product-card__body__wrap">
                        <div className="card-body-top">
                          {item.brand && <div className="body-brand">{item.brand.label}</div>}
                          <div className="body-title">{item.label}</div>
                          <div className="body-price-normal">
                            <p>
                              <span className="body-price-normal__price">S/ {item.price} </span>
                              <span className="body-price-normal__unit">/ Unidad</span>
                            </p>
                          </div>
                        </div>
                        <div className="card-body-bottom">
                          <div className="body-price">
                            <span></span>
                          </div>
                          <div className="body-action">
                            <RouterLink
                              className="nui-no-decoration"
                              to={replacePathParams(ROUTES.PRODUCTSB2B_BY_SLUG, {
                                slug: item.slug,
                              })}>
                              <ColorButton disableElevation>Ver producto</ColorButton>
                            </RouterLink>
                          </div>
                        </div>
                      </div>
                    </div>
                  </CatePaper>
                </Grid>
              </React.Fragment>
            ))}
          </>
        )}
      </Grid>
    </div>
  );
}

CardGrid.propTypes = {
  loading: PropTypes.bool,
  data: PropTypes.array,
};

export default CardGrid;
