import configService from './config';
import fetchApi from './fetchApi';
import * as METHODS from './methods';

const baseUrl = configService.apiUrl;
const blogCategoryService = {};

blogCategoryService.list = async function () {
  const { response } = await fetchApi({
    url: baseUrl + '/blog-category',
    options: METHODS.GET(),
    dataType: [],
    message: {
      success: 'Categorías de los blog obtenidas con éxito.',
      error: 'No se puedo obtener la lista de categorías de los blogs.',
    },
  });

  return { ...response };
};

export default blogCategoryService;
