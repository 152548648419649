import React, { useState, useEffect, useContext } from 'react';
import { useMercadoPago } from 'mercadopago-v2-react';
import { useLocation, Link as RouterLink } from 'react-router-dom';
import { orderService, subscriptionService, experienceService } from 'services';
import { useNotification } from 'hooks';
import { Context } from 'context';
import * as VARIABLES from 'constants/variables';
import * as ROUTES from 'constants/routes';
import './payment.scss';
// Components
import {
  Container,
  MenuItem,
  TextField,
  Grid,
  Radio,
  RadioGroup,
  FormControlLabel,
} from '@material-ui/core';
import { CoffeeBoxIcon, VisaIcon, MasterCardIcon, AmericanExpressIcon } from 'icons';
import {
  Notification,
  CatePaper,
  StoreStepper,
  Progress,
  MerdadoPagoCheckout,
  MercadoPagoSubscriptCheckout,
} from 'components';

import 'antd/dist/antd.css';

import { Drawer } from 'antd';
import Traslate from 'i18n/traslate';
import ELEMENTS from 'constants/elementsForTranslate';
const ProductItem = (props) => {
  const { cover, title, extra, tag, price } = props;
  return (
    <li className="product-card">
      <div className="product-card__cover">{cover}</div>
      <div className="product-card__main">
        <div className="product-title">
          <span>{title}</span>
        </div>
        <div className="product-property">{extra}</div>
        <div className="product-quantity">
          <span>{tag}</span>
        </div>
        <div className="product-price">
          <span>{price}</span>
        </div>
      </div>
    </li>
  );
};

const orderInfoData = [
  {
    id: '01',
    name: 'Boleta',
  },
  {
    id: '02',
    name: 'Factura',
  },
];

const SelectRow = ({ id, text, menuData = [], value, onChange, required, disabled }) => {
  return (
    <li>
      <label htmlFor={id}>{text}</label>
      <div className="input-row">
        <TextField
          select
          required={required}
          disabled={disabled}
          variant="outlined"
          id={id}
          name={id}
          value={value}
          onChange={onChange}>
          {menuData.map((option) => (
            <MenuItem key={option.id} value={option.name}>
              {option.name}
            </MenuItem>
          ))}
        </TextField>
      </div>
    </li>
  );
};

const InfoRow = ({
  id,
  text,
  type,
  value,
  onChange,
  multiline,
  rows,
  disabled,
  required = true,
  onBlur,
}) => (
  <li>
    <label htmlFor={id}>{text}</label>
    <div className="input-row">
      <TextField
        required={required}
        disabled={disabled}
        multiline={multiline}
        rows={rows}
        variant="outlined"
        id={id}
        name={id}
        type={type}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
      />
    </div>
  </li>
);

const ProductItemSubs = (props) => {
  const { cover, title, extra, tag, price, id, sel } = props;
  return (
    <li className="product-card">
      <div className="product-card__cover">{cover}</div>
      <div className="product-card__main">
        <div className="product-title">
          <span>{title}</span>
          <span>
            <FormControlLabel
              key={id}
              value={id}
              control={<Radio />}
              checked={sel === id}
              label=""
            />
          </span>
        </div>
        <div className="product-property">{extra}</div>
        <div className="product-quantity">
          <span>{tag}</span>
        </div>
        <div className="product-price">
          <span>{price}</span>
        </div>
      </div>
    </li>
  );
};

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

function Payment() {
  let query = useQuery();
  let membershipId = query.get('plan_id');
  let experiencepId = query.get('experience_id');
  // const history = useHistory();
  const {
    id,
    name,
    email,
    cartItems,
    total,
    memberships,
    experience,
    clearMemberships,
    shippingMemberships,
    clearShippingMemberships,
    clearExperience,
    // checkout,
    invoice,
    shipping,
    deliveryPrice,
    isStorePickup,
    locale,
    storePickup,
    clearCart,
  } = useContext(Context);
  const { notification, createMessage, closeMessage } = useNotification();
  const [loading, setLoading] = useState(false);
  const [selSub, setSelSub] = useState(0);
  const deliveryPriceProduct = JSON.parse(localStorage.getItem('precio_envio_productos'));
  const departmentProduct = JSON.parse(localStorage.getItem('department_products'));
  const provinceProduct = JSON.parse(localStorage.getItem('province_products'));
  const districtProduct = JSON.parse(localStorage.getItem('district_products'));
  const addressProduct = JSON.parse(localStorage.getItem('address_products'));

  const [modalState, setModalState] = useState(false);
  /* eslint-disable */
  const [modalLoading, setModalLoading] = useState(false);
  /* eslint-enable */

  /* variables para card form */

  const [formData, setFormData] = useState({});
  // const [typeOfInvoice, setTypeOfInvoice] = useState({
  //   invoice: 'Boleta',
  //   ruc: '',
  //   business_name: '',
  //   business_address: '',
  // });

  const {
    cardFlag,
    checkCardDigits,
    createToken,
    identificationTypeOptions,
    installmentOptions,
    issuer,
    setAmountValue,
    months,
    years,
  } = useMercadoPago({
    locale: 'es-PE',
    publicKey: VARIABLES.MERCADO_PAGO_PUBLIC_KEY,
  });

  /* ***variables para card form */

  // const culqiPayment = (Number(total) + deliveryPrice) * 10 * 10;

  let newPrice = null;

  if (membershipId) {
    newPrice = true;
  }

  if (experiencepId) {
    newPrice = true;
  }

  const handleRadioChange = (e) => {
    setSelSub(e.currentTarget.value);
  };

  // const handleInvoiceChange = (e) => {
  //   setTypeOfInvoice({
  //     ...typeOfInvoice,
  //     [e.target.name]: e.target.value,
  //   });

  //   if (e.target.name === 'invoice') {
  //     if (e.target.value === 'Boleta') {
  //       setTypeOfInvoice({
  //         ...typeOfInvoice,
  //         invoice: 'Boleta',
  //         ruc: '',
  //         business_name: '',
  //         business_address: '',
  //       });
  //     }
  //   }
  // };

  const handleMembershipCulqiSuccess = async (event) => {
    // console.log(token);
    setLoading(true);

    setModalLoading(true);
    setModalState(true);

    event.preventDefault();

    const token = await createToken(formData);

    //return false;

    createMessage.info('...Enviando datos');
    //console.dir(shippingMemberShips);
    const res = await subscriptionService.create({
      userId: id,
      tokenId: token,
      subscriptionId: membershipId,
      department: shippingMemberships.department,
      province: shippingMemberships.province,
      district: shippingMemberships.district,
      address: shippingMemberships.address,
    });
    // console.log(res);
    if (!res.info.error) {
      createMessage.success('Subscripción realizada con exito');
      setLoading(false);
      clearMemberships();
      clearShippingMemberships();
      setModalState(false);

      //history.push(ROUTES.USER_PROFILE);
    } else {
      createMessage.error('Error al realizar el pago');
      setLoading(false);
      setModalState(false);
    }
  };

  function handleChangeValueInput(event) {
    setFormData((state) => ({
      ...state,
      [event.target.name]: event.target.value,
    }));
  }

  const handleOrderMercadoPago = async () => {
    // TODO: hacer validación de tipo de facturación

    setLoading(true);

    createMessage.info('...Redireccionando a Mercado Pago');
    let productArray = [];
    cartItems.forEach((item, index) => {
      productArray.push({
        product_id: item.id,
        product_property_detail_id: item.property_active ? item.property_active.id : null,
        product_property_details_other: item.otherGroundProperty
          ? item.otherGroundProperty
          : '',
        quantity: item.quantity,
      });
    });
    if (isStorePickup) {
      const res = await orderService.createPickupStore({
        userId: id,
        datePickup: storePickup.datePickup,
        timePickup: storePickup.timePickup,
        products: productArray,
        is_invoice: invoice.isInvoice,
        ruc: invoice.ruc,
        business_name: invoice.businessName,
        business_address: invoice.businessAddress,
      });
      // console.log(res);
      if (!res.info.error) {
        clearCart();
        // setLoading(false);
        window.location.href = res.data.mercado_pago;
      } else {
        createMessage.error('Error al realizar el pago');
        setLoading(false);
      }
    } else {
      const res = await orderService.create({
        userId: id,
        department: departmentProduct,
        province: provinceProduct,
        district: districtProduct,
        address: addressProduct,
        deliveryPrice: deliveryPriceProduct,
        products: productArray,
        is_invoice: invoice.isInvoice,
        ruc: invoice.ruc,
        business_name: invoice.businessName,
        business_address: invoice.businessAddress,
      });
      if (!res.info.error) {
        clearCart();
        // setLoading(false);
        window.location.href = res.data.mercado_pago;
      } else {
        createMessage.error('Error al realizar el pago');
        setLoading(false);
      }
    }
  };

  const handleExperienceMercadoPago = async () => {
    setLoading(true);
    createMessage.info('...Redireccionando a Mercado Pago');
    const res = await experienceService.createBooking({
      user_id: id,
      experience_id: experiencepId,
      delivered_price: 0,
      department: shipping.department,
      province: shipping.province,
      district: shipping.district,
      address: shipping.address,
    });
    if (!res.info.error) {
      clearExperience();
      // setLoading(false);
      window.location.href = res.data.mercado_pago;
    } else {
      createMessage.error(res.info.message);
      setLoading(false);
    }
  };

  const handleCulqiError = (error) => {
    createMessage.error('Error, Culqui al realizar el pago');
  };

  const handleModalClose = (e) => {
    setModalState(false);
  };

  /*
  const handleModalOk = async () => {
    setModalState(false);
  }
  */

  const showDrawer = async () => {
    setModalState(true);

    setAmountValue(memberships[selSub].preview_price);
  };

  /*
  if (!checkout) {
    history.push(ROUTES.SHOPCART);
  }
  */

  /* Zona useEffect */
  useEffect(() => {
    //ventanaMP();
  }, []);

  /* ***Zona useEffect */

  return (
    <div className="cate-payment">
      <Container maxWidth="lg">
        <div>
          <StoreStepper activeStep={2} locale={locale} />
          <Grid container spacing={3}>
            <Grid item xs={12} md={7}>
              <CatePaper disableElevation className="shipping-info">
                <div className="shipping-title">
                  <p>{Traslate(ELEMENTS.PAYMENT.INFORMATION)}</p>
                </div>
                <div className="shipping-detail">
                  <div className="address-item">
                    <div className="address-main">
                      {name}, {email}
                    </div>
                    <div className="address-detail">{shipping.address}</div>
                    <div className="address-detail">
                      {addressProduct}
                      {/* , {shipping.province}, {shipping.department} */}
                    </div>
                  </div>
                </div>
              </CatePaper>
              <CatePaper disableElevation className="summary-content shipping-info">
                <div className="summary-title">
                  <p>{Traslate(ELEMENTS.PAYMENT.DETAILS)}</p>
                </div>
                <div className="summary-list">
                  {!newPrice && (
                    <ul>
                      {cartItems.map((item, index) => (
                        <ProductItem
                          key={index}
                          cover={
                            <img
                              src={VARIABLES.IMAGE_PATH + item.images[0].image_path}
                              alt={item.label}
                            />
                          }
                          title={item.label}
                          extra={
                            item.property_active && (
                              <span>
                                {item.property_active.label === 'Otro'
                                  ? item.property.label + ' : ' + item.otherGroundProperty
                                  : item.property.label + ' : ' + item.property_active.label}
                              </span>
                            )
                          }
                          tag={'Cantidad: ' + item.quantity}
                          price={'S/. ' + item.price}
                        />
                      ))}
                    </ul>
                  )}
                  {membershipId && (
                    <ul>
                      <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={selSub}
                        onChange={handleRadioChange}>
                        {memberships.map((item, index) => {
                          //transformar el valor del periodo en uno
                          //segun los valores de la app
                          let periodo_trad = item.period;
                          /*switch(item.period){
                        case 'dias': periodo_trad= 'diario';
                                     break;
                        case 'semanas' : if (item.interval_count==2){
                                          periodo_trad= 'Cada 2 semanas';
                                        } else if(item.interval_count==16) {                                          
                                          periodo_trad= 'semestral';
                                        } else if(item.interval_count==1) {                                          
                                          periodo_trad= 'semanal';
                                        }
                                        break;
                        case 'meses'  : periodo_trad= 'mensual';                    
                                        break;
                        case 'años' : periodo_trad='anual';  
                                        break;
                      }*/

                          return (
                            <>
                              <ProductItemSubs
                                cover={<CoffeeBoxIcon />}
                                title={item.title}
                                tag={'Periodo: ' + periodo_trad}
                                price={'S/. ' + item.preview_price}
                                shipping_cost={0}
                                id={item.id}
                                sel={selSub}
                                handleMembershipCulqiSuccess1={handleMembershipCulqiSuccess}
                                handleCulqiError1={handleCulqiError}
                              />
                            </>
                          );
                        })}
                      </RadioGroup>
                    </ul>
                  )}
                  {experiencepId && (
                    <ul>
                      <ProductItem
                        cover={
                          <img
                            src={VARIABLES.IMAGE_PATH + experience?.image_path}
                            alt={experience?.title}
                          />
                        }
                        title={experience?.title}
                        tag={'Tiempo: ' + experience?.duration_time}
                        price={'S/. ' + experience?.price}
                      />
                    </ul>
                  )}
                </div>
              </CatePaper>
              {/* <CatePaper disableElevation className="summary-content">
                <div className="summary-title invoice">
                  <p>Factura o boleta</p>
                </div>
                <div className="summary-list invoice">
                  <SelectRow
                    id="invoice"
                    text="Tipo de comprobante de pago:"
                    menuData={orderInfoData}
                    value={typeOfInvoice.invoice}
                    onChange={handleInvoiceChange}
                  />

                  {typeOfInvoice.invoice === 'Factura' && (
                    <>
                      <InfoRow
                        id="ruc"
                        text="RUC:"
                        type="text"
                        value={typeOfInvoice.ruc}
                        onChange={handleInvoiceChange}
                      />
                      <InfoRow
                        id="business_name"
                        text="Razón Social:"
                        type="text"
                        value={typeOfInvoice.business_name}
                        onChange={handleInvoiceChange}
                      />
                      <InfoRow
                        id="business_address"
                        text="Dirección:"
                        type="text"
                        value={typeOfInvoice.business_address}
                        onChange={handleInvoiceChange}
                      />
                    </>
                  )}
                </div>
              </CatePaper> */}
            </Grid>
            <Grid item xs={12} md={5}>
              {membershipId && (
                <MercadoPagoSubscriptCheckout
                  isStorePickup={isStorePickup}
                  subTotal={parseFloat(memberships[selSub].preview_price)}
                  deliveryPrice={0}
                  handleSuccess={showDrawer}
                />
              )}
              {experiencepId && (
                <MerdadoPagoCheckout
                  subTotal={parseFloat(experience?.price)}
                  deliveryPrice={0}
                  handleSuccess={handleExperienceMercadoPago}
                  experiencepId={experiencepId}
                />
              )}
              {!newPrice && (
                <MerdadoPagoCheckout
                  isStorePickup={isStorePickup}
                  subTotal={parseFloat(total)}
                  deliveryPrice={deliveryPrice}
                  handleSuccess={handleOrderMercadoPago}
                />
              )}
              <div style={{ marginTop: '1.6rem' }}>
                <p className="nui-font-size-7">
                  {Traslate(ELEMENTS.PAYMENT.TERMNS)}{' '}
                  <RouterLink to={ROUTES.TERMS_CONDITIONS}>
                    {Traslate(ELEMENTS.PAYMENT.TERMNS_CONDITIONS)}
                  </RouterLink>
                </p>
              </div>
              <div
                className="nui-box nui-border-radius-medium"
                style={{ textAlign: 'center', marginTop: '2.4rem' }}>
                <div className="box-inner">
                  <p style={{ marginBottom: '0.8rem' }}>
                    {Traslate(ELEMENTS.PAYMENT.SECURITY)}
                  </p>
                  <div>
                    <VisaIcon style={{ fontSize: '4.2rem' }} />
                    <MasterCardIcon style={{ fontSize: '4.2rem', margin: '0 1rem' }} />
                    <AmericanExpressIcon style={{ fontSize: '4.2rem' }} />
                  </div>
                </div>
              </div>
            </Grid>
            <Drawer
              title="Datos de tarjeta"
              placement="right"
              onClose={handleModalClose}
              visible={modalState}>
              <form onSubmit={handleMembershipCulqiSuccess}>
                <pre>issuer: {JSON.stringify(issuer, null, 2)}</pre>
                <pre>cardFlag: {JSON.stringify(cardFlag, null, 2)}</pre>
                <div>
                  <label>Portador: </label>
                  <input
                    type="text"
                    name="cardholderName"
                    placeholder="Nome do portador"
                    value={formData.cardholderName}
                    onChange={handleChangeValueInput}
                  />
                </div>

                <div>
                  <label>E-mail: </label>
                  <input
                    type="email"
                    name="cardholderEmail"
                    placeholder="E-mail do portador"
                    value={formData.cardholderEmail}
                    onChange={handleChangeValueInput}
                  />
                </div>

                <div>
                  <label>Tipo de Documento: </label>
                  <select
                    name="identificationType"
                    value={formData.identificationType}
                    onChange={handleChangeValueInput}>
                    <option disabled value="">
                      Tipo de documento
                    </option>
                    {identificationTypeOptions.map((document) => (
                      <option value={document.value} key={document.value}>
                        {document.label}
                      </option>
                    ))}
                  </select>
                </div>

                <div>
                  <label>Número do Documento: </label>
                  <input
                    type="text"
                    name="identificationNumber"
                    placeholder="000.000.000-00"
                    value={formData.identificationNumber}
                    onChange={handleChangeValueInput}
                  />
                </div>

                <div>
                  <label>Número do Cartão: </label>
                  <input
                    type="text"
                    name="cardNumber"
                    placeholder="0000 0000 0000 0000"
                    value={formData.cardNumber}
                    onChange={(event) => {
                      checkCardDigits(event.target.value);
                      handleChangeValueInput(event);
                    }}
                  />
                </div>

                <div>
                  <label>CVV: </label>
                  <input
                    type="text"
                    name="securityCode"
                    placeholder="000"
                    value={formData.securityCode}
                    onChange={handleChangeValueInput}
                  />
                </div>

                <div>
                  <label>Mês: </label>
                  <select
                    name="cardExpirationMonth"
                    value={formData.cardExpirationMonth}
                    onChange={handleChangeValueInput}>
                    <option disabled value="">
                      Mês
                    </option>
                    {months.map((month) => (
                      <option value={month.value} key={month.value}>
                        {month.label}
                      </option>
                    ))}
                  </select>
                </div>

                <div>
                  <label>Ano: </label>
                  <select
                    name="cardExpirationYear"
                    value={formData.cardExpirationYear}
                    onChange={handleChangeValueInput}>
                    <option disabled value="">
                      Ano
                    </option>
                    {years.map((year) => (
                      <option value={year.value} key={year.value}>
                        {year.label}
                      </option>
                    ))}
                  </select>
                </div>

                <div>
                  <label>Parcelas: </label>
                  <select
                    name="installments"
                    value={formData.installments}
                    onChange={handleChangeValueInput}>
                    <option disabled value="">
                      Parcelas
                    </option>
                    {installmentOptions.map((installment) => (
                      <option value={installment.value} key={installment.value}>
                        {installment.label}
                      </option>
                    ))}
                  </select>
                </div>

                <button type="submit">Efetuar pagamento</button>
              </form>
            </Drawer>
          </Grid>
        </div>
      </Container>
      <Progress color="primary" loading={loading} />
      {notification.state && <Notification onClose={closeMessage} {...notification} />}
    </div>
  );
}

export default Payment;
