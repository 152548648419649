import React, { useContext } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Context } from 'context/Context';
import { useResponsive } from 'hooks';
import * as VARIABLES from 'constants/variables';
import * as ROUTES from 'constants/routes';
import './footer.scss';
// Components
import { Container, Grid, Button } from '@material-ui/core';
import {
  Copyright as CopyrightIcon,
  Facebook as FacebookIcon,
  Instagram as InstagramIcon,
  YouTube as YouTubeIcon,
} from '@material-ui/icons';
// Images
import BasicLogo from 'assets/images/logo/basic.png';
import IconLogo from 'assets/images/logo/icon.png';
import Book from 'assets/images/icons/book.png';
import Traslate from 'i18n/traslate';
import ELEMENTS from 'constants/elementsForTranslate';

const layoutLogo = { xs: 12, sm: 12, md: 4 };

const layoutItem = { xs: 12, sm: 6, md: 2 };

const SocialIcon = ({ url, icon }) => (
  <a className="icon-social" href={url} target="_blank" rel="noopener noreferrer">
    {icon}
  </a>
);

const Footer = (props) => {
  const { id, token } = useContext(Context);
  const { isMobile } = useResponsive();

  return (
    <footer className="cate-main-footer">
      <Container maxWidth="lg">
        <div className="cate-main-footer__cont">
          <Grid container spacing={3}>
            <Grid item {...layoutLogo}>
              <div className="cont-logo">
                <img src={isMobile ? IconLogo : BasicLogo} alt="Logo Cate Cafe" />
              </div>
            </Grid>
            {!isMobile && <Grid item {...layoutItem}></Grid>}
            <Grid item {...layoutItem}>
              <div className="cont-links">
                <h6>{Traslate(ELEMENTS.FOOTER.CONTACT)}</h6>
                <ul>
                  <li>{Traslate(ELEMENTS.FOOTER.STREET)}</li>
                  <li>contacto@cateperu.com</li>
                  <li>+ 51 962 340 342</li>
                </ul>
              </div>
            </Grid>
            <Grid item {...layoutItem}>
              <div className="cont-links">
                <h6>{Traslate(ELEMENTS.FOOTER.INFORMATION)}</h6>
                <ul>
                  <RouterLink className="nui-no-decoration" to={ROUTES.SHIPPING_RETURNS}>
                    <li>{Traslate(ELEMENTS.FOOTER.SHIPPING)}</li>
                  </RouterLink>
                  <RouterLink className="nui-no-decoration" to={ROUTES.TERMS_CONDITIONS}>
                    <li>{Traslate(ELEMENTS.FOOTER.TERMS)}</li>
                  </RouterLink>
                  <RouterLink className="nui-no-decoration" to={ROUTES.PRIVACY_POLICY}>
                    <li>{Traslate(ELEMENTS.FOOTER.PRIVACY)}</li>
                  </RouterLink>
                </ul>
              </div>
            </Grid>
            {(!token || !id) && (
              <Grid item {...layoutItem}>
                <div className="cont-action">
                  <div className="btn-auth">
                    <RouterLink className="nui-no-decoration" to={ROUTES.REGISTER}>
                      <Button variant="contained" color="primary" disableElevation>
                        {Traslate(ELEMENTS.FOOTER.REGISTER)}
                      </Button>
                    </RouterLink>
                  </div>
                  <div className="btn-auth">
                    <RouterLink className="nui-no-decoration" to={ROUTES.LOGIN}>
                      <Button variant="outlined" color="primary">
                        {Traslate(ELEMENTS.NAV.LOGIN)}
                      </Button>
                    </RouterLink>
                  </div>
                </div>
              </Grid>
            )}
            <Grid item {...layoutItem}>
              <div className="cont-links">
                <RouterLink to={ROUTES.BOOK_VIEW}>
                  <img src={Book} alt="Libro de reclamaciones" />
                </RouterLink>
              </div>
            </Grid>
          </Grid>
        </div>
        <div className="cate-main-footer__botton">
          <div className="botton-left">
            <span role="img" className="icon-copyright">
              <CopyrightIcon />
            </span>
            <span>{new Date().getFullYear() + ' Cate Perú Todos los derechos reservados'}</span>
          </div>
          <div className="botton-right">
            <span>{Traslate(ELEMENTS.FOOTER.FOLLOW)}</span>
            <SocialIcon url={VARIABLES.URL_FACEBOOK} icon={<FacebookIcon />} />
            <SocialIcon url={VARIABLES.URL_INSTAGRAM} icon={<InstagramIcon />} />
            <SocialIcon url={VARIABLES.URL_YOUTUBE} icon={<YouTubeIcon />} />
            {/* <SocialIcon url={VARIABLES.URL_PINTEREST} icon={<PinterestIcon />} /> */}
          </div>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
