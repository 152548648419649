import React, { useEffect } from 'react';
// Components
import { Container } from '@material-ui/core';

function ShippingReturns() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div style={{ padding: '4rem 0' }}>
      <Container maxWidth="lg">
        <h1 className="nui-font-size-2 nui-mg-b-2">Políticas de Envío y Devoluciones</h1>
        <div id="page_content">
          <p className="nui-mg-b-1">
            <strong>Devoluciones</strong>
          </p>
          <p>
            Si no te encuentras satisfecho con tu compra, ¡no te preocupes! Queremos que estés
            100% feliz con tu experiencia de compra en Cate Perú y por eso, te damos hasta 48
            hrs después de recibir la entrega de tu pedido para pedir devoluciones o cambios,
            sin preguntas.
          </p>
          <p>¿Cómo funciona el cambio de producto o la devolución de dinero?</p>
          <ul>
            <li>
              • Llevas el producto a una de nuestras tiendas en Lima y allí mismo puedes
              realizar el cambio por otro producto o solicitar la devolución de dinero. Tienes
              que llevar la boleta o el número del pedido y el número de DNI de la persona que
              hizo la compra.
            </li>
            <li>
              • Si no te encuentras en Lima, nos avisas por teléfono o correo para coordinar el
              envío del producto a cambiar o devolver mediante un courier. En este caso, tú como
              cliente tendrías que asumir el costo del envío del producto a nuestras oficinas en
              Lima (Calle Independencia 269, miraflores)
            </li>
            <li>
              • Si es una devolución, te devolvemos el costo del producto en crédito en la
              tienda o si prefieres, en dinero a la tarjeta de crédito con la que hiciste tu
              compra o mediante transferencia bancaria si el pago original fue realizado en
              transferencia o efectivo. La devolución del dinero se realiza en los 5 días
              laborables de recibir el producto en la tienda. Los costos de envío no son
              reembolsables.
            </li>
          </ul>
          <p className="nui-mg-b-1 nui-mg-t-1">
            <strong>Costo de Delivery</strong>
          </p>
          <p>
            ¡Ofrecemos DELIVERY GRATIS en Lima por compras al por mayor! La promoción está
            sujeta a una cantidad mínima establecida por producto.
          </p>

          <p className="nui-mg-b-1 nui-mg-t-1">
            <strong>Condiciones de Envío</strong>
          </p>
          <p>
            Cate Perú realizará hasta 2 intentos de entregar el pedido al lugar de entrega
            indicado por el cliente. En caso no se pueda entregar el pedido, se le notificará al
            cliente vía correo electrónico para que lo pase a recoger, de lo contrario se
            anulará el pedido. No necesariamente Cate Perú realizará previa coordinación antes
            de realizar la entrega, excepto el email de confirmación que recibirá el cliente al
            momento de hacer la compra. Cate Perú se reserva el derecho de solicitar al cliente
            que cambie la dirección de envío o de anular un pedido por motivos de seguridad de
            nuestros repartidores, direcciones inexactas, que presenten dificultad para llegar o
            cualquier motivo que consideremos apropiado. Las entregas se realizarán en el
            domicilio indicado por el cliente y se entregará el pedido a cualquier persona que
            dentro del domicilio reciba el producto excepto indicación explícita y por escrito
            del cliente. En el caso de edificios multifamiliares o de oficinas, el pedido se
            podrá entregar al portero, conserje, mesa de partes o similar. Si el cliente desea
            que el pedido se le entregue únicamente a quien hizo la compra o a una persona
            específica, deberá enviar un email indicando el número del pedido y el nombre y el
            DNI de la o las personas autorizadas a recibir el pedido. El costo de envío NO será
            reembolsado, excepto cuando se trate de un producto que Cate Perú haya confirmado
            como con defecto de fábrica o en caso de haber enviado el producto equivocado.
          </p>

          <p className="nui-mg-b-1 nui-mg-t-1">
            <strong>Plazos de Entrega</strong>
          </p>
          <p>
            En Cate Perú intentamos entregar los pedidos a nuestros lo ántes posible. Entendemos
            que para los clientes es importante tener un estimado confiable sobre cuándo
            recibirán su pedido por lo que en la mayoría de casos ofrecemos un rango de tiempo
            estimado de entrega del pedido. Este rango es solamente un estimado y la fecha real
            de entrega del pedido podría variar. Por lo general entregamos pedidos en un plazo
            menor a 3 días laborables en Lima Metropolitana y menos de 10 días laborables en
            Provincias.
          </p>
          <p className="nui-mg-b-1 nui-mg-t-1">
            <strong>Recepción y revisión del producto</strong>
          </p>
          <p>
            Es responsabilidad del cliente revisar que el producto se encuentre en perfectas
            condiciones y con todas sus piezas completas, incluyendo los manuales, garantía,
            repuestos y otros. Antes de ensamblar, abrir o utilizar un producto, el cliente es
            responsable por leer el manual de instrucciones o instalación. De recibir el
            producto y notar alguna pieza faltante, el cliente deberá informar a Cate Perú
            enviando un email a <a href="mailto:contacto@cateperu.com">contacto@cateperu.com</a>{' '}
            acerca del problema, a más tardar 48 hrs días calendario luego de haber recibido el
            producto. De no recibir una comunicación por un producto incompleto en ese plazo, el
            producto se considerará completo y cualquier pieza faltante será responsabilidad del
            cliente.
          </p>
        </div>
      </Container>
    </div>
  );
}

export default ShippingReturns;
