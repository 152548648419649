import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { experienceService, locationService } from 'services';
import { Context } from 'context/Context';
import { useNotification } from 'hooks';
import * as VARIABLES from 'constants/variables';
import './userExperiencesDetail.scss';
// Components
import { Notification, CatePaper, Progress } from 'components';
import { Container, Grid, Button } from '@material-ui/core';
import { ClockDesignIcon, CalendarDesignIcon, ShoppingBagDesignIcon } from 'icons';
import KeyboardTabRoundedIcon from '@material-ui/icons/KeyboardTabRounded';
import { Skeleton } from '@material-ui/lab';

function UserExperiencesDetail(props) {
  const resId = props.match.params.id;
  let query = new URLSearchParams(useLocation().search);
  let status = query.get('collection_status');
  let paymentId = query.get('payment_id');
  const { id } = useContext(Context);
  const { notification, createMessage, closeMessage } = useNotification();
  const [loading, setLoading] = useState(true);
  const [updateloading, setUpdateLoading] = useState(false);
  const [error, setError] = useState(false);
  const [reservationObj, setReservationObj] = useState({
    experience: {},
    user: {},
  });
  const [shipping, setShipping] = useState({});

  const fetchReservationById = async () => {
    setLoading(true);
    const res = await experienceService.showBooking(resId);
    if (!res.info.error) {
      if (res.data.user_id + '' !== id) {
        setError(true);
      }
      setReservationObj(res.data);
      fetchDistrictPriceByName(res.data.district);
    } else {
      createMessage.error(res.info.message);
    }
    setLoading(false);
  };

  const fetchReservationUpdate = async () => {
    setUpdateLoading(true);
    const res = await experienceService.updateBooking({
      reservation_id: resId,
      status: status,
      payment_id: paymentId,
    });
    if (!res.info.error) {
      createMessage.success('Experiencia actualizada');
    } else {
      createMessage.error(res.info.message);
    }
    setUpdateLoading(false);
  };

  const fetchDistrictPriceByName = async (district) => {
    const res = await locationService.getdistrictPriceByName(district);
    if (!res.info.error) {
      setShipping(res.data);
    }
  };

  useEffect(() => {
    if (status === 'approved' && paymentId) {
      fetchReservationUpdate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, paymentId]);

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchReservationById();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (error) {
    return (
      <div className="cate-reservation-detail">
        <Container maxWidth="lg">
          <div className="page-title">
            <h3>No tiene los permisos para ver esta reserva</h3>
          </div>
        </Container>
      </div>
    );
  }

  return (
    <div className="cate-reservation-detail">
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <CatePaper disableElevation className="reservation-detail-card">
              <div className="detail-info content-space">
                <h6>Reserva #{resId}</h6>
              </div>
              <div className="content-space">
                <p className="text-overline">Usuario</p>
                <p className="text-body">
                  {reservationObj.user?.name + ' ' + reservationObj.user?.last_name}
                </p>
                <p className="text-body">{reservationObj.user?.email}</p>
                <p className="text-body">
                  {'Telefono: +' +
                    reservationObj.user?.phone_code +
                    ' - ' +
                    reservationObj.user?.phone_number}
                </p>
              </div>
              <div className="content-space">
                <p className="text-overline">Dirección</p>
                <p className="text-body">{reservationObj.address}</p>
                <p className="text-body">
                  {reservationObj.department}, {reservationObj.province},{' '}
                  {reservationObj.district}
                </p>
                <p className="text-body">Perú</p>
              </div>
              <div>
                {/* <dl className="info-price">
                  <dt>Subtotal</dt>
                  <dd>S/. {parseFloat(reservationObj.total) - parseFloat(shipping.price)}</dd>
                </dl> */}
                {/* <dl className="info-price">
                  <dt>Costo del despacho</dt>
                  <dd>S/. {parseFloat(shipping.price)}</dd>
                </dl> */}
                <dl className="info-price price-final">
                  <dt>Total</dt>
                  <dd>S/. {parseFloat(reservationObj.total)}</dd>
                </dl>
              </div>
            </CatePaper>
          </Grid>
          <Grid item xs={12} md={8}>
            <CatePaper disableElevation className="experience-detail-card">
              <div className="experience-detail-card__cover">
                <img
                  className="cover-image"
                  src={VARIABLES.IMAGE_PATH + reservationObj.experience?.image_path}
                  alt={reservationObj.experience?.title}
                />
              </div>
              <div className="experience-detail-card__warp">
                <div className="experience-detail-card__title">
                  <span>{reservationObj.experience?.title}</span>
                </div>
                <div className="experience-detail-card__category">
                  <div className="category-group">
                    <div className="category-item">
                      <ClockDesignIcon />
                      <div className="category-item__text">
                        <p>Duracion: </p>
                        <h6>
                          {reservationObj.experience?.duration_time
                            ? reservationObj.experience?.duration_time
                            : '...'}
                        </h6>
                      </div>
                    </div>
                    <div className="category-item">
                      <CalendarDesignIcon />
                      <div className="category-item__text">
                        <p>Dia: </p>
                        <h6>
                          {reservationObj.experience?.meeting_date
                            ? reservationObj.experience?.meeting_date
                            : '...'}
                        </h6>
                      </div>
                    </div>
                    {reservationObj.experience?.has_products && (
                      <div className="category-item">
                        <ShoppingBagDesignIcon />
                        <div className="category-item__text">
                          <p>Incluye: </p>
                          <h6>productos</h6>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {reservationObj.experience.meeting_link && reservationObj.payment_id && (
                  <div className="experience-detail-card__link">
                    <a
                      className="nui-no-decoration"
                      href={reservationObj.experience.meeting_link}
                      target="_blank"
                      rel="noopener noreferrer">
                      <Button
                        disableElevation
                        variant="contained"
                        color="primary"
                        endIcon={<KeyboardTabRoundedIcon />}>
                        Ir a la reunión
                      </Button>
                    </a>
                  </div>
                )}
                <div className="experience-detail-card__post">
                  {!loading ? (
                    <div
                      className="nui-content-post"
                      dangerouslySetInnerHTML={{
                        __html: reservationObj.experience?.description,
                      }}
                    />
                  ) : (
                    <div>
                      <Skeleton height={24} />
                      <Skeleton height={24} />
                      <Skeleton height={24} />
                      <Skeleton height={24} />
                      <Skeleton height={24} width="40%" />
                      <Skeleton height={24} />
                      <Skeleton height={24} />
                      <Skeleton height={24} width="25%" />
                    </div>
                  )}
                </div>
                {reservationObj.experience.video_path && (
                  <div className="experience-detail-card__video">
                    <video controls controlsList="nodownload noremoteplayback">
                      <source
                        src={VARIABLES.IMAGE_PATH + reservationObj.experience?.video_path}
                        type="video/mp4"
                      />
                    </video>
                  </div>
                )}
              </div>
            </CatePaper>
            <div className="experience-detail-gallery">
              <Grid container spacing={3}>
                {!loading ? (
                  <>
                    {reservationObj?.experience?.files?.map((item, index) => (
                      <Grid item key={index} xs={12} sm={6} md={4}>
                        <CatePaper disableElevation className="galllery-item">
                          <img
                            src={VARIABLES.IMAGE_PATH + item.file_path}
                            alt={reservationObj.experience?.title}
                          />
                        </CatePaper>
                      </Grid>
                    ))}
                  </>
                ) : (
                  <>
                    {Array.from(new Array(6)).map((data, index) => (
                      <Grid item key={index} xs={12} sm={6} md={4}>
                        <CatePaper disableElevation disableBorder className="galllery-item">
                          <div>
                            <Skeleton height={224} width="100%" />
                          </div>
                        </CatePaper>
                      </Grid>
                    ))}
                  </>
                )}
              </Grid>
            </div>
          </Grid>
        </Grid>
      </Container>
      <Progress color="primary" loading={updateloading} />
      {notification.state && <Notification onClose={closeMessage} {...notification} />}
    </div>
  );
}

export default UserExperiencesDetail;
