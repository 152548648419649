import SvgIcon from '@material-ui/core/SvgIcon';

const ShoppingBagDesignSvg = () => (
  <>
    <g>
      <g>
        <g>
          <path
            d="M390.802,342.188l-31.2-224c-2.8-20-19.6-34.4-39.6-34.4h-37.2c-2.4-44.4-39.6-80-84.8-80h-6c-45.2,0-82,35.6-84.4,80
				h-36.4c-20,0-37.2,14.8-39.6,34.4l-31.2,224c-1.6,11.6,1.6,23.2,9.2,31.6c7.6,8.8,18.8,13.6,30.4,13.6h311.2
				c11.6,0,22.4-5.2,30-14C388.802,364.988,392.402,353.788,390.802,342.188z M192.002,19.788h6c36.4,0,66.4,28.4,68.8,64h-143.2
				C126.002,48.188,155.602,19.788,192.002,19.788z M369.202,363.388c-4.4,5.2-11.2,8.4-18,8.4h-311.2c-7.2,0-13.6-3.2-18-8.4
				c-4.4-5.2-6.4-12-5.6-19.2l0.8-4.8h214.8c4.4,0,8-3.6,8-8c0-4.4-3.6-8-8-8h-212.8l28.4-203.6c1.6-12,11.6-20.4,23.6-20.4h36v28
				c0,4.4,3.6,8,8,8c4.4,0,8-3.6,8-8v-28h144v28c0,4.4,3.6,8,8,8c4.4,0,8-3.6,8-8v-28h36.8c12,0,22,8.8,23.6,20.8l31.2,224
				C376.002,351.388,374.002,358.188,369.202,363.388z"
          />
          <path
            d="M279.202,323.788h-12c-4.4,0-8,3.6-8,8c0,4.4,3.6,8,8,8h12c4.4,0,8-3.6,8-8
				C287.202,327.388,283.602,323.788,279.202,323.788z"
          />
        </g>
      </g>
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </>
);

const ShoppingBagDesign = (props) => (
  <SvgIcon
    viewBox="0 0 391.176 391.176"
    style={{ enableBackground: 'new 0 0 391.176 391.176' }}
    xmlSpace="preserve"
    {...props}>
    <ShoppingBagDesignSvg />
  </SvgIcon>
);

export default ShoppingBagDesign;
