import React from 'react';
import { CulqiProvider, Culqi } from 'react-culqi';
import PropTypes from 'prop-types';
import * as VARIABLES from 'constants/variables';
// Components
import { RedButton } from 'components';

const culqiProps = {
  publicKey: VARIABLES.CULQI_PUBLIC_KEY,
  // title: 'Cate Tasting Room',
  options: {
    style: {
      maincolor: '#ff9a55',
      logo: 'https://cate-cafe-web.cate-cafe.vercel.app/static/media/basic.d0b612fe.png',
    },
  },
};

function CulqiCheckout(props) {
  // eslint-disable-next-line no-unused-vars
  const { subTotal, deliveryPrice, culqiPayment, handleCulqiSuccess, handleCulqiError } = props;

  return (
    <CulqiProvider
      // amount={culqiPayment}
      onToken={handleCulqiSuccess}
      onError={handleCulqiError}
      {...culqiProps}>
      <Culqi>
        {({ openCulqi, setAmount, amount }) => (
          <div>
            <div className="overview-title">
              <h3>Resumen del pedido</h3>
            </div>
            <div className="overview-body">
              <div className="price-group">
                <dl>
                  <dt>Costo de Producto</dt>
                  <dd>S/. {subTotal}</dd>
                </dl>
              </div>
              <div className="price-group">
                <dl>
                  <dt>Costo de envío</dt>
                  <dd>S/. {deliveryPrice}</dd>
                </dl>
              </div>
            </div>
            <div className="overview-total">
              <dl>
                <dt>Total</dt>
                <dd>S/. {subTotal + deliveryPrice}</dd>
              </dl>
            </div>
            <div className="overview-btn">
              <RedButton
                fullWidth
                disableElevation
                variant="contained"
                color="primary"
                onClick={openCulqi}>
                PROCEDER AL PAGO
              </RedButton>
            </div>
          </div>
        )}
      </Culqi>
    </CulqiProvider>
  );
}

CulqiCheckout.propTypes = {
  subTotal: PropTypes.number,
  deliveryPrice: PropTypes.number,
  culqiPayment: PropTypes.number,
  handleCulqiSuccess: PropTypes.func.isRequired,
  handleCulqiError: PropTypes.func.isRequired,
};

export default CulqiCheckout;
