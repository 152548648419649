import React from 'react';
import { useHistory } from 'react-router-dom';
import * as ROUTES from 'constants/routes';
import PropTypes from 'prop-types';
import './authDialog.scss';
// Components
import { Button, Dialog } from '@material-ui/core';

function AuthDialog(props) {
  const { open, onClose, location } = props;
  const history = useHistory();

  return (
    <Dialog open={open} maxWidth="xs" onClose={onClose} className="cate-auth-dialog">
      <div className="auth-dialog-wrap">
        <div className="auth-dialog-wrap__title">
          <h4>¡Hola! Para continuar con la compra, ingresa a tu cuenta</h4>
        </div>
        <Button
          disableElevation
          color="primary"
          variant="contained"
          onClick={() =>
            history.push({ pathname: ROUTES.REGISTER, state: { from: location } })
          }>
          Soy nuevo
        </Button>
        <Button
          disableElevation
          color="primary"
          onClick={() => history.push({ pathname: ROUTES.LOGIN, state: { from: location } })}>
          Ya tengo cuenta
        </Button>
      </div>
    </Dialog>
  );
}

AuthDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  location: PropTypes.string.isRequired,
};

export default AuthDialog;
