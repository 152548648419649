import React from 'react';
import './banner.scss';
// Images
import MobilePost from 'assets/images/background/mobile-posts.png';

function Banner(props) {
  return (
    <div className="cate-blog-banner">
      <div className="cate-blog-banner__background">
        <img src={MobilePost} alt="blog banner" />
        <div className="background-content">
          <h1 className="background-content__title">Blog</h1>
          <p className="background-content__desc">
            Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia
            consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro
            quisquam est, qui dolorem.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Banner;
