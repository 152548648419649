import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import './progress.scss';
// components
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStylesCustom = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  bottom: {
    color: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  top: {
    color: '#ff9a55',
    animationDuration: '550ms',
    position: 'absolute',
    left: 0,
  },
  circle: {
    strokeLinecap: 'round',
  },
}));

const CustomCircularProgress = (props) => {
  const classes = useStylesCustom();

  return (
    <div className={classes.root}>
      <CircularProgress
        variant="determinate"
        className={classes.bottom}
        size={48}
        thickness={4}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        className={classes.top}
        classes={{
          circle: classes.circle,
        }}
        size={48}
        thickness={4}
        {...props}
      />
    </div>
  );
};

function Progress(props) {
  const { loading } = props;

  const handleBodyStyleClose = () => {
    document.body.style.overflowY = 'hidden';
    document.body.style.width = 'calc(100% - 17px)';
  };

  const handleBodyStyleOpen = () => {
    document.body.style.overflowY = '';
    document.body.style.width = '';
  };

  useEffect(() => {
    if (loading) {
      handleBodyStyleClose();
    } else {
      handleBodyStyleOpen();
    }
  }, [loading]);

  return (
    <React.Fragment>
      {loading && (
        <div className="cate-progress">
          <div className="cate-progress__background"></div>
          <div className="cate-progress__wrap">
            <CustomCircularProgress />
          </div>
        </div>
      )}
    </React.Fragment>
  );
}

Progress.propTypes = {
  loading: PropTypes.bool.isRequired,
};

export default Progress;
