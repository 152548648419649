import configService from './config';
import fetchApi from './fetchApi';
import * as METHODS from './methods';

const baseUrl = configService.apiUrl;
const blogService = {};

blogService.list = async function () {
  const { response } = await fetchApi({
    url: baseUrl + '/blog',
    options: METHODS.GET(),
    dataType: [],
    message: {
      success: 'Lista de blogs obtenida con éxito.',
      error: 'No se pudo obtener la lista de blogs.',
    },
  });

  return { ...response };
};

blogService.listRecent = async function () {
  const { response } = await fetchApi({
    url: baseUrl + '/blog/recent',
    options: METHODS.GET(),
    dataType: [],
    message: {
      success: 'Lista de blogs obtenida con éxito.',
      error: 'No se pudo obtener la lista de blogs.',
    },
  });

  return { ...response };
};

blogService.listByCategory = async function (categoryId) {
  const { response } = await fetchApi({
    url: baseUrl + '/blog/category/' + categoryId,
    options: METHODS.GET(),
    dataType: [],
    message: {
      success: 'Lista de blogs obtenida con éxito.',
      error: 'No se pudo obtener la lista de blogs.',
    },
  });

  return { ...response };
};

blogService.showBySlug = async function (slug) {
  const { response } = await fetchApi({
    url: baseUrl + '/blog/' + slug,
    options: METHODS.GET(),
    dataType: {},
    message: {
      success: 'Blog obtenido con éxito.',
      error: 'No se pudo obtener del blog solicitado.',
    },
  });

  return { ...response };
};

export default blogService;
