import configService from './config';
import fetchApi from './fetchApi';
import * as METHODS from './methods';

const baseUrl = configService.apiUrl;
const orderService = {};

orderService.listByUser = async function (userId) {
  const { response } = await fetchApi({
    url: baseUrl + '/orders/' + userId,
    options: METHODS.GET(),
    dataType: [],
    message: {
      success: 'Lista de ordenes obtenida con éxito.',
      error: 'No se pudo obtener la lista de ordenes.',
    },
  });

  return { ...response };
};

orderService.show = async function (orderId) {
  const { response } = await fetchApi({
    url: baseUrl + '/orders/show/' + orderId,
    options: METHODS.GET(),
    dataType: {},
    message: {
      success: 'Detalle de la orden obtenido con éxito.',
      error: 'No se pudo obtener el detalle de la orden.',
    },
  });

  return { ...response };
};

orderService.create = async function (arg) {

  const { response } = await fetchApi({
    url: baseUrl + '/shopping-cart',
    options: METHODS.POST({
      user_id: arg.userId,
      department: arg.department,
      province: arg.province,
      district: arg.district,
      address: arg.address,
      delivery_price: arg.deliveryPrice,
      products: arg.products,
      is_invoice: arg.is_invoice,
      ruc: arg.ruc,
      business_name: arg.business_name,
      business_address: arg.business_address,
    }),
    dataType: {},
    message: {
      success: 'Orden creada con éxito.',
      error: 'No se pudo crear la orden.',
    },
  });

  return { ...response };
};

orderService.createPickupStore = async function (arg) {

  const { response } = await fetchApi({
    url: baseUrl + '/shopping-cart',
    options: METHODS.POST({
      user_id: arg.userId,
      is_store_pickup: 1,
      date_pickup: arg.datePickup,
      time_pickup: arg.timePickup,
      delivery_price: 0,
      products: arg.products,
      is_invoice: arg.is_invoice,
      ruc: arg.ruc,
      business_name: arg.business_name,
      business_address: arg.business_address,
    }),
    dataType: {},
    message: {
      success: 'Orden creada con éxito.',
      error: 'No se pudo crear la orden.',
    },
  });

  return { ...response };
};

orderService.update = async function (arg) {
  const { response } = await fetchApi({
    url: baseUrl + '/shopping-cart/update',
    options: METHODS.POST({
      order_id: arg.order_id,
      status: arg.status,
      payment_id: arg.payment_id,
      merchant_order_id: arg.merchant_order_id
    }),
    dataType: {},
    message: {
      success: 'Orden actualizada con éxito.',
      error: 'No se pudo actualizar la orden.',
    },
  });

  return { ...response };
};

export default orderService;
