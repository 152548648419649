import configService from './config';
import fetchApi from './fetchApi';
import * as METHODS from './methods';

const baseURL = `${configService.apiUrl}/subscriptions/plans`;
const baseAdminURL = `${configService.apiUrlAdmin}/subscriptions/plans`;

const SubscriptionsPlans = {};

SubscriptionsPlans.list = async function () {
  const { response } = await fetchApi({
    url: `${baseURL}`,
    options: METHODS.GET(),
    dataType: [],
    message: {
      success: 'Lista de planes obtenida con éxito',
      error: 'No se pudo obtener la lista de planes.',
    },
  });

  return { ...response };
};

SubscriptionsPlans.create = async function (data) {
  const { response } = await fetchApi({
    url: baseURL,
    options: METHODS.POST(data),
    dataType: {},
    message: {
      success: 'Plan creado con éxito.',
      error: 'No se pudo crear el plan.',
    },
  });

  return { ...response };
};

SubscriptionsPlans.update = async function (data) {
  const { id } = data;

  const { response } = await fetchApi({
    url: `${baseURL}/${id}`,
    options: METHODS.PUT(data),
    dataType: {},
    message: {
      success: 'Plan actualizado con éxito.',
      error: 'No se pudo actualizar el plan.',
    },
  });

  return { ...response };
};

SubscriptionsPlans.updateEnabled = async function (data) {
  const { id } = data;

  const enabledData = {
    enabled: data.enabled,
  };

  const { response } = await fetchApi({
    url: `${baseURL}/${id}/status`,
    options: METHODS.PUT(enabledData),
    dataType: {},
    message: {
      success: 'Plan actualizado con éxito.',
      error: 'No se pudo actualizar el plan.',
    },
  });

  return { ...response };
};

SubscriptionsPlans.getWithId = async function (id) {
  const { response } = await fetchApi({
    url: baseAdminURL + '/' + id,
    options: METHODS.GET(),
    dataType: {},
    message: {
      success: 'Suscripción obtenida con éxito.',
      error: 'No se pudo obtener la suscripción solicitada.',
    },
  });

  return { ...response };
};

export default SubscriptionsPlans;
