import ELEMENTS from 'constants/elementsForTranslate';
import { LOCALES } from '../locales';

const esLa = {
  [LOCALES.SPANISH]: {
    [ELEMENTS.NAV.HOME]: 'Inicio',
    [ELEMENTS.NAV.PRODUCTS]: 'Productos',
    [ELEMENTS.NAV.SHOP]: 'Tienda',
    [ELEMENTS.NAV.ABOUT]: 'Nosotros',
    [ELEMENTS.NAV.MEMBERSHIPS]: 'Suscripciones',
    [ELEMENTS.NAV.EXPERIENCES]: 'Experiencias de cata',
    [ELEMENTS.NAV.SUCURSALES]: 'Sucursales',
    [ELEMENTS.NAV.HELLO]: 'Hola!',
    [ELEMENTS.NAV.OTHERS]: 'Recursos',
    [ELEMENTS.NAV.COURSES]: 'Cursos',
    [ELEMENTS.NAV.LOGIN]: 'Iniciar Sesión',

    [ELEMENTS.NAV.MANAGE]: 'Administra tu cuenta',
    [ELEMENTS.NAV.CHANGE_PASSWORD]: 'Cambio de Clave',
    [ELEMENTS.NAV.MY_SHOPPING]: 'Mis compras',
    [ELEMENTS.NAV.MY_EXPERIENCES]: 'Mis experiencias',
    [ELEMENTS.NAV.MY_MEMBERSHIPS]: 'Mis membresías',
    [ELEMENTS.NAV.LOGOUT]: 'Cerrar Sesión',

    // BANNER
    [ELEMENTS.BANNER.SLOGAN]: 'Los sabores del Perú {component} en un solo lugar.',
    [ELEMENTS.BANNER.SHOP]: 'Comprar',

    // RECOMMENDED PRODUCTS
    [ELEMENTS.RECOMMEDED_PRODUCTS.TITLE]: 'Nuestros productos recomendados',

    // CAROUSEL
    [ELEMENTS.CAROUSEL.TITLE]: 'Nuestros productos',
    [ELEMENTS.CAROUSEL.SUBTITLE]: 'Consigue lo que necesitas',

    // ABOUT
    [ELEMENTS.ABOUT.PARAGRAPH]:
      'Comprar en nuestra tienda es súper fácil rápido y seguro. Y lo mejor es que no tienes que salir de casa, nosotros nos encargamos de llegar a tu destino.',
    [ELEMENTS.ABOUT.TITLE]: 'Realiza tu pedido y recíbelo en casa',

    //SUBSCRIBE
    [ELEMENTS.SUBSCRIBE.TITLE]: 'Suscríbete a Cate Tasting Room',
    [ELEMENTS.SUBSCRIBE.SUBTITLE]:
      'Obten tu suscripción y accede a los beneficios de Cate Tasting Room.',
    [ELEMENTS.SUBSCRIBE.BUTTON]: 'Súscríbete',

    // FOOTER
    [ELEMENTS.FOOTER.CONTACT]: 'Contacto',
    [ELEMENTS.FOOTER.STREET]: 'Calle Independencia 269 Miraflores',
    [ELEMENTS.FOOTER.INFORMATION]: 'Información de interés',
    [ELEMENTS.FOOTER.SHIPPING]: 'Envíos y Devoluciones',
    [ELEMENTS.FOOTER.TERMS]: 'Terminos y Condiciones',
    [ELEMENTS.FOOTER.PRIVACY]: 'Política de Privacidad',
    [ELEMENTS.FOOTER.FOLLOW]: 'Siguenos en ',
    [ELEMENTS.FOOTER.REGISTER]: 'Regístrate',

    // PRODUCTS
    [ELEMENTS.PRODUCTS.CATEGORIES]: 'Categorías',
    [ELEMENTS.PRODUCTS.ALL]: 'Todo',
    [ELEMENTS.PRODUCTS.LOOK]: 'Ver producto',
    [ELEMENTS.PRODUCTS.UNIT]: 'Unidad',
    [ELEMENTS.PRODUCTS.NEW]: 'Nuevo',
    [ELEMENTS.PRODUCTS.PRODUCT]: 'PRODUCTO',
    [ELEMENTS.PRODUCTS.SOLD_OUT]: 'AGOTADO',

    // PRODUCTS DETAIL
    [ELEMENTS.PRODUCT_DETAIL.BACK]: 'Atrás',
    [ELEMENTS.PRODUCT_DETAIL.UNITS_AVAILABLE]: 'Unidades disponibles',
    [ELEMENTS.PRODUCT_DETAIL.AMOUNT]: 'Cantidad',
    [ELEMENTS.PRODUCT_DETAIL.WHOLESALE]:
      'Por la compra de 6 unidades o más el precio unitario es de: ',
    [ELEMENTS.PRODUCT_DETAIL.TYPE_GROUND]:
      '¿Deseas moler tu café? Elige el tipo de molido que más prefieras: ',
    [ELEMENTS.PRODUCT_DETAIL.ADD_CART]: 'Añadir a la cesta',
    [ELEMENTS.PRODUCT_DETAIL.USER_RATING]: 'Calificación de clientes',
    [ELEMENTS.PRODUCT_DETAIL.GLOBAL_RATING]: 'Calificaciones globales',
    [ELEMENTS.PRODUCT_DETAIL.SPECIFICATIONS]: 'Especificaciones',
    [ELEMENTS.PRODUCT_DETAIL.HEIGHT]: 'Alto',
    [ELEMENTS.PRODUCT_DETAIL.WIDTH]: 'Ancho',
    [ELEMENTS.PRODUCT_DETAIL.DEPTH]: 'Profundidad',
    [ELEMENTS.PRODUCT_DETAIL.WEIGHT]: 'Peso',
    [ELEMENTS.PRODUCT_DETAIL.RATINGS_COMMENTS]:
      'Calificaciones y comentarios acerca del producto',
    [ELEMENTS.PRODUCT_DETAIL.LET_RATING]: 'Dejar reseña',
    [ELEMENTS.PRODUCT_DETAIL.RATING]: 'Calificación',
    [ELEMENTS.PRODUCT_DETAIL.COMMENT]: 'Reseña',
    [ELEMENTS.PRODUCT_DETAIL.SEND_COMMENT]: 'Enviar reseña',
    [ELEMENTS.PRODUCT_DETAIL.LIST_COMMENTS]: 'Listado de comentarios',
    [ELEMENTS.PRODUCT_DETAIL.ENTER_REVIEW]: 'Ingrese reseña',

    // MEMBERSHIPS
    [ELEMENTS.MEMBERSHIPS.TITLE]:
      'Selecciona un plan y unete a la familia de Cate Tasting Room',
    [ELEMENTS.MEMBERSHIPS.SUBTITLE]:
      'Obten tu suscripción y accede a los beneficios de Cate Tasting Room.',
    [ELEMENTS.MEMBERSHIPS.FOOTER_MESSAGE]:
      '*Los envíos se realizarán al tercer día luego de haber confirmado la suscripción',

    [ELEMENTS.MEMBERSHIPS.SUBSCRIBE]: 'Suscríbete',
    [ELEMENTS.MEMBERSHIPS.BUTTON]: 'Suscríbete',

    [ELEMENTS.MEMBERSHIPS.MODAL.SELECT]: 'Selecciona categoría y producto',
    [ELEMENTS.MEMBERSHIPS.MODAL.CATEGORY]: 'Categoría',
    [ELEMENTS.MEMBERSHIPS.MODAL.PRODUCT]: 'Productos',
    [ELEMENTS.MEMBERSHIPS.MODAL.PRODUCT_SELECT]:
      'Seleccione una categoría para obtener los productos',
    [ELEMENTS.MEMBERSHIPS.MODAL.DEPARTMENT]: 'Departamentos',
    [ELEMENTS.MEMBERSHIPS.MODAL.PROVINCIA]: 'Provincias',
    [ELEMENTS.MEMBERSHIPS.MODAL.PROVINCIA_SELECT]:
      'Seleccione un departamento para obtener las provincias',
    [ELEMENTS.MEMBERSHIPS.MODAL.DISTRICT]: 'Distritos',
    [ELEMENTS.MEMBERSHIPS.MODAL.DISTRICT_SELECT]:
      'Seleccione una provincia para obtener los distritos',
    [ELEMENTS.MEMBERSHIPS.MODAL.LADING]: 'Cargando...',

    // SUCURSALES
    [ELEMENTS.BRANCHES.TITLE]: 'Sucursales',
    [ELEMENTS.BRANCHES.ADDRESS]: 'Dirección',
    [ELEMENTS.BRANCHES.REFERENCE]: 'Referencia',
    [ELEMENTS.BRANCHES.PHONE]: 'Teléfono',
    [ELEMENTS.BRANCHES.LOOK_UBICATION]: 'Ver ubicación',

    // About
    [ELEMENTS.ABOUT.PARAGRAPH1_L1]:
      'Nosotros, con la ayuda de nuestro catador y consultor Q-Grader, Henrry Guillén,',
    [ELEMENTS.ABOUT.PARAGRAPH1_L2]:
      'calificamos cada lote de café en referencia a 10 atributos en una escala del 1',
    [ELEMENTS.ABOUT.PARAGRAPH1_L3]:
      'al 10, de esta forma el café perfecto tendría 100 puntos. Manejamos dos marcas',
    [ELEMENTS.ABOUT.PARAGRAPH1_L3]:
      'al 10, de esta forma el café perfecto tendría 100 puntos. Manejamos dos marcas',
    [ELEMENTS.ABOUT.PARAGRAPH1_L4]:
      'de café de especialidad: Bitácora para los cafés de más de 84 puntos y White Cup',
    [ELEMENTS.ABOUT.PARAGRAPH1_L5]: 'para los de menor puntaje, que llamamos de Taza Limpia.',
    [ELEMENTS.ABOUT.PARAGRAPH2]:
      'Henry Guillén, catador Q-Grader, Jurado nacional de los años 2017, 2018, 2019,y 2020 del concurso Taza de Excelencia Perú.',
    [ELEMENTS.ABOUT.PARAGRAPH3]:
      '(Existe un sistema de evaluación aceptado mundialmente que es el sistema SCAA. Solo una persona certificada como Q-Grader por el Coffee Quality Institute tiene la autoridad para otorgarla)',
    [ELEMENTS.ABOUT.PARAGRAPH4]:
      'Nosotros buscamos traer al mercado chocolate peruano de las diferentes zonas del país y recrear las sinfonías con las especias de nuestro medio llevando chocolates saborizados que prueben su valía en una taza como lo hacían antaño nuestros ancestros. Así como el té tiene su TEA TIME, o el café su COFFEE SHOP, creemos que ha llegado el momento de reunir al chocolate con sus pares en un CHOC TIME.',
    [ELEMENTS.ABOUT.PARAGRAPH5_TITLE]: 'Aliados Productores',
    [ELEMENTS.ABOUT.PARAGRAPH5_L1]:
      'Impartimos capacitaciones y verificamos el trabajo de los productores sobre cómo',
    [ELEMENTS.ABOUT.PARAGRAPH5_L2]:
      'elaborar café de especialidad para establecer sólidas prácticas y compartir',
    [ELEMENTS.ABOUT.PARAGRAPH5_L3]: 'información entre todos los actores.',
    [ELEMENTS.ABOUT.FIND_US]: 'Nos encuentras en',
    [ELEMENTS.EXPERIENCES.TITLE]: 'Experiencias',
    [ELEMENTS.EXPERIENCES.EXPIRED]: 'Expirado',
    [ELEMENTS.EXPERIENCES.FROM]: 'Desde',

    // EXPERIENCES DETAIL
    [ELEMENTS.EXPERIENCES_DETAIL.DURATION]: 'Duración: ',
    [ELEMENTS.EXPERIENCES_DETAIL.DAY]: 'Día: ',
    [ELEMENTS.EXPERIENCES_DETAIL.IT_INCLUDES]: 'Incluye: ',
    [ELEMENTS.EXPERIENCES_DETAIL.PRODUCTS]: 'productos',
    [ELEMENTS.EXPERIENCES_DETAIL.AVAILABLE]: 'Cupos disponibles: ',
    [ELEMENTS.EXPERIENCES_DETAIL.PRICE]: 'Precio: ',
    [ELEMENTS.EXPERIENCES_DETAIL.RESERVE]: 'Reservar',

    // SHOPCART
    [ELEMENTS.SHOPCART.SHOPCART_COUNT]: 'Cantidad disponible limitada',
    [ELEMENTS.SHOPCART.SHOPCART_EMPTY]: 'Tu Cesta está vacía',
    [ELEMENTS.SHOPCART.SUMMARY]: 'Resumen del pedido',
    [ELEMENTS.SHOPCART.LIMITED]: 'Cantidad disponible limitada',
    [ELEMENTS.SHOPCART.CONTINUED]: 'Continuar con mi compra',
    [ELEMENTS.SHOPCART.SHIPPING]: 'Envío',
    [ELEMENTS.SHOPCART.ARRIVE]: 'El Cate movil llegará a tu casa',
    [ELEMENTS.SHOPCART.DELIVERY_TIME]: 'Tiempo de entrega: 2-5 días',

    // DISPATCH
    [ELEMENTS.SHIPPING.CHOOSE]: 'Elige tus opciones de despacho',
    [ELEMENTS.SHIPPING.DISPATCH]: 'Despacho a domicilio',
    [ELEMENTS.SHIPPING.RETIRE]: 'Retirar en tienda',
    [ELEMENTS.SHIPPING.CHOOSE_DISTRICT]:
      'Selecciona el Distrito donde se enviaran tus productos.',
    [ELEMENTS.SHIPPING.DEPARTMENT]: 'Departamento',
    [ELEMENTS.SHIPPING.PROVINCE]: 'Provincia',
    [ELEMENTS.SHIPPING.DISTRICT]: 'Distrito',
    [ELEMENTS.SHIPPING.ADDRESS]: 'Dirección',
    [ELEMENTS.SHIPPING.PAYMENT_RECEIPT]: 'Comprobante de pago',
    [ELEMENTS.SHIPPING.CONFIRM_DATE]:
      'Confirma la fecha y la hora en la que te acercaras a recoger tu pedido.',
    [ELEMENTS.SHIPPING.DATE]: 'Fecha: ',
    [ELEMENTS.SHIPPING.HOUR]: 'Hora: ',
    [ELEMENTS.SHIPPING.PAY]: 'PROCEDER AL PAGO',

    // Payment
    [ELEMENTS.PAYMENT.INFORMATION]: 'Información de envío',
    [ELEMENTS.PAYMENT.DETAILS]: 'Detalles del pedido',
    [ELEMENTS.PAYMENT.TERMNS]:
      'Al hacer clic en "Proceder al Pago", confirmo que he leído y acepto los',
    [ELEMENTS.PAYMENT.TERMNS_CONDITIONS]: 'Términos y Condiciones',
    [ELEMENTS.PAYMENT.SECURITY]: 'MercadoPago mantiene seguros tu información y tu pago',

    // MEMBERSHIP CHECKOUT
    [ELEMENTS.CHECKOUT.NAME]: 'Nombre',
    [ELEMENTS.CHECKOUT.LAST_NAME]: 'Apellido',
    [ELEMENTS.CHECKOUT.DOCUMENT_NUMBER]: 'Nro de documento',
    [ELEMENTS.CHECKOUT.SHIPPING_DIRECTION]: 'Dirección donde se enviará el producto.',
    [ELEMENTS.CHECKOUT.TYPE_INVOICE]: 'Tipo de facturación',

    // LOGIN
    [ELEMENTS.LOGIN.WELCOME]: 'Bienvenido a',
    [ELEMENTS.LOGIN.PASSWORD]: 'Contraseña',
    [ELEMENTS.LOGIN.REMEMBER]: 'Recordarme',
    [ELEMENTS.LOGIN.HAVE_ACCOUNT]: 'No tienes una cuenta? ',
    [ELEMENTS.LOGIN.REGISTER]: 'Regístrate',
    [ELEMENTS.LOGIN.FORGOT_PASSWORD]: 'Olvidaste tu contraseña?',

    // REGISTER
    [ELEMENTS.REGISTER.JOIN_UP]: 'Unete a nuestra familia',
    [ELEMENTS.REGISTER.ACEPT]: 'Acepto los ',
    [ELEMENTS.REGISTER.TERMNS]: 'Términos ',
    [ELEMENTS.REGISTER.PRIVACY_POLICY]: 'Politica de Privacidad',
    [ELEMENTS.REGISTER.Y]: 'y ',

    // User Profile
    [ELEMENTS.USER_PROFILE.ADMIN]: 'Administra tu cuenta',
    [ELEMENTS.USER_PROFILE.PROFILE]: 'Perfil',
    [ELEMENTS.USER_PROFILE.BASIC_INFORMATION]: 'Información básica',
    [ELEMENTS.USER_PROFILE.CHANGE]: 'Cambiar los datos basicos de tu cuenta',
    [ELEMENTS.USER_PROFILE.SAVE]: 'Guardar cambios',
    [ELEMENTS.USER_PROFILE.EXTRA]: 'Información extra',
    [ELEMENTS.USER_PROFILE.CHANGE_UBICATION]:
      'Cambiar los datos de ubicación y de identificación de tu cuenta.',

    // CHANGE PASSWORD
    [ELEMENTS.CHANGE_PASSWORD.ENTER_EMAIL]:
      'Ingresa tu dirección de correo electrónico para restablecer la contraseña.',
    [ELEMENTS.CHANGE_PASSWORD.SEND]: 'Enviar',
  },
};

export default esLa;
