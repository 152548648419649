import React from 'react';
import PropTypes from 'prop-types';
import { useResponsive } from 'hooks';
// Components
import { TopBar, Drawer } from './components';

const LoginAndLogout = (props) => {
  const { children } = props;
  const { isDesktop } = useResponsive();
  const [drawerState, setDrawerState] = React.useState(false);

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setDrawerState(open);
  };

  return (
    <div style={{ paddingTop: '72px' }}>
      <TopBar menuClick={toggleDrawer(true)} />
      {!isDesktop && <Drawer open={drawerState} onClose={toggleDrawer(false)} />}
      <main style={{ minHeight: 'calc(100vh - 72px)' }}>{children}</main>
    </div>
  );
};

LoginAndLogout.propTypes = {
  children: PropTypes.node,
};

export default LoginAndLogout;
