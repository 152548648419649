import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { experienceService } from 'services';
import { replacePathParams } from 'utils';
import { Context } from 'context/Context';
import * as VARIABLES from 'constants/variables';
import * as ROUTES from 'constants/routes';
import './experienceDetail.scss';
// Components
import { CatePaper, RedButton, AuthDialog } from 'components';
import { ClockDesignIcon, CalendarDesignIcon, ShoppingBagDesignIcon } from 'icons';
import { Container, Button, Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import EventAvailableRoundedIcon from '@material-ui/icons/EventAvailableRounded';
import { LOCALES } from 'i18n';
import Traslate from 'i18n/traslate';
import ELEMENTS from 'constants/elementsForTranslate';

function ExperienceDetail(props) {
  const history = useHistory();
  const experienceId = props.match.params.id;
  const { token, addExperience, locale } = useContext(Context);
  const [authModalOpen, setAuthModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [experienceObj, setExperienceObj] = useState({});
  const handleAuthModal = (arg) => {
    setAuthModalOpen(arg);
  };

  const handleActionClick = () => {
    if (token) {
      addExperience(experienceObj);
      history.push(ROUTES.PAYMENT + '?experience_id=' + experienceObj.id);
    } else {
      handleAuthModal(true);
    }
  };

  const fetchListExperience = async () => {
    setLoading(true);
    const res = await experienceService.byId(experienceId);
    if (!res.info.error) {
      setExperienceObj(res.data);
    }
    setLoading(false);
  };

  const onDownload = () => {
    const link = document.createElement('a');
    link.download = `file.pdf`;
    link.href = VARIABLES.IMAGE_PATH + experienceObj.pdf_path;
    link.target = '_blank';
    link.click();
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchListExperience();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="cate-exp-detail">
      <Container maxWidth="lg">
        <CatePaper disableElevation style={{ marginBottom: '2.4rem' }}>
          <div className="exp-detail-cover">
            <img
              className="cover-image"
              src={VARIABLES.IMAGE_PATH + experienceObj.image_path}
              alt={experienceObj.title}
            />
            <div className="cover-text">
              <h1>
                {locale === LOCALES.ENGLISH ? experienceObj.title_en : experienceObj.title}
              </h1>
            </div>
          </div>
          <div className="exp-detail-content">
            <div className="content-category">
              <div className="content-category__group">
                <div className="category-item">
                  <ClockDesignIcon />
                  <div className="category-item__text">
                    <p>{Traslate(ELEMENTS.EXPERIENCES_DETAIL.DURATION)}</p>
                    <h6>{experienceObj.duration_time ? experienceObj.duration_time : '...'}</h6>
                  </div>
                </div>
                <div className="category-item">
                  <CalendarDesignIcon />
                  <div className="category-item__text">
                    <p>{Traslate(ELEMENTS.EXPERIENCES_DETAIL.DAY)}</p>
                    <h6>{experienceObj.meeting_date ? experienceObj.meeting_date : '...'}</h6>
                  </div>
                </div>
                {experienceObj.has_products && (
                  <div className="category-item">
                    <ShoppingBagDesignIcon />
                    <div className="category-item__text">
                      <p>{Traslate(ELEMENTS.EXPERIENCES_DETAIL.IT_INCLUDES)}</p>
                      <h6>{Traslate(ELEMENTS.EXPERIENCES_DETAIL.PRODUCTS)}</h6>
                    </div>
                  </div>
                )}
              </div>
              {experienceObj.pdf_path && (
                <Button
                  onClick={onDownload}
                  variant="contained"
                  color="primary"
                  style={{ margin: '10px 0px' }}>
                  <svg class="svg-icon" viewBox="0 0 20 20">
                    <path
                      fill="none"
                      d="M15.608,6.262h-2.338v0.935h2.338c0.516,0,0.934,0.418,0.934,0.935v8.879c0,0.517-0.418,0.935-0.934,0.935H4.392c-0.516,0-0.935-0.418-0.935-0.935V8.131c0-0.516,0.419-0.935,0.935-0.935h2.336V6.262H4.392c-1.032,0-1.869,0.837-1.869,1.869v8.879c0,1.031,0.837,1.869,1.869,1.869h11.216c1.031,0,1.869-0.838,1.869-1.869V8.131C17.478,7.099,16.64,6.262,15.608,6.262z M9.513,11.973c0.017,0.082,0.047,0.162,0.109,0.226c0.104,0.106,0.243,0.143,0.378,0.126c0.135,0.017,0.274-0.02,0.377-0.126c0.064-0.065,0.097-0.147,0.115-0.231l1.708-1.751c0.178-0.183,0.178-0.479,0-0.662c-0.178-0.182-0.467-0.182-0.645,0l-1.101,1.129V1.588c0-0.258-0.204-0.467-0.456-0.467c-0.252,0-0.456,0.209-0.456,0.467v9.094L8.443,9.553c-0.178-0.182-0.467-0.182-0.645,0c-0.178,0.184-0.178,0.479,0,0.662L9.513,11.973z"></path>
                  </svg>
                </Button>
              )}
            </div>
            {!loading ? (
              <div
                className="nui-content-post"
                dangerouslySetInnerHTML={{
                  __html:
                    locale === LOCALES.ENGLISH
                      ? experienceObj.description_en
                      : experienceObj.description,
                }}
              />
            ) : (
              <div>
                <Skeleton height={24} />
                <Skeleton height={24} />
                <Skeleton height={24} />
                <Skeleton height={24} />
                <Skeleton height={24} width="40%" />
                <Skeleton height={24} />
                <Skeleton height={24} />
                <Skeleton height={24} width="25%" />
              </div>
            )}
            <div className="conten-price">
              <span>{Traslate(ELEMENTS.EXPERIENCES_DETAIL.AVAILABLE)}</span>
              <p>{experienceObj.quota_limit}</p>
            </div>
            {!experienceObj.expired && experienceObj.quota_limit !== 0 ? (
              <>
                <div className="conten-price">
                  <span>{Traslate(ELEMENTS.EXPERIENCES_DETAIL.PRICE)}</span>
                  <p>S/. {experienceObj.price}</p>
                </div>

                <div className="content-action">
                  <RedButton
                    disableElevation
                    disabled={false}
                    variant="contained"
                    color="primary"
                    startIcon={<EventAvailableRoundedIcon />}
                    onClick={handleActionClick}>
                    {Traslate(ELEMENTS.EXPERIENCES_DETAIL.RESERVE)}
                  </RedButton>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </CatePaper>
        <Grid container spacing={3} className="exp-detail-gallery">
          {experienceObj.video_path && (
            <div class="exp-detail-video">
              <video controls controlsList="nodownload noremoteplayback">
                <source
                  src={VARIABLES.IMAGE_PATH + experienceObj.video_path}
                  type="video/mp4"
                />
              </video>
            </div>
          )}
        </Grid>
        <Grid container spacing={3} className="exp-detail-gallery">
          {!loading ? (
            <>
              {experienceObj.files?.map((item, index) => (
                <Grid item key={index} xs={12} sm={6} md={4}>
                  <CatePaper disableElevation className="galllery-item">
                    <img
                      src={VARIABLES.IMAGE_PATH + item.file_path}
                      alt={experienceObj.title}
                    />
                  </CatePaper>
                </Grid>
              ))}
            </>
          ) : (
            <>
              {Array.from(new Array(6)).map((data, index) => (
                <Grid item key={index} xs={12} sm={6} md={4}>
                  <CatePaper disableElevation disableBorder className="galllery-item">
                    <div>
                      <Skeleton height={224} width="100%" />
                    </div>
                  </CatePaper>
                </Grid>
              ))}
            </>
          )}
        </Grid>
      </Container>
      <AuthDialog
        open={authModalOpen}
        onClose={() => handleAuthModal(false)}
        location={replacePathParams(ROUTES.EXPERIENCES_BY_ID, { id: experienceId })}
      />
    </div>
  );
}

export default ExperienceDetail;
