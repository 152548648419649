import React from 'react';
import cls from 'utils/cls';
import PropTypes from 'prop-types';

const rootPaper = 'nui-paper';

const shadowPaper = 'nui-paper--shadow';

const borderPaper = 'nui-paper--border';

function CatePaper(props) {
  const {
    children,
    className,
    disableElevation = false,
    disableBorder = false,
    ...other
  } = props;
  return (
    <div
      className={cls([
        rootPaper,
        !disableElevation && shadowPaper,
        !disableBorder && borderPaper,
        className,
      ])}
      {...other}>
      {children}
    </div>
  );
}

CatePaper.propTypes = {
  /**
   * The content of the paper.
   */
  children: PropTypes.node,
  /**
   * Override or extend the styles applied to the component.
   */
  className: PropTypes.string,
  /**
   * If `true`, no elevation is used.
   */
  disableElevation: PropTypes.bool,
  /**
   * If `true`, no border is used.
   */
  disableBorder: PropTypes.bool,
};

export default CatePaper;
