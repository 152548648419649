import React, { useState, useEffect } from 'react';
import { blogService } from 'services';
import { useNotification } from 'hooks';
import './courseDetail.scss';
// Components
import { Notification, CatePaper } from 'components';
import { Container, Chip } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

function CourseDetail(props) {
  const courseSlug = props.match.params.slug;
  const { notification, createMessage, closeMessage } = useNotification();
  const [loading, setLoading] = useState(true);
  const [courseData, setCourseData] = useState({});

  const fetchShowCourse = async () => {
    setLoading(true);
    const res = await blogService.showBySlug(courseSlug);
    if (!res.info.error) {
      setCourseData(res.data);
    } else {
      createMessage.error(res.info.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchShowCourse();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseSlug]);

  return (
    <div className="cate-course-detail">
      <Container maxWidth={false} className="course-detail-content">
        <CatePaper disableElevation className="course-detail-content__card">
          <div className="course-detail-content__title">
            <h1>{courseData.title}</h1>
          </div>
          {!loading ? (
            <div
              className="nui-content-post"
              dangerouslySetInnerHTML={{ __html: courseData.description }}
            />
          ) : (
            <div>
              <Skeleton variant="text" height={64} width="80%" style={{ marginBottom: 18 }} />
              <Skeleton height={24} />
              <Skeleton height={24} />
              <Skeleton height={24} />
              <Skeleton height={24} />
              <Skeleton height={24} width="40%" />
              <Skeleton variant="rect" height={312} style={{ margin: '16px 0' }} />
              <Skeleton height={24} />
              <Skeleton height={24} />
              <Skeleton height={24} width="25%" />
            </div>
          )}
          <div className="course-detail-content__categories">
            {courseData.category && <Chip label={courseData.category.category} />}
          </div>
          <div className="course-detail-content__extra-data">
            <div className="course-author-list">
              <div className="course-author-item">
                <span className="author-item-wrap">
                  <div className="author-item-image">
                    <AccountCircleIcon />
                  </div>
                  <div className="author-item-info">
                    <div className="author-item-info__subtitle">
                      <p>ESCRITO POR</p>
                    </div>
                    <div className="author-item-info__name">
                      <h3>{courseData.author}</h3>
                    </div>
                  </div>
                </span>
              </div>
            </div>
          </div>
        </CatePaper>
      </Container>
      {notification.state && <Notification onClose={closeMessage} {...notification} />}
    </div>
  );
}

export default CourseDetail;
