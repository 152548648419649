import React, { useState } from 'react';
import {
  Container,
  Grid,
  TextField,
  Paper,
  Radio,
  RadioGroup,
  FormControl,
  Button,
  FormControlLabel,
} from '@material-ui/core';

import './index.scss';

const ComplaintsBook = () => {
  const [checked, setChecked] = React.useState(true);

  const [complaint, setComplaint] = useState({
    document_type: '',
    document_numer: '',
    name: '',
    last_name: '',
    address: '',
    department: '',
    province: '',
    district: '',
    phone_number: '',
    email: '',
    product_description: '',
    service_description: '',
    ocurrency_places: '',
    ocurrency_date: '',
    ocurrency_hour: '',
    discussion: '',
    detail: '',
  });

  const handleInput = (e) => {
    setComplaint({ ...complaint, [e.target.name]: e.target.value });
  };

  const handleChange = (event) => {
    setChecked(event.target.value);
  };

  return (
    <div className="cate-course-detail">
      <Container maxWidth="lg">
        <h2>Libro de Reclamaciones</h2>
        <p>
          Información sobre la recopilación y tratamiento de los datos para Quejas y Reclamos.
        </p>
        {/* <p className="text-top">
          CATE TASTINIG ROOM S.A., con RUC 20508565934, con domicilio Av. Angamos Este N° 1805,
          Piso 10, Distrito de Surquillo, Provincia y Departamento de Lima, es el titular del
          banco de datos personales de Quejas y reclamos (Código RNPDP-PJP N° 19659) ubicado en
          Avenida Presidente Eduardo Frei Montalva n°3615, Conchalí, Chile. HIPERMERCADOS TOTTUS
          S.A. declara que el tratamiento de sus datos personales en este portal tiene por
          finalidad gestionar de manera correcta su reclamo o queja conforme las disposiciones
          legales sobre la materia y llevar un registro histórico de la casuística presentada a
          fin de mejorar nuestros niveles de atención. HIPERMERCADOS TOTTUS S.A. declara que sus
          datos personales serán almacenados de manera indefinida en nuestro Banco de Datos
          Personales de Quejas y reclamos. Sus datos personales serán tratados conforme a
          nuestra Política de Privacidad, publicada en la Política de Privacidad. Usted podrá
          ejercer los derechos que le otorga la normativa de protección de datos personales en
          protegetusdatos@tottus.com.pe indicando su nombre completo y DNI.
        </p> */}
        <Paper elevation={4} className="text-top margin-card">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <h4>Datos Personales</h4>
            </Grid>
            <Grid item xs={6}>
              <TextField
                onChange={handleInput}
                variant="outlined"
                fullWidth
                label="Tipo de Documento"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                onChange={handleInput}
                variant="outlined"
                fullWidth
                label="Número de Documento"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField onChange={handleInput} variant="outlined" fullWidth label="Nombres" />
            </Grid>
            <Grid item xs={6}>
              <TextField
                onChange={handleInput}
                variant="outlined"
                fullWidth
                label="Apellidos"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                onChange={handleInput}
                variant="outlined"
                fullWidth
                label="Dirección"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                onChange={handleInput}
                variant="outlined"
                fullWidth
                label="Departamento"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                onChange={handleInput}
                variant="outlined"
                fullWidth
                label="Provincia"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField onChange={handleInput} variant="outlined" fullWidth label="Distrito" />
            </Grid>
            <Grid item xs={6}>
              <TextField onChange={handleInput} variant="outlined" fullWidth label="Telefono" />
            </Grid>
            <Grid item xs={6}>
              <TextField onChange={handleInput} variant="outlined" fullWidth label="Email" />
            </Grid>
            <Grid item xs={12}>
              <h4>Identificación del bien contratado</h4>
            </Grid>
            <Grid item xs={6}>
              <TextField variant="outlined" fullWidth label="Producto descripción" />
            </Grid>
            <Grid item xs={6}>
              <TextField variant="outlined" fullWidth label="Servicio descripción" />
            </Grid>
            <Grid item xs={4}>
              <TextField variant="outlined" fullWidth label="Lugar de ocurrencia" />
            </Grid>
            <Grid item xs={4}>
              <TextField variant="outlined" fullWidth label="Fecha de ocurrencia" />
            </Grid>
            <Grid item xs={4}>
              <TextField variant="outlined" fullWidth label="Hora de ocurrencia" />
            </Grid>
            <Grid item xs={4}>
              <h5>Condiciones de la hora del consumo</h5>
              <h6>Crédito / Contado pagado</h6>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="gender"
                  name="gender1"
                  value={checked}
                  onChange={handleChange}>
                  <FormControlLabel value="female" control={<Radio />} label="Si" />
                  <FormControlLabel value="male" control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <h4>Detalle del reclamo</h4>
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                rows={5}
                multiline
                fullWidth
                label="Reclamo/Queja"
              />
            </Grid>
            <Grid item xs={12}>
              <h4>Acciones adoptadas por el proveedor</h4>
            </Grid>
            <Grid item xs={12}>
              <TextField variant="outlined" rows={5} multiline fullWidth label="Detalle" />
            </Grid>
            <Grid item xs={12}>
              <Button variant="contained" color="primary">
                Enviar Reclamo
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </div>
  );
};

export default ComplaintsBook;
