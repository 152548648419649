import React, { useState, useEffect, useContext } from 'react';
import * as VARIABLES from 'constants/variables';
import * as ROUTES from 'constants/routes';
import { userService, locationService, subscriptionService } from 'services';
import { Context } from 'context/Context';
import { useNotification } from 'hooks';
// Components
import { Notification } from 'components';
import { CatePaper } from 'components';
import { Container, Grid, TextField, MenuItem, Button } from '@material-ui/core';
import './membershipCheckout.scss';
import SubscriptionsPlans from 'services/subscriptionPlans';
import validations from 'utils/validateForm';
import { useHistory } from 'react-router-dom';
import Traslate from 'i18n/traslate';
import ELEMENTS from 'constants/elementsForTranslate';

const SelectRowWithName = ({
  id,
  text,
  menuData = [],
  value,
  onChange,
  required,
  disabled,
}) => {
  return (
    <li>
      <label htmlFor={id}>{text}</label>
      <div className="input-row">
        <TextField
          select
          required={required}
          disabled={disabled}
          variant="outlined"
          id={id}
          name={id}
          value={value}
          onChange={onChange}>
          {menuData.map((option) => (
            <MenuItem key={option.id} value={option.name}>
              {option.name}
            </MenuItem>
          ))}
        </TextField>
      </div>
    </li>
  );
};

const SelectRow = ({ id, text, menuData = [], value, onChange, required, disabled }) => {
  return (
    <li>
      <label htmlFor={id}>{text}</label>
      <div className="input-row">
        <TextField
          select
          required={required}
          disabled={disabled}
          variant="outlined"
          id={id}
          name={id}
          value={value}
          onChange={onChange}>
          {menuData.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.name}
            </MenuItem>
          ))}
        </TextField>
      </div>
    </li>
  );
};

const InfoRow = ({
  id,
  text,
  type,
  value,
  onChange,
  multiline,
  rows,
  disabled,
  required = true,
  onBlur,
  description,
}) => (
  <li>
    <label htmlFor={id}>{text}</label>
    <div className="input-row">
      <TextField
        required={required}
        disabled={disabled}
        multiline={multiline}
        rows={rows}
        variant="outlined"
        id={id}
        name={id}
        type={type}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
      />
      {description ? (
        <div>
          <span className="nui-font-size-7">
            {Traslate(ELEMENTS.CHECKOUT.SHIPPING_DIRECTION)}
          </span>
        </div>
      ) : (
        <></>
      )}
    </div>
  </li>
);

const InfoPhoneRow = (props) => {
  const { id, prefixId, text, type, value, onChange, prefixValue = '51', disabled } = props;
  return (
    <li>
      <label htmlFor={id}>{text}</label>
      <Grid container spacing={0} className="input-row">
        <Grid item xs={3}>
          <TextField
            select
            disabled={disabled}
            variant="outlined"
            id={prefixId}
            name={prefixId}
            value={prefixValue}
            onChange={onChange}>
            {VARIABLES.PHONE_PREFIX.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={9} style={{ paddingLeft: '2.4rem' }}>
          <TextField
            required
            disabled={disabled}
            variant="outlined"
            id={id}
            name={id}
            type={type}
            inputProps={{
              min: '0',
            }}
            value={value}
            onChange={onChange}
          />
        </Grid>
      </Grid>
    </li>
  );
};

const orderInfoData = [
  {
    id: '01',
    name: 'Boleta',
  },
  {
    id: '02',
    name: 'Factura',
  },
];

function MembershipCheckout() {
  /* eslint-disable */
  // User data
  const { id, token, removeAuth } = useContext(Context);
  const history = useHistory();

  // Id del
  const [subscriptionId, setSubscriptionId] = useState(0);
  // Status Pago - false
  const [localSubscription, setLocalsubscription] = useState({});
  // Datos de plan
  const [planData, setPlanData] = useState({});

  const [shippingCost, setShippingCost] = useState(0);
  const [total, setTotal] = useState(0.0);

  const retrievedObject = JSON.parse(localStorage.getItem('product_property_details_id')) || {};
  const location = JSON.parse(localStorage.getItem('location')) || {};
  const otherProductDetail =
    JSON.parse(localStorage.getItem('product_property_detail_other')) || '';

  const [productPropertyDetails, setProductPropertyDetails] = useState(retrievedObject);
  const { id: productPropertyDetailsId, label: productPropertyDetailsLabel } =
    productPropertyDetails;
  /* eslint-enable */
  // variables para desahbilitar los inputs

  /* eslint-disable */
  const [disabledEmail, setDisabledEmail] = useState(false);
  /* eslint-enable */
  const [btnDisabled, setBtnDisabled] = useState(false);

  // Verificar si el email tiene un error
  const [errorEmail, setErrorEmail] = useState(false);
  const [msgErrorEmail, setMsgErrorEmail] = useState(false);

  // Verificar si existe el email
  const [existeEmail, setExisteEmail] = useState(false);
  const [msgExisteEmail, setMsgExisteEmail] = useState(false);

  // Verificar si el dni tiene un error
  const [errorNroDoc, setErrorNroDoc] = useState(false);
  const [msgErrorNroDoc, setMsgErrorNroDoc] = useState(false);

  const [userObj, setUserObj] = useState({
    name: '',
    last_name: '',
    password: 'password',
    email: '',
    phone_code: '',
    phone_number: '',
    document_number: '',
    invoice: '01',
    ruc: '',
    business_name: '',
    business_address: '',
  });

  const [userDetailObj, setUserDetailObj] = useState({
    department: '',
    provincia: '',
    district: '',
    address: '',
    document_number: '',
  });

  const [deliveryPriceList, setDeliveryPriceList] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const [districts, setDistricts] = useState([]);

  //UI
  const [loading, setLoading] = useState(true);
  const [loaded, setLoaded] = useState(false);
  const { notification, createMessage, closeMessage } = useNotification();

  //zona funciones personalizadas
  const buscarEmail = async (user_email) => {
    const existeUsrEmail = await userService.buscarUserEmail(user_email);
    return existeUsrEmail;
  };

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  function validaDNI(dni) {
    let onlyNumbersDNI = dni.toString().replace(/\D/g, '');
    let ex_regular_dni = /^\d{7,8}(?:[-\s]\d{4})?$/;
    if (
      ex_regular_dni.test(onlyNumbersDNI) === true &&
      !/[a-zA-Z]/g.test(dni) &&
      onlyNumbersDNI === dni.replace(/(\d)[\s.]+(?=\d)/g, '$1')
    ) {
      return true;
    } else {
      return false;
    }
  }

  const validateEmail = (email) => {
    if (isValidEmail(email)) {
      setErrorEmail(false);
      setMsgErrorEmail('');

      buscarEmail(email).then((resp) => {
        if (resp.data.length > 0) {
          setExisteEmail(true);
          setMsgExisteEmail(
            'El email ya ha sido registrado en el sistema, ingrese uno diferente',
          );
          setBtnDisabled(true);
        } else {
          setExisteEmail(false);
          setMsgExisteEmail('');
          setBtnDisabled(false);
        }
      });
    } else {
      setErrorEmail(true);
      setMsgErrorEmail('El email ingresado no es valido');
      setExisteEmail(false);
      console.log('email invalido');
    }
  };

  // Inputs and submits
  const handleUserInputChange = (e) => {
    setUserObj({
      ...userObj,
      [e.target.name]: e.target.value,
    });

    if (e.target.name === 'invoice') {
      if (e.target.value === '01') {
        setUserObj({
          ...userObj,
          invoice: '01',
          ruc: '',
          business_name: '',
          business_address: '',
        });
      }
    }
  };

  const onBlurNroDoc = (e) => {
    if (e.target.name === 'document_number') {
      if (validaDNI(e.target.value)) {
        setErrorNroDoc(false);
        setMsgErrorNroDoc('');
        setBtnDisabled(false);
      } else {
        setErrorNroDoc(true);
        setMsgErrorNroDoc('El Nro de documento es incorrecto.');
        setBtnDisabled(true);
      }
    }
  };

  const onBlurEmail = (e) => {
    validateEmail(e.target.value);
  };

  const handleUserSubmit = (e) => {
    e.preventDefault();
    const { error, message } = validations.plansForm(userObj, userDetailObj);
    // const isLogged = !!id;
    console.log(error, message);

    if (error || errorEmail || errorNroDoc) {
      console.log('error');
      createMessage.error(message);
      return;
    }

    fetchSubscribe();
  };

  const loadProvinceList = async (departmentId) => {
    let provincesList = [];
    Object.values(deliveryPriceList[departmentId].provinces).forEach((item) => {
      provincesList.push({
        id: item.province_id,
        name: item.province_name,
      });
    });
    setProvinces(provincesList);
  };

  const loadDistrictList = async (departmentId, provinceId) => {
    let districtList = [];
    Object.values(deliveryPriceList[departmentId].provinces[provinceId].districts).forEach(
      (item) => {
        districtList.push({
          id: item.ditrict_id,
          name: item.district_name,
        });
      },
    );
    setDistricts(districtList);
  };

  const onDepartmentChange = (value) => {
    setUserDetailObj({
      ...userDetailObj,
      ['department']: value.target.value,
      ['provincia']: '',
      ['district']: '',
    });
    loadProvinceList(value.target.value);
  };

  const onProvinceChange = (value) => {
    setUserDetailObj({
      ...userDetailObj,
      ['provincia']: value.target.value,
      ['district']: '',
    });
    loadDistrictList(userDetailObj.department, value.target.value);
  };

  const onDistrictChange = (value) => {
    setUserDetailObj({
      ...userDetailObj,
      ['district']: value.target.value,
    });

    const districtSelected =
      deliveryPriceList[userDetailObj.department]?.provinces[userDetailObj.provincia]
        ?.districts[value.target.value];
    let shippingPrice = 0;
    let totalPrice = planData.price;
    if (districtSelected && districtSelected.price) {
      shippingPrice = districtSelected.price;
      totalPrice = totalPrice + shippingPrice;
    }
    setShippingCost(shippingPrice);
    setTotal(totalPrice);
  };

  // FETCH
  const fetchGetDataOfUser = async () => {
    setLoading(true);
    const res = await userService.show(id);
    if (!res.info.error) {
      setUserObj({
        ...userObj,
        ...res.data,
        phone_code: res.data.phone_code ? res.data.phone_code : '',
        phone_number: res.data.phone_number ? res.data.phone_number : '',
      });
      if (res.data.user_detail) {
        setUserDetailObj({
          ...userDetailObj,
          ...res.data.user_detail,
        });
      } else {
        setUserDetailObj({
          department: '',
          provincia: '',
          district: '',
          address: '',
          document_number: '',
        });
      }
    } else {
      console.log(res.info.message);
      createMessage.error(res.info.message);
    }
    setLoading(false);
  };

  const fetchSubscribe = async () => {
    setBtnDisabled(true);
    createMessage.info('Enviando datos...');

    const res = await userService.registerAndSubscribe(
      userObj,
      userDetailObj,
      planData,
      token,
      productPropertyDetailsId,
      productPropertyDetailsLabel === 'Otro' ? otherProductDetail : '',
    );
    if (!res?.info?.error) {
      createMessage.success(res.info.message);
      window.location.replace(res.data.mercado_pago_init_point);
    } else {
      if (res.info.status === 401) {
        removeAuth();
        createMessage.error(res.info.message);
        return history.push(ROUTES.LOGIN);
      }
      createMessage.error(res.info.message);
    }
    setBtnDisabled(false);
  };

  const fetchData = async () => {
    const params = new URLSearchParams(window.location.search);

    // Recupera el id del URL
    const plan_id = params.get('plan_id');
    setSubscriptionId(plan_id);

    // const subscript = await subscriptionService.show(plan_id);
    const plan = await SubscriptionsPlans.getWithId(plan_id);
    setPlanData(plan.data);

    // let objSubscript = subscript.data;
    // setLocalsubscription({ ...objSubscript, ...{ status_pago: false } });
    // setTipoAbono(subscript.data.period);

    // const tot_pago = subscript.data.preview_price; //+subscript.data.shipping_cost;
    // setTotal(tot_pago);
  };

  const fetchDeliveryPriceList = async () => {
    const responseDeliveryPriceList = await subscriptionService.listDeliveryPrices();
    if (!responseDeliveryPriceList.info.error) {
      localStorage.setItem(
        VARIABLES.LOCAL_STORAGE.DELIVERY_PRICE_LIST,
        JSON.stringify(responseDeliveryPriceList.data),
      );
      localStorage.setItem(
        VARIABLES.LOCAL_STORAGE.DELIVERY_PRICE_LIST_DATE_LOADED,
        new Date().toString(),
      );
      setDeliveryPriceList(responseDeliveryPriceList.data);
    } else {
      createMessage.error(responseDeliveryPriceList.info.message);
      setDeliveryPriceList([]);
    }
  };

  const handleUserDetailInputChange = (e) => {
    setUserDetailObj({
      ...userDetailObj,
      [e.target.name]: e.target.value,
    });
  };
  //zona useEffect
  useEffect(async () => {
    window.scrollTo(0, 0);

    fetchData().catch(console.error);

    await fetchDeliveryPriceList();

    if (id !== undefined) {
      setDisabledEmail(true);
      await fetchGetDataOfUser();
    } else {
      setDisabledEmail(false);
      setLoading(false);
    }

    setLoaded(true);
  }, []);

  useEffect(() => {
    if (location) {

      if (Object.keys(deliveryPriceList).length > 0 && location.department) {
        let departmentList = [];
        Object.values(deliveryPriceList).forEach((item) => {
          departmentList.push({
            id: item.department_id,
            name: item.department_name,
          });
        });
        setDepartments(departmentList);
      }

      if (Object.keys(deliveryPriceList).length > 0 && location.provincia) {
        let provincesList = [];
        Object.values(deliveryPriceList[location.department].provinces).forEach((item) => {
          provincesList.push({
            id: item.province_id,
            name: item.province_name,
          });
        });
        setProvinces(provincesList);
      }

      if (Object.keys(deliveryPriceList).length > 0 && location.district) {
        let districtList = [];
        Object.values(
          deliveryPriceList[location.department].provinces[location.provincia].districts,
        ).forEach((item) => {
          districtList.push({
            id: item.ditrict_id,
            name: item.district_name,
          });
        });
        setDistricts(districtList);

        const districtSelected =
          deliveryPriceList[location.department].provinces[location.provincia].districts[
            location.district
          ];
        let shippingPrice = 0;
        let totalPrice = planData.price;
        if (districtSelected && districtSelected.price) {
          shippingPrice = districtSelected.price;
          totalPrice = totalPrice + shippingPrice;
        }
        console.log('onDistrictChange', 'planData', planData);
        setShippingCost(shippingPrice);
        setTotal(totalPrice);
      }

      setUserDetailObj({
        ...userDetailObj,
        department: location.department,
        provincia: location.provincia,
        district: location.district,
      });

      // setTotal(currentDistrict?.price + planData.price);
      // setAllLocation();
    }
  }, [loaded]);

  useEffect(() => {
    if (!id) {
      setUserObj({
        ...userObj,
        email: '',
        name: '',
        last_name: '',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <div className="cate-subscription">
      <Container maxWidth="md">
        <div className="subscription-content">
          <CatePaper disableElevation className="profile-box">
            {!loading && Object.keys(deliveryPriceList).length > 0 ? (
              <form className="subscription-form" onSubmit={handleUserSubmit}>
                <ul className="flex-outer">
                  <InfoRow
                    id="email"
                    text="Email*:"
                    type="email"
                    value={userObj.email}
                    onChange={handleUserInputChange}
                    disabled={id ? true : false}
                    onBlur={onBlurEmail}
                  />
                  <li>
                    <label></label>
                    <div>
                      {errorEmail ? (
                        <span style={{ color: '#e11d48' }}>{msgErrorEmail}</span>
                      ) : null}
                      {existeEmail ? (
                        <span style={{ color: '#e11d48' }}>{msgExisteEmail}</span>
                      ) : null}
                    </div>
                  </li>

                  <InfoRow
                    id="name"
                    text={Traslate(ELEMENTS.CHECKOUT.NAME)}
                    type="text"
                    value={userObj.name}
                    onChange={handleUserInputChange}
                    disabled={id !== undefined || errorEmail ? true : false}
                  />
                  <InfoRow
                    id="last_name"
                    text={Traslate(ELEMENTS.CHECKOUT.LAST_NAME)}
                    type="text"
                    value={userObj.last_name}
                    onChange={handleUserInputChange}
                    disabled={id !== undefined || errorEmail ? true : false}
                  />
                  <InfoRow
                    id="document_number"
                    text={Traslate(ELEMENTS.CHECKOUT.DOCUMENT_NUMBER)}
                    type="text"
                    value={userDetailObj.document_number}
                    onChange={handleUserDetailInputChange}
                    disabled={errorEmail}
                    onBlur={onBlurNroDoc}
                  />
                  <li>
                    <label></label>
                    <div>
                      {errorNroDoc ? (
                        <span style={{ color: '#e11d48' }}>{msgErrorNroDoc}</span>
                      ) : null}
                    </div>
                  </li>

                  {/**Telefono de contacto  */}
                  <InfoPhoneRow
                    id="phone_number"
                    prefixId="phone_code"
                    text={Traslate(ELEMENTS.BRANCHES.PHONE)}
                    type="number"
                    value={userObj.phone_number}
                    onChange={handleUserInputChange}
                    prefixValue={userObj.phone_code}
                    onPrefixChange={handleUserInputChange}
                    disabled={errorEmail}
                  />

                  <SelectRow
                    id="department"
                    text={Traslate(ELEMENTS.SHIPPING.DEPARTMENT)}
                    menuData={departments}
                    value={userDetailObj.department}
                    onChange={onDepartmentChange}
                    disabled={errorEmail}
                  />
                  <SelectRow
                    id="provincia"
                    text={Traslate(ELEMENTS.SHIPPING.PROVINCE)}
                    menuData={provinces}
                    value={userDetailObj.provincia}
                    onChange={onProvinceChange}
                    disabled={errorEmail}
                  />
                  <SelectRow
                    id="district"
                    text={Traslate(ELEMENTS.SHIPPING.DISTRICT)}
                    menuData={districts}
                    value={userDetailObj.district}
                    onChange={onDistrictChange}
                    disabled={errorEmail}
                    style={{ marginBottom: 0 }}
                  />
                  {/**Direccion de envio */}
                  <InfoRow
                    id="address"
                    text={Traslate(ELEMENTS.SHIPPING.ADDRESS)}
                    type="text"
                    value={userDetailObj.address}
                    onChange={handleUserDetailInputChange}
                    disabled={errorEmail}
                    description="Dirección donde se enviará el producto."
                  />
                  <SelectRow
                    id="invoice"
                    text={Traslate(ELEMENTS.CHECKOUT.TYPE_INVOICE)}
                    menuData={orderInfoData}
                    value={userObj.invoice}
                    onChange={handleUserInputChange}
                  />

                  {userObj.invoice === '02' && (
                    <>
                      <InfoRow
                        id="ruc"
                        text="RUC*:"
                        type="text"
                        value={userObj.ruc}
                        onChange={handleUserInputChange}
                      />
                      <InfoRow
                        id="business_name"
                        text="Razón Social*:"
                        type="text"
                        value={userObj.business_name}
                        onChange={handleUserInputChange}
                      />
                      <InfoRow
                        id="business_address"
                        text="Dirección de la empresa*:"
                        type="text"
                        value={userObj.business_address}
                        onChange={handleUserInputChange}
                      />
                    </>
                  )}

                  <li className="total-price">
                    <label></label>
                    <div>
                      <h1>
                        {Traslate(ELEMENTS.EXPERIENCES_DETAIL.PRICE)}:{' '}
                        <span>S/. {planData?.price?.toFixed(2)}</span>
                      </h1>
                    </div>
                  </li>
                  <li className="total-price">
                    <label></label>
                    <div>
                      <h1>
                        Delivery:{' '}
                        <span>
                          S/.{' '}
                          {shippingCost >= 0 ? shippingCost?.toFixed(2) : 'Escoge un distrito'}
                        </span>
                      </h1>
                    </div>
                  </li>
                  <li className="total-price">
                    <label></label>
                    <div>
                      <h1>
                        Total:{' '}
                        <span>
                          S./ {isNaN(total) ? 'Escoge un distrito' : total?.toFixed(2)}
                        </span>
                      </h1>
                    </div>
                  </li>
                  <li className="last-row">
                    <Button
                      disableElevation
                      disabled={btnDisabled || errorEmail}
                      variant="contained"
                      color="primary"
                      type="submit">
                      Subscribirse
                    </Button>
                  </li>
                </ul>
              </form>
            ) : (
              <div></div>
            )}
          </CatePaper>
        </div>
      </Container>
      {notification.state && <Notification onClose={closeMessage} {...notification} />}
    </div>
  );
}

export default MembershipCheckout;
