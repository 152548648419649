import React, { useContext, useEffect, useState } from 'react';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import { orderService, locationService } from 'services';
import { Context } from 'context/Context';
import { useNotification } from 'hooks';
import { replacePathParams } from 'utils';
import * as VARIABLES from 'constants/variables';
import * as ROUTES from 'constants/routes';
import './orderDetail.scss';
// Dependencies
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
// Components
import { VisaIcon, AmexIcon, MasterCardIcon, AmericanExpressIcon, DinersClubIcon } from 'icons';
import { Notification, CatePaper, OrderState, Progress } from 'components';
import { Container, Grid, Button } from '@material-ui/core';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
// Images
import EmptyBox from 'assets/images/background/empty-box.png';

const variantCard = {
  Visa: VisaIcon,
  Amex: AmexIcon,
  MasterCard: MasterCardIcon,
  AmericanExpress: AmericanExpressIcon,
  Diners: DinersClubIcon,
};

function OrderDetail(props) {
  const orderId = props.match.params.id;
  let query = new URLSearchParams(useLocation().search);
  let status = query.get('collection_status');
  let paymentId = query.get('payment_id');
  let merchantOrderId = query.get("merchant_order_id")
  const { id } = useContext(Context);
  const history = useHistory();
  const { notification, createMessage, closeMessage } = useNotification();
  const [loading, setLoading] = useState(true);
  const [updateloading, setUpdateLoading] = useState(false);
  const [error, setError] = useState(false);
  const [orderObj, setOrderObj] = useState({});
  const [shipping, setShipping] = useState({});
  const paymentCard = orderObj.card && orderObj.card.card_brand;
  const PaymentIcon =
    variantCard[paymentCard] !== undefined ? variantCard[paymentCard] : variantCard['Visa'];

  const handleGoBack = () => {
    history.push(ROUTES.USER_PURCHASES);
  };

  const fetchOrdersById = async () => {
    setLoading(true);
    const res = await orderService.show(orderId);
    // console.log(res);
    if (!res.info.error) {
      if (res.data.order.user_id + '' !== id) {
        setError(true);
      }
      setOrderObj(res.data);
      fetchDistrictPriceByName(res.data.order.district);
    } else {
      createMessage.error(res.info.message);
    }
    setLoading(false);
  };

  const fetchUpdateOrder = async () => {
    setUpdateLoading(true);

    try {
      const res = await orderService.update({
        order_id: orderId,
        status: status,
        payment_id: paymentId,
        merchant_order_id: merchantOrderId
      });

      if (!res.info.error) {
        createMessage.success('Reserva actualizada');
        fetchOrdersById();
      } else {
        createMessage.error(res.info.message);
      }
    } catch(error) {
      console.error(error)
    }
    setUpdateLoading(false);
  };

  const fetchDistrictPriceByName = async (district) => {
    const res = await locationService.getdistrictPriceByName(district);
    // console.log(res);
    if (!res.info.error) {
      setShipping(res.data);
    }
  };

  useEffect(() => {
    if (paymentId) {
      fetchUpdateOrder();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, paymentId]);

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchOrdersById();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (error) {
    return (
      <div className="cate-order-detail">
        <Container maxWidth="lg">
          <div className="page-title">
            <h3>No tiene los permisos para ver este pedido</h3>
          </div>
        </Container>
      </div>
    );
  }

  return (
    <div className="cate-order-detail">
      <Container maxWidth="lg">
        <div className="order-detail-top">
          <Button disableRipple startIcon={<ArrowBackIosRoundedIcon />} onClick={handleGoBack}>
            Atras
          </Button>
        </div>
        <div className="page-title">
          <h1>Detalles del pedido</h1>
        </div>
        {!loading ? (
          <>
            <div className="order-extra">
              <span>
                Comprado el{' '}
                {format(new Date(orderObj.order.created_at), "dd 'de' MMMM 'de' yyyy", {
                  locale: es,
                })}
              </span>
              <i className="order-extra__separator" role="img" />
              <span>Pedido n.º {orderObj.order.id}</span>
              <i className="order-extra__separator" role="img" />
              <OrderState status={orderObj.order.status} />
            </div>
            <CatePaper disableElevation disableBorder>
              <div className="order-info nui-box">
                <div className="box-inner">
                  <Grid container spacing={0}>
                    <Grid item xs={12} md={4}>
                      <div className="order-info__address">
                        <h5 className="info-subtitle">Dirección de envío</h5>
                        <ul>
                          <li>{orderObj.order.address}</li>
                          <li>
                            {orderObj.order.department}, {orderObj.order.province},{' '}
                            {orderObj.order.district}
                          </li>
                          <li>Perú</li>
                        </ul>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={5}>
                      <div className="order-info__method">
                        <h5 className="info-subtitle">Método de pago</h5>
                        {orderObj.card ? (
                          <div className="method-wrap">
                            <PaymentIcon />
                            <span>****{orderObj.card.card_number.substr(-4, 4)}</span>
                          </div>
                        ) : (
                          <div>
                            <span>No se encontró el metodo de pago</span>
                          </div>
                        )}
                      </div>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <div className="order-info__resume">
                        <h5 className="info-subtitle">Resumen del pedido</h5>
                        <div className="resume-container">
                          <div className="resume-item">
                            <div className="item-name">
                              <span>Subtotal de producto(s):</span>
                            </div>
                            <div className="item-subtotals">
                              {shipping ? (
                                <span>S/. {orderObj.order.total - shipping.price}</span>
                              ) : (
                                <span>S/. {orderObj.order.total}</span>
                              )}
                            </div>
                          </div>
                          <div className="resume-item">
                            <div className="item-name">
                              <span>Envío:</span>
                            </div>
                            <div className="item-subtotals">
                              {shipping ? <span>S/. {shipping.price}</span> : <span>...</span>}
                            </div>
                          </div>
                          <div className="resume-space" />
                          <div className="resume-item">
                            <div className="item-name">
                              <span>Total:</span>
                            </div>
                            <div className="item-subtotals">
                              <span>S/. {orderObj.order.total}</span>
                            </div>
                          </div>
                          <div className="resume-space" />
                          <div className="resume-item">
                            <div className="item-name-last">
                              <span>Importe total:</span>
                            </div>
                            <div className="item-subtotals-last">
                              <span>S/. {orderObj.order.total}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </CatePaper>
            <CatePaper disableElevation disableBorder>
              <div className="order-products nui-box">
                <div className="box-inner">
                  {orderObj.items.length > 0 ? (
                    <ul>
                      {orderObj.items.map((item, index) => {
                        let propertyData = null;
                        if (item.product_poperty_detail_id) {
                          const propertyObj = item.property.detail.find(
                            (e) => e.id === item.product_poperty_detail_id,
                          );
                          propertyData = propertyObj;
                        }

                        return (
                          <li className="product-wrap" key={index}>
                            <div className="product-info">
                              <div className="product-left">
                                <RouterLink
                                  to={replacePathParams(ROUTES.PRODUCTS_BY_SLUG, {
                                    slug: item.slug,
                                  })}
                                  target="_blank">
                                  <img
                                    src={
                                      item.images[0]
                                        ? VARIABLES.IMAGE_PATH + item.images[0].image_path
                                        : VARIABLES.IMAGE_FALLBACK
                                    }
                                    alt="producto"
                                  />
                                </RouterLink>
                              </div>
                              <div className="product-right">
                                <p className="product-title">
                                  <RouterLink
                                    className="nui-no-decoration"
                                    to={replacePathParams(ROUTES.PRODUCTS_BY_SLUG, {
                                      slug: item.slug,
                                    })}
                                    target="_blank">
                                    {item.label}
                                  </RouterLink>
                                </p>
                                <p className="product-category">
                                  <span>Categoria:</span> {item.category.category}
                                </p>
                                {propertyData && (
                                  <p className="product-property">
                                    <span>Propiedades de artículo: </span>
                                    {propertyData.label}
                                  </p>
                                )}
                                <p className="product-amount">
                                  {propertyData ? (
                                    <span> S/. {propertyData.price} </span>
                                  ) : (
                                    <span> S/. {item.price} </span>
                                  )}
                                  <span>x {item.quantity}</span>
                                </p>
                                <p className="product-policy"></p>
                              </div>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  ) : (
                    <div className="product-empty">
                      <img src={EmptyBox} alt="empty-box" />
                      <p>No se encontraron productos</p>
                    </div>
                  )}
                </div>
              </div>
            </CatePaper>
          </>
        ) : (
          <div></div>
        )}
      </Container>
      <Progress color="primary" loading={updateloading} />
      {notification.state && <Notification onClose={closeMessage} {...notification} />}
    </div>
  );
}

export default OrderDetail;
