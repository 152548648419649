import React, { useState, useContext } from 'react';
import { Link as RouterLink, Redirect } from 'react-router-dom';
import { useInputValue, useNotification, useResponsive } from 'hooks';
import { Context } from 'context/Context';
import { userService } from 'services';
import * as ROUTES from 'constants/routes';
import './login.scss';
// Components
import { Notification } from 'components';
import {
  Paper,
  Grid,
  Container,
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
// Images
import CoffeeBreak from 'assets/images/background/coffee-break.png';
import Traslate from 'i18n/traslate';
import ELEMENTS from 'constants/elementsForTranslate';

function Login(props) {
  const { isDesktop } = useResponsive();
  const { id, token, activateAuth } = useContext(Context);
  const { notification, createMessage, closeMessage } = useNotification();
  const [disabledSubmit, setDisableSubmit] = useState(false);
  const [checkbox, setCheckbox] = useState(true);
  const email = useInputValue('');
  const password = useInputValue('');

  const handleCheckboxChange = (event) => {
    setCheckbox(event.target.checked);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    fetchLoginUser();
  };

  const fetchLoginUser = async () => {
    setDisableSubmit(true);
    createMessage.info('Enviando datos...');
    const res = await userService.login({ email: email.value, password: password.value });
    if (!res.info.error) {
      createMessage.success(res.info.message);
      activateAuth(
        res.data.user.id,
        res.data.user.name,
        res.data.user.email,
        res.data.token.access_token,
        null,
      );
    } else {
      createMessage.error("Error en las credenciales");
      setDisableSubmit(false);
    }
  };

  let redirectLocation = ROUTES.HOME;

  if (props.location.state) {
    redirectLocation = props.location.state.from;
  }

  if (id && token) return <Redirect to={redirectLocation} />;

  return (
    <div className="cate-login">
      <Container maxWidth="lg">
        <Grid container spacing={0} className="cate-login__grid">
          {isDesktop && (
            <Grid item xs={12} md={6}>
              <div className="login-cover">
                <img src={CoffeeBreak} alt="placeholder login" />
              </div>
            </Grid>
          )}
          <Grid item xs={12} md={6}>
            <div className="login-card">
              <Paper className="login-card__paper">
                <div className="card-header">
                  <div className="header-t1">{Traslate(ELEMENTS.LOGIN.WELCOME)}</div>
                  <div className="header-t2">Cate Tasting Room</div>
                </div>
                <div className="card-content">
                  <form className="card-content__form" onSubmit={handleSubmit}>
                    <TextField
                      id="email"
                      label="Email"
                      type="email"
                      required
                      fullWidth
                      variant="outlined"
                      margin="normal"
                      value={email.value}
                      onChange={email.onChange}
                    />
                    <TextField
                      id="password"
                      label={Traslate(ELEMENTS.LOGIN.PASSWORD)}
                      type="password"
                      required
                      fullWidth
                      variant="outlined"
                      margin="normal"
                      value={password.value}
                      onChange={password.onChange}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checkbox}
                          onChange={handleCheckboxChange}
                          name="checkedB"
                          color="primary"
                        />
                      }
                      label={
                        <span className="nui-font-size-base">
                          {Traslate(ELEMENTS.LOGIN.REMEMBER)}
                        </span>
                      }
                    />
                    <div className="form-btn-submit">
                      <Button
                        type="submit"
                        fullWidth
                        disabled={disabledSubmit}
                        variant="contained"
                        color="primary"
                        size="large">
                        Iniciar sesión
                      </Button>
                    </div>
                    <div className="form-link form-link-register">
                      <span className="link-black">
                        {Traslate(ELEMENTS.LOGIN.HAVE_ACCOUNT)}
                      </span>
                      <RouterLink className="nui-no-decoration" to={ROUTES.REGISTER}>
                        <span>{Traslate(ELEMENTS.LOGIN.REGISTER)}</span>
                      </RouterLink>
                    </div>
                    <div className="form-link form-link-lost-pass">
                      <RouterLink
                        className="nui-no-decoration"
                        to={ROUTES.CHANGE_USER_PASSWORD}>
                        <span>{Traslate(ELEMENTS.LOGIN.FORGOT_PASSWORD)}</span>
                      </RouterLink>
                    </div>
                  </form>
                </div>
              </Paper>
            </div>
          </Grid>
        </Grid>
      </Container>
      {notification.state && <Notification onClose={closeMessage} {...notification} />}
    </div>
  );
}

export default Login;
