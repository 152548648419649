import React, { useState, useEffect, useContext } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { experienceService } from 'services';
import { dateFormat, replacePathParams } from 'utils';
import * as VARIABLES from 'constants/variables';
import * as ROUTES from 'constants/routes';
import './experiences.scss';
// Components
import { Container, Grid } from '@material-ui/core';
import { ClockIcon } from 'icons';
import { Skeleton } from '@material-ui/lab';
import { Context } from 'context';
import { LOCALES } from 'i18n';
import Traslate from 'i18n/traslate';
import ELEMENTS from 'constants/elementsForTranslate';

function Experiences() {
  const { locale } = useContext(Context);
  const [loading, setLoading] = useState(true);
  const [experienceList, setExperienceList] = useState([]);

  const fetchListExperience = async () => {
    setLoading(true);
    const res = await experienceService.list();
    if (!res.info.error) {
      setExperienceList(res.data);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchListExperience();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="cate-experiences">
      <Container maxWidth="lg">
        <div className="nui-main-title">
          <h1>{Traslate(ELEMENTS.EXPERIENCES.TITLE)}</h1>
        </div>
        <section>
          <Grid container spacing={3}>
            {!loading ? (
              <>
                {experienceList.map((item, index) => (
                  <Grid item key={index} xs={12} sm={6} md={4}>
                    <RouterLink
                      className="nui-no-decoration"
                      to={replacePathParams(ROUTES.EXPERIENCES_BY_ID, { id: item.id })}>
                      <article className="experience-card">
                        <div className="experience-card__info-hover">
                          <div style={{ padding: 16 }}>
                            {item.expired ? (
                              <div className="info-status">
                                <span>{Traslate(ELEMENTS.EXPERIENCES.EXPIRED)}</span>
                              </div>
                            ) : (
                              <></>
                            )}
                            <div className="info-clock">
                              <ClockIcon className="info-clock-icon" />
                              <span className="info-clock-text">{item.duration_time}</span>
                            </div>
                          </div>
                        </div>
                        <div
                          className="experience-card__img"
                          style={{
                            backgroundImage: `url('${VARIABLES.IMAGE_PATH + item.image_path}')`,
                          }}
                        />
                        <div
                          className="experience-card__img--hover"
                          style={{
                            backgroundImage: `url('${VARIABLES.IMAGE_PATH + item.image_path}')`,
                          }}
                        />
                        <div className="experience-card__info">
                          {/*Todo: Cambiar a formato "13 DE ABR" */}
                          <span className="info-category">
                            {dateFormat.experienceDate(item.meeting_date)}
                          </span>
                          <h3 className="info-title" style={{ fontSize: '17px' }}>
                            {locale === LOCALES.ENGLISH ? item.title_en : item.title}
                          </h3>
                          <span className="info-price">
                            {Traslate(ELEMENTS.EXPERIENCES.FROM)} S/. {item.price}
                          </span>
                        </div>
                      </article>
                    </RouterLink>
                  </Grid>
                ))}
              </>
            ) : (
              <>
                {Array.from(new Array(6)).map((data, index) => (
                  <Grid item key={index} xs={12} sm={6} md={4}>
                    <article className="experience-card">
                      <Skeleton height={386} />
                    </article>
                  </Grid>
                ))}
              </>
            )}
          </Grid>
        </section>
      </Container>
    </div>
  );
}

export default Experiences;
