import React, { useContext, useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Context } from 'context/Context';
import { orderService } from 'services';
import { useNotification } from 'hooks';
import { replacePathParams } from 'utils';
import * as VARIABLES from 'constants/variables';
import * as ROUTES from 'constants/routes';
import './orders.scss';
// Dependencies
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
// Components
import { Notification, CatePaper, OrderState } from 'components';
import { EmptyInboxIcon } from 'icons';
import { Container } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
// Images - Icons
import EmptyBox from 'assets/images/background/empty-box.png';

function Orders() {
  const { id } = useContext(Context);
  const { notification, createMessage, closeMessage } = useNotification();
  const [ordersLoading, setOrdersLoading] = useState(true);
  const [ordersList, setOrdersList] = useState([]);

  const fetchListOrders = async () => {
    setOrdersLoading(true);
    const res = await orderService.listByUser(id);
    // console.log(res);
    if (!res.info.error) {
      setOrdersList(res.data.reverse());
    } else {
      createMessage.error(res.info.message);
    }
    setOrdersLoading(false);
  };

  useEffect(() => {
    fetchListOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="cate-order">
      <Container maxWidth="lg">
        <div className="page-title">
          <h1>Pedidos</h1>
        </div>
        {!ordersLoading ? (
          <div className="order-group">
            {ordersList.length > 0 ? (
              <>
                {ordersList.map((item, index) => (
                  <CatePaper disableElevation disableBorder key={index} className="order-item">
                    <div className="order-item__head order-item--box">
                      <div className="box-inner">
                        <div className="head-wrap">
                          <div className="head-left">
                            <div className="row">
                              <p className="title">Pedido realizado</p>
                              <p>
                                {format(
                                  new Date(item.order.created_at),
                                  "dd 'de' MMMM 'de' yyyy",
                                  {
                                    locale: es,
                                  },
                                )}
                              </p>
                            </div>
                            <div className="row">
                              <p className="title">Total</p>
                              <p className="price">S/. {item.order.total}</p>
                            </div>
                            <div className="row">
                              <p className="title">Estado</p>
                              <OrderState status={item.order.status} />
                            </div>
                          </div>
                          <div className="head-right">
                            <div className="order-id">
                              <p className="first-row">Pedido N.º {item.order.id}</p>
                            </div>
                            <div className="order-actions">
                              <p>
                                <RouterLink
                                  className="nui-no-decoration"
                                  to={replacePathParams(ROUTES.USER_PURCHASES_BY_ID, {
                                    id: item.order.id,
                                  })}>
                                  Detalles del pedido
                                </RouterLink>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="order-item__body order-item--box">
                      <div className="box-inner">
                        {item.items.length > 0 ? (
                          <>
                            {item.items.map((subItem, subIndex) => {
                              let propertyData = null;
                              if (subItem.product_poperty_detail_id) {
                                const t = subItem.property.detail.find(
                                  (e) => e.id === subItem.product_poperty_detail_id,
                                );
                                propertyData = t;
                              }
                              return (
                                <div className="product-wrap" key={subIndex}>
                                  <div className="product-info">
                                    <div className="product-left">
                                      <RouterLink
                                        to={replacePathParams(ROUTES.PRODUCTS_BY_SLUG, {
                                          slug: subItem.slug,
                                        })}
                                        target="_blank">
                                        <img
                                          src={
                                            subItem.image
                                              ? VARIABLES.IMAGE_PATH + subItem.image.image_path
                                              : VARIABLES.IMAGE_FALLBACK
                                          }
                                          alt="producto"
                                        />
                                      </RouterLink>
                                    </div>
                                    <div className="product-right">
                                      <p className="product-title">
                                        <RouterLink
                                          className="nui-no-decoration"
                                          to={replacePathParams(ROUTES.PRODUCTS_BY_SLUG, {
                                            slug: subItem.slug,
                                          })}
                                          target="_blank">
                                          {subItem.label}
                                        </RouterLink>
                                      </p>
                                      <p className="product-category">
                                        <span>Categoria:</span> {subItem.category.category}
                                      </p>
                                      {propertyData && (
                                        <p className="product-property">
                                          <span>Propiedades de artículo: </span>
                                          {propertyData.label}
                                        </p>
                                      )}
                                      <p className="product-amount">
                                        {propertyData ? (
                                          <span> S/. {propertyData.price} </span>
                                        ) : (
                                          <span> S/. {subItem.price} </span>
                                        )}
                                        <span>x {subItem.quantity}</span>
                                      </p>
                                      <p className="product-policy"></p>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </>
                        ) : (
                          <div className="product-empty">
                            <img src={EmptyBox} alt="empty-box" />
                            <p>No se encontraron productos</p>
                          </div>
                        )}
                      </div>
                    </div>
                  </CatePaper>
                ))}
              </>
            ) : (
              <div className="order-empty">
                <EmptyInboxIcon />
                <p>No se realizó ningún pedido</p>
              </div>
            )}
          </div>
        ) : (
          <div className="order-group">
            {Array.from(new Array(4)).map((item, index) => (
              <CatePaper disableElevation disableBorder key={index} className="order-item">
                <Skeleton variant="rect" />
              </CatePaper>
            ))}
          </div>
        )}
      </Container>
      {notification.state && <Notification onClose={closeMessage} {...notification} />}
    </div>
  );
}

export default Orders;
