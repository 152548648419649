import React, { useState, useContext } from 'react';
import { Context } from 'context/Context';
import { useResponsive } from 'hooks';
import PropTypes from 'prop-types';
import * as VARIABLES from 'constants/variables';
import './productModal.scss';
// Components
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import RemoveRoundedIcon from '@material-ui/icons/RemoveRounded';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';

function ProductModal(props) {
  const { data, open = false, onClose } = props;
  const { addProduct } = useContext(Context);
  const { isMobile } = useResponsive();
  const [count, setCount] = useState(1);

  const handleAddCount = (e) => {
    if (e) {
      setCount(count + 1);
    } else if (count === 1) {
      setCount(1);
    } else {
      setCount(count - 1);
    }
  };

  if (!data) {
    return <div></div>;
  }

  return (
    <Dialog
      className="product-detail-modal"
      fullScreen={isMobile}
      open={open}
      onClose={onClose}
      aria-labelledby="dialog-product">
      <div className="product-modal">
        {isMobile && (
          <div className="product-modal__appbar">
            <Button aria-label="goBack" onClick={onClose}>
              <ArrowBackRoundedIcon />
            </Button>
            <div className="appbar-title">{data.label}</div>
          </div>
        )}
        <div className="product-modal__left">
          <div className="modal-left-category">
            <Chip size="small" color="primary" label={data.category.category} />
          </div>
          <div className="modal-left-image">
            <img
              src={
                data.images[0]
                  ? VARIABLES.IMAGE_PATH + data.images[0].image_path
                  : VARIABLES.IMAGE_FALLBACK
              }
              alt={data.label}
            />
          </div>
        </div>
        <div className="product-modal__right">
          <div className="modal-body-top">
            <div className="modal-title">{data.label}</div>
            <div className="modal-desc">{data.description}</div>
          </div>
          <div className="modal-body-bottom">
            <div className="modal-price">
              <span>S/. </span>
              <span>{data.price}</span>
            </div>
            <div className="modal-actions">
              <div className="modal-actions__counter">
                <Button variant="contained" onClick={() => handleAddCount(false)}>
                  <RemoveRoundedIcon />
                </Button>
                <div className="counter-display">{count}</div>
                <Button variant="contained" onClick={() => handleAddCount(true)}>
                  <AddRoundedIcon />
                </Button>
              </div>
              <div className="modal-actions__buy">
                <Button
                  variant="contained"
                  color="primary"
                  disableElevation
                  onClick={() => addProduct(data)}>
                  Agregrar
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
}

ProductModal.propTypes = {
  data: PropTypes.object,
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default ProductModal;
