import SvgIcon from '@material-ui/core/SvgIcon';

const AmericanExpressSvg = () => (
  <g>
    <polygon
      xmlns="http://www.w3.org/2000/svg"
      points="43.2,207.6 60.8,207.6 52,185.2 "
      fill="#228fe0"
      data-original="#2fabf7"
    />
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="M261.6,188.4c-1.6-0.8-4-0.8-6.4-0.8h-16v12.8h16c2.4,0,4.8,0,6.4-0.8s2.4-3.2,2.4-5.6  C264.8,190.8,263.2,189.2,261.6,188.4z"
      fill="#228fe0"
      data-original="#228fe0"
    />
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="M432.8,164.4v9.6l-4.8-9.6h-37.6v9.6l-4.8-9.6h-51.2c-8.8,0-16,1.6-22.4,4.8v-4.8h-36v4.8  c-4-3.2-8.8-4.8-15.2-4.8H132l-8.8,20l-8.8-20H73.6v9.6l-4.8-9.6H34.4l-16,37.6L0,243.6h18.4h22.4l4.8-12.8h11.2l4.8,12.8H108V234  l4,9.6h23.2l4-9.6v9.6h111.2v-20.8h1.6c1.6,0,1.6,0,1.6,2.4v17.6h57.6V238c4.8,2.4,12,4.8,21.6,4.8h24l4.8-12.8h11.2l4.8,12.8H424  v-12l7.2,12h37.6v-78.4H432.8L432.8,164.4z M162.4,231.6h-13.6v-44l-19.2,44h-12l-19.2-44v44H71.2l-5.6-12H38.4l-4.8,12.8H18.4  l24-56.8h20l22.4,53.6v-53.6h21.6L124,214l16-38.4h22.4V231.6z M216.8,187.6h-31.2V198H216v11.2h-30.4v11.2h31.2v12H172v-56.8h44.8  V187.6z M276.8,210.8c1.6,3.2,2.4,5.6,2.4,10.4v11.2h-13.6v-7.2c0-3.2,0-8-2.4-11.2c-2.4-2.4-4.8-2.4-9.6-2.4h-14.4v20.8h-13.6  v-56.8H256c7.2,0,12,0,16,2.4s6.4,6.4,6.4,12.8c0,8.8-5.6,13.6-9.6,15.2C272.8,206.8,275.2,209.2,276.8,210.8z M300.8,231.6h-13.6  v-56.8h13.6V231.6z M458.4,231.6h-19.2l-25.6-42.4v42.4h-27.2l-4.8-12h-28l-4.8,12.8h-15.2c-6.4,0-14.4-1.6-19.2-6.4  c-4.8-4.8-7.2-11.2-7.2-21.6c0-8,1.6-16,7.2-22.4c4-4.8,11.2-6.4,20-6.4h12.8v12h-12.8c-4.8,0-7.2,0.8-10.4,3.2  c-2.4,2.4-4,7.2-4,12.8c0,6.4,0.8,10.4,4,13.6c2.4,2.4,5.6,3.2,9.6,3.2h5.6l18.4-44h20L400,230v-53.6h20l23.2,39.2v-39.2h13.6v55.2  L458.4,231.6L458.4,231.6z"
      fill="#228fe0"
      data-original="#0571c1"
    />
    <g xmlns="http://www.w3.org/2000/svg">
      <polygon
        points="358.4,207.6 376.8,207.6 368,185.2  "
        fill="#228fe0"
        data-original="#228fe0"
      />
      <polygon
        points="222.4,322.8 222.4,277.2 201.6,299.6  "
        fill="#228fe0"
        data-original="#228fe0"
      />
    </g>
    <polygon
      xmlns="http://www.w3.org/2000/svg"
      points="136.8,282.8 136.8,293.2 166.4,293.2 166.4,304.4 136.8,304.4 136.8,316.4 169.6,316.4   184.8,299.6 170.4,282.8 "
      fill="#228fe0"
      data-original="#2fabf7"
    />
    <path
      xmlns="http://www.w3.org/2000/svg"
      id="SVGCleanerId_0"
      d="M252.8,282.8H236v14.4h17.6c4.8,0,8-2.4,8-7.2  C260.8,285.2,257.6,282.8,252.8,282.8z"
      fill="#228fe0"
      data-original="#228fe0"
    />
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="M500,296.4v-36h-9.6h-24c-7.2,0-12.8,1.6-16.8,4.8v-4.8h-36.8c-5.6,0-12.8,1.6-16,4.8v-4.8H332v4.8  c-4.8-4-13.6-4.8-17.6-4.8h-43.2v4.8c-4-4-13.6-4.8-18.4-4.8h-48l-11.2,12l-10.4-12h-72v78.4h70.4l11.2-12l10.4,12h43.2v-18.4h5.6  c5.6,0,12.8,0,18.4-2.4v21.6h36v-20.8h1.6c2.4,0,2.4,0,2.4,2.4v18.4h108.8c7.2,0,14.4-1.6,18.4-4.8v4.8H472c7.2,0,14.4-0.8,19.2-4  l0,0c8-4.8,12.8-13.6,12.8-24C504,306,502.4,300.4,500,296.4z M252,309.2h-16v19.2h-25.6l-16-18.4l-16.8,18.4h-52.8v-56.8h53.6  l16,18.4l16.8-18.4h42.4c10.4,0,22.4,3.2,22.4,18.4C275.2,306,264,309.2,252,309.2z M332,306c1.6,2.4,2.4,5.6,2.4,10.4v11.2h-13.6  v-7.2c0-3.2,0-8.8-2.4-11.2c-1.6-2.4-4.8-2.4-9.6-2.4h-14.4v20.8h-13.6v-56.8h30.4c6.4,0,12,0,16,2.4s7.2,6.4,7.2,12.8  c0,8.8-5.6,13.6-9.6,15.2C328.8,302.8,331.2,304.4,332,306z M387.2,282.8H356v10.4h30.4v11.2H356v11.2h31.2v12h-44.8v-56.8h44.8  V282.8z M420.8,327.6h-25.6v-12h25.6c2.4,0,4,0,5.6-1.6c0.8-0.8,1.6-2.4,1.6-4s-0.8-3.2-1.6-4c-0.8-0.8-2.4-1.6-4.8-1.6  c-12.8-0.8-28,0-28-17.6c0-8,4.8-16.8,19.2-16.8h26.4v13.6h-24.8c-2.4,0-4,0-5.6,0.8s-1.6,2.4-1.6,4c0,2.4,1.6,3.2,3.2,4  s3.2,0.8,4.8,0.8h7.2c7.2,0,12,1.6,15.2,4.8c2.4,2.4,4,6.4,4,12C441.6,322,434.4,327.6,420.8,327.6z M489.6,322  c-3.2,3.2-8.8,5.6-16.8,5.6h-25.6v-12h25.6c2.4,0,4,0,5.6-1.6c0.8-0.8,1.6-2.4,1.6-4s-0.8-3.2-1.6-4c-0.8-0.8-2.4-1.6-4.8-1.6  c-12.8-0.8-28,0-28-17.6c0-8,4.8-16.8,19.2-16.8h26.4v13.6h-24c-2.4,0-4,0-5.6,0.8s-1.6,2.4-1.6,4c0,2.4,0.8,3.2,3.2,4  c1.6,0.8,3.2,0.8,4.8,0.8h7.2c7.2,0,12,1.6,15.2,4.8c0.8,0,0.8,0.8,0.8,0.8c2.4,3.2,3.2,7.2,3.2,11.2  C494.4,314.8,492.8,318.8,489.6,322z"
      fill="#228fe0"
      data-original="#0571c1"
    />
    <path
      xmlns="http://www.w3.org/2000/svg"
      id="SVGCleanerId_1"
      d="M317.6,284.4c-1.6-0.8-4-0.8-6.4-0.8h-16v12.8h16c2.4,0,4.8,0,6.4-0.8  s2.4-3.2,2.4-5.6C320.8,286.8,319.2,285.2,317.6,284.4z"
      fill="#228fe0"
      data-original="#228fe0"
    />
    <g xmlns="http://www.w3.org/2000/svg">
      <path
        d="M261.6,188.4c-1.6-0.8-4-0.8-6.4-0.8h-16v12.8h16c2.4,0,4.8,0,6.4-0.8s2.4-3.2,2.4-5.6   C264.8,190.8,263.2,189.2,261.6,188.4z"
        fill="#228fe0"
        data-original="#228fe0"
      />
      <polygon
        points="358.4,207.6 376.8,207.6 368,185.2  "
        fill="#228fe0"
        data-original="#228fe0"
      />
      <polygon
        points="222.4,322.8 222.4,277.2 201.6,299.6  "
        fill="#228fe0"
        data-original="#228fe0"
      />
    </g>
    <g xmlns="http://www.w3.org/2000/svg">
      <path
        id="SVGCleanerId_0_1_"
        d="M252.8,282.8H236v14.4h17.6c4.8,0,8-2.4,8-7.2   C260.8,285.2,257.6,282.8,252.8,282.8z"
        fill="#228fe0"
        data-original="#228fe0"
      />
    </g>
    <g xmlns="http://www.w3.org/2000/svg">
      <path
        id="SVGCleanerId_1_1_"
        d="M317.6,284.4c-1.6-0.8-4-0.8-6.4-0.8h-16v12.8h16c2.4,0,4.8,0,6.4-0.8   s2.4-3.2,2.4-5.6C320.8,286.8,319.2,285.2,317.6,284.4z"
        fill="#228fe0"
        data-original="#228fe0"
      />
    </g>
    <g xmlns="http://www.w3.org/2000/svg">
      <polygon
        points="247.2,326 236,314 236,327.6 209.6,327.6 193.6,309.2 176,327.6 123.2,327.6 123.2,271.6    176.8,271.6 193.6,290 201.6,280.4 181.6,260.4 111.2,260.4 111.2,338.8 181.6,338.8 193.6,326.8 204,338.8 247.2,338.8  "
        fill="#228fe0"
        data-original="#2fabf7"
      />
      <polygon
        points="164,242.8 153.6,231.6 148.8,231.6 148.8,226.8 136.8,214.8 128.8,231.6 117.6,231.6    98.4,187.6 98.4,231.6 71.2,231.6 65.6,219.6 38.4,219.6 32.8,231.6 18.4,231.6 42.4,175.6 62.4,175.6 84.8,229.2 84.8,175.6    96.8,175.6 85.6,164.4 73.6,164.4 73.6,174 69.6,164.4 34.4,164.4 18.4,202 0,242.8 18.4,242.8 41.6,242.8 46.4,230.8 57.6,230.8    63.2,242.8 108,242.8 108,233.2 112,242.8 135.2,242.8 139.2,233.2 139.2,242.8  "
        fill="#228fe0"
        data-original="#2fabf7"
      />
      <polygon
        points="127.2,206 114.4,193.2 124,214  "
        fill="#228fe0"
        data-original="#2fabf7"
      />
    </g>
    <g xmlns="http://www.w3.org/2000/svg">
      <path
        d="M491.2,334.8c7.2-4.8,12-12.8,12.8-21.6L492.8,302c0.8,2.4,1.6,4.8,1.6,8c0,4.8-1.6,8.8-4.8,12   c-3.2,3.2-8.8,5.6-16.8,5.6h-25.6v-12h25.6c2.4,0,4,0,5.6-1.6c0.8-0.8,1.6-2.4,1.6-4s-0.8-3.2-1.6-4c-0.8-0.8-2.4-1.6-4.8-1.6   c-12.8-0.8-28,0-28-17.6c0-8,4.8-15.2,16.8-16.8l-8.8-8.8c-1.6,0.8-2.4,1.6-3.2,1.6V258h-36.8c-5.6,0-12.8,1.6-16,4.8V258H332v4.8   c-4.8-4-13.6-4.8-17.6-4.8h-43.2v4.8c-4-4-13.6-4.8-18.4-4.8h-48l-11.2,12l-10.4-12h-8.8l24,24l12-12.8h42.4   c10.4,0,22.4,3.2,22.4,18.4c0,16-11.2,19.2-23.2,19.2h-16v12l12,12v-12h4c5.6,0,12.8,0,18.4-2.4V338h36v-20.8h1.6   c2.4,0,2.4,0,2.4,2.4V338h108.8c7.2,0,14.4-1.6,18.4-4.8v4.8H472C478.4,338.8,485.6,338,491.2,334.8L491.2,334.8z M332,306   c1.6,2.4,2.4,5.6,2.4,10.4v11.2h-13.6v-7.2c0-3.2,0-8.8-2.4-11.2c-1.6-2.4-4.8-2.4-9.6-2.4h-14.4v20.8h-13.6v-56.8h30.4   c6.4,0,12,0,16,2.4s7.2,6.4,7.2,12.8c0,8.8-5.6,13.6-9.6,15.2C328.8,302.8,331.2,304.4,332,306z M387.2,282.8H356v10.4h30.4v11.2   H356v11.2h31.2v12h-44.8v-56.8h44.8V282.8z M420.8,327.6h-25.6v-12h25.6c2.4,0,4,0,5.6-1.6c0.8-0.8,1.6-2.4,1.6-4s-0.8-3.2-1.6-4   c-0.8-0.8-2.4-1.6-4.8-1.6c-12.8-0.8-28,0-28-17.6c0-8,4.8-16.8,19.2-16.8h26.4v13.6h-24.8c-2.4,0-4,0-5.6,0.8s-1.6,2.4-1.6,4   c0,2.4,1.6,3.2,3.2,4s3.2,0.8,4.8,0.8h7.2c7.2,0,12,1.6,15.2,4.8c2.4,2.4,4,6.4,4,12C441.6,322,434.4,327.6,420.8,327.6z"
        fill="#228fe0"
        data-original="#228fe0"
      />
      <path
        d="M459.2,288.4c0,2.4,0.8,3.2,3.2,4c1.6,0.8,3.2,0.8,4.8,0.8h7.2c4.8,0,8,0.8,11.2,2.4l-12-12h-7.2   c-2.4,0-4,0-5.6,0.8C460,285.2,459.2,286.8,459.2,288.4z"
        fill="#228fe0"
        data-original="#228fe0"
      />
      <polygon
        points="431.2,240.4 432.8,242.8 433.6,242.8  "
        fill="#228fe0"
        data-original="#228fe0"
      />
      <polygon
        points="387.2,196.4 400.8,229.2 400.8,210  "
        fill="#228fe0"
        data-original="#228fe0"
      />
      <path
        d="M250.4,222.8h1.6c1.6,0,1.6,0,1.6,2.4v17.6h57.6V238c4.8,2.4,12,4.8,21.6,4.8h24l4.8-12.8h11.2   l4.8,12.8H424v-8l-11.2-11.2v8.8h-27.2l-4-12.8h-28l-4.8,12.8h-15.2c-6.4,0-14.4-1.6-19.2-6.4c-4.8-4.8-7.2-11.2-7.2-21.6   c0-8,1.6-16,7.2-22.4c4-4.8,11.2-6.4,20-6.4h12.8v12h-12.8c-4.8,0-7.2,0.8-10.4,3.2c-2.4,2.4-4,7.2-4,12.8c0,6.4,0.8,10.4,4,13.6   c2.4,2.4,5.6,3.2,9.6,3.2h5.6l18.4-44h8l-11.2-11.2h-20.8c-8.8,0-16,1.6-22.4,4.8v-4.8H276v4.8c-4-3.2-8.8-4.8-15.2-4.8H132   l-8.8,20l-8.8-20H79.2l11.2,11.2h16L120,206l4.8,4.8l14.4-35.2h22.4v56.8H148v-44l-13.6,32l23.2,23.2h92L250.4,222.8z M287.2,175.6   h13.6v56.8h-13.6V175.6z M216.8,187.6h-31.2V198H216v11.2h-30.4v11.2h31.2v12H172v-56.8h44.8V187.6z M239.2,231.6h-13.6v-56.8H256   c7.2,0,12,0,16,2.4s6.4,6.4,6.4,12.8c0,8.8-5.6,13.6-9.6,15.2c3.2,0.8,5.6,3.2,6.4,4.8c1.6,3.2,2.4,5.6,2.4,10.4v11.2H264v-7.2   c0-3.2,0-8-2.4-11.2c-0.8-1.6-3.2-1.6-8-1.6h-14.4L239.2,231.6L239.2,231.6z"
        fill="#228fe0"
        data-original="#228fe0"
      />
    </g>
    <g xmlns="http://www.w3.org/2000/svg"></g>
    <g xmlns="http://www.w3.org/2000/svg"></g>
    <g xmlns="http://www.w3.org/2000/svg"></g>
    <g xmlns="http://www.w3.org/2000/svg"></g>
    <g xmlns="http://www.w3.org/2000/svg"></g>
    <g xmlns="http://www.w3.org/2000/svg"></g>
    <g xmlns="http://www.w3.org/2000/svg"></g>
    <g xmlns="http://www.w3.org/2000/svg"></g>
    <g xmlns="http://www.w3.org/2000/svg"></g>
    <g xmlns="http://www.w3.org/2000/svg"></g>
    <g xmlns="http://www.w3.org/2000/svg"></g>
    <g xmlns="http://www.w3.org/2000/svg"></g>
    <g xmlns="http://www.w3.org/2000/svg"></g>
    <g xmlns="http://www.w3.org/2000/svg"></g>
    <g xmlns="http://www.w3.org/2000/svg"></g>
  </g>
);

const AmericanExpress = (props) => (
  <SvgIcon
    viewBox="0 0 504 504"
    xmlSpace="preserve"
    style={{ enableBackground: 'new 0 0 512 512' }}
    {...props}>
    <AmericanExpressSvg />
  </SvgIcon>
);

export default AmericanExpress;
