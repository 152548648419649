import React, { useState, useEffect, useContext } from 'react';
import { subscriptionService } from 'services';
import { useHistory } from 'react-router-dom';
import { Context } from 'context/Context';
import { useNotification } from 'hooks';
import PropTypes from 'prop-types';
import * as ROUTES from 'constants/routes';
import './subscribe.scss';
import * as VARIABLES from 'constants/variables';
// Components
import { Notification, CatePaper, AuthDialog } from 'components';
import { Container, Grid, Button } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { PriceCard } from 'views/Memberships/Memberships';
//  Icons
import Confirmacion from 'assets/images/icons/confirmacion.png';

import Traslate from 'i18n/traslate';
import ELEMENTS from 'constants/elementsForTranslate';

const layout = { sm: 10, md: 6, lg: 4 };

const Item = ({ text, redirectToPlan, planId, color }) => (
  <div className="item">
    <li>
      <img className="list-icon" src={Confirmacion} alt="icono" />
      <span className="list-text">{text}</span>
    </li>
    <Button
      variant="contained"
      color={color === 'primary' ? 'inherit' : 'primary'}
      onClick={() => redirectToPlan(planId)}>
      Suscríbete
    </Button>
  </div>
);

const Subscribe = (props) => {
  const { id } = props;
  const { id: userId, locale } = useContext(Context);
  // eslint-disable-next-line no-unused-vars
  const history = useHistory();
  const { notification, createMessage, closeMessage } = useNotification();
  const [authModalOpen, setAuthModalOpen] = useState(false);
  const [subscriptionList, setSubscriptionList] = useState([]);
  const [deliveryPriceList, setDeliveryPriceList] = useState([]);

  const handleAuthModal = (arg) => {
    setAuthModalOpen(arg);
  };

  const fetchListSubscriptions = async () => {
    const res = await subscriptionService.list();
    if (!res.info.error) {
      setSubscriptionList(res.data);
    } else {
      setSubscriptionList([]);
      createMessage.error(res.info.message);
    }
  };

  const fetchDeliveryPriceList = async () => {
    subscriptionService
      .listDeliveryPrices()
      .then((response) => {
        if (!response.info.error) {
          localStorage.setItem(
            VARIABLES.LOCAL_STORAGE.DELIVERY_PRICE_LIST,
            JSON.stringify(response.data),
          );
          localStorage.setItem(
            VARIABLES.LOCAL_STORAGE.DELIVERY_PRICE_LIST_DATE_LOADED,
            new Date().toString(),
          );
          setDeliveryPriceList(response.data);
        } else {
          createMessage.error(response.info.message);
          setDeliveryPriceList([]);
        }
      })
      .catch((reason) => {
        console.log(reason);
        setDeliveryPriceList([]);
      });
  };

  useEffect(() => {
    fetchListSubscriptions();
    fetchDeliveryPriceList();
  }, []);

  return (
    <section id={id} className="cate-home-subscribe">
      <Container maxWidth="lg">
        <div className="cate-home-subscribe__header">
          <h2>{Traslate(ELEMENTS.SUBSCRIBE.TITLE)}</h2>
          <p>{Traslate(ELEMENTS.SUBSCRIBE.SUBTITLE)}</p>
        </div>
        {subscriptionList.length > 0 && Object.keys(deliveryPriceList).length > 0 ? (
          <Grid container spacing={3}>
            {subscriptionList.length > 0 ? (
              subscriptionList.slice(0, 3).map((item, index) => (
                <Grid item key={index} {...layout}>
                  <PriceCard
                    color={!(index & 1) ? 'default' : 'primary'}
                    data={item}
                    createMessage={createMessage}
                    deliveryPriceList={deliveryPriceList}
                    id={userId}
                    locale={locale}
                  />
                </Grid>
              ))
            ) : (
              <></>
            )}
          </Grid>
        ) : (
          <Grid container spacing={3}>
            {Array.from(new Array(3)).map((item, index) => (
              <Grid item key={index} {...layout}>
                <CatePaper disableElevation>
                  <Skeleton
                    variant="rect"
                    width="100%"
                    height={608}
                    style={{ borderRadius: 'inherit' }}
                  />
                </CatePaper>
              </Grid>
            ))}
          </Grid>
        )}
      </Container>
      <AuthDialog
        open={authModalOpen}
        onClose={() => handleAuthModal(false)}
        location={ROUTES.MEMBERSHIPS}
      />
      {notification.state && <Notification onClose={closeMessage} {...notification} />}
    </section>
  );
};

Subscribe.propTypes = {
  id: PropTypes.string,
};

export default Subscribe;
