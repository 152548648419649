import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import Context from 'context';
import PropTypes from 'prop-types';
import * as ROUTES from 'constants/routes';

const RouteWithLayout = (props) => {
  const { layout: Layout, component: Component, auth = false, ...rest } = props;

  if (auth) {
    return (
      <Context.Consumer>
        {({ id, token }) => {
          if (id && token) {
            return (
              <Route
                {...rest}
                render={(matchProps) => (
                  <Layout>
                    <Component {...matchProps} />
                  </Layout>
                )}
              />
            );
          } else {
            return (
              <Route
                {...rest}
                render={(matchProps) => (
                  <Redirect
                    to={{
                      pathname: ROUTES.LOGIN,
                      state: { from: matchProps.location },
                    }}
                  />
                )}
              />
            );
          }
        }}
      </Context.Consumer>
    );
  }

  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <Layout>
          <Component {...matchProps} />
        </Layout>
      )}
    />
  );

  // if (isItVerified) {
  //   return (
  //     <Route
  //       {...rest}
  //       render={(matchProps) => (
  //         <Layout>
  //           <Component {...matchProps} />
  //         </Layout>
  //       )}
  //     />
  //   );
  // } else {
  //   return (
  //     <Route
  //       {...rest}
  //       render={(matchProps) => (
  //         <Redirect
  //           to={{
  //             pathname: ROUTES.CONFIRM_EMAIL,
  //             state: { from: matchProps.location },
  //           }}
  //         />
  //       )}
  //     />
  //   );
  // }
};

RouteWithLayout.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
  auth: PropTypes.bool,
  isItVerified: PropTypes.bool,
  path: PropTypes.string,
};

export default RouteWithLayout;
