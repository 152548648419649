const MuiBadge = {
  root: {
    // textTransform: 'initial',
  },
  badge: {
    fontSize: '1.2rem',
  },
};

export default MuiBadge;
