import SvgIcon from '@material-ui/core/SvgIcon';

const EmptyShoppingBagSvg = () => (
  <g>
    <path d="m419.263 346.467c-4.142 0-7.5 3.358-7.5 7.5v115.702c0 15.07-12.261 27.331-27.332 27.331h-256.862c-15.071 0-27.332-12.261-27.332-27.331v-285.137h73.026c4.142 0 7.5-3.358 7.5-7.5s-3.358-7.5-7.5-7.5h-30.334v-25.742h-.01c0-1.955-.754-3.841-2.131-5.247l-20.065-20.495h44.167c4.464 14.451 17.946 24.981 33.843 24.981s29.379-10.53 33.843-24.981h61.735c4.142 0 7.5-3.358 7.5-7.5s-3.358-7.5-7.5-7.5h-60.47c-2.249-17.375-17.13-30.845-35.108-30.845s-32.859 13.469-35.108 30.845h-60.74c-.091 0-.18.012-.271.014-.143.005-.286.009-.429.022-9.405.372-16.947 8.117-16.947 17.612v348.973c0 23.341 18.99 42.331 42.332 42.331h256.862c23.342 0 42.332-18.99 42.332-42.331v-115.702c-.001-4.142-3.359-7.5-7.501-7.5zm-304.686-176.935 13.352-10.396v10.396zm-14.34-7.845v-40.991c0-.595.204-1.138.537-1.581l23.418 23.919zm98.495-74.483c11.255 0 20.413 9.157 20.413 20.413s-9.157 20.413-20.413 20.413-20.413-9.157-20.413-20.413c.001-11.256 9.158-20.413 20.413-20.413z" />
    <path d="m409.814 103.084c-.138-.013-.276-.016-.414-.021-.096-.001-.189-.014-.285-.014h-77.779c-4.142 0-7.5 3.358-7.5 7.5s3.358 7.5 7.5 7.5h59.941l-20.065 20.495c-1.377 1.406-2.131 3.292-2.131 5.247h-.01v25.742h-46.196c.003-.179.012-.358.012-.538 0-16.281-13.246-29.527-29.527-29.527s-29.527 13.246-29.527 29.527c0 .18.009.358.012.538h-53.555c-4.142 0-7.5 3.358-7.5 7.5s3.358 7.5 7.5 7.5h201.473v132.408c0 4.142 3.358 7.5 7.5 7.5s7.5-3.358 7.5-7.5v-196.245c0-9.495-7.543-17.241-16.949-17.612zm1.412 16.031c.332.443.537.986.537 1.581v40.991l-23.955-18.653zm-27.155 40.021 13.352 10.396h-13.352zm-105.237 9.859c0-8.01 6.517-14.527 14.527-14.527s14.527 6.517 14.527 14.527c0 .18-.018.358-.025.538h-29.004c-.007-.18-.025-.358-.025-.538z" />
    <path d="m206.232 230.636c0-13.475-10.963-24.438-24.438-24.438s-24.438 10.962-24.438 24.438c0 10.859 7.122 20.082 16.938 23.256v54.011c0 45.053 36.653 81.706 81.706 81.706s81.706-36.653 81.706-81.706v-54.011c9.816-3.174 16.938-12.397 16.938-23.256 0-13.475-10.963-24.438-24.438-24.438s-24.438 10.962-24.438 24.438c0 10.859 7.122 20.082 16.938 23.256v54.011c0 36.782-29.924 66.706-66.706 66.706s-66.706-29.924-66.706-66.706v-54.011c9.817-3.174 16.938-12.397 16.938-23.256zm123.974-9.438c5.204 0 9.438 4.233 9.438 9.438 0 4.614-3.33 8.458-7.712 9.272-.555-.131-1.131-.208-1.726-.208s-1.171.077-1.726.208c-4.382-.814-7.712-4.658-7.712-9.272 0-5.204 4.234-9.438 9.438-9.438zm-148.412 0c5.204 0 9.438 4.233 9.438 9.438s-4.234 9.438-9.438 9.438-9.438-4.234-9.438-9.438 4.234-9.438 9.438-9.438z" />
    <path d="m321.487 71.65c16.281 0 29.527-13.246 29.527-29.527s-13.246-29.527-29.527-29.527-29.527 13.245-29.527 29.526 13.246 29.528 29.527 29.528zm0-44.055c8.01 0 14.527 6.517 14.527 14.527s-6.517 14.527-14.527 14.527-14.527-6.517-14.527-14.527 6.517-14.527 14.527-14.527z" />
    <path d="m135.413 76.723c16.281 0 29.527-13.246 29.527-29.527s-13.246-29.527-29.527-29.527-29.527 13.246-29.527 29.527 13.245 29.527 29.527 29.527zm0-44.054c8.01 0 14.527 6.517 14.527 14.527s-6.517 14.527-14.527 14.527-14.527-6.517-14.527-14.527 6.516-14.527 14.527-14.527z" />
    <path d="m215.885 31.526c1.464 1.464 3.384 2.197 5.303 2.197s3.839-.732 5.303-2.197l4.058-4.058 4.058 4.058c1.464 1.465 3.384 2.197 5.303 2.197s3.839-.732 5.303-2.197c2.929-2.929 2.929-7.677 0-10.606l-4.058-4.058 4.058-4.058c2.929-2.929 2.929-7.678 0-10.606-2.929-2.929-7.678-2.929-10.606 0l-4.058 4.058-4.058-4.058c-2.929-2.929-7.678-2.929-10.606 0-2.929 2.929-2.929 7.677 0 10.606l4.058 4.058-4.058 4.058c-2.929 2.928-2.929 7.677 0 10.606z" />
    <path d="m383.253 85.236c1.464 1.464 3.384 2.197 5.303 2.197s3.839-.732 5.303-2.197l4.058-4.058 4.058 4.058c1.464 1.465 3.384 2.197 5.303 2.197s3.839-.732 5.303-2.197c2.929-2.929 2.929-7.677 0-10.606l-4.058-4.058 4.058-4.058c2.929-2.929 2.929-7.678 0-10.606-2.929-2.929-7.678-2.929-10.606 0l-4.058 4.058-4.058-4.058c-2.928-2.929-7.677-2.929-10.606 0s-2.929 7.677 0 10.606l4.058 4.058-4.058 4.058c-2.929 2.929-2.929 7.678 0 10.606z" />
  </g>
);

const EmptyShoppingBag = (props) => (
  <SvgIcon enableBackground="new 0 0 512 512" viewBox="0 0 512 512" {...props}>
    <EmptyShoppingBagSvg />
  </SvgIcon>
);

export default EmptyShoppingBag;
