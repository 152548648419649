import React, { useState, useEffect, useContext } from 'react';
import { userService, locationService, subscriptionService } from 'services';
import { Context } from 'context/Context';
import { useNotification } from 'hooks';
import * as VARIABLES from 'constants/variables';
import PropTypes from 'prop-types';
import './userProfile.scss';
// Components
import { Notification, CatePaper, CardUserPlan } from 'components';
import { withStyles } from '@material-ui/core/styles';
import { Container, Tabs, Tab, Grid, TextField, MenuItem, Button } from '@material-ui/core';
import ELEMENTS from 'constants/elementsForTranslate';
import Traslate from 'i18n/traslate';

const AntTabs = withStyles({
  root: {
    borderBottom: '0.1rem solid #e8e8e8',
  },
  indicator: {
    backgroundColor: 'var(--color-primary-darker)',
  },
})(Tabs);

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    minWidth: 72,
    marginRight: theme.spacing(4),
    fontSize: '1.6rem',
    fontWeight: 'var(--font-weight-semibold)',
    fontFamily: 'Montserrat',
    '&:hover': {
      color: 'var(--color-primary)',
      opacity: 1,
    },
    '&$selected': {
      color: 'var(--color-primary)',
    },
    '&:focus': {
      color: 'var(--color-primary)',
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}>
      {children}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const InfoRow = ({
  id,
  text,
  type,
  value,
  onChange,
  multiline,
  rows,
  disabled,
  required = true,
}) => (
  <li>
    <label htmlFor={id}>{text}</label>
    <div className="input-row">
      <TextField
        required={required}
        disabled={disabled}
        multiline={multiline}
        rows={rows}
        variant="outlined"
        id={id}
        name={id}
        type={type}
        value={value}
        onChange={onChange}
      />
    </div>
  </li>
);

const SelectRow = ({ id, text, menuData = [], value, onChange, required, disabled }) => (
  <li>
    <label htmlFor={id}>{text}</label>
    <div className="input-row">
      <TextField
        select
        required={required}
        disabled={disabled}
        variant="outlined"
        id={id}
        name={id}
        value={value}
        onChange={onChange}>
        {menuData.map((option) => (
          <MenuItem key={option.id} value={option.name}>
            {option.name}
          </MenuItem>
        ))}
      </TextField>
    </div>
  </li>
);

const InfoPhoneRow = (props) => {
  const { id, prefixId, text, type, value, onChange, prefixValue = '51', disabled } = props;
  return (
    <li>
      <label htmlFor={id}>{text}</label>
      <Grid container spacing={0} className="input-row">
        <Grid item xs={3}>
          <TextField
            select
            disabled={disabled}
            variant="outlined"
            id={prefixId}
            name={prefixId}
            value={prefixValue}
            onChange={onChange}>
            {VARIABLES.PHONE_PREFIX.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={9} style={{ paddingLeft: '2.4rem' }}>
          <TextField
            required
            disabled={disabled}
            variant="outlined"
            id={id}
            name={id}
            type={type}
            inputProps={{
              min: '0',
            }}
            value={value}
            onChange={onChange}
          />
        </Grid>
      </Grid>
    </li>
  );
};

function UserProfile() {
  const { id } = useContext(Context);
  const { notification, createMessage, closeMessage } = useNotification();
  const [value, setValue] = useState(0);
  const [loading, setLoading] = useState(true);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [departments, setDepartments] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const [districts, setDistricts] = useState([]);
  // User data
  const [userObj, setUserObj] = useState({});
  const [userDetailObj, setUserDetailObj] = useState({});
  const [subsList, setSubsList] = useState([]);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleUserInputChange = (e) => {
    setUserObj({
      ...userObj,
      [e.target.name]: e.target.value,
    });
  };

  const handleUserDetailInputChange = (e) => {
    setUserDetailObj({
      ...userDetailObj,
      [e.target.name]: e.target.value,
    });
  };

  const handleUserSubmit = (e) => {
    e.preventDefault();
    fetchUpdateUser();
  };

  const handleUserDetailSubmit = (e) => {
    e.preventDefault();
    if (!userDetailObj.document_number) {
      createMessage.warning('Campo número de documento vacío.');
      return;
    }
    if (!userDetailObj.address) {
      createMessage.warning('Campo dirección vacío.');
      return;
    }
    fetchUpdateUserDetail();
  };

  const handleSubscriptionDelete = (subId) => {
    if (subId) {
      fetchDeleteSubscription(id);
    }
  };

  const fetchListSubscription = async () => {
    const res = await subscriptionService.listByUser(id);
    if (!res.info.error) {
      setSubsList(res.data);
    }
  };

  const fetchDeleteSubscription = async () => {
    const res = await subscriptionService.delete(id);
    if (!res.info.error) {
      createMessage.warning(res.info.message);
    } else {
      createMessage.error(res.info.message);
    }
  };

  const fetchShowUser = async () => {
    setLoading(true);
    const res = await userService.show(id);
    // console.log(res.data);
    if (!res.info.error) {
      setUserObj(res.data);
      if (res.data.user_detail) {
        setUserDetailObj(res.data.user_detail);
        if (res.data.user_detail.department) {
          onDepartmentChange(res.data.user_detail.department);
        }
        if (res.data.user_detail.provincia) {
          onProvincesChange(res.data.user_detail.provincia);
        }
      } else {
        setUserDetailObj({
          department: '',
          provincia: '',
          district: '',
          address: '',
          document_number: '',
        });
      }
    } else {
      createMessage.error(res.info.message);
    }
    setLoading(false);
  };

  const fetchUpdateUser = async () => {
    setBtnDisabled(true);
    createMessage.info('Enviando datos...');
    const res = await userService.update({
      id: id,
      name: userObj.name,
      lastName: userObj.last_name,
      password: userObj.password,
      email: userObj.email,
      phoneCode: userObj.phone_code,
      phoneNumer: userObj.phone_numer,
    });
    // console.log(res.data);
    if (!res.info.error) {
      createMessage.success(res.info.message);
      setUserObj(res.data);
    } else {
      createMessage.error(res.info.message);
    }
    setBtnDisabled(false);
  };

  const fetchUpdateUserDetail = async () => {
    setBtnDisabled(true);
    createMessage.info('Enviando datos...');
    const res = await userService.updateDetail({
      id: id,
      department: userDetailObj.department ? userDetailObj.department : '',
      provincia: userDetailObj.provincia ? userDetailObj.provincia : '',
      district: userDetailObj.district ? userDetailObj.district : '',
      address: userDetailObj.address ? userDetailObj.address : '',
      documentNumber: userDetailObj.document_number ? userDetailObj.document_number : '',
    });
    // console.log(res.data);
    if (!res.info.error) {
      createMessage.success(res.info.message);
      setUserDetailObj(res.data);
    } else {
      createMessage.error(res.info.message);
    }
    setBtnDisabled(false);
  };

  const onDepartmentChange = (value) => {
    const depItem = departments.find((item) => item.name === value);
    if (depItem) {
      fetchProvinceList(depItem.id);
    }
  };

  const onProvincesChange = (value) => {
    const prvItem = provinces.find((item) => item.name === value);
    if (prvItem) {
      fetchDistrictList(prvItem.id);
    }
  };

  const fetchDepartmentsList = async () => {
    const res = await locationService.departmentsList();
    // console.log(res);
    if (!res.info.error) {
      setDepartments(res.data);
    } else {
      setDepartments([]);
    }
  };

  const fetchProvinceList = async (departmentId) => {
    const res = await locationService.provinceList(departmentId);
    // console.log(res);
    if (!res.info.error) {
      setProvinces(res.data);
    } else {
      setProvinces([]);
    }
  };

  const fetchDistrictList = async (provinceId) => {
    const res = await locationService.districtList(provinceId);
    // console.log(res);
    if (!res.info.error) {
      setDistricts(res.data);
    } else {
      setDistricts([]);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchShowUser();
    fetchDepartmentsList();
    fetchListSubscription();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userDetailObj.department && departments.length > 0) {
      onDepartmentChange(userDetailObj.department);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetailObj, departments]);

  useEffect(() => {
    if (userDetailObj.provincia && provinces.length > 0) {
      onProvincesChange(userDetailObj.provincia);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetailObj, provinces]);

  return (
    <div className="cate-profile">
      <Container maxWidth="md">
        <div className="profile-title">
          <h1>{Traslate(ELEMENTS.USER_PROFILE.ADMIN)}</h1>
        </div>
        <div>
          <AntTabs
            value={value}
            onChange={handleTabChange}
            variant="scrollable"
            aria-label="tab">
            <AntTab label={Traslate(ELEMENTS.USER_PROFILE.PROFILE)} {...a11yProps(0)} />
            <AntTab label={Traslate(ELEMENTS.NAV.MEMBERSHIPS)} {...a11yProps(1)} />
          </AntTabs>
          <div className="profile-content">
            <TabPanel value={value} index={0}>
              <div className="profile-subtitle">
                <h4>{Traslate(ELEMENTS.USER_PROFILE.BASIC_INFORMATION)}</h4>
                <p className="small">{Traslate(ELEMENTS.USER_PROFILE.CHANGE)}</p>
              </div>
              <CatePaper disableElevation className="profile-box">
                {!loading ? (
                  <form className="profile-form" onSubmit={handleUserSubmit}>
                    <ul className="flex-outer">
                      <InfoRow
                        id="name"
                        text={Traslate(ELEMENTS.CHECKOUT.NAME)}
                        type="text"
                        value={userObj.name}
                        onChange={handleUserInputChange}
                      />
                      <InfoRow
                        id="last_name"
                        text={Traslate(ELEMENTS.CHECKOUT.LAST_NAME)}
                        type="text"
                        value={userObj.last_name}
                        onChange={handleUserInputChange}
                      />
                      <InfoPhoneRow
                        id="phone_number"
                        prefixId="phone_code"
                        text={Traslate(ELEMENTS.BRANCHES.PHONE)}
                        type="number"
                        value={userObj.phone_number}
                        onChange={handleUserInputChange}
                        prefixValue={userObj.phone_code}
                        onPrefixChange={handleUserInputChange}
                      />
                      <InfoRow
                        disabled
                        id="email"
                        text="E-mail*:"
                        type="email"
                        value={userObj.email}
                        onChange={handleUserInputChange}
                      />
                      <li className="last-row">
                        <Button
                          disableElevation
                          disabled={btnDisabled}
                          variant="contained"
                          color="primary"
                          type="submit">
                          {Traslate(ELEMENTS.USER_PROFILE.SAVE)}
                        </Button>
                      </li>
                    </ul>
                  </form>
                ) : (
                  <div></div>
                )}
              </CatePaper>
              <div className="profile-subtitle">
                <h4>Información extra</h4>
                <p className="small">{Traslate(ELEMENTS.USER_PROFILE.CHANGE_UBICATION)}</p>
              </div>
              <CatePaper disableElevation className="profile-box">
                {!loading ? (
                  <form className="profile-form" onSubmit={handleUserDetailSubmit}>
                    <ul className="flex-outer">
                      <InfoRow
                        id="document_number"
                        text={Traslate(ELEMENTS.CHECKOUT.DOCUMENT_NUMBER)}
                        type="text"
                        value={userDetailObj.document_number}
                        onChange={handleUserDetailInputChange}
                      />
                      <InfoRow
                        id="address"
                        text={Traslate(ELEMENTS.BRANCHES.ADDRESS)}
                        type="text"
                        value={userDetailObj.address}
                        onChange={handleUserDetailInputChange}
                      />
                      <SelectRow
                        id="department"
                        text={Traslate(ELEMENTS.SHIPPING.DEPARTMENT)}
                        menuData={departments}
                        value={userDetailObj.department}
                        onChange={handleUserDetailInputChange}
                      />
                      <SelectRow
                        id="provincia"
                        text={Traslate(ELEMENTS.SHIPPING.PROVINCE)}
                        menuData={provinces}
                        value={userDetailObj.provincia}
                        onChange={handleUserDetailInputChange}
                      />
                      <SelectRow
                        id="district"
                        text={Traslate(ELEMENTS.SHIPPING.DISTRICT)}
                        menuData={districts}
                        value={userDetailObj.district}
                        onChange={handleUserDetailInputChange}
                      />
                      <li className="last-row">
                        <Button
                          disableElevation
                          disabled={btnDisabled}
                          variant="contained"
                          color="primary"
                          type="submit">
                          {Traslate(ELEMENTS.USER_PROFILE.SAVE)}
                        </Button>
                      </li>
                    </ul>
                  </form>
                ) : (
                  <div></div>
                )}
              </CatePaper>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <div className="profile-subtitle">
                <h4>Suscripciones activas</h4>
              </div>
              <Grid container spacing={3}>
                {subsList.map((item, index) => (
                  <Grid item key={index} xs={12}>
                    <CardUserPlan
                      title={item.subscription?.title}
                      description={item.subscription?.description}
                      period={item.subscription?.period}
                      price={item.subscription?.price}
                      handleDelete={() => handleSubscriptionDelete(item.id)}
                    />
                  </Grid>
                ))}
              </Grid>
            </TabPanel>
          </div>
        </div>
      </Container>
      {notification.state && <Notification onClose={closeMessage} {...notification} />}
    </div>
  );
}

export default UserProfile;
