import configService from './config';
import fetchWrapper from './fetchWrapper';

const baseUrl = configService.apiUrl;
const productCommentService = {};

//productCommentService

productCommentService.create = async function (arg) {
  const data = await fetchWrapper.post(`${baseUrl}/products/${arg.product_id}/comments`, {
    product_id: arg.product_id,
    user_id: arg.user_id,
    title: arg.title,
    detail: arg.detail,
    rating: arg.rating,
    parent_id: arg.parent_id,
  });

  return data;
};

productCommentService.listByProduct = async function (productId) {
  const data = await fetchWrapper.get(`${baseUrl}/products/${productId}/comments`);

  return data;
};

export default productCommentService;
