import React, { useState, useEffect } from 'react';
import { useNotification } from 'hooks';
import { productService } from 'services';
import './grindTypeSelection.scss';
import { Skeleton, Input } from 'antd';

function GrindTypeSelection({ productSlug, propertyActive, setPropertyActive }) {
  const [isPropertyOther, setIsPropertyOther] = useState(false);
  const [otherGroundProperty, setOtherGroundProperty] = useState('');
  const [loading, setLoading] = useState(true);
  const [product, setProduct] = useState({});
  const { notification, createMessage, closeMessage } = useNotification();

  const handlePropertyActive = (arg) => {
    setPropertyActive(arg);
    localStorage.setItem('product_property_details_id', JSON.stringify(arg.item));
    localStorage.setItem('product_property_detail_other', JSON.stringify(''));
    setOtherGroundProperty('');
  };

  const handleOtherPropertyChange = (e) => {
    localStorage.setItem('product_property_detail_other', JSON.stringify(e.target.value));
    setOtherGroundProperty(e.target.value);
  };

  const fetchShowProduct = async () => {
    setLoading(true);
    const res = await productService.showBySlug(productSlug);
    if (!res.info.error) {
      setProduct(res.data);
      if (res.data.property && res.data.property.detail[0]) {
        setPropertyActive({
          index: 0,
          item: { ...res.data.property.detail[0] },
        });
      }

      setLoading(false);
    } else {
      createMessage.error(res.info.message);
    }
  };

  useEffect(() => {
    fetchShowProduct();
  }, [productSlug]);

  useEffect(() => {
    setOtherGroundProperty('');
    if (propertyActive?.item.label === 'Otro') {
      setIsPropertyOther(true);
    } else {
      setIsPropertyOther(false);
    }
  }, [propertyActive]);

  if (!product.slug) {
    return (
      <Skeleton
        variant="rect"
        width="100%"
        height={608}
        style={{ borderRadius: 'inherit', marginTop: '10px' }}
      />
    );
  }

  if (propertyActive.index === null || propertyActive.index === undefined) {
    return <></>;
  }

  return (
    <div className="product-property">
      <div className="product-property__title">
        {product?.property?.label + ' : '}
        {propertyActive.item.label && <span>{propertyActive.item.label}</span>}
      </div>
      <ul className="product-property__list">
        {product?.property?.detail.map((item, index) => (
          <li
            key={index}
            className={`property-item ${propertyActive.index === index ? 'active' : undefined}`}
            onClick={() => handlePropertyActive({ item, index })}>
            <div className="property-text">
              <span>{item.label}</span>
            </div>
          </li>
        ))}
        {isPropertyOther && (
          <Input
            placeholder="Escriba su tipo de molido"
            value={otherGroundProperty}
            onChange={handleOtherPropertyChange}
          />
        )}
      </ul>
    </div>
  );
}

export default GrindTypeSelection;
