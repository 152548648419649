import React, { useState, useContext } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Context } from 'context/Context';
import { useResponsive } from 'hooks';
import * as ROUTES from 'constants/routes';
import PropTypes from 'prop-types';
import './topBar.scss';
// Components
import { UserPopover, UserDialog } from 'components';
import { AppBar, Toolbar, Button, IconButton, Badge, Menu, MenuItem } from '@material-ui/core';
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import PersonRoundedIcon from '@material-ui/icons/PersonRounded';
import { Switch } from 'antd';
// Images
import BasicLogo from 'assets/images/logo/basic.png';

const ItemLink = ({ path, text }) => (
  <RouterLink to={path} className="nui-no-decoration">
    <Button color="inherit">{text}</Button>
  </RouterLink>
);

const separaNombre = (name) => {
  if (name != null) {
    var nombresSep = name.split(/(?=[A-Z])/);
    return nombresSep;
  } else {
    return null;
  }
};

const getPrimerNombre = (name) => {
  if (name != null) {
    return separaNombre(name)[0];
  } else {
    return null;
  }
};

//const nombresSep = separaNombre(name);

const UserAuthSection = ({ token, name, popoverId, openPopover, changeLanguage }) => (
  <>
    <Switch checkedChildren="EN" unCheckedChildren="ES" onChange={changeLanguage} />
    {token ? (
      <div className="topbar-button-user" onClick={openPopover}>
        <Button
          color="primary"
          aria-describedby={popoverId}
          aria-label="user-data"
          aria-haspopup="true"
          startIcon={<PersonRoundedIcon />}>
          <div className="button-user-text">
            <span>Hola!</span>
            <br />
            {/* 👇️ iterate object VALUES */}
            <span>{name != null ? name : ''}</span>
          </div>
        </Button>
      </div>
    ) : (
      <RouterLink to={ROUTES.LOGIN} className="nui-no-decoration">
        <div className="topbar-button-user">
          <Button variant="outlined" color="primary">
            Iniciar Sesión
          </Button>
        </div>
      </RouterLink>
    )}
  </>
);

function TopBar(props) {
  const { menuClick } = props;
  const { isDesktop } = useResponsive();
  const { name, token, itemCount, toggleLanguage } = useContext(Context);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'user-popover' : undefined;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const primerNombre = getPrimerNombre(name);

  // constantes para submen
  const [anchorElM, setAnchorElM] = React.useState(null);
  const openM = Boolean(anchorElM);
  const handleClickM = (event) => {
    setAnchorElM(event.currentTarget);
  };
  const handleCloseM = () => {
    setAnchorElM(null);
  };
  const handleChangeLanguage = () => {
    toggleLanguage();
  };

  // FIN constantes para submenu

  return (
    <AppBar className="cate-main-topbar" position="fixed" color="inherit" elevation={2}>
      <Toolbar className="cate-main-topbar__tollbar" variant="regular">
        <div className="topbar-left">
          {!isDesktop && (
            <IconButton color="primary" aria-label="menu-btn" onClick={menuClick}>
              <MenuRoundedIcon />
            </IconButton>
          )}
          <div className="topbar-logo">
            <RouterLink to={ROUTES.HOME} className="nui-no-decoration">
              <img src={BasicLogo} alt="Cate Cafe Logo" />
            </RouterLink>
          </div>
          {isDesktop && (
            <div className="topbar-link">
              <ItemLink path={ROUTES.HOME} text="Inicio" />
              <ItemLink path={ROUTES.PRODUCTS} text="Tienda" />
              <ItemLink path={ROUTES.ABOUT} text="Nosotros" />
              <ItemLink path={ROUTES.MEMBERSHIPS} text="Suscripciones" />
              <ItemLink path={ROUTES.EXPERIENCES} text="Experiencias de cata" />
              <ItemLink path={ROUTES.SUCURSALES} text="Sucursales" />

              {/*-----menu de material */}
              <Button
                id="basic-button"
                aria-controls={openM ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={openM ? 'true' : undefined}
                onClick={handleClickM}
                style={{
                  /* style es opcional, solo sirve si el menu tiene un icono */ width: '100px',
                  height: '30px',
                }}>
                Recursos
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorElM}
                open={openM}
                onClose={handleCloseM}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                getContentAnchorEl={null}>
                <MenuItem onClick={handleCloseM}>
                  <ItemLink path={ROUTES.BLOG} text="Blog" />
                </MenuItem>
                <MenuItem onClick={handleCloseM}>
                  <ItemLink path={ROUTES.COURSES} text="Cursos" />
                </MenuItem>
              </Menu>
              {/*FIN de menu de material */}
              {/* <ItemLink path={ROUTES.PRODUCTSB2B} text="B2B" /> */}
              {/* <ItemLink path={ROUTES.ABOUT} text="Nosotros" /> */}
            </div>
          )}
        </div>
        <div className="topbar-right">
          {/* {isDesktop && <SearchInput />} */}
          <UserAuthSection
            token={token}
            name={primerNombre}
            popoverId={id}
            openPopover={handleClick}
            changeLanguage={handleChangeLanguage}
          />
          <RouterLink to={ROUTES.SHOPCART} className="nui-no-decoration">
            <IconButton color="primary" aria-label="shop-car">
              <Badge badgeContent={itemCount} color="error" overlap="rectangular" showZero>
                <ShoppingCartOutlinedIcon />
              </Badge>
            </IconButton>
          </RouterLink>
        </div>
        {isDesktop ? (
          <UserPopover id={id} open={open} anchorEl={anchorEl} onClose={handleClose} />
        ) : (
          <UserDialog open={open} onClose={handleClose} />
        )}
      </Toolbar>
    </AppBar>
  );
}

TopBar.propTypes = {
  menuClick: PropTypes.func,
};

export default TopBar;
