import configService from './config';
import fetchApi from './fetchApi';
import * as METHODS from './methods';

const baseUrl = configService.apiUrl;
const productService = {};

productService.list = async function () {
  const { response } = await fetchApi({
    url: baseUrl + '/products',
    options: METHODS.GET(),
    dataType: [],
    message: {
      success: 'Lista de productos obtenida con éxito.',
      error: 'No se pudo obtener la lista de productos.',
    },
  });

  return { ...response };
};

productService.listByCategory = async function (catId) {
  const { response } = await fetchApi({
    url: baseUrl + '/products/filter/category/' + catId,
    options: METHODS.GET(),
    dataType: [],
    message: {
      success: 'Lista de productos obtenida con éxito.',
      error: 'No se pudo obtener la lista de productos.',
    },
  });

  return { ...response };
};

productService.show = async function (id) {
  const { response } = await fetchApi({
    url: baseUrl + '/products/show/' + id,
    options: METHODS.GET(),
    dataType: {},
    message: {
      success: 'Producto obtenido con éxito.',
      error: 'No se pudo obtener del producto solicitado.',
    },
  });

  return { ...response };
};

productService.showBySlug = async function (slug, userId) {
  const { response } = await fetchApi({
    url: baseUrl + '/products/show-by-slug/' + slug + '?user_id=' + userId,
    options: METHODS.GET(),
    dataType: {},
    message: {
      success: 'Producto obtenido con éxito.',
      error: 'No se pudo obtener del producto solicitado.',
    },
  });

  return { ...response };
};

productService.getFavorites = async function () {
  const { response } = await fetchApi({
    url: baseUrl + '/products/get-favorites',
    options: METHODS.GET(),
    dataType: {},
    message: {
      success: 'Producto obtenido con éxito.',
      error: 'No se pudo obtener del producto solicitado.',
    },
  });

  return { ...response };
};

productService.getRecommended = async function () {
  const { response } = await fetchApi({
    url: baseUrl + '/products/get-recommended',
    options: METHODS.GET(),
    dataType: {},
    message: {
      success: 'Producto obtenido con éxito.',
      error: 'No se pudo obtener del producto solicitado.',
    },
  });

  return { ...response };
};

export default productService;
