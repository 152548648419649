import React, { useContext } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Context } from 'context/Context';
import * as ROUTES from 'constants/routes';
import './userDialog.scss';
// Components
import { List, ListItem, Dialog, Divider } from '@material-ui/core';
import AccountBoxRoundedIcon from '@material-ui/icons/AccountBoxRounded';
import LocalMallRoundedIcon from '@material-ui/icons/LocalMallRounded';
import HttpsRoundedIcon from '@material-ui/icons/HttpsRounded';
import PowerSettingsNewRoundedIcon from '@material-ui/icons/PowerSettingsNewRounded';
import EventRoundedIcon from '@material-ui/icons/EventRounded';
import AllInboxIcon from '@material-ui/icons/AllInbox';

const ItemLink = ({ path, icon, text, onClick }) => {
  if (!path) {
    return (
      <ListItem button className="user-dialog-item" onClick={onClick}>
        <div className="user-dialog-icon">{icon}</div>
        <div className="user-dialog-text">{text}</div>
      </ListItem>
    );
  }

  return (
    <RouterLink className="nui-no-decoration" to={path}>
      <ListItem button className="user-dialog-item" onClick={onClick}>
        <div className="user-dialog-icon">{icon}</div>
        <div className="user-dialog-text">{text}</div>
      </ListItem>
    </RouterLink>
  );
};

const UserDialog = (props) => {
  const { open, onClose } = props;
  const { name, removeAuth } = useContext(Context);

  const handleCloseSession = () => {
    onClose();
    removeAuth();
  };

  return (
    <Dialog
      className="cate-user-dialog"
      open={open}
      onClose={onClose}
      aria-labelledby="user-dialog">
      <div className="cate-user-dialog__title">Hola fff! {name}</div>
      <List className="cate-user-dialog__list list-1">
        <ItemLink
          path={ROUTES.USER_PROFILE}
          icon={<AccountBoxRoundedIcon />}
          text="Administra tu cuenta"
        />
        <ItemLink
          path={ROUTES.CHANGE_USER_PASSWORD}
          icon={<HttpsRoundedIcon />}
          text="Cambio de Clave"
        />
        <ItemLink
          path={ROUTES.USER_PURCHASES}
          icon={<LocalMallRoundedIcon />}
          text="Mis compras"
        />
        <ItemLink
          path={ROUTES.USER_EXPERIENCES}
          icon={<EventRoundedIcon />}
          text="Mis Experiencias"
        />
        <ItemLink
          path={ROUTES.USER_MEMBERSHIPS}
          icon={<AllInboxIcon />}
          text="Mis membresías"
        />
      </List>
      <Divider />
      <List className="cate-user-dialog__list">
        <ItemLink
          icon={<PowerSettingsNewRoundedIcon />}
          text="Cerrar sesión"
          onClick={handleCloseSession}
        />
      </List>
    </Dialog>
  );
};

UserDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default UserDialog;
