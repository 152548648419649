import React, { useState, useEffect } from 'react';
// Components
import { Container } from '@material-ui/core';
import { CardGrid } from './components';
import { sucursalService } from 'services';
import Traslate from 'i18n/traslate';
import ELEMENTS from 'constants/elementsForTranslate';

function Sucursales() {
  const [sucursalesLoading, setSucursalesLoading] = useState(true);
  //const [sucursalesList, setSucursalesList] = useState([{'id':1},{'id':2},{'id':2},{'id':2},{'id':1},{'id':2}]);
  const [sucursalesList, setSucursalesList] = useState([]);
  const handleOpenModal = (item) => {};

  const fetchListSucursales = async () => {
    const sucursalesListDB = await sucursalService.list();
    if (sucursalesListDB.data.length > 0) {
      setSucursalesList(sucursalesListDB.data);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    //setSucursalesL
    setSucursalesLoading(false);

    //Se obtiene la lista de sucursales desde la DB
    fetchListSucursales();
  }, []);

  return (
    <div style={{ padding: '4rem 0' }}>
      <Container maxWidth="lg">
        <h1 className="nui-font-size-2 nui-mg-b-2">{Traslate(ELEMENTS.BRANCHES.TITLE)}</h1>
        <div id="page_content">
          <CardGrid
            loading={sucursalesLoading}
            data={sucursalesList}
            onClick={handleOpenModal}
          />
        </div>
      </Container>
    </div>
  );
}

export default Sucursales;
