import SvgIcon from '@material-ui/core/SvgIcon';

const DinersClubSvg = () => (
  <>
    <path
      style={{ fill: '#006AC9' }}
      d="M165.6,176c0,24.8,15.2,45.6,36.8,53.6V122.4C180.8,130.4,165.6,151.2,165.6,176z"
    />
    <path
      style={{ fill: '#004A97' }}
      d="M243.2,122.4v107.2c21.6-8,36.8-29.6,36.8-53.6C280,151.2,264.8,130.4,243.2,122.4z"
    />
    <g>
      <path style={{ fill: '#023563' }} d="M243.2,229.6c21.6-8,36.8-29.6,36.8-53.6" />
      <path
        style={{ fill: '#023563' }}
        d="M269.6,76.8h-46.4c-55.2,0-100,40.8-100,100c0,54.4,44.8,98.4,100,98.4h46.4c54.4,0,104-44,104-98.4
		C372.8,117.6,323.2,76.8,269.6,76.8z M223.2,266.4c-50.4,0-90.4-40.8-90.4-90.4c0-50.4,40.8-91.2,90.4-91.2
		c50.4,0,91.2,40.8,91.2,91.2C313.6,226.4,272.8,266.4,223.2,266.4z"
      />
    </g>
    <g>
      <path
        style={{ fill: '#004A97' }}
        d="M20.8,343.2c0,8,5.6,8.8,11.2,8.8c22.4,0,30.4-16.8,30.4-32.8c0-20-12.8-33.6-32.8-33.6
		c-4,0-6.4,0-8,0.8v56.8L20.8,343.2L20.8,343.2z M10.4,293.6c0-9.6-4.8-8.8-9.6-8.8v-2.4c4,0,8,0,12,0c4.8,0,10.4,0,18.4,0
		c27.2,0,41.6,18.4,41.6,36.8c0,10.4-6.4,36.8-43.2,36.8c-5.6,0-10.4,0-15.2,0s-9.6,0-14.4,0v-2.4c6.4-0.8,9.6-0.8,9.6-8v-52H10.4z"
      />
      <path
        style={{ fill: '#004A97' }}
        d="M88.8,291.2c-2.4,0-4.8-2.4-4.8-5.6c0-2.4,2.4-4.8,4.8-4.8s4.8,2.4,4.8,4.8
		C93.6,288.8,91.2,291.2,88.8,291.2 M77.6,352.8H80c3.2,0,4.8,0,4.8-3.2v-28.8c0-4.8-1.6-5.6-5.6-7.2V312c4.8-1.6,10.4-3.2,11.2-4
		c0.8-0.8,1.6-0.8,1.6-0.8c0.8,0,0.8,0.8,0.8,1.6v40c0,3.2,2.4,3.2,5.6,3.2h1.6v2.4c-3.2,0-7.2,0-11.2,0s-7.2,0-11.2,0
		C77.6,354.4,77.6,352.8,77.6,352.8z"
      />
      <path
        style={{ fill: '#004A97' }}
        d="M109.6,321.6c0-4-0.8-4.8-6.4-7.2v-1.6c4.8-1.6,8.8-3.2,13.6-4.8c0,0,0.8,0,0.8,0.8v7.2
		c5.6-4,11.2-8,18.4-8c8.8,0,12,6.4,12,14.4v27.2c0,3.2,2.4,3.2,5.6,3.2h1.6v2.4c-4,0-7.2,0-11.2,0s-7.2,0-11.2,0v-2.4h1.6
		c3.2,0,4.8,0,4.8-3.2v-27.2c0-5.6-4-8.8-9.6-8.8c-3.2,0-8.8,2.4-12,4.8v31.2c0,3.2,2.4,3.2,5.6,3.2h1.6v2.4c-4,0-7.2,0-11.2,0
		s-7.2,0-11.2,0v-2.4h2.4c3.2,0,4.8,0,4.8-3.2L109.6,321.6L109.6,321.6z"
      />
      <path
        style={{ fill: '#004A97' }}
        d="M181.6,323.2c2.4,0,3.2-1.6,3.2-2.4c0-5.6-3.2-9.6-8.8-9.6c-6.4,0-11.2,4.8-12,12H181.6z
		 M163.2,326.4c0,0.8,0,2.4,0,6.4c0.8,9.6,7.2,18.4,15.2,18.4c5.6,0,10.4-3.2,14.4-7.2l1.6,1.6c-4.8,6.4-11.2,12-19.2,12
		c-16.8,0-20-16-20-23.2c0-20.8,13.6-26.4,20.8-26.4c8.8,0,17.6,5.6,17.6,16.8c0,0.8,0,1.6,0,1.6h-0.8L163.2,326.4L163.2,326.4z"
      />
      <path
        style={{ fill: '#004A97' }}
        d="M196,352.8h3.2c3.2,0,4.8,0,4.8-3.2V320c0-3.2-4-4-5.6-4.8v-1.6c8-3.2,12-5.6,12.8-5.6
		s0.8,0,0.8,1.6v9.6l0,0c2.4-4,7.2-10.4,13.6-10.4c2.4,0,5.6,1.6,5.6,5.6c0,3.2-1.6,5.6-4.8,5.6s-3.2-2.4-7.2-2.4
		c-1.6,0-8,2.4-8,8.8v24.8c0,3.2,2.4,3.2,4.8,3.2h5.6v2.4c-5.6,0-10.4,0-14.4,0s-8.8,0-12.8,0v-4L196,352.8L196,352.8z"
      />
      <path
        style={{ fill: '#004A97' }}
        d="M236.8,340.8c1.6,7.2,5.6,12.8,13.6,12.8c6.4,0,8.8-4,8.8-7.2c0-12-23.2-8-23.2-25.6
		c0-5.6,4.8-13.6,16-13.6c3.2,0,8,0.8,12,3.2l0.8,10.4h-2.4c-0.8-6.4-4.8-10.4-11.2-10.4c-4,0-8,2.4-8,7.2c0,12,24,8.8,24,24.8
		c0,7.2-5.6,14.4-18.4,14.4c-4,0-8.8-1.6-12.8-3.2l-0.8-12L236.8,340.8z"
      />
      <path
        style={{ fill: '#004A97' }}
        d="M361.6,300.8h-2.4c-1.6-12-10.4-16.8-22.4-16.8s-29.6,8-29.6,33.6c0,20.8,15.2,36,31.2,36
		c10.4,0,19.2-7.2,20.8-18.4l2.4,0.8l-2.4,15.2c-4.8,2.4-16,5.6-23.2,5.6c-24.8,0-40-16-40-39.2c0-21.6,19.2-36.8,40-36.8
		c8.8,0,16.8,2.4,24.8,5.6L361.6,300.8z"
      />
      <path
        style={{ fill: '#004A97' }}
        d="M365.6,352.8h1.6c3.2,0,4.8,0,4.8-3.2v-58.4c0-7.2-1.6-7.2-5.6-8v-1.6c4-1.6,8.8-3.2,11.2-4.8
		c0.8-0.8,1.6-0.8,2.4-0.8s0.8,0.8,0.8,1.6v72.8c0,3.2,2.4,3.2,5.6,3.2h1.6v2.4c-3.2,0-7.2,0-11.2,0s-7.2,0-11.2,0L365.6,352.8
		L365.6,352.8z"
      />
      <path
        style={{ fill: '#004A97' }}
        d="M432.8,349.6c0,1.6,0.8,1.6,3.2,1.6c1.6,0,3.2,0,4,0v2.4c-4.8,0.8-12.8,2.4-15.2,3.2H424V348
		c-6.4,4.8-11.2,8.8-18.4,8.8c-5.6,0-11.2-4-11.2-12.8v-27.2c0-2.4-0.8-5.6-6.4-5.6v-1.6c4,0,12-0.8,13.6-0.8c0.8,0,0.8,0.8,0.8,3.2
		v27.2c0,3.2,0,12,8.8,12c3.2,0,8-2.4,12.8-6.4v-28c0-2.4-4.8-3.2-8.8-4v-1.6c9.6-0.8,15.2-1.6,16-1.6s0.8,0.8,0.8,1.6v38.4H432.8z"
      />
      <path
        style={{ fill: '#004A97' }}
        d="M453.6,343.2c0,4,4,10.4,11.2,10.4c11.2,0,16-11.2,16-20c0-11.2-8.8-20.8-16.8-20.8
		c-4,0-7.2,2.4-9.6,4.8v25.6H453.6z M453.6,315.2c4-3.2,9.6-7.2,16-7.2c12,0,19.2,10.4,19.2,22.4c0,13.6-10.4,27.2-24.8,27.2
		c-8,0-12-2.4-14.4-4l-3.2,2.4l-2.4-0.8c0.8-6.4,1.6-12,1.6-18.4V292c0-7.2-1.6-7.2-5.6-8v-1.6c4-1.6,8.8-3.2,11.2-4.8
		c0.8-0.8,1.6-0.8,2.4-0.8s0.8,0.8,0.8,1.6v36.8H453.6z"
      />
    </g>
    <g>
      <path
        style={{ fill: '#006AC9' }}
        d="M0.8,416h0.8c2.4,0,4,0,4-3.2v-29.6c0-3.2-2.4-3.2-4-3.2H0.8v-1.6c2.4,0,5.6,0,8.8,0s6.4,0,8.8,0
		v1.6h-0.8c-2.4,0-4,0-4,3.2v29.6c0,3.2,1.6,3.2,4,3.2h0.8v1.6c-2.4,0-6.4,0-8.8,0s-6.4,0-8,0V416H0.8z"
      />
      <path
        style={{ fill: '#006AC9' }}
        d="M18.4,417.6V416h-0.8c-2.4,0-4.8,0-4.8-3.2v-29.6c0-3.2,2.4-3.2,4.8-3.2h0.8v-1.6
		c-2.4,0-5.6,0-8.8,0c-2.4,0-5.6,0-8,0v1.6h0.8c2.4,0,4.8,0,4.8,3.2v29.6c0,3.2-2.4,3.2-4.8,3.2H1.6v1.6c2.4,0,5.6,0,8,0
		S15.2,417.6,18.4,417.6 M18.4,418.4c-2.4,0-6.4,0-8.8,0s-6.4,0-8,0l0,0l0,0V416l0,0l0,0h0.8c2.4,0,4,0,4-3.2v-29.6
		c0-3.2-1.6-3.2-4-3.2H0.8l0,0v-1.6l0,0c2.4,0,5.6,0,8.8,0s6.4,0,8.8,0l0,0l0,0v1.6l0,0h-0.8c-2.4,0-4,0-4,3.2v29.6
		c0,3.2,1.6,3.2,4,3.2h0.8l0,0V418.4L18.4,418.4z"
      />
      <path
        style={{ fill: '#006AC9' }}
        d="M57.6,406.4L57.6,406.4v-21.6c0-4.8-3.2-5.6-4.8-5.6h-1.6v-1.6c2.4,0,5.6,0,8,0s4.8,0,7.2,0v1.6
		h-0.8c-2.4,0-4.8,0.8-4.8,7.2V412c0,1.6,0,4,0,5.6h-2.4l-29.6-32.8V408c0,4.8,0.8,6.4,5.6,6.4h0.8v1.6c-2.4,0-4.8,0-7.2,0
		s-4.8,0-8,0l0,0h0.8c4,0,4.8-2.4,4.8-7.2v-24c0-3.2-2.4-4.8-4.8-4.8l0,0v-1.6c2.4,0,4.8,0,6.4,0c1.6,0,3.2,0,4.8,0L57.6,406.4z"
      />
      <path
        style={{ fill: '#006AC9' }}
        d="M57.6,406.4L57.6,406.4L57.6,406.4L57.6,406.4z M57.6,406.4L57.6,406.4L57.6,406.4z M58.4,418.4H60
		c0-1.6,0-4,0-5.6v-25.6c0-7.2,3.2-7.2,5.6-7.2h0.8v-1.6c-2.4,0-4.8,0-6.4,0c-2.4,0-4.8,0-8,0v1.6h0.8c1.6,0,5.6,0.8,5.6,5.6v21.6
		l0,0l0,0l-25.6-28.8c-1.6,0-3.2,0-4.8,0c-2.4,0-4,0-6.4,0v1.6h0.8c2.4,0,5.6,2.4,5.6,5.6v24c0,4.8-1.6,7.2-5.6,7.2h-0.8v1.6
		c2.4,0,4.8,0,7.2,0c2.4,0,4.8,0,7.2,0v-1.6h-1.6c-4.8,0-5.6-1.6-5.6-7.2v-24L58.4,418.4z M60.8,419.2h-2.4l-28.8-32v23.2
		c0,4.8,0.8,6.4,4.8,6.4H36l0,0v1.6l0,0c-2.4,0-4.8,0-7.2,0s-4.8,0-8,0l0,0l0,0V416l0,0h0.8c4,0,4.8-2.4,4.8-7.2v-24
		c0-3.2-2.4-4.8-4.8-4.8H20l0,0v-1.6l0,0c2.4,0,4.8,0,6.4,0c1.6,0,3.2,0,5.6,0l24.8,28v-20.8c0-4.8-3.2-4.8-4.8-4.8h-0.8l0,0v-1.6
		l0,0c2.4,0,5.6,0,8,0s4.8,0,7.2,0l0,0l0,0v1.6l0,0h-0.8c-2.4,0-4.8,0-4.8,7.2v25.6C60.8,415.2,60.8,416.8,60.8,419.2L60.8,419.2
		L60.8,419.2z"
      />
      <path
        style={{ fill: '#006AC9' }}
        d="M75.2,380.8c-4,0-4.8,0.8-5.6,4.8H68c0-1.6,0.8-3.2,0.8-4.8c0-1.6,0-3.2,0-4.8h1.6
		c0.8,1.6,1.6,1.6,3.2,1.6h29.6c1.6,0,3.2,0,3.2-1.6h1.6c0,1.6-0.8,3.2-0.8,4.8c0,1.6,0,3.2,0,4.8l-1.6,0.8c0-2.4,0-5.6-4-5.6H92
		v30.4c0,4,1.6,4.8,4.8,4.8h0.8v1.6c-2.4,0-6.4,0-9.6,0s-7.2,0-9.6,0V416h0.8c3.2,0,4.8,0,4.8-4.8v-30.4L75.2,380.8L75.2,380.8z"
      />
      <path
        style={{ fill: '#006AC9' }}
        d="M97.6,418.4c-2.4,0-6.4,0-9.6,0s-7.2,0-9.6,0l0,0l0,0V416l0,0h0.8c3.2,0,4.8,0,4.8-4.8v-30.4h-8.8
		l0,0l0,0h9.6v30.4c0,4.8-1.6,4.8-4.8,4.8h-0.8v1.6c2.4,0,6.4,0,9.6,0C91.2,417.6,95.2,417.6,97.6,418.4v-1.6h-0.8
		c-2.4,0-4.8-0.8-4.8-4.8v-30.4h9.6c4,0,4,3.2,4.8,5.6l1.6-0.8c0-1.6,0-3.2,0-4.8c0-1.6,0-3.2,0.8-4H108c0,1.6-1.6,1.6-3.2,1.6H75.2
		l0,0c-1.6,0-3.2,0-3.2-1.6h-2.4c0,1.6,0,3.2,0,4.8c0,1.6-0.8,3.2-0.8,4.8h1.6c0.8-4,1.6-5.6,5.6-4.8l0,0l0,0c-4,0-4,0.8-4.8,4.8
		l0,0l0,0H68l0,0c0-1.6,0.8-3.2,0.8-4.8c0-1.6,0-3.2,0-4.8l0,0l0,0h1.6l0,0c0,1.6,1.6,1.6,3.2,1.6l0,0h29.6c1.6,0,2.4,0,3.2-1.6l0,0
		l0,0h1.6l0,0c0,1.6-0.8,3.2-0.8,4.8c0,1.6,0,3.2,0,4.8l0,0l0,0h-2.4l0,0c0-2.4,0-5.6-4-5.6H92V412c0,4,1.6,4.8,4.8,4.8h1.6l0,0
		L97.6,418.4L97.6,418.4z"
      />
      <path
        style={{ fill: '#006AC9' }}
        d="M109.6,416h0.8c2.4,0,4,0,4-3.2v-29.6c0-3.2-2.4-3.2-4-3.2h-0.8v-1.6c3.2,0,9.6,0,14.4,0
		c4.8,0,10.4,0,14.4,0c0,2.4,0,6.4,0,8.8h-1.6c0-4-0.8-6.4-7.2-6.4h-8V396h7.2c3.2,0,4-1.6,4.8-4.8h1.6c0,2.4,0,4.8,0,6.4
		c0,2.4,0,4,0,6.4h-1.6c0-3.2-0.8-5.6-4.8-5.6h-7.2V412c0,4,3.2,4,7.2,4c7.2,0,9.6-0.8,12-7.2h1.6c-0.8,3.2-1.6,6.4-1.6,8.8
		c-4,0-10.4,0-15.2,0c-4.8,0-12,0-15.2,0V416H109.6z"
      />
      <path
        style={{ fill: '#006AC9' }}
        d="M140,417.6c0.8-3.2,0.8-5.6,1.6-8.8h-0.8c-1.6,6.4-4.8,7.2-12,7.2c-3.2,0-7.2,0-7.2-4v-13.6h7.2
		c4,0,4,2.4,4.8,5.6h1.6c0-2.4,0-4,0-6.4s0-4,0-6.4h-1.6c0,3.2-1.6,4.8-4.8,4.8h-7.2v-15.2h8c5.6,0,7.2,3.2,7.2,6.4h1.6
		c0-1.6,0-4,0-5.6c0-0.8,0-1.6,0-2.4c-4,0-9.6,0-14.4,0c-4.8,0-10.4,0-13.6,0v1.6h0.8c2.4,0,4.8,0,4.8,3.2v29.6
		c0,3.2-2.4,3.2-4.8,3.2h-0.8v1.6c3.2,0,9.6,0,15.2,0C129.6,417.6,136,417.6,140,417.6 M140,418.4c-4,0-10.4,0-15.2,0
		c-4.8,0-12,0-15.2,0l0,0l0,0V416l0,0l0,0h0.8c2.4,0,4,0,4-3.2v-29.6c0-2.4-1.6-3.2-4-3.2h-0.8l0,0v-1.6l0,0c3.2,0,9.6,0,14.4,0
		c4.8,0,10.4,0,14.4,0l0,0l0,0c0,0.8,0,1.6,0,3.2s0,4,0,5.6l0,0l0,0l-1.6,0.8l0,0c0-4-0.8-6.4-6.4-6.4h-8V396h6.4c3.2,0,4-1.6,4-4.8
		l0,0l0,0h1.6l0,0c0,2.4,0,4.8,0,6.4c0,2.4,0,4,0,6.4l0,0l0,0h-1.6l0,0c0-3.2-0.8-5.6-4-5.6h-6.4v12.8c0,3.2,3.2,3.2,6.4,3.2
		c7.2,0,9.6,0,11.2-6.4l0,0l0,0l1.6,0.8l0,0C141.6,412,140.8,415.2,140,418.4L140,418.4L140,418.4z"
      />
      <path
        style={{ fill: '#006AC9' }}
        d="M156.8,397.6h2.4c5.6,0,8.8-2.4,8.8-8.8c0-4.8-3.2-8-8-8c-1.6,0-2.4,0-3.2,0V397.6z M149.6,384
		c0-4-2.4-4-4-4h-0.8v-1.6c1.6,0,5.6,0,8.8,0s6.4,0,8.8,0c7.2,0,13.6,1.6,13.6,10.4c0,4.8-3.2,8-8,10.4l9.6,14.4
		c1.6,2.4,2.4,3.2,5.6,3.2v1.6c-1.6,0-4,0-5.6,0s-4,0-5.6,0c-4-5.6-8-12-12-18.4h-4v12c0,4,2.4,4.8,4.8,4.8h0.8v1.6
		c-3.2,0-6.4,0-9.6,0c-2.4,0-5.6,0-8,0V416h0.8c2.4,0,4-0.8,4-3.2V384H149.6z"
      />
      <path
        style={{ fill: '#006AC9' }}
        d="M160,397.6c5.6,0,8.8-1.6,8.8-8.8c0-4.8-3.2-8-8-8c-1.6,0-2.4,0-3.2,0v16.8H160z M156.8,397.6
		L156.8,397.6V380l0,0c0.8,0,1.6,0,3.2,0c4.8,0,8.8,3.2,8.8,8.8c0,7.2-3.2,8.8-8.8,8.8L156.8,397.6L156.8,397.6z M156.8,399.2h4l0,0
		c4,6.4,7.2,12.8,12,18.4c1.6,0,3.2,0,5.6,0c1.6,0,4,0,5.6,0V416c-2.4,0-4-0.8-5.6-3.2l-9.6-15.2l0,0c4.8-1.6,8-4.8,8-9.6
		c0-8-6.4-9.6-13.6-9.6c-3.2,0-5.6,0-8.8,0s-6.4,0-8.8,0v1.6h0.8c1.6,0,4,0,4,4.8v28.8c0,2.4-2.4,3.2-4,3.2h-1.6v1.6
		c2.4,0,5.6,0,8,0c3.2,0,6.4,0,9.6,0v-1.6h-0.8c-2.4,0-4.8,0-4.8-4.8L156.8,399.2L156.8,399.2z M184,418.4c-1.6,0-4,0-5.6,0
		s-3.2,0-5.6,0c-4-5.6-8-12-12-18.4h-3.2v12c0,4,1.6,4,4.8,4h1.6l0,0v1.6l0,0c-3.2,0-6.4,0-9.6,0c-2.4,0-5.6,0-8,0l0,0l0,0V416l0,0
		h0.8c2.4,0,4-0.8,4-3.2V384c0-4-2.4-4-4-4h-2.4l0,0v-1.6l0,0c1.6,0,5.6,0,8.8,0s6.4,0,8.8,0c7.2,0,13.6,2.4,13.6,10.4
		c0,4.8-3.2,8-8,10.4l9.6,14.4c1.6,2.4,2.4,3.2,5.6,3.2l0,0l0,0L184,418.4L184,418.4z"
      />
      <path
        style={{ fill: '#006AC9' }}
        d="M222.4,406.4L222.4,406.4v-21.6c0-4.8-3.2-5.6-4.8-5.6H216v-1.6c2.4,0,5.6,0,8,0s4.8,0,7.2,0v1.6
		h-0.8c-2.4,0-4.8,0.8-4.8,7.2V412c0,1.6,0,4,0,5.6h-2.4l-29.6-32.8V408c0,4.8,0.8,6.4,5.6,6.4h1.6v1.6c-2.4,0-4.8,0-7.2,0
		s-4.8,0-8,0l0,0h0.8c4,0,4.8-2.4,4.8-7.2v-24c0-3.2-2.4-4.8-5.6-4.8h-0.8v-1.6c2.4,0,4,0,6.4,0c1.6,0,3.2,0,4.8,0L222.4,406.4z"
      />
      <path
        style={{ fill: '#006AC9' }}
        d="M222.4,406.4L222.4,406.4L222.4,406.4L222.4,406.4z M222.4,406.4L222.4,406.4L222.4,406.4z
		 M223.2,418.4h1.6c0-1.6,0-4,0-5.6v-25.6c0-7.2,3.2-7.2,5.6-7.2h0.8v-1.6c-2.4,0-4.8,0-6.4,0c-2.4,0-4.8,0-8,0v1.6h0.8
		c1.6,0,5.6,0.8,5.6,5.6v21.6l0,0l0,0l-25.6-28.8c-1.6,0-3.2,0-4.8,0c-2.4,0-4,0-6.4,0v1.6h0.8c2.4,0,5.6,2.4,5.6,5.6v24
		c0,4.8-1.6,7.2-5.6,7.2h-0.8v1.6c2.4,0,4.8,0,7.2,0s4.8,0,7.2,0v-1.6H200c-4.8,0-5.6-1.6-5.6-7.2v-24L223.2,418.4z M225.6,419.2
		h-2.4l-28.8-32v23.2c0,4.8,0.8,6.4,4.8,6.4h1.6l0,0v1.6l0,0c-2.4,0-4.8,0-7.2,0s-4.8,0-8,0l0,0l0,0V416l0,0h0.8
		c4,0,4.8-2.4,4.8-7.2v-24c0-3.2-2.4-4.8-4.8-4.8h-1.6l0,0v-1.6l0,0c2.4,0,4.8,0,6.4,0c1.6,0,3.2,0,5.6,0l24.8,28v-20.8
		c0-4.8-3.2-4.8-4.8-4.8h-1.6l0,0v-1.6l0,0c2.4,0,5.6,0,8,0s4.8,0,7.2,0l0,0l0,0v1.6l0,0h-0.8c-2.4,0-4.8,0-4.8,7.2v25.6
		C225.6,415.2,225.6,416.8,225.6,419.2L225.6,419.2L225.6,419.2z"
      />
      <path
        style={{ fill: '#006AC9' }}
        d="M248.8,384.8L248.8,384.8l-5.6,16h10.4L248.8,384.8z M240.8,409.6c-0.8,1.6-1.6,3.2-1.6,4.8
		c0,1.6,2.4,1.6,4,1.6h0.8v1.6c-2.4,0-4.8,0-7.2,0s-4,0-6.4,0V416l0,0c2.4,0,4-1.6,4.8-4l8.8-26.4c0.8-2.4,1.6-4.8,2.4-7.2
		c1.6-0.8,4-1.6,5.6-2.4c0,0,0,0,0.8,0l0,0c0,0.8,0,0.8,0.8,1.6l11.2,30.4c0.8,1.6,1.6,4,2.4,5.6s1.6,2.4,4,2.4l0,0v1.6
		c-2.4,0-4.8,0-8,0s-5.6,0-8.8,0V416h0.8c1.6,0,3.2,0,3.2-1.6c0-0.8-0.8-2.4-0.8-4l-2.4-6.4h-12.8L240.8,409.6z"
      />
      <path
        style={{ fill: '#006AC9' }}
        d="M248.8,384.8L248.8,384.8L248.8,384.8z M244,400.8h10.4l-5.6-16L244,400.8z M243.2,400.8l5.6-16.8
		l0,0l0,0l0,0l0,0l0,0l0,0l0,0l5.6,16.8H243.2z M254.4,417.6c3.2,0,5.6,0,8,0s4.8,0,8,0V416l0,0c-1.6,0-3.2-0.8-4-2.4
		c-0.8-0.8-1.6-3.2-1.6-5.6l-10.4-29.6c0-0.8,0-0.8-0.8-1.6l0,0l0,0l0,0c-0.8,0.8-3.2,1.6-4.8,2.4c0,2.4-1.6,4.8-2.4,7.2l-8.8,26.4
		c-0.8,2.4-3.2,4-5.6,4l0,0v1.6c1.6,0,4,0,5.6,0c2.4,0,4.8,0,6.4,0v-1.6l0,0c-1.6,0-4.8,0-4.8-2.4c0-0.8,0.8-2.4,1.6-4.8l0,0l0,0
		l1.6-6.4H256l2.4,6.4c0.8,1.6,0.8,3.2,0.8,4c0,1.6-2.4,1.6-4,1.6l0,0v2.4H254.4z M271.2,418.4c-2.4,0-4.8,0-8,0s-5.6,0-8.8,0l0,0
		l0,0V416l0,0h0.8c1.6,0,3.2,0,3.2-1.6c0-0.8-0.8-2.4-0.8-4l-2.4-6.4h-12.8l-1.6,5.6c-0.8,1.6-1.6,3.2-1.6,4c0,1.6,2.4,1.6,4,1.6
		h0.8l0,0v1.6l0,0c-2.4,0-4.8,0-7.2,0s-4,0-6.4,0l0,0l0,0V416l0,0l0,0c2.4,0,4-1.6,4.8-3.2l8.8-26.4c0.8-2.4,1.6-4.8,2.4-7.2
		c1.6-0.8,4-1.6,4.8-2.4c0,0,0,0,0.8,0h0.8c0,0.8,0,0.8,0.8,1.6l11.2,29.6c0.8,2.4,1.6,4,2.4,5.6s1.6,2.4,3.2,2.4h0.8l0,0
		L271.2,418.4L271.2,418.4z"
      />
      <path
        style={{ fill: '#006AC9' }}
        d="M273.6,380.8c-4,0-4.8,0.8-5.6,4.8h-1.6c0-1.6,0.8-3.2,0.8-4.8c0-1.6,0-3.2,0-4.8h1.6
		c0.8,1.6,1.6,1.6,3.2,1.6h29.6c1.6,0,3.2,0,3.2-1.6h1.6c0,1.6-0.8,3.2-0.8,4.8c0,1.6,0,3.2,0,4.8l-1.6,0.8c0-2.4-0.8-5.6-4-5.6
		h-9.6v30.4c0,4,1.6,4.8,4.8,4.8h0.8v1.6c-2.4,0-6.4,0-9.6,0s-7.2,0-9.6,0V416h0.8c3.2,0,4.8,0,4.8-4.8v-30.4L273.6,380.8
		L273.6,380.8z"
      />
      <path
        style={{ fill: '#006AC9' }}
        d="M296,418.4c-2.4,0-6.4,0-9.6,0s-7.2,0-9.6,0l0,0l0,0V416l0,0h0.8c3.2,0,4,0,4.8-4.8v-30.4h-8.8l0,0
		l0,0h9.6v30.4c0,4.8-1.6,4.8-4.8,4.8h-0.8v1.6c2.4,0,6.4,0,9.6,0C289.6,417.6,293.6,417.6,296,418.4v-1.6h-0.8
		c-2.4,0-4.8-0.8-4.8-4.8v-30.4h9.6c4,0,4,3.2,4.8,5.6l1.6-0.8c0-1.6,0-3.2,0-4.8c0-1.6,0-3.2,0.8-4h-0.8c0,1.6-1.6,1.6-3.2,1.6
		h-29.6l0,0c-1.6,0-2.4,0-3.2-1.6h-0.8c0,1.6,0,3.2,0,4.8c0,1.6-0.8,3.2-0.8,4.8h1.6c0.8-4,1.6-5.6,5.6-4.8l0,0l0,0
		c-4,0-4,0.8-4.8,4.8l0,0l0,0h-2.4l0,0c0-1.6,0.8-3.2,0.8-4.8c0-1.6,0-3.2,0-4.8l0,0l0,0h1.6l0,0c0,1.6,1.6,1.6,3.2,1.6l0,0H304
		c1.6,0,2.4,0,3.2-1.6l0,0l0,0h1.6l0,0c0,1.6-0.8,3.2-0.8,4.8c0,1.6,0,3.2,0,4.8l0,0l0,0l-2.4,0.8l0,0c0-2.4,0-5.6-4-5.6h-8.8v30.4
		c0,4,1.6,4.8,4.8,4.8h1.6l0,0L296,418.4L296,418.4z"
      />
      <path
        style={{ fill: '#006AC9' }}
        d="M308,416L308,416c3.2,0,4.8,0,4.8-3.2v-29.6c0-3.2-2.4-3.2-4-3.2H308v-1.6c2.4,0,5.6,0,8.8,0
		c3.2,0,6.4,0,8.8,0v1.6h-0.8c-2.4,0-4,0-4,3.2v29.6c0,3.2,2.4,3.2,4,3.2h0.8v1.6c-2.4,0-6.4,0-8.8,0s-6.4,0-8,0V416H308z"
      />
      <path
        style={{ fill: '#006AC9' }}
        d="M325.6,417.6V416h-0.8c-2.4,0-4.8,0-4.8-3.2v-29.6c0-3.2,2.4-3.2,4.8-3.2h0.8v-1.6
		c-2.4,0-5.6,0-8.8,0c-2.4,0-5.6,0-8,0v1.6h0.8c2.4,0,4.8,0,4.8,3.2v29.6c0,3.2-2.4,3.2-4.8,3.2h-0.8v1.6c2.4,0,5.6,0,8,0
		S322.4,417.6,325.6,417.6 M325.6,418.4c-2.4,0-6.4,0-8.8,0s-6.4,0-8,0l0,0l0,0V416l0,0l0,0h0.8c2.4,0,4,0,4-3.2v-29.6
		c0-2.4-1.6-3.2-4-3.2h-0.8l0,0v-1.6l0,0c2.4,0,5.6,0,8.8,0c3.2,0,6.4,0,8.8,0l0,0l0,0v1.6l0,0h-0.8c-2.4,0-4,0-4,3.2v29.6
		c0,2.4,1.6,3.2,4,3.2h0.8l0,0L325.6,418.4L325.6,418.4z"
      />
      <path
        style={{ fill: '#006AC9' }}
        d="M349.6,416c11.2,0,12.8-9.6,12.8-18.4c0-8-4.8-18.4-13.6-18.4c-10.4,0-12.8,8.8-12.8,16.8
		C335.2,406.4,340,416,349.6,416 M348.8,377.6c12,0,21.6,7.2,21.6,20s-9.6,21.6-21.6,21.6s-21.6-8-21.6-20.8
		C327.2,386.4,336.8,377.6,348.8,377.6"
      />
      <path
        style={{ fill: '#006AC9' }}
        d="M335.2,396c0-8,3.2-16.8,13.6-16.8c9.6,0,14.4,10.4,14.4,18.4s-1.6,18.4-13.6,18.4l0,0l0,0
		c11.2,0,12.8-9.6,12.8-17.6s-4.8-18.4-13.6-18.4C338.4,380,336,388.8,335.2,396c0.8,10.4,4.8,20,14.4,20l0,0l0,0
		C340,416.8,335.2,406.4,335.2,396 M327.2,398.4c0-12,9.6-21.6,21.6-21.6l0,0l0,0C336.8,377.6,328,386.4,327.2,398.4
		c0.8,12,9.6,20,21.6,20s21.6-8.8,21.6-21.6c0-12-9.6-19.2-21.6-19.2l0,0l0,0c12,0,22.4,8,22.4,20c0,13.6-9.6,22.4-21.6,22.4
		C336.8,419.2,327.2,411.2,327.2,398.4"
      />
      <path
        style={{ fill: '#006AC9' }}
        d="M408.8,406.4L408.8,406.4v-21.6c0-4.8-3.2-5.6-4.8-5.6h-1.6v-1.6c2.4,0,5.6,0,8,0s4.8,0,7.2,0v1.6
		h-0.8c-2.4,0-4.8,0.8-4.8,7.2V412c0,1.6,0,4,0,5.6h-2.4L380,384.8V408c0,4.8,0.8,6.4,5.6,6.4h0.8v1.6c-2.4,0-4.8,0-7.2,0
		c-2.4,0-4.8,0-8,0l0,0h0.8c4,0,4.8-2.4,4.8-7.2v-24c0-3.2-2.4-4.8-4.8-4.8h-0.8v-1.6c2.4,0,4,0,6.4,0c1.6,0,3.2,0,4.8,0
		L408.8,406.4z"
      />
      <path
        style={{ fill: '#006AC9' }}
        d="M408.8,406.4L408.8,406.4L408.8,406.4L408.8,406.4z M408.8,406.4L408.8,406.4L408.8,406.4z
		 M409.6,418.4h1.6c0-1.6,0-4,0-5.6v-25.6c0-7.2,3.2-7.2,5.6-7.2h0.8v-1.6c-2.4,0-4.8,0-6.4,0c-2.4,0-4.8,0-8,0v1.6h0.8
		c1.6,0,5.6,0.8,5.6,5.6v21.6l0,0l0,0L384,378.4c-1.6,0-3.2,0-4.8,0c-2.4,0-4,0-6.4,0v1.6h0.8c2.4,0,5.6,2.4,5.6,5.6v24
		c0,4.8-1.6,7.2-5.6,7.2h-0.8v1.6c2.4,0,4.8,0,7.2,0c2.4,0,4.8,0,7.2,0v-1.6h-0.8c-4.8,0-5.6-1.6-5.6-7.2v-24L409.6,418.4z
		 M412,419.2h-2.4l-28.8-32v23.2c0,4.8,0.8,6.4,4.8,6.4h1.6l0,0v1.6l0,0c-2.4,0-4.8,0-7.2,0c-2.4,0-4.8,0-8,0l0,0l0,0V416l0,0h0.8
		c4,0,4.8-2.4,4.8-7.2v-24c0-3.2-2.4-4.8-4.8-4.8H372l0,0v-1.6l0,0c2.4,0,4.8,0,6.4,0s3.2,0,5.6,0l24.8,28v-20.8
		c0-4.8-3.2-4.8-4.8-4.8h-1.6l0,0v-1.6l0,0c2.4,0,5.6,0,8,0s4.8,0,7.2,0l0,0l0,0v1.6l0,0h-0.8c-2.4,0-4.8,0-4.8,7.2v25.6
		C412,415.2,412,416.8,412,419.2L412,419.2L412,419.2z"
      />
      <path
        style={{ fill: '#006AC9' }}
        d="M435.2,384.8L435.2,384.8l-5.6,16h11.2L435.2,384.8z M427.2,409.6c-0.8,1.6-1.6,3.2-1.6,4.8
		c0,1.6,2.4,1.6,4,1.6h0.8v1.6c-2.4,0-4.8,0-7.2,0c-2.4,0-4,0-6.4,0V416l0,0c2.4,0,4-1.6,4.8-4l8.8-26.4c0.8-2.4,1.6-4.8,2.4-7.2
		c1.6-0.8,4-1.6,5.6-2.4c0,0,0,0,0.8,0l0,0c0,0.8,0,0.8,0.8,1.6l10.4,29.6c0.8,1.6,1.6,4,2.4,5.6c0.8,3.2,2.4,3.2,4,3.2l0,0v1.6
		c-2.4,0-4.8,0-8,0c-2.4,0-5.6,0-8.8,0V416h0.8c1.6,0,3.2,0,3.2-1.6c0-0.8-0.8-2.4-0.8-4l-2.4-6.4H428L427.2,409.6z"
      />
      <path
        style={{ fill: '#006AC9' }}
        d="M435.2,384.8L435.2,384.8L435.2,384.8z M430.4,400.8h9.6l-4.8-15.2L430.4,400.8z M429.6,400.8
		l5.6-16.8l0,0l0,0l0,0l0,0l0,0l0,0l0,0l5.6,16.8H429.6z M440.8,417.6c3.2,0,5.6,0,8,0s4.8,0,8,0V416l0,0c-1.6,0-3.2-0.8-4-2.4
		s-1.6-4-2.4-5.6L440,378.4c0-0.8,0-0.8-0.8-1.6l0,0l0,0l0,0c-0.8,0.8-3.2,1.6-4.8,2.4c0,2.4-1.6,4.8-2.4,7.2l-8.8,26.4
		c-0.8,2.4-3.2,4-4.8,4l0,0v1.6c1.6,0,4,0,5.6,0c2.4,0,4.8,0,6.4,0v-1.6l0,0c-1.6,0-4.8,0-4.8-2.4c0-0.8,0.8-2.4,1.6-4.8l0,0l0,0
		l1.6-6.4h13.6l2.4,6.4c0.8,1.6,0.8,3.2,0.8,4c0,1.6-2.4,1.6-4,1.6l0,0v2.4H440.8z M457.6,418.4c-2.4,0-4.8,0-8,0
		c-2.4,0-5.6,0-8.8,0l0,0l0,0V416l0,0h0.8c1.6,0,3.2,0,3.2-1.6c0-0.8-0.8-2.4-0.8-4l-2.4-6.4h-12.8l-1.6,5.6c-0.8,1.6-1.6,3.2-1.6,4
		c0,1.6,2.4,1.6,4,1.6h0.8l0,0v1.6l0,0c-2.4,0-4.8,0-7.2,0c-2.4,0-4,0-6.4,0l0,0l0,0V416l0,0l0,0c2.4,0,4-1.6,4.8-3.2l8.8-26.4
		c0.8-2.4,1.6-4.8,2.4-7.2c1.6-0.8,4-1.6,5.6-2.4c0,0,0,0,0.8,0h0.8c0,0.8,0,0.8,0.8,1.6l10.4,29.6c0.8,2.4,1.6,4,2.4,5.6
		s1.6,2.4,3.2,2.4h0.8l0,0L457.6,418.4L457.6,418.4z"
      />
      <path
        style={{ fill: '#006AC9' }}
        d="M472,412.8c0,2.4,1.6,3.2,3.2,3.2c2.4,0,4.8,0,7.2,0c2.4,0,4.8-1.6,5.6-3.2c0.8-1.6,1.6-3.2,1.6-4
		h1.6c-0.8,3.2-1.6,6.4-2.4,9.6c-4.8,0-10.4,0-15.2,0s-10.4,0-15.2,0V416h0.8c2.4,0,4,0,4-4v-29.6c0-3.2-2.4-3.2-4-3.2h-0.8v-1.6
		c3.2,0,5.6,0,8.8,0c3.2,0,5.6,0,8.8,0v1.6h-1.6c-2.4,0-4,0-4,3.2v30.4H472z"
      />
      <path
        style={{ fill: '#006AC9' }}
        d="M488.8,417.6c0.8-3.2,1.6-6.4,2.4-8.8h-0.8c0,1.6-0.8,2.4-1.6,4c-0.8,1.6-3.2,3.2-5.6,3.2
		c-1.6,0-3.2,0-4,0s-2.4,0-3.2,0c-1.6,0-4-0.8-4-3.2v-29.6c0-3.2,1.6-3.2,4-3.2h0.8v-1.6c-3.2,0-5.6,0-8.8,0c-3.2,0-5.6,0-8.8,0v1.6
		h0.8c2.4,0,4.8,0,4.8,3.2v29.6c0,4-2.4,4-4.8,4h-0.8v1.6c4.8,0,9.6,0,15.2,0C479.2,417.6,484,417.6,488.8,417.6 M489.6,418.4
		c-4.8,0-10.4,0-15.2,0c-4.8,0-10.4,0-15.2,0l0,0l0,0V416l0,0h0.8c2.4,0,4,0,4-3.2v-29.6c0-2.4-1.6-3.2-4-3.2h-0.8l0,0v-1.6l0,0
		c3.2,0,6.4,0,8.8,0c3.2,0,5.6,0,8.8,0l0,0l0,0v1.6l0,0h-1.6c-2.4,0-4,0-4,3.2v29.6c0,2.4,1.6,2.4,3.2,3.2c0.8,0,2.4,0,3.2,0
		c1.6,0,3.2,0,4,0c2.4,0,4-1.6,5.6-3.2c0.8-1.6,1.6-2.4,1.6-4l0,0l0,0h1.6l0,0C491.2,412,490.4,415.2,489.6,418.4L489.6,418.4
		L489.6,418.4z"
      />
      <path
        style={{ fill: '#006AC9' }}
        d="M489.6,382.4L489.6,382.4c1.6,0,1.6-0.8,1.6-1.6c0-0.8-0.8-1.6-0.8-1.6h-0.8L489.6,382.4
		L489.6,382.4z M488,385.6L488,385.6c0.8,0,0.8,0,0.8-0.8V380c0-0.8,0-0.8-0.8-0.8l0,0h3.2c0.8,0,2.4,0.8,2.4,1.6
		c0,0.8-0.8,1.6-1.6,1.6l0.8,1.6c0.8,0.8,0.8,1.6,1.6,1.6l0,0h-0.8c-0.8,0-0.8-0.8-2.4-2.4h-0.8v1.6c0,0.8,0,0,0.8,0.8l0,0
		L488,385.6L488,385.6z M490.4,387.2c2.4,0,4.8-2.4,4.8-4.8s-2.4-4.8-4.8-4.8s-4.8,2.4-4.8,4.8C486.4,384.8,488,387.2,490.4,387.2
		 M490.4,376.8c3.2,0,6.4,2.4,6.4,6.4s-2.4,5.6-6.4,5.6s-6.4-2.4-6.4-5.6S487.2,376.8,490.4,376.8"
      />
    </g>
    <path
      style={{ fill: '#004A97' }}
      d="M355.2,160.8c0-25.6-10.4-48.8-28-67.2c-16.8-10.4-36.8-16.8-57.6-16.8h-46.4
	c-55.2,0-100,40.8-100,100c0,24.8,9.6,47.2,24.8,64.8c16,11.2,35.2,19.2,56,22.4c-40.8-8.8-71.2-44.8-71.2-88.8
	c0-50.4,40.8-91.2,90.4-91.2c50.4,0,91.2,40.8,91.2,91.2c0,49.6-39.2,89.6-88.8,90.4c1.6,0,2.4,0,4,0
	C298.4,266.4,355.2,219.2,355.2,160.8z"
    />
    <path
      style={{ fill: '#006AC9' }}
      d="M222.4,76.8c-55.2,0-100,40.8-100,100c0,24,8.8,46.4,24,63.2c0.8,0.8,1.6,1.6,2.4,2.4
	c16,11.2,34.4,18.4,55.2,22.4c-40.8-8.8-71.2-44.8-71.2-88.8c0-50.4,40.8-91.2,90.4-91.2c50.4,0,91.2,40.8,91.2,91.2
	c0,49.6-39.2,89.6-88.8,90.4c1.6,0,2.4,0,4,0c4,0,8,0,12-0.8c52.8-12.8,86.4-66.4,76.8-123.2c-4.8-26.4-17.6-48.8-36-64.8
	c-4-0.8-8-0.8-12-0.8H222.4z"
    />
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </>
);

const DinersClub = (props) => (
  <SvgIcon
    viewBox="0 0 496.8 496.8"
    xmlSpace="preserve"
    style={{ enableBackground: 'new 0 0 496.8 496.8' }}
    {...props}>
    <DinersClubSvg />
  </SvgIcon>
);

export default DinersClub;
