import configService from './config';
import fetchApi from './fetchApi';
import * as METHODS from './methods';

const baseUrl = configService.apiUrl;
const baseWebUrl = configService.apiUrlAdmin;

const subscriptionService = {};

subscriptionService.listUngrouped = async function () {
  const { response } = await fetchApi({
    url: baseWebUrl + '/subscriptions/plans/',
    options: METHODS.GET(),
    dataType: [],
    message: {
      success: 'Lista de suscripciones obtenida con éxito.',
      error: 'No se pudo obtener la lista de suscripciones.',
    },
  });

  return { ...response };
};

subscriptionService.list = async function () {
  const { response } = await fetchApi({
    url: baseUrl + '/subscriptions/types',
    options: METHODS.GET(),
    dataType: [],
    message: {
      success: 'Lista de suscripciones obtenida con éxito.',
      error: 'No se pudo obtener la lista de suscripciones.',
    },
  });

  return { ...response };
};

subscriptionService.listByUser = async function (userId) {
  const { response } = await fetchApi({
    url: baseUrl + '/subscriptions/user/' + userId,
    options: METHODS.GET(),
    dataType: [],
    message: {
      success: 'Lista de suscripciones obtenida con éxito.',
      error: 'No se pudo obtener la lista de suscripciones.',
    },
  });

  return { ...response };
};

subscriptionService.show = async function (id) {
  const { response } = await fetchApi({
    url: baseUrl + '/subscriptions/show/' + id,
    options: METHODS.GET(),
    dataType: {},
    message: {
      success: 'Suscripción obtenida con éxito.',
      error: 'No se pudo obtener la suscripción solicitada.',
    },
  });

  return { ...response };
};

subscriptionService.create = async function (arg) {
  const { response } = await fetchApi({
    url: baseUrl + '/subscriptions/plan',
    options: METHODS.POST({
      user_id: arg.userId,
      token_id: arg.tokenId,
      subscription_id: arg.subscriptionId,
      department: arg.department,
      province: arg.province,
      district: arg.district,
      address: arg.address,
    }),
    dataType: {},
    message: {
      success: 'Suscripción creada con éxito.',
      error: 'No se pudo crear la suscripción.',
    },
  });

  return { ...response };
};

subscriptionService.delete = async function (id) {
  const { response } = await fetchApi({
    url: baseUrl + '/subscriptions/cancel/' + id,
    options: METHODS.PUT(),
    dataType: {},
    message: {
      success: 'Suscripción eliminada con éxito.',
      error: 'No se pudo eliminar la suscripción.',
    },
  });

  return { ...response };
};

subscriptionService.buscarUserNroDocumento = async function (nro_doc) {
  const { response } = await fetchApi({
    url: baseUrl + '/subscriptions/user_details/nro_documento/' + nro_doc,
    options: METHODS.GET(),
    dataType: [],
    message: {
      success: 'Busqueda de documento completada con éxito.',
      error: 'No se pudo obtener la lista de suscripciones.',
    },
  });

  return { ...response };
};

subscriptionService.listDeliveryPrices = async function () {
  const { response } = await fetchApi({
    url: baseUrl + '/common/delivery/prices',
    options: METHODS.GET(),
    dataType: [],
    message: {
      success: 'Lista de precios de delivery obtenida satisfactoriamente.',
      error: 'No se pudo obtener la lista de precios de delivery.',
    },
  });

  return { ...response };
};

export default subscriptionService;
