import React, { useContext, useState, useEffect } from 'react';
import { productService, productCategoryService } from 'services';
import { useLocation } from 'react-router-dom';
import { useNotification, useResponsive } from 'hooks';
import './products.scss';
// Components
import { Notification, FilterTabs, CatePaper } from 'components';
import { CardGrid, ProductModal } from './components';
import { Container } from '@material-ui/core';
import Traslate from 'i18n/traslate';
import ELEMENTS from 'constants/elementsForTranslate';
import { Context } from 'context';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

function Products() {
  let query = useQuery();
  const { locale } = useContext(Context);
  // let categoryId = query.get('id');
  let categoryId = JSON.parse(localStorage.getItem('product_category')) || 0;
  // let categoryName = query.get('name');
  let categoryName = localStorage.getItem('product_category_name') || 'all';
  const { isMobile } = useResponsive();
  const { notification, createMessage, closeMessage } = useNotification();
  const [productsLoading, setProductsLoading] = useState(true);
  const [productsList, setProductsList] = useState([]);
  const [categoryTitle, setCategoryTitle] = useState(Traslate(ELEMENTS.PRODUCTS.ALL));
  const [categoryList, setCategoryList] = useState([]);
  // vars Modal
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState(null);

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleOpenModal = (item) => {
    setModalData(item);
    setOpenModal(true);
  };

  const handleCategoryChange = (id, name) => {
    if (id === 'all') {
      setCategoryTitle('Todos los productos');
      localStorage.setItem('product_category', 0);
      localStorage.setItem('product_category_name', 'all');
      fetchListProducts();
    } else {
      setCategoryTitle(name);
      localStorage.setItem('product_category', id);
      localStorage.setItem('product_category_name', name);
      fetchListProductsByCategory(id);
    }
  };

  const fetchListProducts = async () => {
    setProductsLoading(true);
    const res = await productService.list();
    if (!res.info.error) {
      setProductsList(res.data);
    } else {
      createMessage.error(res.info.message);
    }
    setProductsLoading(false);
  };

  const fetchListProductsByCategory = async (id) => {
    setProductsLoading(true);
    const res = await productService.listByCategory(id);
    if (!res.info.error) {
      setProductsList(res.data);
    } else {
      createMessage.error(res.info.message);
    }
    setProductsLoading(false);
  };

  const fetchListCategories = async () => {
    const res = await productCategoryService.list();
    if (!res.info.error) {
      setCategoryList(res.data);
    } else {
      createMessage.error(res.info.message);
    }
  };

  useEffect(() => {
    if (categoryId) {
      setCategoryTitle(categoryName);
      fetchListProductsByCategory(categoryId);
    } else {
      fetchListProducts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryId]);

  useEffect(() => {
    fetchListCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="cate-products">
      <Container maxWidth="lg">
        <div className="cate-products__wrap">
          <CatePaper
            disableElevation={isMobile ? false : true}
            disableBorder={isMobile ? true : false}
            className="cate-products-filter">
            <div className="cate-products-filter__title">
              <h4>{Traslate(ELEMENTS.PRODUCTS.CATEGORIES)}</h4>
            </div>
            <FilterTabs
              tab={0}
              data={categoryList}
              onTabClick={handleCategoryChange}
              locale={locale}
            />
          </CatePaper>
          <div className="cate-products-list">
            <div className="cate-products-list__wrap">
              <div className="product-list-title">
                <div className="product-result">
                  <span className="product-result__label">{categoryTitle} </span>
                  <span className="product-result__amount">{`(${productsList.length} Resultados)`}</span>
                </div>
              </div>
              <CardGrid
                loading={productsLoading}
                data={productsList}
                onClick={handleOpenModal}
                locale={locale}
              />
            </div>
          </div>
        </div>
      </Container>
      <ProductModal data={modalData} open={openModal} onClose={handleCloseModal} />
      {notification.state && <Notification onClose={closeMessage} {...notification} />}
    </div>
  );
}

export default Products;
