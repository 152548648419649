// eslint-disable-next-line
const VARIANT_MONTH = {
  1: 'ENERO',
  2: 'FEBEBRERO',
  3: 'MARZO',
  4: 'ABRIL',
  5: 'MAYO',
  6: 'JUNIO',
  7: 'JULIO',
  8: 'AGOSTO',
  9: 'SEPTIEMBRE',
  10: 'OCTUBRE',
  11: 'NOVIEMBRE',
  12: 'DICICIEMBRE',
};

const dateFormat = {};

dateFormat.fullDate = function (date_string) {
  let myDate = new Date(date_string);

  let year = myDate.getFullYear();
  let month = myDate.getMonth() + 1;
  let day = myDate.getDate();

  const date_format = day + '/' + month + '/' + year;

  return date_format;
};

dateFormat.experienceDate = function (date = '14-14-14') {
  const dateInArray = date.split('-');
  const [_, month, day] = dateInArray;

  return day + ' ' + VARIANT_MONTH[month];
};

export default dateFormat;
