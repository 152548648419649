import React, { useState, useEffect, useContext } from 'react';
import { blogService, blogCategoryService } from 'services';
import { useNotification, useResponsive } from 'hooks';
import './blog.scss';
// Components
import { Notification, FilterTabs, CatePaper } from 'components';
// eslint-disable-next-line no-unused-vars
import { Banner, CardGrid } from './components';
import { Container } from '@material-ui/core';
import Traslate from 'i18n/traslate';
import ELEMENTS from 'constants/elementsForTranslate';
import { Context } from 'context';

function Blog() {
  const { isMobile } = useResponsive();
  const { locale } = useContext(Context);
  const { notification, createMessage, closeMessage } = useNotification();
  const [blogLoading, setBlogLoading] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [categoryLoading, setCategoryLoading] = useState(true);
  const [blogList, setBlogList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);

  const handleCategoryChange = (id) => {
    if (id === 'all') {
      fetchListBlog();
    } else {
      fetchListBlogByCategory(id);
    }
  };

  const fetchListBlog = async () => {
    setBlogLoading(true);
    const res = await blogService.list();
    if (!res.info.error) {
      setBlogList(res.data);
    } else {
      createMessage.error(res.info.message);
    }
    setBlogLoading(false);
  };

  const fetchListBlogByCategory = async (id) => {
    setBlogLoading(true);
    const res = await blogService.listByCategory(id);
    if (!res.info.error) {
      setBlogList(res.data);
    } else {
      createMessage.error(res.info.message);
    }
    setBlogLoading(false);
  };

  const fetchListCategories = async () => {
    setCategoryLoading(true);
    const res = await blogCategoryService.list();
    if (!res.info.error) {
      const filterCategories = [...res.data.filter((item) => item.id !== 15)];
      setCategoryList(filterCategories);
    } else {
      createMessage.error(res.info.message);
    }
    setCategoryLoading(false);
  };

  useEffect(() => {
    fetchListBlog();
    fetchListCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="cate-blog">
      {/*
      <Banner />
      */}
      <div className="cate-blog-content">
        <Container maxWidth="lg">
          <div className="nui-main-title">
            <h1>Blog</h1>
          </div>
          <div className="cate-blog-wrap">
            <div className="cate-blog-wrap__list">
              <CardGrid loading={blogLoading} data={blogList} locale={locale} />
            </div>
            <CatePaper
              disableElevation={isMobile ? false : true}
              disableBorder={isMobile ? true : false}
              className="cate-blog-filter">
              <div className="cate-blog-filter__title">
                <h4>{Traslate(ELEMENTS.PRODUCTS.CATEGORIES)}</h4>
              </div>
              <FilterTabs
                tab={0}
                data={categoryList}
                onTabClick={handleCategoryChange}
                locale={locale}
              />
            </CatePaper>
          </div>
        </Container>
      </div>
      {notification.state && <Notification onClose={closeMessage} {...notification} />}
    </div>
  );
}

export default Blog;
