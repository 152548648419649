import React, { useEffect } from 'react';
// Components
import { Container } from '@material-ui/core';

const PENDING = 'pending';
const SUCCESSFUL = 'successful';
const REJECTED = 'rejected';

const PendingConfirmation = () => {
  return <div>Pago sin confirmar, por favor verifique el estado en Mercado Pago</div>;
};

const SuccessfulConfirmation = () => {
  return <div>Gracias por Subscribirte!!!</div>;
};

const ConfirmationReject = () => {
  return <div>Pago denegado</div>;
};

const renderStatus = (status) => {
  switch (status) {
    case PENDING:
      return <PendingConfirmation />;
    case SUCCESSFUL:
      return <SuccessfulConfirmation />;
    case REJECTED:
      return <ConfirmationReject />;
    default:
      return <h1>Hubo un error </h1>;
  }
};

function SubscriptionStatus() {
  // let status = document.cookie
  //   .split(';')
  //   .find((item) => item.trim().startsWith('status='))
  //   .split('=')[1];

  // console.log(status);

  const status = REJECTED;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div style={{ padding: '4rem 0' }}>
      <Container maxWidth="lg">
        <div id="page_content">
          <h1 className="nui-font-size-2 nui-mg-b-2">{renderStatus(status)}</h1>
        </div>
      </Container>
    </div>
  );
}

export default SubscriptionStatus;
