import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import * as ROUTES from 'constants/routes';

// Components
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  Divider,
  makeStyles,
  ListItemText,
  Collapse,
} from '@material-ui/core';

import HomeRoundedIcon from '@material-ui/icons/HomeRounded';
import LocalMallRoundedIcon from '@material-ui/icons/LocalMallRounded';
import MenuBookRoundedIcon from '@material-ui/icons/MenuBookRounded';
import CardMembershipRoundedIcon from '@material-ui/icons/CardMembershipRounded';
import LocalCafeRoundedIcon from '@material-ui/icons/LocalCafeRounded';
import Book from '@material-ui/icons/Book';

import InboxIcon from '@material-ui/icons/MoveToInbox';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

const ItemLink = ({ path, icon, text }) => (
  <RouterLink to={path} className="nui-no-decoration">
    <ListItem button>
      <ListItemIcon>{icon}</ListItemIcon>
      <div className="nui-font-size-base nui-semibold">{text}</div>
    </ListItem>
  </RouterLink>
);

// Constantes Submenu con Nested List Items

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  nestedSecondLevel: {
    paddingLeft: theme.spacing(8),
  },
}));

// FIN Constantes Submenu con Nested List Items

function CateDrawer(props) {
  const { open, onClose } = props;

  // constantes para submenu
  // const [anchorElM, setAnchorElM] = React.useState(null);
  // const openM = Boolean(anchorElM);
  // const handleClickM = (event) => {
  //   setAnchorElM(event.currentTarget);
  // };

  // const handleCloseM = () => {
  //   setAnchorElM(null);
  // };

  //FIN constantes para submenu

  // constantes de Submenu con Nested List Items
  const classes = useStyles();
  const [openRM, setOpenRM] = React.useState(false);

  const handleClickRM = () => {
    setOpenRM(!openRM);
  };
  // FIN constantes de Submenu con Nested List Items
  return (
    <Drawer anchor="left" open={open} onClose={onClose}>
      <List component="nav" aria-labelledby="nested-list-subheader" className={classes.root}>
        <ItemLink path={ROUTES.HOME} icon={<HomeRoundedIcon />} text="Inicio" />
        <ItemLink path={ROUTES.PRODUCTS} icon={<LocalMallRoundedIcon />} text="Tienda" />
        <ItemLink
          path={ROUTES.MEMBERSHIPS}
          icon={<CardMembershipRoundedIcon />}
          text="Suscripciones"
        />
        <ItemLink
          path={ROUTES.EXPERIENCES}
          icon={<LocalCafeRoundedIcon />}
          text="Experiencias de cata"
        />
        {/* Submenu con Nested List Items*/}
        <ListItem button onClick={handleClickRM}>
          <ListItemIcon>
            <InboxIcon />
          </ListItemIcon>
          <ListItemText>
            <div className="nui-font-size-base nui-semibold">Recursos</div>
          </ListItemText>

          {openRM ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openRM} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <div button className={classes.nested}>
              <ItemLink path={ROUTES.BLOG} icon={<Book />} text="Blog" />
            </div>
          </List>
          <List component="div" disablePadding>
            <div button className={classes.nested}>
              <ItemLink path={ROUTES.COURSES} icon={<MenuBookRoundedIcon />} text="Cursos" />
            </div>
          </List>
        </Collapse>

        {/* Fin Submenu con Nested List Items*/}
        {/* <ItemLink path={ROUTES.PRODUCTSB2B} icon={<StoreOutlined/>}text="Wholesale" /> */}
      </List>
      <Divider />
    </Drawer>
  );
}

CateDrawer.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default CateDrawer;
