import React, { useContext } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Context } from 'context/Context';
import * as ROUTES from 'constants/routes';
import './userPopover.scss';
// Components
import { Popover, List, ListItem, ListItemIcon, Divider } from '@material-ui/core';
import AccountBoxRoundedIcon from '@material-ui/icons/AccountBoxRounded';
import LocalMallRoundedIcon from '@material-ui/icons/LocalMallRounded';
import HttpsRoundedIcon from '@material-ui/icons/HttpsRounded';
import PowerSettingsNewRoundedIcon from '@material-ui/icons/PowerSettingsNewRounded';
import EventRoundedIcon from '@material-ui/icons/EventRounded';
import AllInboxIcon from '@material-ui/icons/AllInbox';
import Traslate from 'i18n/traslate';
import ELEMENTS from 'constants/elementsForTranslate';

const ItemLink = ({ path, icon, text, onClick }) => {
  if (!path) {
    return (
      <ListItem button onClick={onClick}>
        <ListItemIcon>{icon}</ListItemIcon>
        <div className="topbar-popover-text">{text}</div>
      </ListItem>
    );
  }

  return (
    <RouterLink className="nui-no-decoration" to={path}>
      <ListItem button onClick={onClick}>
        <ListItemIcon>{icon}</ListItemIcon>
        <div className="topbar-popover-text">{text}</div>
      </ListItem>
    </RouterLink>
  );
};

const UserPopover = (props) => {
  const { id, open, anchorEl, onClose } = props;
  const { removeAuth } = useContext(Context);

  const handleCloseSession = () => {
    onClose();
    removeAuth();
  };

  return (
    <Popover
      id={id}
      className="topbar-popover"
      elevation={2}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}>
      <List>
        <ItemLink
          path={ROUTES.USER_PROFILE}
          icon={<AccountBoxRoundedIcon />}
          text={Traslate(ELEMENTS.NAV.MANAGE)}
        />
        <ItemLink
          path={ROUTES.CHANGE_USER_PASSWORD}
          icon={<HttpsRoundedIcon />}
          text={Traslate(ELEMENTS.NAV.CHANGE_PASSWORD)}
        />
        <ItemLink
          path={ROUTES.USER_PURCHASES}
          icon={<LocalMallRoundedIcon />}
          text={Traslate(ELEMENTS.NAV.MY_SHOPPING)}
        />
        <ItemLink
          path={ROUTES.USER_EXPERIENCES}
          icon={<EventRoundedIcon />}
          text={Traslate(ELEMENTS.NAV.MY_EXPERIENCES)}
        />
        <ItemLink
          path={ROUTES.USER_MEMBERSHIPS}
          icon={<AllInboxIcon />}
          text={Traslate(ELEMENTS.NAV.MY_MEMBERSHIPS)}
        />
      </List>
      <Divider />
      <List>
        <ItemLink
          icon={<PowerSettingsNewRoundedIcon />}
          text={Traslate(ELEMENTS.NAV.LOGOUT)}
          onClick={handleCloseSession}
        />
      </List>
    </Popover>
  );
};

UserPopover.propTypes = {
  id: PropTypes.string,
  open: PropTypes.bool,
  anchorEl: PropTypes.object,
  handleClose: PropTypes.func,
};

export default UserPopover;
