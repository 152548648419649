import configService from './config';
import fetchApi from './fetchApi';
import * as METHODS from './methods';

const baseUrl = configService.apiUrl;
const productCategoryService = {};

productCategoryService.list = async function () {
  const { response } = await fetchApi({
    url: baseUrl + '/product-category',
    options: METHODS.GET(),
    dataType: [],
    message: {
      success: 'Las categorías de los productos han sido obtenidas con éxito.',
      error: 'No se han podido obtener las categorías de los productos.',
    },
  });

  return { ...response };
};

productCategoryService.listNew = async function () {
  const { response } = await fetchApi({
    url: baseUrl + '/product-category/news',
    options: METHODS.GET(),
    dataType: [],
    message: {
      success: 'Las categorías de los productos han sido obtenidas con éxito.',
      error: 'No se han podido obtener las categorías de los productos.',
    },
  });

  return { ...response };
};

export default productCategoryService;
