const Storage = (cartItems) => {
  localStorage.setItem('cart', JSON.stringify(cartItems.length > 0 ? cartItems : []));
};

export const sumItems = (cartItems) => {
  Storage(cartItems);
  let itemCount = cartItems.reduce((total, product) => total + product.quantity, 0);
  let total = cartItems
    .reduce((total, product) => total + product.price * product.quantity, 0)
    .toFixed(2);
  return { itemCount, total };
};

export const CartReducer = (state, action) => {
  let elementsIndex = -1;
  let newArray = [];

  switch (action.type) {
    case 'ADD_ITEM':
      if (!state.cartItems.find((item) => item.ak_product === action.payload.ak_product)) {
        state.cartItems.push({
          ...action.payload,
          quantity: 1,
        });
      }
      return {
        ...state,
        ...sumItems(state.cartItems),
        cartItems: [...state.cartItems],
      };

    case 'ADD_ITEM_COUNT':
      if (!state.cartItems.find((item) => item.ak_product === action.payload.ak_product)) {
        state.cartItems.push({
          ...action.payload,
          quantity: action.count,
        });
      }
      return {
        ...state,
        ...sumItems(state.cartItems),
        cartItems: [...state.cartItems],
      };
    case 'REMOVE_ITEM':
      return {
        ...state,
        ...sumItems(
          state.cartItems.filter((item) => item.ak_product !== action.payload.ak_product),
        ),
        cartItems: [
          ...state.cartItems.filter((item) => item.ak_product !== action.payload.ak_product),
        ],
      };
    case 'INCREASE':
      elementsIndex = state.cartItems.findIndex(
        (item) => item.ak_product === action.payload.ak_product,
      );
      newArray = [...state.cartItems];

      newArray[elementsIndex] = {
        ...newArray[elementsIndex],
        quantity: newArray[elementsIndex].quantity + 1,
      };

      return {
        ...state,
        ...sumItems(newArray),
        cartItems: [...newArray],
      };
    case 'DECREASE':
      elementsIndex = state.cartItems.findIndex(
        (item) => item.ak_product === action.payload.ak_product,
      );
      newArray = [...state.cartItems];

      newArray[elementsIndex] = {
        ...newArray[elementsIndex],
        quantity: newArray[elementsIndex].quantity - 1,
      };

      return {
        ...state,
        ...sumItems(newArray),
        cartItems: [...newArray],
      };
    case 'SET_COUNT':
      elementsIndex = state.cartItems.findIndex(
        (item) => item.ak_product === action.payload.ak_product,
      );
      newArray = [...state.cartItems];

      newArray[elementsIndex] = {
        ...newArray[elementsIndex],
        quantity: action.count,
      };

      return {
        ...state,
        ...sumItems(newArray),
        cartItems: [...newArray],
      };
    case 'ADD_SHIPPING':
      return {
        ...state,
        shipping: {
          department: action.department,
          province: action.province,
          district: action.district,
          address: action.address,
        },
        deliveryPrice: action.price,
      };
    case 'SET_CHECKOUT':
      return {
        ...state,
        checkout: action.checkout,
      };
    case 'ADD_STORE_PICKUP':
      return {
        ...state,
        storePickup: {
          datePickup: action.datePickup,
          timePickup: action.timePickup,
        },
      };
    case 'SET_STORE_PICKUP':
      return {
        ...state,
        isStorePickup: action.isStorePickup,
      };
    case 'ADD_INVOICE':
      return {
        ...state,
        invoice: {
          isInvoice: action.isInvoice,
          ruc: action.ruc,
          businessName: action.businessName,
          businessAddress: action.businessAddress,
        },
      };
    case 'CLEAR_INVOICE':
      return {
        ...state,
        invoice: {},
      };
    case 'CLEAR':
      return {
        cartItems: [],
        ...sumItems([]),
        checkout: false,
        shipping: {},
        deliveryPrice: null,
        isStorePickup: false,
        invoice: {},
      };
    case 'SET_LOCATION':
      return {
        ...state,
        location: action.payload,
      };
    default:
      return state;
  }
};
