import React, { useState, useEffect, useContext } from 'react';
import { LOCALES } from '../../../i18n/locales';
import { Link as RouterLink } from 'react-router-dom';
import Carousel from 'react-multi-carousel';
import { productService } from 'services';
import * as VARIABLES from 'constants/variables';
import * as ROUTES from 'constants/routes';
import PropTypes from 'prop-types';
import './carousel.scss';
import 'react-multi-carousel/lib/styles.css';

import Traslate from 'i18n/traslate';
// Components
import { Container, Grid } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import ELEMENTS from 'constants/elementsForTranslate';
import { Context } from 'context';

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const layout = { xs: 12, sm: 6, md: 3 };

const Card = (props) => {
  const { id, text, textEn, image, locale } = props;
  return (
    <RouterLink
      to={ROUTES.PRODUCTS + '?id=' + id + '&name=' + text}
      className="nui-no-decoration nui-block">
      <div className="home-carousel-card">
        <div className="home-carousel-card__cover">
          <img
            src={image ? VARIABLES.IMAGE_PATH + image : VARIABLES.IMAGE_FALLBACK}
            alt={text}
          />
        </div>
        <div className="home-carousel-card__content">
          <span className="content-title">{locale === LOCALES.ENGLISH ? textEn : text}</span>
        </div>
      </div>
    </RouterLink>
  );
};

const CarouselRecom = (props) => {
  const { locale } = useContext(Context);
  const { id } = props;
  const [recommendedLoading, setCategoryLoading] = useState(true);
  const [recommended, setRecommended] = useState([]);
  // const recommendedCopy = recommended;
  // const ordererdRecommendedProducts = recommendedCopy.sort((a, b) =>
  //   a.posic_image_recom > b.posic_image_recom ? 1 : -1,
  // );

  // console.log(ordererdRecommendedProducts);

  const fetchListRecommended = async () => {
    setCategoryLoading(true);
    const res = await productService.getRecommended();
    if (!res.info.error) {
      setRecommended(res.data);
    }
    setCategoryLoading(false);
  };

  useEffect(() => {
    fetchListRecommended();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section id={id} className="cat-home-carousel">
      <Container maxWidth="lg">
        <div className="cat-home-carousel__header">
          <h2>{Traslate(ELEMENTS.RECOMMEDED_PRODUCTS.TITLE)}</h2>
        </div>
        <div className="cat-home-carousel__body">
          {recommendedLoading ? (
            <Grid container spacing={3}>
              {Array.from(new Array(4)).map((item, index) => (
                <Grid item key={index} {...layout}>
                  <Skeleton
                    className="home-carousel-card"
                    animation="wave"
                    variant="rect"
                    height={250}
                  />
                </Grid>
              ))}
            </Grid>
          ) : (
            <Carousel autoPlay infinite responsive={responsive}>
              {recommended.map((item, index) => {
                return (
                  <Card
                    key={item.id}
                    id={item.id}
                    text={item.label}
                    textEn={item.label_en}
                    image={item.images[0]?.image_path}
                    locale={locale}
                  />
                );
              })}
            </Carousel>
          )}
        </div>
      </Container>
    </section>
  );
};

CarouselRecom.propTypes = {
  id: PropTypes.string,
};

export default CarouselRecom;
