const MuiPaper = {
  elevation1: {
    boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)',
  },
  elevation2: {
    boxShadow: '0 0 0 1px rgba(63,63,68,0.1), 0 2px 5px 0 rgba(63,63,68,0.1)',
  },
  elevation3: {
    boxShadow: '0 0 0 1px rgba(63,63,68,0.1), 0px 3px 5px 0 rgba(0, 0, 0, 0.2)',
  },
  rounded: {
    borderRadius: '0.8rem',
  },
};

export default MuiPaper;
