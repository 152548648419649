import { LOCALES } from '../i18n';
import React, { createContext, useReducer } from 'react';
import { useState } from 'react';
import { useCookies } from 'react-cookie';
import { CartReducer, sumItems } from './CartReducer';
export const Context = createContext();

const storage = localStorage.getItem('cart') ? JSON.parse(localStorage.getItem('cart')) : [];
const initialState = {
  cartItems: storage,
  ...sumItems(storage),
  checkout: false,
  shipping: {},
  deliveryPrice: null,
  storePickup: {},
  isStorePickup: false,
  invoice: {},
};

const Provider = ({ children }) => {
  const [cookies, setCookies, removeCookies] = useCookies([
    'id',
    'name',
    'email',
    'token',
    'role',
    'memberships',
    'experience',
    'shippingMemberships',
  ]);

  const [state, dispatch] = useReducer(CartReducer, initialState);

  const userLang = navigator.language || navigator.userLanguage;
  const lang = userLang.substring(0, 2);
  const [locale, setLocale] = useState(lang === 'es' ? LOCALES.SPANISH : LOCALES.ENGLISH);

  const increase = (payload) => {
    dispatch({ type: 'INCREASE', payload });
  };

  const decrease = (payload) => {
    dispatch({ type: 'DECREASE', payload });
  };

  const addProduct = (payload) => {
    dispatch({ type: 'ADD_ITEM', payload });
  };

  const addProductCount = (payload, count) => {
    dispatch({ type: 'ADD_ITEM_COUNT', payload, count });
  };

  const removeProduct = (payload) => {
    dispatch({ type: 'REMOVE_ITEM', payload });
  };

  const setProductCount = (payload, count) => {
    dispatch({ type: 'SET_COUNT', payload, count });
  };

  const setShipping = (department, province, district, address, price) => {
    dispatch({ type: 'ADD_SHIPPING', department, province, district, address, price });
  };

  const setCheckout = (checkout) => {
    dispatch({ type: 'SET_CHECKOUT', checkout });
  };

  const addStorePickup = (datePickup, timePickup) => {
    dispatch({ type: 'ADD_STORE_PICKUP', datePickup, timePickup });
  };

  const setStorePickup = (isStorePickup) => {
    dispatch({ type: 'SET_STORE_PICKUP', isStorePickup });
  };

  const addInvoice = (isInvoice, ruc, businessName, businessAddress) => {
    dispatch({ type: 'ADD_INVOICE', isInvoice, ruc, businessName, businessAddress });
  };

  const clearInvoice = () => {
    dispatch({ type: 'CLEAR_INVOICE' });
  };

  const clearCart = () => {
    dispatch({ type: 'CLEAR' });
  };

  const activateAuth = (id, name, email, token, role) => {
    setCookies('id', id, { path: '/' });
    setCookies('name', name, { path: '/' });
    setCookies('email', email, { path: '/' });
    setCookies('token', token, { path: '/' });
    setCookies('role', role, { path: '/' });
  };

  const removeAuth = () => {
    removeCookies('id', { pth: '/' });
    removeCookies('name', { path: '/' });
    removeCookies('email', { path: '/' });
    removeCookies('token', { path: '/' });
    removeCookies('role', { path: '/' });
  };

  // eslint-disable-next-line
  const verifiedUser = (isItVerified) => {
    setCookies('isItVerified', isItVerified, { path: '/' });
  };

  const addMemberships = (memberships) => {
    setCookies('memberships', memberships, { path: '/' });
  };

  const clearMemberships = () => {
    removeCookies('memberships', { path: '/' });
  };

  const addExperience = (experience) => {
    setCookies('experience', experience, { path: '/' });
  };

  const clearExperience = () => {
    removeCookies('experience', { path: '/' });
  };

  const addShippingMemberships = (shippingMemberships) => {
    setCookies('shippingMemberships', shippingMemberships, { path: '/' });
  };

  const clearShippingMemberships = () => {
    removeCookies('shippingMemberships', { path: '/' });
  };

  const toggleLanguage = () => {
    if (locale === LOCALES.ENGLISH) {
      setLocale(LOCALES.SPANISH);
    } else {
      setLocale(LOCALES.ENGLISH);
    }
  };

  const value = {
    id: cookies.id,
    name: cookies.name,
    email: cookies.email,
    token: cookies.token,
    role: cookies.role,
    memberships: cookies.memberships,
    experience: cookies.experience,
    shippingMemberships: cookies.shippingMemberships,
    locale,
    ...state,
    activateAuth,
    removeAuth,
    addMemberships,
    clearMemberships,
    addExperience,
    clearExperience,
    addShippingMemberships,
    clearShippingMemberships,
    removeProduct,
    addProduct,
    addProductCount,
    increase,
    decrease,
    setProductCount,
    setShipping,
    setCheckout,
    addStorePickup,
    setStorePickup,
    addInvoice,
    clearInvoice,
    clearCart,
    setLocale,
    toggleLanguage,
  };

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  Provider,
  Consumer: Context.Consumer,
};
