import React from 'react';
import { Button, Result } from 'antd';
import './confirmEmail.scss';

function ConfirmEmail() {
  const handleClick = () => {
    fetchSendNewConfirmationEmail();
  };

  const fetchSendNewConfirmationEmail = async () => {};

  return (
    <div className="confirm-email">
      <Result
        status="success"
        title="Confirma tu correo"
        subTitle="Se te ha enviado un enlace para confirmar tu correo"
        extra={[
          <Button type="primary" key="console" onClick={handleClick}>
            Reenviar correo
          </Button>,
        ]}></Result>
    </div>
  );
}

export default ConfirmEmail;
