import configService from './config';
import fetchApi from './fetchApi';
import * as METHODS from './methods';

const baseUrl = configService.apiUrl;
const userService = {};

userService.login = async function (args) {
  const body = {
    email: args.email,
    password: args.password,
  };

  const { response } = await fetchApi({
    url: baseUrl + '/login',
    options: METHODS.POST(body),
    dataType: {},
    message: {
      success: 'Usuario validado con éxito.',
      error: 'No se puedo encontrar al usuario.',
    },
  });

  return { ...response };
};

userService.register = async function (args) {
  const body = {
    name: args.name,
    last_name: args.lastName,
    email: args.email,
    password: args.password,
    phone_code: args.phoneCode,
    phone_number: args.phone,
    document_number: args.document_number,
  };

  const { response } = await fetchApi({
    url: baseUrl + '/register',
    options: METHODS.POST(body),
    dataType: {},
    message: {
      success: 'Usuario registrado con éxito.',
      error: 'No se puedo registrar al usuario.',
    },
  });

  return { ...response };
};

userService.registerAndSubscribe = async function (
  user,
  userDetail,
  plan,
  token,
  productPropertyDetailsId,
  otherProductDetail,
) {
  const {
    name,
    last_name,
    email,
    phone_code,
    phone_number,
    invoice,
    ruc,
    business_name,
    business_address,
  } = user;
  const { address, department, district, document_number, provincia } = userDetail;
  const { id: plan_id, version } = plan;

  const body = {
    subscription_plan_id: plan_id,
    subscription_plan_version: version,
    name,
    last_name,
    email,
    phone_code,
    phone: phone_number,
    document_number,
    district,
    province: provincia,
    address,
    department,
    invoice: invoice === 'Factura' ? true : false,
    ruc,
    business_name,
    business_address,
    token,
    product_property_details_id: productPropertyDetailsId,
    product_property_details_other: otherProductDetail,
  };

  const { response } = await fetchApi({
    url: baseUrl + '/subscriptions/create-subscription-user',
    options: METHODS.AUTH_POST(body, token),
    dataType: {},
    message: {
      success: 'Usuario registrado y subscrito satisfactoriamente.',
      error: 'No se puedo registrar al usuario.',
    },
  });

  return { ...response };
};

userService.registerBySubscription = async function (args) {
  const body = {
    name: args.name,
    last_name: args.lastName,
    email: args.email,
    password: args.password,
    phone_code: args.phoneCode,
    phone_number: args.phone,
    document_number: args.document_number,
    department: args.department,
    province: args.province,
    district: args.district,
    address: args.address,
  };

  const { response } = await fetchApi({
    url: baseUrl + '/register-by-subscription',
    options: METHODS.POST(body),
    dataType: {},
    message: {
      success: 'Usuario registrado con éxito.',
      error: 'No se puedo registrar al usuario.',
    },
  });

  return { ...response };
};

userService.show = async function (id) {
  const { response } = await fetchApi({
    url: baseUrl + '/user/show/' + id,
    options: METHODS.GET(),
    dataType: {},
    message: {
      success: 'Datos del usuario obtenidos con éxito.',
      error: 'No se pudo obtener los datos del usuario.',
    },
  });

  return { ...response };
};

userService.showDetail = async function (id) {
  const { response } = await fetchApi({
    url: baseUrl + '/user-detail/show/' + id,
    options: METHODS.GET(),
    dataType: {},
    message: {
      success: 'Datosextra  del usuario obtenidos con éxito.',
      error: 'No se pudo obtener los datos extra del usuario.',
    },
  });

  return { ...response };
};

userService.update = async function (args) {
  const body = {
    name: args.name,
    last_name: args.lastName,
    password: args.password,
    email: args.email,
    phone_code: args.phoneCode,
    phone_numer: args.phoneNumer,
  };

  const { response } = await fetchApi({
    url: baseUrl + '/user/update/' + args.id,
    options: METHODS.PUT(body),
    dataType: {},
    message: {
      success: 'Datos actualizados con éxito.',
      error: 'No se pudo actualizar los datos del usuario.',
    },
  });

  return { ...response };
};

userService.updateDetail = async function (args) {
  const body = {
    user_id: args.id,
    department: args.department,
    provincia: args.provincia,
    district: args.district,
    address: args.address,
    document_number: args.documentNumber,
  };

  const { response } = await fetchApi({
    url: baseUrl + '/user-detail/update-or-store/' + args.id,
    options: METHODS.POST(body),
    dataType: {},
    message: {
      success: 'Datos actualizados con éxito.',
      error: 'No se pudo actualizar los datos del usuario.',
    },
  });

  return { ...response };
};

userService.sendEmailChangePwd = async function (args) {
  const body = {
    email: args.email,
  };

  const { response } = await fetchApi({
    url: baseUrl + '/mail/change-password',
    options: METHODS.POST(body),
    dataType: {},
    message: {
      success: 'Mensaje enviado con éxito.',
      error: 'No se pudo enviar el mensaje al correo ingresado.',
    },
  });

  return { ...response };
};

userService.changePassword = async function (args) {
  const body = {
    email: args.email,
    verify_code: args.verifyCode,
    password: args.newPassword,
  };

  const { response } = await fetchApi({
    url: baseUrl + '/change-password',
    options: METHODS.POST(body),
    dataType: {},
    message: {
      success: 'Contraseña cambiada con éxito.',
      error: 'Ocurrió un error al cambiar la contraseña.',
    },
  });

  return { ...response };
};

userService.updateSource = async function (args) {
  const body = {
    user_id: args.user_id,
    is_source_subscription: args.is_source_subscription,
  };

  const { response } = await fetchApi({
    url: baseUrl + '/user/update-source',
    options: METHODS.PUT(body),
    dataType: {},
    message: {
      success: 'Usuario validado con éxito.',
      error: 'No se puedo encontrar al usuario.',
    },
  });

  return { ...response };
};

userService.buscarUserEmail = async function (email) {
  const { response } = await fetchApi({
    url: baseUrl + '/user-detail/buscar-email/' + email,
    options: METHODS.GET(),
    dataType: {},
    message: {
      success: 'Datos del usuario obtenidos con éxito.',
      error: 'No se pudo obtener los datos del usuario.',
    },
  });

  return { ...response };
};
export default userService;
