import React, { useState, useEffect } from 'react';
import { LOCALES } from '../../i18n/locales';
import * as VARIABLES from 'constants/variables';
import { subscriptionService, locationService } from 'services';
import { useHistory } from 'react-router-dom';
import { useNotification } from 'hooks';
import * as ROUTES from 'constants/routes';
import { Context } from 'context/Context';
import './memberships.scss';
// Components
import { Notification, CatePaper, AuthDialog } from 'components';
import { Modal } from 'antd';
import { Container, Grid, MenuItem, TextField, Button, Chip } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import Traslate from 'i18n/traslate';
import ELEMENTS from 'constants/elementsForTranslate';
import { useContext } from 'react';

const layout = { sm: 12, md: 4 };

const SelectRowWithouEnglish = ({
  id,
  text,
  menuData = [],
  value,
  onChange,
  required,
  disabled,
  placeholder,
  locale,
}) => {
  return (
    <li>
      <label htmlFor={id}>{text}</label>
      <div className="input-row">
        <TextField
          select
          required={required}
          disabled={disabled}
          variant="outlined"
          id={id}
          name={id}
          value={value}
          size="small"
          fullWidth
          helperText={placeholder}
          onChange={onChange}>
          {menuData.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.name}
            </MenuItem>
          ))}
        </TextField>
      </div>
    </li>
  );
};

const SelectRow = ({
  id,
  text,
  menuData = [],
  value,
  onChange,
  required,
  disabled,
  placeholder,
  locale,
}) => {
  return (
    <li>
      <label htmlFor={id}>{text}</label>
      <div className="input-row">
        <TextField
          select
          required={required}
          disabled={disabled}
          variant="outlined"
          id={id}
          name={id}
          value={value}
          size="small"
          fullWidth
          helperText={placeholder}
          onChange={onChange}>
          {menuData.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {locale === LOCALES.ENGLISH ? option.name_en : option.name}
            </MenuItem>
          ))}
        </TextField>
      </div>
    </li>
  );
};

const SelectRowLabel = ({
  id,
  text,
  menuData = [],
  value,
  onChange,
  required,
  disabled,
  placeholder,
  locale,
}) => {
  return (
    <li>
      <label htmlFor={id}>{text}</label>
      <div className="input-row">
        <TextField
          select
          required={required}
          disabled={disabled}
          variant="outlined"
          id={id}
          name={id}
          value={value}
          size="small"
          fullWidth
          helperText={placeholder}
          onChange={onChange}>
          {menuData.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {locale === LOCALES.ENGLISH ? option.label_en : option.label}
            </MenuItem>
          ))}
        </TextField>
      </div>
    </li>
  );
};

export const PriceCard = (props) => {
  const history = useHistory();
  const { color, data, createMessage, deliveryPriceList, id, locale } = props;
  const { name, name_en, categories } = data;
  const { concepts } = data;

  const [categorySelected, setCategorySelected] = useState('');
  const [productSelected, setProductSelected] = useState('');
  const [currentProducts, setCurrentProducts] = useState([]);
  const [currentPlanId, setCurrentPlanId] = useState('');

  const [confirmLoading, setConfirmLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [departments, setDepartments] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [location, setLocation] = useState({
    department: '',
    provincia: '',
    district: '',
  });

  const handleCategoryChange = (event) => {
    setCategorySelected(event.target.value);
    const currentCategory = categories.find((category) => category.id === event.target.value);
    const { products } = currentCategory;
    setCurrentProducts(products);
  };

  const handleProductsChange = (event) => {
    setProductSelected(event.target.value);
    const currentProduct = currentProducts.find((product) => product.id === event.target.value);
    const { plan_id } = currentProduct;
    setCurrentPlanId(plan_id);
  };

  const redirectToDetail = () => {
    if (!currentPlanId) {
      return createMessage.error('Seleccione una categoría y un producto.');
    }

    if (!location.department || !location.district || !location.department) {
      return createMessage.error('Complete los campos de ubicación.');
    }

    localStorage.setItem('location', JSON.stringify(location));
    history.push(ROUTES.MEMBERSHIPS_DETAIL + '?plan_id=' + currentPlanId);
  };

  const handleModalOpen = () => {
    if (!id) {
      history.push(ROUTES.LOGIN);
      return;
    }
    setIsModalOpen(true);
  };

  const afterCloseModal = () => {};
  const closeModal = () => {
    setIsModalOpen(false);
    setCurrentPlanId('');
    setCategorySelected('');
    setProductSelected('');
    setCurrentProducts([]);
  };

  const loadDepartmentsList = async () => {
    if (departments.length > 0) {
      return;
    }

    let departmentList = [];
    Object.values(deliveryPriceList).forEach((item) => {
      departmentList.push({
        id: item.department_id,
        name: item.department_name,
      });
    });
    setDepartments(departmentList);
  };

  const loadProvinceList = async (departmentId) => {
    let provincesList = [];
    Object.values(deliveryPriceList[departmentId].provinces).forEach((item) => {
      provincesList.push({
        id: item.province_id,
        name: item.province_name,
      });
    });
    setProvinces(provincesList);
  };

  const loadDistrictList = async (departmentId, provinceId) => {
    let districtList = [];
    Object.values(deliveryPriceList[departmentId].provinces[provinceId].districts).forEach(
      (item) => {
        districtList.push({
          id: item.ditrict_id,
          name: item.district_name,
        });
      },
    );
    setDistricts(districtList);
  };

  const onDepartmentChange = (value) => {
    setLocation({
      ...location,
      ['department']: value.target.value,
      ['provincia']: '',
      ['district']: '',
    });
    loadProvinceList(value.target.value);
  };

  const onProvinceChange = (value) => {
    setLocation({
      ...location,
      ['provincia']: value.target.value,
      ['district']: '',
    });
    loadDistrictList(location.department, value.target.value);
  };

  const onDistrictChange = (value) => {
    setLocation({
      ...location,
      ['district']: value.target.value,
    });
  };

  useEffect(() => {
    loadDepartmentsList();
  }, []);

  // useEffect(() => {
  //   if (location.department && departments) {
  //     onDepartmentChange(location.department);
  //   }
  // }, [location.department]);

  // useEffect(() => {
  //   console.log('cambia provincias');
  //   if (location.provincia && provinces) {
  //     onProvinceChange(location.provincia);
  //   }
  // }, [location.provincia]);

  // useEffect(() => {}, [location.provinces]);

  return (
    <CatePaper disableElevation className={`pricing-card pricing-card--${color}`}>
      <div className="pricing-card__body">
        <div className="pricing-header">
          <span>{locale === LOCALES.ENGLISH ? name_en : name}</span>
        </div>
        <div className="pricing-list">
          <img src={VARIABLES.IMAGE_PATH + data.foto} alt="café" />
          <div className="categories-container">
            <div className="concepts-container">
              {concepts.map((concept, index) => (
                <p className="concept" key={index}>
                  - {locale === LOCALES.ENGLISH ? concept.name_en : concept.name}
                </p>
              ))}
            </div>
            <div className="button-container">
              <p>
                <em> {Traslate(ELEMENTS.MEMBERSHIPS.FOOTER_MESSAGE)}</em>
              </p>
              <Button
                variant="contained"
                color={color === 'primary' ? 'inherit' : 'primary'}
                size="large"
                onClick={handleModalOpen}>
                {Traslate(ELEMENTS.MEMBERSHIPS.BUTTON)}
              </Button>
            </div>
            <Modal
              title={locale === LOCALES.ENGLISH ? name_en : name}
              visible={isModalOpen}
              confirmLoading={confirmLoading}
              afterClose={afterCloseModal}
              onOk={redirectToDetail}
              onCancel={closeModal}
              cancelText={locale === LOCALES.ENGLISH ? 'Cancel' : 'Cancelar'}>
              <div className="modal-membership">
                <h4>{Traslate(ELEMENTS.MEMBERSHIPS.MODAL.SELECT)}</h4>
                <h5>{Traslate(ELEMENTS.MEMBERSHIPS.MODAL.CATEGORY)}</h5>
                <SelectRow
                  id="category"
                  menuData={categories}
                  value={categorySelected}
                  onChange={handleCategoryChange}
                  placeholder={categories.length === 0 ? 'Cargando categorías' : ''}
                  locale={locale}
                />
                <h5>{Traslate(ELEMENTS.MEMBERSHIPS.MODAL.PRODUCT)}</h5>
                {!categorySelected && (
                  <span>{Traslate(ELEMENTS.MEMBERSHIPS.MODAL.PRODUCT_SELECT)}</span>
                )}
                <SelectRowLabel
                  id="product"
                  menuData={currentProducts}
                  value={productSelected}
                  onChange={handleProductsChange}
                  placeholder={
                    currentProducts.length === 0 && !!categorySelected
                      ? 'Cargando productos'
                      : ''
                  }
                  locale={locale}
                />

                <h5>{Traslate(ELEMENTS.MEMBERSHIPS.MODAL.DEPARTMENT)}</h5>

                <SelectRowWithouEnglish
                  id="department"
                  menuData={departments}
                  value={location.department}
                  onChange={onDepartmentChange}
                  placeholder={departments.length === 0 ? 'Cargando departamentos' : ''}
                  locale={locale}
                />

                <h5>{Traslate(ELEMENTS.MEMBERSHIPS.MODAL.PROVINCIA)}</h5>
                {!location.department && (
                  <span>{Traslate(ELEMENTS.MEMBERSHIPS.MODAL.PROVINCIA_SELECT)}</span>
                )}
                <SelectRowWithouEnglish
                  id="provincia"
                  menuData={provinces}
                  value={location.provincia}
                  onChange={onProvinceChange}
                  placeholder={
                    provinces.length === 0 && location.department.length !== 0
                      ? 'Cargando provincias'
                      : ''
                  }
                  locale={locale}
                />

                <h5>{Traslate(ELEMENTS.MEMBERSHIPS.MODAL.DISTRICT)}</h5>
                {!location.provincia && (
                  <span>{Traslate(ELEMENTS.MEMBERSHIPS.MODAL.DISTRICT_SELECT)}</span>
                )}
                <SelectRowWithouEnglish
                  id="district"
                  menuData={districts}
                  value={location.district}
                  onChange={onDistrictChange}
                  locale={locale}
                  placeholder={districts.length === 0 && !!location.provincia ? 'Cargando' : ''}
                />
              </div>
            </Modal>
          </div>
        </div>
      </div>
    </CatePaper>
  );
};

function Memberships() {
  const { id, locale } = useContext(Context);
  const { notification, createMessage, closeMessage } = useNotification();
  const [authModalOpen, setAuthModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [plans, setPlans] = useState([]);
  const [deliveryPriceList, setDeliveryPriceList] = useState([]);
  const handleAuthModal = (arg) => {
    setAuthModalOpen(arg);
  };

  const fetchListSubscriptions = async () => {
    const res = await subscriptionService.list();
    if (!res.info.error) {
      setPlans(res.data);
    } else {
      createMessage.error(res.info.message);
    }
  };

  const fetchDeliveryPriceList = async () => {
    subscriptionService
      .listDeliveryPrices()
      .then((response) => {
        if (!response.info.error) {
          localStorage.setItem(
            VARIABLES.LOCAL_STORAGE.DELIVERY_PRICE_LIST,
            JSON.stringify(response.data),
          );
          localStorage.setItem(
            VARIABLES.LOCAL_STORAGE.DELIVERY_PRICE_LIST_DATE_LOADED,
            new Date().toString(),
          );
          setDeliveryPriceList(response.data);
        } else {
          createMessage.error(response.info.message);
          setDeliveryPriceList([]);
        }
      })
      .catch((reason) => {
        console.log(reason);
        setDeliveryPriceList([]);
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setLoading(true);
    fetchListSubscriptions().then(() => {
      setLoading(false);
    });

    fetchDeliveryPriceList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="cate-membership">
      <Container maxWidth="xl">
        <div className="membership-header">
          <h1>{Traslate(ELEMENTS.MEMBERSHIPS.TITLE)}</h1>
          <p>{Traslate(ELEMENTS.MEMBERSHIPS.SUBTITLE)}</p>
        </div>
        <div className="membership-prices">
          {!loading && Object.keys(deliveryPriceList).length > 0 ? (
            <div className="cards-grid">
              {plans.map((item, index) => {
                return (
                  <div key={index} className="card-item">
                    <PriceCard
                      color={!(index & 1) ? 'default' : 'primary'}
                      data={item}
                      createMessage={createMessage}
                      deliveryPriceList={deliveryPriceList}
                      id={id}
                      locale={locale}
                    />
                  </div>
                );
              })}
            </div>
          ) : (
            <Grid container spacing={3}>
              {Array.from(new Array(3)).map((item, index) => (
                <Grid item key={index} {...layout}>
                  <CatePaper disableElevation>
                    <Skeleton
                      variant="rect"
                      width="100%"
                      height={608}
                      style={{ borderRadius: 'inherit' }}
                    />
                  </CatePaper>
                </Grid>
              ))}
            </Grid>
          )}
        </div>
      </Container>
      <AuthDialog
        open={authModalOpen}
        onClose={() => handleAuthModal(false)}
        location={ROUTES.MEMBERSHIPS}
      />
      {notification.state && <Notification onClose={closeMessage} {...notification} />}
    </div>
  );
}

export default Memberships;
