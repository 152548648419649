import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useInputValue, useNotification } from 'hooks';
import { userService } from 'services';
import * as ROUTES from 'constants/routes';
import './changePassword.scss';
// Components
import { Notification, CatePaper, Progress } from 'components';
import { Container, TextField, Button } from '@material-ui/core';
import Traslate from 'i18n/traslate';
import ELEMENTS from 'constants/elementsForTranslate';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function ChangePassword() {
  let query = useQuery();
  let queryState = query.get('state');
  const history = useHistory();
  const { notification, createMessage, closeMessage } = useNotification();
  const [viewState, setViewState] = useState(queryState === 'confirmar' ? '1' : '0');
  const [keepEmail, setKeepEmail] = useState(false);
  const [loading, setLoading] = useState(false);
  const email = useInputValue('');
  const code = useInputValue('');
  const password = useInputValue('');
  const repeatPassword = useInputValue('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!email.value) {
      return;
    }
    fetchSendEmail();
  };

  const handleConfirmSubmit = (e) => {
    e.preventDefault();
    if (!email.value) {
      return;
    }
    if (password.value !== repeatPassword.value) {
      createMessage.warning('Las contraseñas no coinciden.');
    } else {
      fetchValidateToken();
    }
  };

  const fetchSendEmail = async () => {
    setLoading(true);
    createMessage.info('Enviando datos...');
    const res = await userService.sendEmailChangePwd({ email: email.value });
    if (!res.info.error) {
      createMessage.success('Mensaje enviado con éxito al correo ' + email.value);
      setKeepEmail(true);
      setViewState('1');
    } else {
      createMessage.error(res.info.message);
    }
    setLoading(false);
  };

  const fetchValidateToken = async () => {
    setLoading(true);
    createMessage.info('Enviando datos...');
    const res = await userService.changePassword({
      email: email.value,
      verifyCode: code.value,
      newPassword: password.value,
    });
    if (!res.info.error) {
      createMessage.success('Contraseña cambiada con éxito.');
      history.push(ROUTES.LOGIN);
    } else {
      createMessage.error(res.info.message);
    }
    setLoading(false);
  };

  return (
    <div className="cate-change-pwd">
      <Container maxWidth="sm">
        <div className="change-pwd-wrap">
          <CatePaper disableElevation className="change-pwd-wrap__box">
            <div hidden={viewState !== '0'}>
              <div className="box-style">
                <h1>{Traslate(ELEMENTS.LOGIN.FORGOT_PASSWORD)} </h1>
                <p>{Traslate(ELEMENTS.CHANGE_PASSWORD.ENTER_EMAIL)}</p>
                <form className="change-pwd-form" onSubmit={handleSubmit}>
                  <TextField
                    id="email"
                    label="Email"
                    type="email"
                    variant="outlined"
                    value={email.value}
                    onChange={email.onChange}
                  />
                  <Button
                    disableElevation
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={loading}>
                    {Traslate(ELEMENTS.CHANGE_PASSWORD.SEND)}
                  </Button>
                </form>
              </div>
            </div>
            <div hidden={viewState !== '1'}>
              <div className="box-style">
                <h1>Correo electrónico enviado</h1>
                <p>
                  Revisa la bandeja de entrada de <strong>{email.value}</strong> para obtener
                  intrucciones sobre cómo restablecer tu contraseña. Es posible que debas
                  revisar la carpeta de spam.
                </p>
                <form className="change-pwd-form" onSubmit={handleConfirmSubmit}>
                  {!keepEmail && (
                    <TextField
                      className="field-margin"
                      id="email_repeat"
                      label="Email"
                      type="email"
                      variant="outlined"
                      value={email.value}
                      onChange={email.onChange}
                    />
                  )}
                  <TextField
                    className="field-margin"
                    id="code"
                    label="Codigo"
                    type="text"
                    variant="outlined"
                    value={code.value}
                    onChange={code.onChange}
                  />
                  <TextField
                    className="field-margin"
                    id="password"
                    label="Contraseña"
                    type="password"
                    variant="outlined"
                    value={password.value}
                    onChange={password.onChange}
                  />
                  <TextField
                    id="repeat_password"
                    label="Repetir Contraseña"
                    type="password"
                    variant="outlined"
                    value={repeatPassword.value}
                    onChange={repeatPassword.onChange}
                  />
                  <Button
                    disableElevation
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={loading}>
                    Enviar
                  </Button>
                </form>
              </div>
            </div>
          </CatePaper>
        </div>
      </Container>
      <Progress color="primary" loading={loading} />
      {notification.state && <Notification onClose={closeMessage} {...notification} />}
    </div>
  );
}

export default ChangePassword;
