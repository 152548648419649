import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { replacePathParams } from 'utils';
import * as VARIABLES from 'constants/variables';
import * as ROUTES from 'constants/routes';
import PropTypes from 'prop-types';
import './cardGrid.scss';
// Components
import { Grid, Card, CardActionArea, CardMedia, CardContent } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { LOCALES } from 'i18n';

const layout = { xs: 12, sm: 6, md: 4 };

const BlogCard = ({ title, subTitle, image, locale }) => (
  <Card className="blog-card">
    <CardActionArea>
      <CardMedia className="blog-card__media" image={image} title="Contemplative Reptile" />
      <CardContent className="blog-card__content">
        <div>
          <h4>{title}</h4>
          <p>{subTitle}</p>
        </div>
        <div className="content-action">
          <span className="action-text">
            {locale === LOCALES.ENGLISH ? 'See more' : 'Ver más'}
          </span>
          <span className="action-icon">
            <ArrowForwardIcon />
          </span>
        </div>
      </CardContent>
    </CardActionArea>
  </Card>
);

const BlogCardSkeleton = () => (
  <Card className="blog-card">
    <CardActionArea>
      <Skeleton animation="wave" variant="rect" className="blog-card__media" />
      <CardContent className="blog-card__content">
        <div style={{ width: '100%' }}>
          <Skeleton animation="wave" height={24} width="70%" style={{ marginBottom: 6 }} />
          <Skeleton animation="wave" height={18} width="100%" />
          <Skeleton animation="wave" height={18} width="100%" />
          <Skeleton animation="wave" height={18} width="70%" />
        </div>
        <Skeleton animation="wave" height={36} width="100px" />
      </CardContent>
    </CardActionArea>
  </Card>
);

function CardGrid(props) {
  const { loading, data, locale } = props;

  return (
    <div className="cate-blog-list">
      <Grid container spacing={3}>
        {loading ? (
          <>
            {Array.from(new Array(16)).map((data, index) => (
              <Grid item key={index} {...layout}>
                <BlogCardSkeleton />
              </Grid>
            ))}
          </>
        ) : (
          <>
            {data.map((item, index) => (
              <Grid item key={index} {...layout}>
                <RouterLink
                  className="nui-no-decoration"
                  to={replacePathParams(ROUTES.BLOG_BY_SLUG, { slug: item.slug })}>
                  <BlogCard
                    title={locale === LOCALES.ENGLISH ? item.title_en : item.title}
                    subTitle={locale === LOCALES.ENGLISH ? item.subtitle_en : item.subtitle}
                    image={
                      item.image_path
                        ? VARIABLES.IMAGE_PATH + item.image_path
                        : VARIABLES.IMAGE_FALLBACK
                    }
                    locale={locale}
                  />
                </RouterLink>
              </Grid>
            ))}
          </>
        )}
      </Grid>
    </div>
  );
}

CardGrid.propTypes = {
  loading: PropTypes.bool,
  data: PropTypes.array,
};

export default CardGrid;
