import React, { useState, useEffect, useContext } from 'react';
import * as ROUTES from 'constants/routes';
import { useHistory } from 'react-router-dom';
import { Context } from 'context/Context';
import { useNotification } from 'hooks';
import { Notification } from 'components';
import { Container, Button } from '@material-ui/core';
import { Table, Modal } from 'antd';
import './userMemberships.scss';
import UserSubscription from 'services/userSubscription';

const ACTIVE = '1';
const CANCELLED = '3';

function UserMemberships() {
  const history = useHistory();
  const { id: userId } = useContext(Context);
  const [userSubscriptions, setUserSubscriptions] = useState([]);
  const [activeMemberships, setActiveMemberships] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [currentMembership, setCurrentMembership] = useState({});
  const { notification, createMessage, closeMessage } = useNotification();

  // Modal
  const closeModal = () => {
    setIsModalOpen(false);
    setCurrentMembership({});
  };

  const handleConfirm = () => {
    fetchDeleteSubscription();
    setIsModalOpen(false);
  };

  const afterCloseModal = () => {};

  // functions
  const startToDeleteMembership = (membership) => {
    setIsModalOpen(true);
    setCurrentMembership(membership);
  };

  const addKeyToMemberships = (memberships) => {
    let membershipsWithKey = memberships.map((membership, index) => ({
      ...membership,
      key: index,
    }));

    return membershipsWithKey;
  };

  const filterActiveMemberships = (memberships) => {
    const activeMemberships = memberships.filter((membership) => membership.status === ACTIVE);

    return activeMemberships;
  };

  // FETCHS
  const fetchUserSubscriptions = async () => {
    const response = await UserSubscription.list(userId);

    if (!response?.info?.error) {
      const membershipsWithKey = addKeyToMemberships(response.data);

      createMessage.success('Suscripciones obtenidas.');
      setUserSubscriptions(membershipsWithKey);
      setActiveMemberships(filterActiveMemberships(membershipsWithKey));
    } else {
      history.push(ROUTES.CONFIRM_EMAIL);
      createMessage.error('Error al traer las suscripciones');
      console.log(response?.info?.error);
    }
  };

  const fetchDeleteSubscription = async () => {
    setConfirmLoading(true);
    const response = await UserSubscription.delete(currentMembership);

    if (!response?.info?.error) {
      createMessage.success('Suscripción eliminada con éxito.');
      await fetchUserSubscriptions();
    } else {
      createMessage.error('Error al eliminar tu suscripción..');
      console.log(response?.info?.error);
    }

    await fetchUserSubscriptions();
    setConfirmLoading(false);
  };

  const membershipsColumns = [
    {
      title: 'Plan de suscripción',
      dataIndex: 'plan_name',
      key: 'plan_name',
      render: (text) => <span>{text}</span>,
    },
    {
      title: 'Distrito de envío',
      dataIndex: 'district_name',
      key: 'district_name',
      render: (text) => <span>{text}</span>,
    },
    {
      title: 'Fecha de suscripción',
      dataIndex: 'subscription_date',
      key: 'subscription_date',
      render: (text) => <span>{text}</span>,
    },
    {
      title: 'Fecha de finalización',
      dataIndex: 'expiration_date',
      key: 'expiration_date',
      render: (text) => <span>{text}</span>,
    },
    {
      title: 'Precio',
      dataIndex: 'plan_price',
      key: 'plan_price',
      render: (text) => <span>S/.{text}</span>,
    },
    {
      title: 'Cancelar subs.',
      dataIndex: 'id',
      key: 'id',
      render: (text, sub) => (
        <Button
          disabled={sub.status === CANCELLED ? true : false}
          onClick={() => startToDeleteMembership(sub)}>
          {sub.status === CANCELLED ? 'Cancelado' : 'Cancelar'}
        </Button>
      ),
    },
  ];

  useEffect(() => {
    fetchUserSubscriptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="cate-memberships">
      <Container maxWidth="md">
        <Table
          dataSource={userSubscriptions}
          columns={membershipsColumns}
          pagination={{ pageSize: 15 }}
        />
        <Modal
          title="Confirmar"
          visible={isModalOpen}
          confirmLoading={confirmLoading}
          afterClose={afterCloseModal}
          onOk={handleConfirm}
          onCancel={closeModal}
          cancelText="Cancelar">
          ¿Desea cancelar esta membresía?
        </Modal>
      </Container>
      {notification.state && <Notification onClose={closeMessage} {...notification} />}
    </div>
  );
}

export default UserMemberships;
