import React from 'react';
import PropTypes from 'prop-types';
import './cardUserPlan.scss';
// Components
import { CatePaper } from 'components';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

function CardUserPlan(props) {
  const { title, description, period, price, handleDelete } = props;

  return (
    <CatePaper disableElevation className="card-user-plan">
      <div className="plan-top">
        <h6>{title}</h6>
        <div className="plan-delete" onClick={handleDelete}>
          <DeleteOutlineIcon />
        </div>
      </div>
      <div className="plan-content">
        <div className="plan-content__info">
          <div>{description}</div>
        </div>
        <div className="plan-content__invoice">
          <h6>Frecuencia:</h6>
          <p>{period}</p>
          <h6 className="space">Pago:</h6>
          <p>S/. {price}</p>
        </div>
      </div>
    </CatePaper>
  );
}

CardUserPlan.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  price: PropTypes.number,
};

export default CardUserPlan;
