import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { courseService } from 'services';
import { useNotification } from 'hooks';
import { replacePathParams } from 'utils';
import * as VARIABLES from 'constants/variables';
import * as ROUTES from 'constants/routes';
import './courses.scss';
// Components
import { Notification, CatePaper } from 'components';
import { Container, Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

const layout = { xs: 12, sm: 6, md: 4 };

function Courses() {
  const { notification, createMessage, closeMessage } = useNotification();
  const [loading, setLoading] = useState(false);
  const [coursesArray, setCoursesArray] = useState([]);

  const fetchListCourses = async () => {
    setLoading(true);
    const res = await courseService.list();
    if (!res.info.error) {
      setCoursesArray(res.data);
    } else {
      createMessage.error(res.info.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchListCourses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="cate-courses">
      <Container maxWidth="lg">
        <div className="nui-main-title">
          <h1>Cursos</h1>
        </div>
        <div>
          {!loading ? (
            <Grid container spacing={3}>
              {coursesArray.map((item, index) => (
                <Grid item key={index} {...layout}>
                  <RouterLink
                    className="nui-no-decoration"
                    to={replacePathParams(ROUTES.COURSES_BY_SLUG, { slug: item.slug })}>
                    <CatePaper disableElevation className="course-item">
                      <div className="course-item__cover">
                        <img
                          src={
                            item.image_path
                              ? VARIABLES.IMAGE_PATH + item.image_path
                              : VARIABLES.IMAGE_FALLBACK
                          }
                          alt={item.title}
                        />
                      </div>
                      <div className="course-item__content">
                        <div>
                          <h4 className="item-title">{item.title}</h4>
                          <p className="item-subtitle">{item.subtitle}</p>
                        </div>
                      </div>
                    </CatePaper>
                  </RouterLink>
                </Grid>
              ))}
            </Grid>
          ) : (
            <Grid container spacing={3}>
              {Array.from(new Array(6)).map((data, index) => (
                <Grid item key={index} {...layout}>
                  <CatePaper disableElevation className="course-item">
                    <div className="course-item__cover">
                      <Skeleton variant="rect" />
                    </div>
                    <div className="course-item__content">
                      <div>
                        <Skeleton variant="text" width="60%" height={24} />
                        <Skeleton variant="text" />
                        <Skeleton variant="text" />
                        <Skeleton variant="text" width="40%" />
                      </div>
                    </div>
                  </CatePaper>
                </Grid>
              ))}
            </Grid>
          )}
        </div>
      </Container>
      {notification.state && <Notification onClose={closeMessage} {...notification} />}
    </div>
  );
}

export default Courses;
