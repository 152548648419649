import SvgIcon from '@material-ui/core/SvgIcon';

const CalendarDesignSvg = () => (
  <>
    <g>
      <g>
        <g>
          <path
            d="M344,56h-40V32c0-13.2-10.8-24-24-24s-24,10.8-24,24v24H128V32c0-13.2-10.8-24-24-24S80,18.8,80,32v24H40
				C18,56,0,74,0,96v48c0,10.4,6.8,19.2,16,22.4V336c0,22.8,23.2,40,44,40h268c22,0,40-18,40-40V166.8c9.6-3.6,16-12.4,16-22.8V96
				C384,74,366,56,344,56z M272,32c0-4.4,3.6-8,8-8c4.4,0,8,3.6,8,8v64c0,4.4-3.6,8-8,8c-4.4,0-8-3.6-8-8V32z M96,32
				c0-4.4,3.6-8,8-8c4.4,0,8,3.6,8,8v64c0,4.4-3.6,8-8,8c-4.4,0-8-3.6-8-8V32z M368,144c0,4.4-3.2,7.2-6.4,8c0,0-0.4,0-0.4,0.4
				c-0.4,0-0.8,0-1.2,0c-4.4,0-8,3.6-8,8v176c0,13.2-10.8,24-24,24H60c-12.8,0-28-10.8-28-24V168h240c4.4,0,8-3.6,8-8
				c0-4.4-3.6-8-8-8H24c-4.4,0-8-3.6-8-8V96c0-13.2,10.8-24,24-24h40v24c0,13.2,10.8,24,24,24s24-10.8,24-24V72h128v24
				c0,13.2,10.8,24,24,24s24-10.8,24-24V72h40c13.2,0,24,10.8,24,24V144z"
          />
          <path d="M320,152h-12c-4.4,0-8,3.6-8,8c0,4.4,3.6,8,8,8h12c4.4,0,8-3.6,8-8C328,155.6,324.4,152,320,152z" />
          <path d="M120,216H72c-4.4,0-8,3.6-8,8c0,4.4,3.6,8,8,8h48c4.4,0,8-3.6,8-8C128,219.6,124.4,216,120,216z" />
          <path d="M120,280H72c-4.4,0-8,3.6-8,8c0,4.4,3.6,8,8,8h48c4.4,0,8-3.6,8-8C128,283.6,124.4,280,120,280z" />
          <path d="M212,216h-48c-4.4,0-8,3.6-8,8c0,4.4,3.6,8,8,8h48c4.4,0,8-3.6,8-8C220,219.6,216.4,216,212,216z" />
          <path d="M212,280h-48c-4.4,0-8,3.6-8,8c0,4.4,3.6,8,8,8h48c4.4,0,8-3.6,8-8C220,283.6,216.4,280,212,280z" />
          <path d="M304,216h-48c-4.4,0-8,3.6-8,8c0,4.4,3.6,8,8,8h48c4.4,0,8-3.6,8-8C312,219.6,308.4,216,304,216z" />
          <path d="M304,280h-48c-4.4,0-8,3.6-8,8c0,4.4,3.6,8,8,8h48c4.4,0,8-3.6,8-8C312,283.6,308.4,280,304,280z" />
        </g>
      </g>
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </>
);

const CalendarDesign = (props) => (
  <SvgIcon
    viewBox="0 0 384 384"
    style={{ enableBackground: 'new 0 0 384 384' }}
    xmlSpace="preserve"
    {...props}>
    <CalendarDesignSvg />
  </SvgIcon>
);

export default CalendarDesign;
