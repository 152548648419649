import React from 'react';
import PropTypes from 'prop-types';
import './about.scss';
// Components
import { Container, Grid } from '@material-ui/core';
// Images
import Order from 'assets/images/background/order.jpg';
import Traslate from 'i18n/traslate';
import ELEMENTS from 'constants/elementsForTranslate';

const layout = { sm: 12, md: 6 };

const About = (props) => {
  const { id } = props;

  return (
    <section id={id} className="cat-home-about">
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item {...layout}>
            <div className="cat-home-about__image">
              <img src={Order} alt="order" />
            </div>
          </Grid>
          <Grid item {...layout}>
            <div className="cat-home-about__body">
              <h3 className="body-title">{Traslate(ELEMENTS.ABOUT.TITLE)}</h3>
              <p>{Traslate(ELEMENTS.ABOUT.PARAGRAPH)}</p>
            </div>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
};

About.propTypes = {
  id: PropTypes.string,
};

export default About;
