import configService from './config';
import fetchApi from './fetchApi';
import * as METHODS from './methods';

const baseURL = `${configService.apiUrl}`;
const UserSubscription = {};

UserSubscription.list = async function (id) {
  const { response } = await fetchApi({
    url: `${baseURL}/user-detail/subscriptions/${id}`,
    options: METHODS.GET(),
    dataType: [],
    message: {
      success: 'Lista subscripciones obtenida con éxito',
      error: 'No se pudo obtener las subscripciones.',
    },
  });

  return { ...response };
};

UserSubscription.delete = async function (membership) {
  const { id } = membership;

  const { response } = await fetchApi({
    url: `${baseURL}/subscriptions/cancel-subscription-user/${id}`,
    options: METHODS.DELETE(),
    dataType: [],
    message: {
      success: 'Membresía eliminada',
      error: 'No se pudo eliminar la membresía.',
    },
  });

  return { ...response };
};

export default UserSubscription;
