import React from 'react';
import PropTypes from 'prop-types';
import * as VARIABLES from 'constants/variables';
import './orderState.scss';

function OrderState(props) {
  const { status = '409' } = props;

  if (!VARIABLES.ORDER_STATE_COLOR[status]) {
    return (
      <span className="nui-font-size-base" color={VARIABLES.ORDER_STATE_COLOR['409']}>
        {VARIABLES.ORDER_STATE_NAME['409']}
      </span>
    );
  }

  return (
    <span
      className={`nui-font-size-base order-state order-state--${VARIABLES.ORDER_STATE_COLOR[status]}`}>
      {VARIABLES.ORDER_STATE_NAME[status]}
    </span>
  );
}

OrderState.propTypes = {
  status: PropTypes.oneOf(['1', '2', '3', '4', 1, 2, 3, 4]).isRequired,
};

export default OrderState;
