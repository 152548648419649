import SvgIcon from '@material-ui/core/SvgIcon';

const CoffeeBoxSvg = () => (
  <>
    <path
      style={{ fill: '#C49A6C' }}
      d="M336,131.328c0.001-7.45,1.735-14.799,5.064-21.464L368,56H264l-26.936,53.864
c-3.329,6.665-5.063,14.014-5.064,21.464V200h-48v-68.672c0.001-7.45,1.735-14.799,5.064-21.464L216,56H112l-26.936,53.864
c-3.329,6.665-5.063,14.014-5.064,21.464V472h256V131.328z M241.944,361.944c-28.12,28.112-66.112,35.712-84.856,16.968
s-11.144-56.736,16.968-84.856s66.112-35.712,84.856-16.968S270.056,333.824,241.944,361.944L241.944,361.944z"
    />
    <path
      style={{ fill: '#A97C50' }}
      d="M336,131.328V472h64V131.328c-0.001-7.45-1.735-14.799-5.064-21.464L368,56l-26.936,53.864
C337.735,116.529,336.001,123.878,336,131.328z"
    />
    <g>
      <path style={{ fill: '#C49A6C' }} d="M112,8h104v48H112V8z" />
      <path style={{ fill: '#C49A6C' }} d="M264,8h104v48H264V8z" />
    </g>
    <path
      style={{ fill: '#FFAF46' }}
      d="M189.064,109.864c-3.329,6.665-5.063,14.014-5.064,21.464V200h48v-68.672
c0.001-7.45,1.735-14.799,5.064-21.464L264,56V8h-48v48L189.064,109.864z"
    />
    <g>
      <path
        style={{ fill: '#754C29' }}
        d="M157.088,378.912c18.744,18.744,56.736,11.144,84.856-16.968s35.712-66.112,16.968-84.856
L157.088,378.912z"
      />
      <path
        style={{ fill: '#754C29' }}
        d="M174.056,294.056c-28.112,28.12-35.712,66.112-16.968,84.856l101.824-101.824
C240.168,258.344,202.176,265.944,174.056,294.056L174.056,294.056z"
      />
    </g>
    <g>
      <path
        style={{ fill: '#231F20' }}
        d="M402.088,106.288L376,54.112V8c0-4.418-3.582-8-8-8H112c-4.418,0-8,3.582-8,8v46.112l-26.088,52.176
c-3.881,7.78-5.904,16.354-5.912,25.048V472c0,4.418,3.582,8,8,8h320c4.418,0,8-3.582,8-8V131.336
C407.992,122.642,405.969,114.068,402.088,106.288z M360,48h-88V16h88V48z M256,54.112l-26.088,52.176
c-3.881,7.78-5.904,16.354-5.912,25.048V192h-32v-60.664c0.01-6.211,1.455-12.336,4.224-17.896L223.2,59.576
c0.048-0.096,0.056-0.2,0.104-0.296s0.112-0.352,0.184-0.528c0.285-0.77,0.449-1.58,0.488-2.4c0-0.112,0.064-0.2,0.064-0.312V16h32
L256,54.112z M120,16h88v32h-88V16z M333.912,106.288c-3.881,7.78-5.904,16.354-5.912,25.048V464H88V131.336
c0.01-6.211,1.455-12.336,4.224-17.896L116.944,64h86.112l-21.144,42.288c-3.881,7.78-5.904,16.354-5.912,25.048V200
c0,4.418,3.582,8,8,8h48c4.418,0,8-3.582,8-8v-68.664c0.01-6.211,1.455-12.336,4.224-17.896L268.944,64h86.112L333.912,106.288z
M392,464h-48V131.336c0.01-6.211,1.455-12.336,4.224-17.896L368,73.888l19.776,39.552c2.769,5.56,4.214,11.685,4.224,17.896V464z"
      />
      <path
        style={{ fill: '#231F20' }}
        d="M218.4,260.16c-19.069,4.347-36.456,14.161-50.032,28.24c-14.062,13.571-23.864,30.946-28.208,50
c-4.377,16.294-0.126,33.696,11.272,46.136c8.799,8.477,20.652,13.036,32.864,12.64c4.468-0.004,8.925-0.451,13.304-1.336
c19.069-4.347,36.456-14.161,50.032-28.24c14.062-13.571,23.864-30.946,28.208-50c4.377-16.294,0.126-33.696-11.272-46.136
C252.131,260.036,234.71,255.77,218.4,260.16L218.4,260.16z M155.84,341.6c3.734-15.97,12.028-30.511,23.872-41.856
c11.349-11.861,25.903-20.166,41.888-23.904c3.313-0.674,6.683-1.022,10.064-1.04c6.443-0.175,12.783,1.638,18.16,5.192
c-3.516,2.726-7.336,5.034-11.384,6.88c-16.417,7.932-29.668,21.183-37.6,37.6c-2.995,6.674-7.264,12.698-12.568,17.736
c-5.043,5.303-11.069,9.575-17.744,12.576c-4.932,2.286-9.598,5.108-13.912,8.416C154.467,356.193,154.199,348.743,155.84,341.6
L155.84,341.6z M260.16,314.4c-3.734,15.97-12.028,30.511-23.872,41.856c-11.349,11.861-25.903,20.166-41.888,23.904
c-9.592,2.512-19.793,1.029-28.272-4.112c3.532-2.747,7.371-5.075,11.44-6.936c8.238-3.78,15.693-9.075,21.976-15.608
c6.554-6.276,11.865-13.732,15.656-21.976c6.368-13.249,17.055-23.936,30.304-30.304c4.922-2.289,9.577-5.114,13.88-8.424
C261.533,299.807,261.801,307.257,260.16,314.4L260.16,314.4z"
      />
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </>
);

const CoffeeBox = (props) => (
  <SvgIcon
    xmlSpace="preserve"
    viewBox="0 0 480 480"
    style={{ enableBackground: 'new 0 0 480 480' }}
    {...props}>
    <CoffeeBoxSvg />
  </SvgIcon>
);

export default CoffeeBox;
