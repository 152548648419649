import React from 'react';
import './notFound.scss';
// Components
import { Container } from '@material-ui/core';
// Images
import Error404 from 'assets/images/background/error-404.png';

function NotFound() {
  return (
    <div className="cate-not-found">
      <Container maxWidth="lg">
        <div className="cate-not-found__wrap">
          <img src={Error404} alt="Error 404" />
          <h4>Página no encontrada</h4>
        </div>
      </Container>
    </div>
  );
}

export default NotFound;
