import React, { useContext, useEffect } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { Context } from 'context/Context';
import { useResponsive } from 'hooks';
import { replacePathParams } from 'utils';
import * as VARIABLES from 'constants/variables';
import * as ROUTES from 'constants/routes';
import './shopcart.scss';
// Components
import { EmptyShoppingBag } from 'icons';
import { CatePaper, AuthDialog, StoreStepper, RedButton } from 'components';
import { Container, Grid, IconButton } from '@material-ui/core';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import RemoveRoundedIcon from '@material-ui/icons/RemoveRounded';
import Traslate from 'i18n/traslate';
import ELEMENTS from 'constants/elementsForTranslate';

const Card = (props) => {
  const { product, url, quantity } = props;
  const { increase, decrease, setProductCount, removeProduct } = useContext(Context);
  const { isMobile } = useResponsive();

  const onChangeInput = (e) => {
    if (e.target.value === '0') {
      setProductCount(product, 1);
    } else {
      setProductCount(product, parseInt(e.target.value));
    }
  };

  return (
    <li className="shopping-cart-product">
      <div className="product-image">
        <RouterLink to={url} target="_blank" className="nui-no-decoration">
          <img src={VARIABLES.IMAGE_PATH + product.images[0].image_path} alt={product.label} />
        </RouterLink>
        {isMobile && (
          <div className="action-group">
            <div className="action-group__btn-delete">
              <DeleteOutlineIcon />
            </div>
          </div>
        )}
      </div>
      <div className="product-main">
        <div className="product-title">
          <RouterLink to={url} target="_blank" className="nui-no-decoration">
            <span>{product.label}</span>
          </RouterLink>
        </div>
        {product.property_active && (
          <div className="product-attr">
            <dl>
              <dt>
                <span>{product.property.label} : </span>
              </dt>
              <dd>
                {product.property_active.label === 'Otro' ? (
                  <span>{product.otherGroundProperty}</span>
                ) : (
                  <span>{product.property_active.label}</span>
                )}
              </dd>
            </dl>
          </div>
        )}
        <div className="product-price">
          <div className="product-price__main">
            {quantity >= 6 ? (
              <span>S/. {product?.wholesale_price?.toFixed(2)}</span>
            ) : (
              <span>S/. {product?.price?.toFixed(2)}</span>
            )}
          </div>
        </div>
        <div className="product-logistics">
          <span className="product-logistics__cost">
            <span>{Traslate(ELEMENTS.SHOPCART.SHIPPING)}: </span>
            <span>{Traslate(ELEMENTS.SHOPCART.ARRIVE)}</span>
          </span>
          <span className="product-logistics__delivery">
            {Traslate(ELEMENTS.SHOPCART.DELIVERY_TIME)}
          </span>
        </div>
      </div>
      <div className="product-action">
        {!isMobile && (
          <div className="action-group">
            <div className="action-group__btn-delete">
              <IconButton onClick={() => removeProduct(product)}>
                <DeleteOutlineIcon />
              </IconButton>
            </div>
          </div>
        )}
        <div className="product-num">
          <div className="product-num__picker">
            <div className="product-num__picker__wrap">
              <span className="pick-before">
                {product.quantity <= 1 ? (
                  <div className="btn-pick disable">
                    <RemoveRoundedIcon />
                  </div>
                ) : (
                  <div className="btn-pick" onClick={() => decrease(product)}>
                    <RemoveRoundedIcon />
                  </div>
                )}
              </span>
              <span className="pick-input">
                <input value={product.quantity} onChange={onChangeInput} />
              </span>
              <span className="pick-after">
                <div className="btn-pick" onClick={() => increase(product)}>
                  <AddRoundedIcon />
                </div>
              </span>
            </div>
          </div>
          {product.stock <= 10 && (
            <div className="product-num__stock-tip">
              <span>{Traslate(ELEMENTS.SHOPCART.SHOPCART_COUNT)}</span>
            </div>
          )}
        </div>
      </div>
    </li>
  );
};

function Shopcart() {
  const { token, cartItems, itemCount, total, locale } = useContext(Context);
  const [authModalOpen, setAuthModalOpen] = React.useState(false);
  const { isMobile } = useResponsive();
  const history = useHistory();

  const handleAuthModal = (arg) => {
    setAuthModalOpen(arg);
  };

  const handleActionClick = () => {
    if (token) {
      history.push(ROUTES.SHIPPING);
    } else {
      handleAuthModal(true);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="cate-shopcart">
      <Container maxWidth="lg">
        <div>
          <StoreStepper activeStep={0} locale={locale} />
          <Grid container spacing={3} direction={isMobile ? 'column-reverse' : 'row'}>
            <Grid item xs={12} md={8}>
              <CatePaper disableElevation className="shopping-cart-list">
                <div className="shopping-cart-list__container">
                  <div className="shopping-cart-title">
                    <h2>{`Cesta (${itemCount})`}</h2>
                  </div>
                  {cartItems.length > 0 ? (
                    <ul>
                      {cartItems.map((item, index) => (
                        <Card
                          key={index}
                          product={item}
                          url={replacePathParams(ROUTES.PRODUCTS_BY_SLUG, {
                            slug: item.slug,
                          })}
                        />
                      ))}
                    </ul>
                  ) : (
                    <div className="shopping-cart-empty">
                      <EmptyShoppingBag />
                      <p>{Traslate(ELEMENTS.SHOPCART.SHOPCART_EMPTY)}</p>
                    </div>
                  )}
                </div>
              </CatePaper>
            </Grid>
            <Grid item xs={12} md={4}>
              <CatePaper disableElevation className="price-overview">
                <div className="price-overview__container">
                  <div className="overview-title">
                    <h3>{Traslate(ELEMENTS.SHOPCART.SUMMARY)}</h3>
                  </div>
                  <div className="overview-body">
                    <div className="price-group">
                      <dl>
                        <dt>Subtotal</dt>
                        <dd>S/. {total}</dd>
                      </dl>
                    </div>
                    <div className="price-group"></div>
                  </div>
                  <div className="overview-btn-order">
                    <dl>
                      <dt>Total</dt>
                      <dd>S/. {total}</dd>
                    </dl>
                  </div>
                  <div className="overview-btn-next">
                    <RedButton
                      fullWidth
                      disableElevation
                      disabled={cartItems.length > 0 ? false : true}
                      variant="contained"
                      color="primary"
                      onClick={handleActionClick}>
                      {Traslate(ELEMENTS.SHOPCART.CONTINUED)}
                    </RedButton>
                  </div>
                </div>
              </CatePaper>
            </Grid>
          </Grid>
        </div>
      </Container>
      <AuthDialog
        open={authModalOpen}
        onClose={() => handleAuthModal(false)}
        location={ROUTES.SHOPCART}
      />
    </div>
  );
}

export default Shopcart;
