import React, { useState, useContext } from 'react';
import { Link as RouterLink, Redirect } from 'react-router-dom';
import { useInputValue, useNotification, useResponsive } from 'hooks';
import { Context } from 'context/Context';
import { userService } from 'services';
import * as VARIABLES from 'constants/variables';
import * as ROUTES from 'constants/routes';
import './register.scss';
// Components
import { Notification } from 'components';
import {
  Paper,
  Grid,
  Container,
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
  MenuItem,
} from '@material-ui/core';
// Images
import CoffeeBreak from 'assets/images/background/coffee-break.png';
import Traslate from 'i18n/traslate';
import ELEMENTS from 'constants/elementsForTranslate';

function Register(props) {
  const { isDesktop } = useResponsive();
  // eslint-disable-next-line no-unused-vars
  const { id, token, activateAuth } = useContext(Context);
  const { notification, createMessage, closeMessage } = useNotification();
  const [disabledSubmit, setDisableSubmit] = useState(false);
  const [checkbox, setCheckbox] = useState(true);
  const name = useInputValue('');
  const lastName = useInputValue('');
  const email = useInputValue('');
  const password = useInputValue('');
  const phone = useInputValue('');
  const document = useInputValue('');
  const [prefix, setPrefix] = React.useState(51);

  const handlePrefixChange = (event) => {
    setPrefix(event.target.value);
  };

  const handleCheckboxChange = (event) => {
    setCheckbox(event.target.checked);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    fetchRegisterUser();
  };

  const fetchRegisterUser = async () => {
    setDisableSubmit(true);
    createMessage.info('Enviando datos...');
    const res = await userService.register({
      name: name.value,
      lastName: lastName.value,
      email: email.value,
      password: password.value,
      phoneCode: prefix,
      phone: phone.value,
      document_number: document.value,
    });
    // console.log(res);
    if (!res.info.error) {
      createMessage.success(res.info.message);
      if (res.data.user) {
        activateAuth(
          res.data.user.id,
          res.data.user.name,
          res.data.user.email,
          res.data.token,
          null,
        );
      }
    } else {
      createMessage.error(res.info.message);
      setDisableSubmit(false);
    }
  };

  let redirectLocation = ROUTES.HOME;

  if (props.location.state) {
    redirectLocation = props.location.state.from;
  }

  if (id && token) return <Redirect to={redirectLocation} />;

  return (
    <div className="cate-register">
      <Container maxWidth="lg">
        <Grid container spacing={0} className="cate-register__grid">
          {isDesktop && (
            <Grid item xs={12} md={6}>
              <div className="register-cover">
                <img src={CoffeeBreak} alt="placeholder register" />
              </div>
            </Grid>
          )}
          <Grid item xs={12} md={6}>
            <div className="register-card">
              <Paper className="register-card__paper">
                <div className="card-header">
                  <div className="header-t1">{Traslate(ELEMENTS.LOGIN.WELCOME)}</div>
                  <div className="header-t2">Cate Tasting Room</div>
                  <div className="header-t3">{Traslate(ELEMENTS.REGISTER.JOIN_UP)}</div>
                </div>
                <div className="card-content">
                  <form className="card-content__form" onSubmit={handleSubmit}>
                    <TextField
                      id="name"
                      label={Traslate(ELEMENTS.CHECKOUT.NAME)}
                      type="text"
                      required
                      fullWidth
                      variant="outlined"
                      margin="normal"
                      value={name.value}
                      onChange={name.onChange}
                    />
                    <TextField
                      id="lastName"
                      label={Traslate(ELEMENTS.CHECKOUT.LAST_NAME)}
                      type="text"
                      required
                      fullWidth
                      variant="outlined"
                      margin="normal"
                      value={lastName.value}
                      onChange={lastName.onChange}
                    />
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={4}>
                        <TextField
                          id="phonePrefix"
                          label="Código"
                          select
                          fullWidth
                          variant="outlined"
                          margin="normal"
                          value={prefix}
                          onChange={handlePrefixChange}>
                          {VARIABLES.PHONE_PREFIX.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid item xs={8}>
                        <TextField
                          id="phone"
                          label={Traslate(ELEMENTS.BRANCHES.PHONE)}
                          type="number"
                          inputProps={{
                            min: '0',
                          }}
                          required
                          fullWidth
                          variant="outlined"
                          margin="normal"
                          value={phone.value}
                          onChange={phone.onChange}
                        />
                      </Grid>
                    </Grid>
                    <TextField
                      id="document"
                      label={Traslate(ELEMENTS.CHECKOUT.DOCUMENT_NUMBER)}
                      type="text"
                      required
                      fullWidth
                      variant="outlined"
                      margin="normal"
                      value={document.value}
                      onChange={document.onChange}
                    />
                    <TextField
                      id="email"
                      label="Email"
                      type="email"
                      required
                      fullWidth
                      variant="outlined"
                      margin="normal"
                      value={email.value}
                      onChange={email.onChange}
                    />
                    <TextField
                      id="password"
                      label={Traslate(ELEMENTS.LOGIN.PASSWORD)}
                      type="password"
                      required
                      fullWidth
                      variant="outlined"
                      margin="normal"
                      value={password.value}
                      onChange={password.onChange}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checkbox}
                          onChange={handleCheckboxChange}
                          name="checkedB"
                          color="primary"
                        />
                      }
                      label={
                        <span className="form-link nui-font-size-base">
                          <span className="link-black">
                            {Traslate(ELEMENTS.REGISTER.ACEPT)}
                          </span>
                          <RouterLink
                            className="nui-no-decoration"
                            to={ROUTES.TERMS_CONDITIONS}
                            target="_blank"
                            rel="noopener noreferrer">
                            <span>{Traslate(ELEMENTS.REGISTER.TERMNS)}</span>
                          </RouterLink>
                          <span className="link-black">{Traslate(ELEMENTS.REGISTER.Y)}</span>
                          <RouterLink
                            className="nui-no-decoration"
                            to={ROUTES.PRIVACY_POLICY}
                            target="_blank"
                            rel="noopener noreferrer">
                            <span>{Traslate(ELEMENTS.REGISTER.PRIVACY_POLICY)}</span>
                          </RouterLink>
                        </span>
                      }
                    />
                    <div className="form-btn-submit">
                      <Button
                        disableElevation
                        type="submit"
                        fullWidth
                        disabled={disabledSubmit || !checkbox}
                        variant="contained"
                        color="primary"
                        size="large">
                        {Traslate(ELEMENTS.LOGIN.REGISTER)}
                      </Button>
                    </div>
                  </form>
                </div>
              </Paper>
            </div>
          </Grid>
        </Grid>
      </Container>
      {notification.state && <Notification onClose={closeMessage} {...notification} />}
    </div>
  );
}

export default Register;
