import configService from './config';
import fetchApi from './fetchApi';
import * as METHODS from './methods';

const baseUrl = configService.apiUrl;
const locationService = {};

locationService.departmentsList = async function () {
  const { response } = await fetchApi({
    url: baseUrl + '/common/departments',
    options: METHODS.GET(),
    dataType: [],
    message: {
      success: 'Lista de departamentos obtenida con éxito.',
      error: 'No se pudo obtener la lista de departamentos.',
    },
  });

  return { ...response };
};

locationService.provinceList = async function (departmentId) {
  const { response } = await fetchApi({
    url: baseUrl + '/common/provinces/' + departmentId,
    options: METHODS.GET(),
    dataType: [],
    message: {
      success: 'Lista de provincias obtenida con éxito.',
      error: 'No se pudo obtener la lista de provincias.',
    },
  });

  return { ...response };
};

locationService.districtList = async function (provinceId) {
  const { response } = await fetchApi({
    url: baseUrl + '/common/districts/' + provinceId + '?only-with-price=true',
    options: METHODS.GET(),
    dataType: [],
    message: {
      success: 'Lista de distritos obtenida con éxito.',
      error: 'No se pudo obtener la lista de distritos.',
    },
  });

  return { ...response };
};

locationService.getdistrictPriceByName = async function (districtName) {
  const { response } = await fetchApi({
    url: baseUrl + '/district/getPriceByName/' + districtName,
    options: METHODS.GET(),
    dataType: {},
    message: {
      success: 'Detalle del distrito obtenido con éxito.',
      error: 'No se pudo el detalle del distrito.',
    },
  });

  return { ...response };
};

locationService.getdistrictPriceById = async function (districtId) {
  const { response } = await fetchApi({
    url: baseUrl + '/district/getPriceById/' + districtId,
    options: METHODS.GET(),
    dataType: {},
    message: {
      success: 'Detalle del distrito obtenido con éxito.',
      error: 'No se pudo el detalle del distrito.',
    },
  });

  return { ...response };
};

export default locationService;
