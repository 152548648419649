import React from 'react';
import './about.scss';

import { Container, Grid, Card, CardContent } from '@material-ui/core';

import aboutOne from '../../assets/images/background/about1.png';
import aboutTwo from '../../assets/images/background/about2.png';
import aboutThree from '../../assets/images/background/about3.png';
import cafeSign from '../../assets/images/icons/sign.png';
import agricultore from '../../assets/images/icons/agriculture.png';
import coffee from '../../assets/images/icons/coffee.png';
// Map
import { GoogleMap, useJsApiLoader, InfoWindow } from '@react-google-maps/api';
import ELEMENTS from 'constants/elementsForTranslate';
import Traslate from 'i18n/traslate';

const containerStyle = {
  width: '800px',
  height: '400px',
};

const center = {
  lat: -12.11258,
  lng: -77.03239,
};

const position = { lat: -12.11258, lng: -77.03239 };

const divStyle = {
  background: `white`,
  border: `1px solid #ccc`,
  padding: 15,
};

function About() {
  // Maps geo

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyB2kxPUePwxZr1sHvnSBHhz-YiIa5aClBM',
  });

  const onLoadInfo = (infoWindow) => {
    console.log('infoWindow: ', infoWindow);
  };

  // const Map = ReactMapboxGl({
  //   accessToken:
  //     'pk.eyJ1IjoibGluZGVyM2hzIiwiYSI6ImNrbnl3bzk3YjAwMXUycHBjYnBneTE1b3EifQ.zBCfH-qlWFb1GEihwulkMg',
  // });

  return (
    <div className="cate-about">
      <Container maxWidth="lg">
        <div className="nui-empty-page">
          <Grid container>
            <Grid item xs={12} sm={12} className="text__center top_text_30">
              <h1>Cate Tasting Room</h1>
            </Grid>
            <Grid item xs={4}>
              <img src={aboutOne} alt="" />
            </Grid>
            <Grid item xs={4}>
              <img src={aboutTwo} alt="" />
            </Grid>
            <Grid item xs={4}>
              <img src={aboutThree} alt="" />
            </Grid>
            <Grid item xs={12} sm={6}></Grid>
            <div className="top_text_30"></div>
          </Grid>
        </div>
        <div className="nui-empty-page text__center">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <h2>Bitácora & Whitecup</h2>
              <p className="top_text_30 text__left">
                {Traslate(ELEMENTS.ABOUT.PARAGRAPH1_L1)} <br />
                {Traslate(ELEMENTS.ABOUT.PARAGRAPH1_L2)} <br />
                {Traslate(ELEMENTS.ABOUT.PARAGRAPH1_L3)} <br /> <br />
                {Traslate(ELEMENTS.ABOUT.PARAGRAPH1_L4)} <br />
                {Traslate(ELEMENTS.ABOUT.PARAGRAPH1_L5)}
              </p>
              <Card className="top_text_30">
                <CardContent className="text__left">
                  {Traslate(ELEMENTS.ABOUT.PARAGRAPH2)}
                </CardContent>
              </Card>
              <Card className="top_text_30">
                <CardContent className="text__left">
                  ({Traslate(ELEMENTS.ABOUT.PARAGRAPH3)})
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6}>
              <img src={cafeSign} width={200} alt="mission" />
            </Grid>
          </Grid>
        </div>
        <div className="nui-empty-page text__center">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <img src={coffee} width={200} alt="mission" />
            </Grid>
            <Grid item xs={12} sm={6}>
              <h2>Choc Time</h2>
              <p className="top_text_30 text__left">{Traslate(ELEMENTS.ABOUT.PARAGRAPH4)}</p>
            </Grid>
          </Grid>
        </div>
        <div className="nui-empty-page text__center">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <h2>{Traslate(ELEMENTS.ABOUT.PARAGRAPH5_TITLE)}</h2>
              <p className="top_text_30 text__left">
                {Traslate(ELEMENTS.ABOUT.PARAGRAPH5_L1)} <br />
                {Traslate(ELEMENTS.ABOUT.PARAGRAPH5_L2)} <br />
                {Traslate(ELEMENTS.ABOUT.PARAGRAPH5_L3)}
              </p>
            </Grid>
            <Grid item xs={12} sm={6}>
              <img src={agricultore} alt="mission" />
            </Grid>
          </Grid>
        </div>
        <div className="nui-empty-page">
          <h2 style={{ marginBottom: 30 }}>{Traslate(ELEMENTS.ABOUT.FIND_US)}</h2>
          {isLoaded ? (
            <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={15}>
              <InfoWindow onLoad={onLoadInfo} position={position}>
                <div style={divStyle}>
                  <h5>Cate Tasting Room</h5>
                </div>
              </InfoWindow>
              {/* Child components, such as markers, info windows, etc. */}
            </GoogleMap>
          ) : (
            <></>
          )}
          {/* <Map
            zoom={[15]}
            // eslint-disable-next-line react/style-prop-object
            style="mapbox://styles/mapbox/streets-v9"
            center={[-77.03239, -12.11258]}
            containerStyle={{
              height: '50vh',
              width: '50vw',
            }}>
            <Layer type="symbol" id="marker" layout={{ 'icon-image': 'marker-15' }}>
              <Feature coordinates={[-77.03239, -12.11258]} />
            </Layer> */}
          {/* <Marker coordinates={[-12.11258, -77.03239]} anchor="bottom">
              <img
                src={
                  'https://upload.wikimedia.org/wikipedia/commons/thumb/8/88/Map_marker.svg/512px-Map_marker.svg.png'
                }
                alt="Marker"
                width={50}
              />
            </Marker> */}
          {/* <Popup
              coordinates={[-77.03239, -12.11258]}
              offset={{
                'bottom-left': [12, -38],
                bottom: [0, -38],
                'bottom-right': [-12, -38],
              }}>
              <div style={{ textAlign: 'center' }}>
                <img src={logo} alt="logo" width={80} />
                <h5>Cate Tasting Room</h5>
              </div>
            </Popup>
          </Map> */}
        </div>
      </Container>
    </div>
  );
}

export default About;
