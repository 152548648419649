const validations = {};

validations.plansForm = function (user, userDetail) {
  // Todo: Hacer la implementación de validación de email aquí
  const {
    last_name,
    email,
    name,
    phone_code,
    phone_number,
    invoice,
    ruc,
    business_name,
    business_address,
  } = user;
  const { address, document_number, provincia, department, district } = userDetail;

  let error = false;
  let message = '';

  if (!email) {
    message = 'El email es inválido';
    console.log('falta email');
    error = true;
  }

  if (!name) {
    message = 'El nombre es inválido';
    console.log('falta nombre');
    error = true;
  }

  if (!last_name) {
    message = 'El apellido es inválido.';
    console.log('falta apellidos');
    error = true;
  }

  if (!document_number || document_number.length < 8) {
    message = 'El DNI es inválido.';
    console.log('falta dni');
    error = true;
  }

  if (!address) {
    message = 'La dirección es inválida.';
    console.log('falta direcc');
    error = true;
  }

  if (!phone_code) {
    message = 'Campo código de teléfono vacío.';
    console.log('falta cod telf');
    error = true;
  }

  if (!phone_number || phone_number.length < 9) {
    message = 'Celular inválido.';
    console.log('falta nro telf');
    error = true;
  }

  if (!provincia) {
    message = 'Campo provincia inválido.';
    console.log('Falta la provincia');
    error = true;
  }

  if (!department) {
    message = 'Campo departamento inválido.';
    console.log('Falta el departamento');
    error = true;
  }

  if (!district) {
    message = 'campo distrito inválido.';
    console.log('falta el distrito');
    error = true;
  }

  if (!invoice) {
    message = 'Tipo de facturación inválido.';
    console.log('falta tipo de facturación');
    error = true;
  }

  if (invoice === 'Factura') {
    if (!ruc || ruc?.length < 11) {
      message = 'RUC inválido.';
      console.log('falta el ruc');
      error = true;
    }

    if (!business_name) {
      message = 'Razón Social inválida.';
      console.log('falta la razón social');
      error = true;
    }

    if (!business_address) {
      message = 'Dirección de la empresa inválida.';
      console.log('falta la dirección de la empresa');
      error = true;
    }
  }

  return { error, message };
};

export default validations;
