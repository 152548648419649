import configService from './config';
import fetchApi from './fetchApi';
import * as METHODS from './methods';

const baseUrl = configService.apiUrl;
const experienceService = {};

experienceService.list = async function () {
  const { response } = await fetchApi({
    url: baseUrl + '/experiences',
    options: METHODS.GET(),
    dataType: [],
    message: {
      success: 'Lista de experiencias obtenida con éxito.',
      error: 'No se pudo obtener la lista de experiencias.',
    },
  });

  return { ...response };
};

experienceService.listByUserId = async function (userId) {
  const { response } = await fetchApi({
    url: baseUrl + '/experiences/booking/' + userId,
    options: METHODS.GET(),
    dataType: [],
    message: {
      success: 'Lista de experiencias obtenida con éxito.',
      error: 'No se pudo obtener la lista de experiencias.',
    },
  });

  return { ...response };
};

experienceService.byId = async function (id) {
  const { response } = await fetchApi({
    url: baseUrl + '/experiences/show/' + id,
    options: METHODS.GET(),
    dataType: {},
    message: {
      success: 'Experiencia obtenida con éxito.',
      error: 'No se pudo obtener la experiencia solicitado.',
    },
  });

  return { ...response };
};

experienceService.showBooking = async function (userId) {
  const { response } = await fetchApi({
    url: baseUrl + '/experiences/booking/show/' + userId,
    options: METHODS.GET(),
    dataType: [],
    message: {
      success: 'Experiencia obtenida con éxito.',
      error: 'No se pudo obtener la experiencia.',
    },
  });

  return { ...response };
};

experienceService.createBooking = async function (arg) {
  const { response } = await fetchApi({
    url: baseUrl + '/experiences/booking/store',
    options: METHODS.POST({
      user_id: arg.user_id,
      experience_id: arg.experience_id,
      delivered_price: arg.delivered_price,
      department: arg.department,
      province: arg.province,
      district: arg.district,
      address: arg.address,
    }),
    dataType: {},
    message: {
      success: 'Experiencia creada con éxito.',
      error: 'No se pudo crear la experiencia.',
    },
  });

  return { ...response };
};

experienceService.updateBooking = async function (arg) {
  const { response } = await fetchApi({
    url: baseUrl + '/experiences/booking/update/' + arg.reservation_id,
    options: METHODS.POST({
      status: arg.status,
      payment_id: arg.payment_id,
    }),
    dataType: {},
    message: {
      success: 'Experiencia actualizada con éxito.',
      error: 'No se pudo actualizar la experiencia.',
    },
  });

  return { ...response };
};

export default experienceService;
