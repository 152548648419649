import React, { useContext, useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { replacePathParams } from 'utils';
import { Context } from 'context/Context';
import { experienceService } from 'services';
import * as VARIABLES from 'constants/variables';
import * as ROUTES from 'constants/routes';
import './userExperiences.scss';
// Components
import { CatePaper } from 'components';
import { Container, Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

function UserExperiences() {
  const { id } = useContext(Context);
  const [loading, setLoading] = useState(true);
  const [expList, setExpList] = useState([]);

  const fetchListOrders = async () => {
    setLoading(true);
    const res = await experienceService.listByUserId(id);
    if (!res.info.error) {
      setExpList(res.data.reverse());
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchListOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="cate-reservation-list">
      <Container maxWidth="lg">
        <div className="nui-main-title">
          <h1>Reservas</h1>
        </div>
        <Grid container spacing={3}>
          {!loading ? (
            <>
              {expList.map((item, index) => (
                <Grid item key={index} xs={12}>
                  <RouterLink
                    className="nui-no-decoration"
                    to={replacePathParams(ROUTES.USER_EXPERIENCES_BY_ID, { id: item.id })}>
                    <CatePaper disableElevation className="reservation-card">
                      <div className="reservation-card__cover">
                        <img
                          src={VARIABLES.IMAGE_PATH + item.experience?.image_path}
                          alt={item.experience?.title}
                        />
                      </div>
                      <div className="reservation-card__content">
                        <span>Reserva #{item.id}</span>
                        <h6>{item.experience?.title}</h6>
                        <p>Duración: {item.experience?.duration_time}</p>
                        <p>Fecha: {item.experience?.meeting_date}</p>
                        <div className="content-state">
                          {item.payment_id ? (
                            <span className="state-success">Confirmado</span>
                          ) : (
                            <span className="state-error">No Pagado</span>
                          )}
                        </div>
                      </div>
                    </CatePaper>
                  </RouterLink>
                </Grid>
              ))}
            </>
          ) : (
            <>
              {Array.from(new Array(4)).map((item, index) => (
                <Grid item key={index} xs={12}>
                  <Skeleton className="nui-skeleton-full" height={182} width="100%" />
                </Grid>
              ))}
            </>
          )}
        </Grid>
      </Container>
    </div>
  );
}

export default UserExperiences;
