import React, { useState, useEffect, useRef, useContext } from 'react';
import * as VARIABLES from 'constants/variables';
import { useNotification } from 'hooks';
import { useHistory } from 'react-router-dom';
import { Container, Grid, Button, Link } from '@material-ui/core';
import { CatePaper, Notification } from 'components';
import { Skeleton } from '@material-ui/lab';
import './membershipDetail.scss';
import BolsaDeCafe from '../../assets/images/background/cafe_bolsa.png';
import SubscriptionsPlans from 'services/subscriptionPlans';
import * as ROUTES from 'constants/routes';
import { locationService } from 'services';
import GrindTypeSelection from 'components/GrindTypeSelection';
import { Context } from 'context/Context';
import { LOCALES } from 'i18n';
import Traslate from 'i18n/traslate';
import ELEMENTS from 'constants/elementsForTranslate';

const layout = { sm: 12, md: 6 };

function MembershipDetail() {
  const { locale } = useContext(Context);
  const params = new URLSearchParams(window.location.search);
  const plan_id = params.get('plan_id');
  const [planData, setPlanData] = useState({});
  const [districtDeliveryPrice, setDistrictDeliveryPrice] = useState('');
  const { category, concepts, product } = planData;

  const location = JSON.parse(localStorage.getItem('location')) || {};
  const [propertyActive, setPropertyActive] = useState({
    index: undefined,
    item: {},
  });

  const fetchPlanData = async (id) => {
    const plan = await SubscriptionsPlans.getWithId(id);
    if (!plan.info.error) {
      setPlanData(plan.data);
    } else {
      setPlanData({});
    }
  };

  const fetchDistrictById = async (id) => {
    const district = await locationService.getdistrictPriceById(id);
    if (!district.info.error) {
      setDistrictDeliveryPrice(district.data.price);
    } else {
      setDistrictDeliveryPrice('');
    }
  };

  useEffect(() => {
    fetchPlanData(plan_id);
    fetchDistrictById(location?.district);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!concepts) {
    return (
      <Grid container spacing={3}>
        {Array.from(new Array(2)).map((item, index) => (
          <Grid item key={index} {...layout}>
            <CatePaper disableElevation>
              <Skeleton
                variant="rect"
                width="100%"
                height={608}
                style={{ borderRadius: 'inherit' }}
              />
            </CatePaper>
          </Grid>
        ))}
      </Grid>
    );
  }

  return (
    <div className="cate-subscription-detail">
      <Container className="cate-subscription-container" maxWidth="lg">
        <Description
          concepts={concepts}
          planData={planData}
          category={category}
          product={product}
          districtDeliveryPrice={districtDeliveryPrice}
          locale={locale}
        />
        <Subscribe
          planId={plan_id}
          productSlug={product.slug}
          propertyActive={propertyActive}
          setPropertyActive={setPropertyActive}
        />
      </Container>
    </div>
  );
}

const Description = ({
  concepts,
  planData,
  category,
  product,
  districtDeliveryPrice,
  locale,
}) => {
  const productRef = useRef(null);

  useEffect(() => {
    productRef.current.innerHTML =
      locale === LOCALES.ENGLISH ? product?.description_en : product?.description;
  }, [product, locale]);

  return (
    <section className="description-container">
      <div className="concepts-container">
        <img
          src={
            VARIABLES.IMAGE_PATH +
            planData?.product_images[planData.product_images.length - 1]?.image_path
          }
          alt={planData?.product.label}
        />
        <h1>{locale === LOCALES.ENGLISH ? planData?.name_en : planData?.name}</h1>
        <p>{locale === LOCALES.ENGLISH ? planData?.description_en : planData?.description}</p>

        <div className="information-container">
          <h1>{locale === LOCALES.ENGLISH ? product?.label_en : product?.label}</h1>
          <div ref={productRef}></div>
        </div>

        <div className="concepts">
          {concepts?.map((concept, index) => (
            <h4 key={index} className="name">
              {locale === LOCALES.ENGLISH ? concept.name_en : concept.name}
            </h4>
          ))}
        </div>
        <h1 className="total-price">
          {Traslate(ELEMENTS.EXPERIENCES_DETAIL.PRICE)}: S/.
          {planData ? planData.price.toFixed(2) : ''}
        </h1>
        <h1 className="total-price">
          Delivery: S/.
          {districtDeliveryPrice ? districtDeliveryPrice.toFixed(2) : '0'}
        </h1>
      </div>
    </section>
  );
};

const Subscribe = ({ planId, productSlug, propertyActive, setPropertyActive }) => {
  const { notification, createMessage, closeMessage } = useNotification();

  const history = useHistory();
  const { item } = propertyActive;
  const { label } = item;

  const redirectToForm = () => {
    const otherProductDetail =
      JSON.parse(localStorage.getItem('product_property_detail_other')) || '';

    if (label === 'Otro') {
      if (
        otherProductDetail === '' ||
        otherProductDetail === null ||
        otherProductDetail === undefined
      )
        return createMessage.error('Escriba su tipo de molido.');
    }
    history.push(ROUTES.MEMBERSHIPS_CHECKOUT + '?plan_id=' + planId);
  };

  return (
    <div className="buy-grid-container">
      <section className="buy-container">
        <Button
          disableElevation
          variant="contained"
          color="primary"
          type="submit"
          onClick={redirectToForm}>
          {Traslate(ELEMENTS.MEMBERSHIPS.SUBSCRIBE)}
        </Button>
        <p>Alguna descripción o texto de información sobre el pago</p>
        <Link>{Traslate(ELEMENTS.FOOTER.TERMS)}</Link>
      </section>
      <GrindTypeSelection
        productSlug={productSlug}
        propertyActive={propertyActive}
        setPropertyActive={setPropertyActive}
      />
      {notification.state && <Notification onClose={closeMessage} {...notification} />}
    </div>
  );
};

export default MembershipDetail;
