import React from 'react';
// import { Context } from 'context/Context';
import { BrowserRouter, Switch, Redirect, Route } from 'react-router-dom';
import * as ROUTES from 'constants/routes';
import RouteWithLayout from './RouteWithLayout';
import { LoginAndLogout as LoginAndLogoutLayout, Main as MainLayout } from 'layouts';
import {
  About as AboutView,
  Blog as BlogView,
  BlogDetail as BlogDetailView,
  ChangePassword as ChangePasswordView,
  Courses as CoursesView,
  CourseDetail as CourseDetailView,
  Experiences as ExperiencesView,
  ExperiencesDetail as ExperiencesDetailView,
  Home as HomeView,
  TermsConditions as TermsConditionsView,
  ShippingReturns as ShippingReturnsView,
  PrivacyPolicy as PrivacyPolicyView,
  Login as LoginView,
  Register as RegisterView,
  Memberships as MembershipsView,
  MembershipCheckout as MembershipCheckoutView,
  MembershipDetail as MembershipDetailView,
  NotFound as NotFoundView,
  ProductDetail as ProductDetailView,
  ProductB2bDetail as ProductB2bDetailView,
  Products as ProductsView,
  ProductsB2b as ProductsB2bView,
  Shopcart as ShopcartView,
  Shipping as ShippingView,
  Payment as PaymentView,
  UserProfile as UserProfileView,
  UserExperiences as UserExperiencesView,
  UserMemberships as UserMembershipsView,
  UserExperiencesDetail as UserExperiencesDetailView,
  Orders as OrdersView,
  OrderDetail as OrderDetailView,
  ComplaintsBook as ComplaintsBookView,
  Sucursales as SucursalesView,
  SubscriptionStatus as SubscriptionStatusView,
  ConfirmEmail as ConfirmEmailView,
} from 'views';

const Routes = () => {
  return (
    <BrowserRouter>
      <Switch>
        <RouteWithLayout component={HomeView} exact layout={MainLayout} path={ROUTES.HOME} />
        <RouteWithLayout
          component={ShippingReturnsView}
          exact
          layout={MainLayout}
          path={ROUTES.SHIPPING_RETURNS}
        />
        <RouteWithLayout
          component={TermsConditionsView}
          exact
          layout={MainLayout}
          path={ROUTES.TERMS_CONDITIONS}
        />
        <RouteWithLayout
          component={PrivacyPolicyView}
          exact
          layout={MainLayout}
          path={ROUTES.PRIVACY_POLICY}
        />
        <RouteWithLayout component={LoginView} exact layout={MainLayout} path={ROUTES.LOGIN} />
        <RouteWithLayout
          component={RegisterView}
          exact
          layout={MainLayout}
          path={ROUTES.REGISTER}
        />
        <RouteWithLayout component={AboutView} exact layout={MainLayout} path={ROUTES.ABOUT} />
        <RouteWithLayout component={BlogView} exact layout={MainLayout} path={ROUTES.BLOG} />
        <RouteWithLayout
          component={BlogDetailView}
          exact
          layout={MainLayout}
          path={ROUTES.BLOG_BY_SLUG}
        />
        <RouteWithLayout
          exact
          component={CoursesView}
          layout={MainLayout}
          path={ROUTES.COURSES}
        />
        <RouteWithLayout
          exact
          component={CourseDetailView}
          layout={MainLayout}
          path={ROUTES.COURSES_BY_SLUG}
        />
        <RouteWithLayout
          exact
          component={ExperiencesView}
          layout={MainLayout}
          path={ROUTES.EXPERIENCES}
        />
        <RouteWithLayout
          exact
          component={ExperiencesDetailView}
          layout={MainLayout}
          path={ROUTES.EXPERIENCES_BY_ID}
        />
        <RouteWithLayout
          component={MembershipsView}
          exact
          layout={MainLayout}
          path={ROUTES.MEMBERSHIPS}
        />
        <RouteWithLayout
          component={MembershipCheckoutView}
          exact
          layout={MainLayout}
          path={ROUTES.MEMBERSHIPS_CHECKOUT}
        />
        <RouteWithLayout
          component={MembershipDetailView}
          exact
          layout={MainLayout}
          path={ROUTES.MEMBERSHIPS_DETAIL}
        />
        <RouteWithLayout
          component={ProductsView}
          exact
          layout={MainLayout}
          path={ROUTES.PRODUCTS}
        />
        <RouteWithLayout
          component={ProductDetailView}
          exact
          layout={MainLayout}
          path={ROUTES.PRODUCTS_BY_SLUG}
        />
        <RouteWithLayout
          component={ProductsB2bView}
          exact
          layout={MainLayout}
          path={ROUTES.PRODUCTSB2B}
        />
        <RouteWithLayout
          component={ProductB2bDetailView}
          exact
          layout={MainLayout}
          path={ROUTES.PRODUCTSB2B_BY_SLUG}
        />
        <RouteWithLayout
          component={ShopcartView}
          exact
          layout={MainLayout}
          path={ROUTES.SHOPCART}
        />
        <RouteWithLayout
          auth
          exact
          component={ShippingView}
          layout={MainLayout}
          path={ROUTES.SHIPPING}
        />
        <RouteWithLayout
          auth
          exact
          component={PaymentView}
          layout={MainLayout}
          path={ROUTES.PAYMENT}
        />
        <RouteWithLayout
          exact
          component={ChangePasswordView}
          layout={MainLayout}
          path={ROUTES.CHANGE_USER_PASSWORD}
        />
        <RouteWithLayout
          auth
          exact
          component={UserProfileView}
          layout={MainLayout}
          path={ROUTES.USER_PROFILE}
        />
        <RouteWithLayout
          auth
          exact
          component={OrdersView}
          layout={MainLayout}
          path={ROUTES.USER_PURCHASES}
        />
        <RouteWithLayout
          auth
          exact
          component={OrderDetailView}
          layout={MainLayout}
          path={ROUTES.USER_PURCHASES_BY_ID}
        />
        <RouteWithLayout
          auth
          exact
          component={UserExperiencesView}
          layout={MainLayout}
          path={ROUTES.USER_EXPERIENCES}
        />
        <RouteWithLayout
          auth
          exact
          component={UserMembershipsView}
          layout={MainLayout}
          path={ROUTES.USER_MEMBERSHIPS}
        />
        <RouteWithLayout
          auth
          exact
          component={UserExperiencesDetailView}
          layout={MainLayout}
          path={ROUTES.USER_EXPERIENCES_BY_ID}
        />
        <RouteWithLayout
          exact
          component={ComplaintsBookView}
          layout={MainLayout}
          path={ROUTES.BOOK_VIEW}
        />
        <RouteWithLayout
          exact
          component={NotFoundView}
          layout={MainLayout}
          path={ROUTES.NOT_FOUND}
        />

        <RouteWithLayout
          component={SucursalesView}
          exact
          layout={MainLayout}
          path={ROUTES.SUCURSALES}
        />
        <RouteWithLayout
          component={SubscriptionStatusView}
          exact
          layout={MainLayout}
          path={ROUTES.SUBSCRIPTION_STATUS}
        />
        <Route exact path={ROUTES.CONFIRM_EMAIL}>
          <LoginAndLogoutLayout>
            <ConfirmEmailView />
          </LoginAndLogoutLayout>
        </Route>
        {/* <RouteForUnverified isItVerified={isItVerified} /> */}
        <Redirect from="*" to={ROUTES.NOT_FOUND} />
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
