import React, { useState, useEffect, useContext } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { Context } from 'context/Context';
import { productService, productCommentService } from 'services';
import { useNotification } from 'hooks';
import * as VARIABLES from 'constants/variables';
import * as ROUTES from 'constants/routes';
import './productDetail.scss';
// Components
import { Notification, CatePaper } from 'components';
import {
  Container,
  Breadcrumbs,
  Button,
  IconButton,
  Chip,
  TextField,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Divider,
  Avatar,
  Grid,
} from '@material-ui/core';
import { format } from 'timeago.js';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import SendIcon from '@material-ui/icons/Send';
import 'antd/lib/rate/style/css';
import 'antd/lib/comment/style/css';
import { Rate, Comment, Input } from 'antd';
import { getFirstLetter } from '../../utils/textFormat';
import Traslate from 'i18n/traslate';
import ELEMENTS from 'constants/elementsForTranslate';
import { LOCALES } from 'i18n';

const userCommentInitialState = {
  user_id: -1,
  product_id: -1,
  title: '',
  detail: '',
  rating: 5,
};

const productRateInitialState = {
  global: {
    totalRating: 0,
    avgRating: 0,
  },
  perStar: {
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
  },
};

const imageActiveInitialState = {
  value: 0,
  type: 'jpg',
};

const propertyActiveInitialState = {
  index: undefined,
  item: {},
};

const CommentList = ({ comments }) => {
  const comentarios = [];

  const totalComments = comments.length;
  for (let index = 0; index < totalComments; index++) {
    comentarios.push(
      <Grid key={comments[index].comment_id} container wrap="nowrap" spacing={2}>
        <Grid item>
          <Avatar alt={comments[index].comment_user_name}>
            {getFirstLetter(comments[index].comment_user_name)}
          </Avatar>
        </Grid>
        <Grid item xs zeroMinWidth>
          <h4 style={{ margin: 0, textAlign: 'left' }}>{comments[index].comment_user_name}</h4>
          <div style={{ textAlign: 'left' }}>
            <Rate value={comments[index].comment_rating} disabled />
            <b className="ml-1">{comments[index].comment_title}</b>
          </div>
          <p style={{ textAlign: 'left' }}>{comments[index].comment_detail}</p>
          <p style={{ textAlign: 'left', color: 'gray' }}>
            {format(comments[index].comment_created_at)}
          </p>
        </Grid>
      </Grid>,
    );
    if (totalComments !== index + 1) {
      comentarios.push(
        <Divider key={`divider-${index}`} variant="fullWidth" style={{ margin: '20px 0' }} />,
      );
    }
  }

  return comentarios;
};

const Editor = ({
  onChangeDetail,
  onchangeRating,
  valueDetail,
  valueRating,
  onSubmit,
  submitting,
}) => (
  <>
    <div className="mb-2">
      <p className="ml-2px mb-0">{Traslate(ELEMENTS.PRODUCT_DETAIL.RATING)}</p>
      <Rate value={valueRating} onChange={onchangeRating} className="nui-font-size-2" />
    </div>
    <div className="mb-2">
      <TextField
        label={Traslate(ELEMENTS.PRODUCT_DETAIL.COMMENT)}
        variant="outlined"
        fullWidth
        multiline
        minRows={4}
        maxLength="2048"
        onChange={onChangeDetail}
        value={valueDetail}
        placeholder="Ingrese reseña"
      />
    </div>
    <div className="mb-3 text-right">
      <Button
        disableElevation
        variant="contained"
        color="secondary"
        startIcon={<SendIcon />}
        type="submit"
        disabled={submitting}
        onClick={onSubmit}>
        {Traslate(ELEMENTS.PRODUCT_DETAIL.SEND_COMMENT)}
      </Button>
    </div>
  </>
);

function ProductDetail(props) {
  const productSlug = props.match.params.slug;

  const history = useHistory();
  const { id, name, addProductCount, locale } = useContext(Context);
  const { notification, createMessage, closeMessage } = useNotification();

  const [count, setCount] = useState(1);
  const [hasDeduction, setHasDeduction] = useState(false);
  const [deduction, setDeduction] = useState(0);
  const [priceWithDeduction, setPriceWithDeduction] = useState(0);

  const [imageActive, setImageActive] = useState(imageActiveInitialState);
  const [propertyActive, setPropertyActive] = useState(propertyActiveInitialState);
  const { item } = propertyActive;
  const { label } = item;

  const [productRate, setProductRate] = useState(Object.assign({}, productRateInitialState));
  const [userComment, setUserComment] = useState(Object.assign({}, userCommentInitialState));

  const [loading, setLoading] = useState(true);
  const [comments, setComments] = useState([]);
  const [data, setData] = useState({});

  const [isPropertyOther, setIsPropertyOther] = useState(false);
  const [otherGroundProperty, setOtherGroundProperty] = useState('');

  const handleGoBack = () => {
    history.push(ROUTES.PRODUCTS);
  };

  const handleImageActive = (value, imagePath) => {
    const type = imagePath.split('.').pop();
    setImageActive({
      value: value,
      type: type,
    });
  };

  const handlePropertyActive = (arg) => {
    setPropertyActive(arg);
  };

  const handleInputCountChange = (e) => {
    if (e.target.value === '0') {
      setCount(1);
    } else {
      setCount(parseInt(e.target.value));
    }
  };

  const handleCount = (e) => {

    if (e) {
      if (data.stock <= count) {
        return createMessage.error('No hay más stock.');
      }
      setCount(count + 1);
    } else if (count === 1) {
      setCount(1);
    } else {
      setCount(count - 1);
    }
  };

  const handleAddToCart = () => {
    if (label === 'Otro') {
      if (
        otherGroundProperty === '' ||
        otherGroundProperty === null ||
        otherGroundProperty === undefined
      )
        return createMessage.error('Escriba su tipo de molido.');
    }

    if (propertyActive.item.label) {
      addProductCount(
        {
          ...data,
          ak_product: 'ak_product_' + data.id + '_' + propertyActive.item.id,
          price: (data.wholesale && count >= 6) ? data.wholesale_price : data.price,
          property_active: {
            ...propertyActive.item,
          },
          otherGroundProperty,
        },
        count,
      );
    } else {
      if (hasDeduction) {
        addProductCount(
          {
            ...data,
            price: (data.wholesale && count >= 6) ? data.wholesale_price : priceWithDeduction,
            ak_product: 'ak_product_' + data.id,
            property_active: null,
            otherGroundProperty,
          },
          count,
        );
      } else {
        addProductCount(
          {
            ...data,
            price: (data.wholesale && count >= 6) ? data.wholesale_price : data.price,
            ak_product: 'ak_product_' + data.id,
            property_active: null,
            otherGroundProperty,
          },
          count,
        );
      }
    }
  };

  const fetchShowProduct = async () => {
    setLoading(true);
    const res = await productService.showBySlug(productSlug, id);
    if (!res.info.error) {
      setData(res.data);
      if (res.data.deduction_value) {
        setHasDeduction(true);
        setDeduction(res.data.deduction_value);
        setPriceWithDeduction(res.data.price_with_deduction);
      }
      if (res.data.property && res.data.property.detail[0]) {
        setPropertyActive({
          index: 0,
          item: { ...res.data.property.detail[0] },
        });
      }

      fetchComments(res.data.id).then(() => console.log('se cargó comentarios'));

      setLoading(false);
    } else {
      createMessage.error(res.info.message);
    }
  };

  const handleProductRate = async (rateByUser) => {
    const _userComment = { ...userComment };
    _userComment.rating = rateByUser;
    setUserComment(_userComment);
  };

  const handleOnChangeInputDetail = (e) => {
    const _userComment = { ...userComment };
    _userComment.detail = e.target.value;
    setUserComment(_userComment);
  };

  const handleOtherPropertyChange = (e) => {
    localStorage.setItem('product_property_other_details_id', JSON.stringify(e.target.value));
    setOtherGroundProperty(e.target.value);
  };

  const handleOnSubmitComment = (e) => {
    if (loading) return false;

    if (userComment.detail.trim().length === 0) {
      createMessage.error('Ingrese reseña');
      return false;
    }

    setLoading(true);

    const _userComment = { ...userComment };
    _userComment.product_id = data.id;
    _userComment.user_id = id;

    productCommentService
      .create(_userComment)
      .then((responseData) => {
        setLoading(false);

        const _comments = [...comments];
        _comments.unshift(responseData);
        setComments(_comments);
        setUserComment(Object.assign({}, userCommentInitialState));
      })
      .catch((reason) => {
        setLoading(false);
        createMessage.errorServidor(reason);
        console.log(reason);
      });
  };

  const fetchComments = async (productId) => {
    const respuesta = await productCommentService.listByProduct(productId);
    setProductRate(respuesta.ratings);
    setComments(respuesta.comments);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchShowProduct();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setOtherGroundProperty('');
    if (propertyActive?.item.label === 'Otro') {
      setIsPropertyOther(true);
    } else {
      setIsPropertyOther(false);
    }
  }, [propertyActive]);

  return (
    <div className="cate-product-detail">
      <Container maxWidth="lg">
        <div className="product-top-wrap">
          <Button disableRipple startIcon={<ArrowBackIosRoundedIcon />} onClick={handleGoBack}>
            {Traslate(ELEMENTS.PRODUCT_DETAIL.BACK)}
          </Button>
        </div>
        <CatePaper disableElevation className="product-main-wrap">
          <div className="img-view-wrap">
            <div className="image-viewer">
              <div className="image-view-magnifier-wrap">
                {loading ? (
                  <div></div>
                ) : (
                  <>
                    {imageActive.type === 'mp4' ? (
                      <video controls="true" controlsList="nodownload" autoplay="autoplay">
                        <source
                          src={VARIABLES.IMAGE_PATH + data.images[imageActive.value].image_path}
                          type="video/mp4"
                        />
                      </video>
                    ) : (
                      <img
                        src={
                          data.images[0]
                            ? VARIABLES.IMAGE_PATH + data.images[imageActive.value].image_path
                            : VARIABLES.IMAGE_FALLBACK
                        }
                        alt={data.label}
                      />
                    )}
                  </>
                )}
              </div>
              {!loading && (
                <div className="images-view-wrap">
                  <ul className="images-view-list">
                    {data.images.map((item, index) => {
                      const type = item.image_path.split('.').pop();
                      return (
                        <li
                          key={index}
                          className={index === imageActive.value ? 'active' : undefined}
                          onClick={() => handleImageActive(index, item.image_path)}
                          onMouseEnter={() => handleImageActive(index, item.image_path)}>
                          <div className="images-view-item">
                            {type === 'mp4' ? (
                              <video controls="false">
                                <source
                                  src={VARIABLES.IMAGE_PATH + item.image_path}
                                  type="video/mp4"
                                />
                              </video>
                            ) : (
                              <object
                                data={VARIABLES.IMAGE_PATH + item.image_path}
                                type="image/png">
                                <img src={VARIABLES.IMAGE_FALLBACK} alt={data.label} />
                              </object>
                            )}
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              )}
            </div>
          </div>
          {loading ? (
            <div className="product-info"></div>
          ) : (
            <div className="product-info">
              <div>
                <div className="product-extra">
                  <Breadcrumbs aria-label="breadcrumb">
                    <RouterLink
                      className="nui-no-decoration"
                      to={ROUTES.PRODUCTS}
                      style={{ opacity: 0.6 }}>
                      {Traslate(ELEMENTS.MEMBERSHIPS.MODAL.PRODUCT)}
                    </RouterLink>
                    {data.category && (
                      <RouterLink className="nui-no-decoration" to={ROUTES.PRODUCTS}>
                        {locale === LOCALES.ENGLISH
                          ? data.category.category_en
                          : data.category.category}
                      </RouterLink>
                    )}
                  </Breadcrumbs>
                </div>
                <div className="product-title">
                  {data.brand && (
                    <div className="product-title__brand">
                      <span>{data.brand.label}</span>
                    </div>
                  )}
                  <h1>{locale === LOCALES.ENGLISH ? data.label_en : data.label}</h1>
                </div>
                <div
                  className="product-desc nui-content-product"
                  dangerouslySetInnerHTML={{
                    __html: locale === LOCALES.ENGLISH ? data.description_en : data.description,
                  }}
                />
                <div className="product-price">
                  <div className="product-price__current">
                    {!hasDeduction && (!data.wholesale || count < 6) && <span>S/. {data.price.toFixed(2)}</span>}

                    {hasDeduction && (!data.wholesale || count < 6) && (
                      <>
                        <span style={{ color: '#f78383' }}>
                          <s>S/. {data.price.toFixed(2)}</s>
                        </span>
                        <br />
                        <span>S/. {priceWithDeduction.toFixed(2)}</span>
                      </>
                    )}

                    {data.wholesale && count >= 6 && (
                      <>
                        <span style={{ color: '#f78383' }}>
                          <s>S/. {data?.price?.toFixed(2)}</s>
                        </span>
                        <br />
                        <span>S/. {data?.wholesale_price?.toFixed(2)}</span>
                      </>
                    )}
                  </div>
                </div>
                <div className="product-stock">
                  <span>
                    {Traslate(ELEMENTS.PRODUCT_DETAIL.UNITS_AVAILABLE)}:{' '}
                    <strong>{data.stock}</strong>
                  </span>
                  {data.stock <= 0 && (
                    <Chip
                      variant="outlined"
                      color="primary"
                      size="small"
                      label="Sin Inventario"
                    />
                  )}
                </div>
                
                { data.wholesale && (
                  <div className="product-price__current">
                  <span>
                    {Traslate(ELEMENTS.PRODUCT_DETAIL.WHOLESALE)} S/. {data.wholesale_price}
                  </span>
                  </div>
                  )
                }

                {hasDeduction && (
                  <div className="product-deduction">
                    Tienes un descuento del {deduction}% por tu suscripción
                  </div>
                )}
                <div className="divider-line" />
                {data.property && data.property.detail[0] && (
                  <div className="product-property">
                    <div className="product-property__title">
                      {data.property.label + ' : '}
                      {propertyActive.item.label && <span>{propertyActive.item.label}</span>}
                    </div>
                    <ul className="product-property__list">
                      {data.property.detail.map((item, index) => (
                        <li
                          key={index}
                          className={`property-item ${
                            propertyActive.index === index ? 'active' : undefined
                          }`}
                          onClick={() => handlePropertyActive({ item, index })}>
                          <div className="property-text">
                            <span>{item.label}</span>
                          </div>
                        </li>
                      ))}
                      {isPropertyOther && (
                        <Input
                          placeholder="Escriba su tipo de molido"
                          value={otherGroundProperty}
                          onChange={handleOtherPropertyChange}
                        />
                      )}
                    </ul>
                  </div>
                )}
                <div className="product-quantity">
                  <div className="product-quantity__title">
                    <span>{Traslate(ELEMENTS.PRODUCT_DETAIL.AMOUNT)}:</span>
                  </div>
                  <div className="product-quantity__picker">
                    <span className="input-group">
                      <span className="input-addon input-remove">
                        <IconButton
                          aria-label="delete"
                          size="small"
                          onClick={() => handleCount(false)}>
                          <RemoveIcon fontSize="large" />
                        </IconButton>
                      </span>
                      <span className="input-addon input-text">
                        <TextField
                          id="input-count"
                          type="number"
                          size="small"
                          value={count}
                          onChange={handleInputCountChange}
                        />
                      </span>
                      <span className="input-addon input-add">
                        <IconButton
                          aria-label="add"
                          size="small"
                          onClick={() => handleCount(true)}>
                          <AddIcon fontSize="large" />
                        </IconButton>
                      </span>
                    </span>
                  </div>
                  <div></div>
                </div>
              </div>
              <div className="product-action">
                <Button
                  disableElevation
                  variant="contained"
                  color="primary"
                  startIcon={<AddShoppingCartIcon />}
                  onClick={handleAddToCart}
                  disabled={data.stock <= 0 ? true : false}>
                  {Traslate(ELEMENTS.PRODUCT_DETAIL.ADD_CART)}
                </Button>
              </div>
              <Divider variant="fullWidth" style={{ marginTop: '20px 0' }} />
              <div className="product-action">
                <h5>{Traslate(ELEMENTS.PRODUCT_DETAIL.USER_RATING)}</h5>
                <Grid container wrap="nowrap" spacing={2}>
                  <Grid item>
                    <Rate
                      disabled={true}
                      allowHalf
                      value={
                        productRate.global.avgRating === 0 ? 0 : productRate.global.avgRating
                      }
                      className="nui-font-size-2"
                    />
                  </Grid>
                  <Grid item>
                    <h2>{productRate.global.avgRating} de 5</h2>
                  </Grid>
                </Grid>
                <div>
                  <p className="mb-0">
                    {productRate.global.totalRating}{' '}
                    {Traslate(ELEMENTS.PRODUCT_DETAIL.GLOBAL_RATING)}
                  </p>
                </div>
              </div>
            </div>
          )}
        </CatePaper>
        <CatePaper disableElevation className="product-extra-wrap">
          <div className="product-extra-title">
            <h2>{Traslate(ELEMENTS.PRODUCT_DETAIL.SPECIFICATIONS)}</h2>
          </div>
          <div className="product-extra-info">
            <Table className="product-extra-info__table" aria-label="custom pagination table">
              <TableBody>
                <TableRow className="table-row table-row-gray">
                  <TableCell component="th" scope="row">
                    {Traslate(ELEMENTS.PRODUCT_DETAIL.HEIGHT)}
                  </TableCell>
                  <TableCell align="left">
                    {data.height ? (
                      <>
                        {data.height} {data.height_unit}
                      </>
                    ) : (
                      <>{'...'}</>
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    {Traslate(ELEMENTS.PRODUCT_DETAIL.WIDTH)}
                  </TableCell>
                  <TableCell align="left">
                    {data.width ? (
                      <>
                        {data.width} {data.width_unit}
                      </>
                    ) : (
                      <>{'...'}</>
                    )}
                  </TableCell>
                </TableRow>
                <TableRow className="table-row table-row-gray">
                  <TableCell component="th" scope="row">
                    {Traslate(ELEMENTS.PRODUCT_DETAIL.DEPTH)}
                  </TableCell>
                  <TableCell align="left">
                    {data.depth ? (
                      <>
                        {data.depth} {data.depth_unit}
                      </>
                    ) : (
                      <>{'...'}</>
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    {Traslate(ELEMENTS.PRODUCT_DETAIL.WEIGHT)}
                  </TableCell>
                  <TableCell align="left">
                    {data.weight ? (
                      <>
                        {data.weight} {data.weight_unit}
                      </>
                    ) : (
                      <>{'...'}</>
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
        </CatePaper>
        <CatePaper disableElevation className="product-extra-wrap">
          <div className="product-extra-title">
            <h2>{Traslate(ELEMENTS.PRODUCT_DETAIL.RATINGS_COMMENTS)}</h2>
          </div>
          <div className="product-extra-info">
            <h4>{Traslate(ELEMENTS.PRODUCT_DETAIL.LET_RATING)}</h4>
            <Comment
              avatar={<Avatar>{getFirstLetter(name)}</Avatar>}
              content={
                <Editor
                  onChangeDetail={handleOnChangeInputDetail}
                  onchangeRating={handleProductRate}
                  valueDetail={userComment.detail}
                  valueRating={userComment.rating}
                  onSubmit={handleOnSubmitComment}
                  submitting={loading}
                />
              }
            />
            <Divider variant="fullWidth" style={{ marginTop: '30px 0' }} />
            <h3 className="mb-3">{Traslate(ELEMENTS.PRODUCT_DETAIL.LIST_COMMENTS)}</h3>
            {comments.length > 0 && <CommentList comments={comments} />}
          </div>
        </CatePaper>
      </Container>
      {notification.state && <Notification onClose={closeMessage} {...notification} />}
    </div>
  );
}

export default ProductDetail;
