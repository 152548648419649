import React, { useEffect, useState } from 'react';
import { LOCALES } from '../../i18n/locales';
import PropTypes from 'prop-types';
import { useResponsive } from 'hooks';
// Components
import { withStyles } from '@material-ui/core/styles';
import { Tabs, Tab } from '@material-ui/core';

const a11yProps = (index) => {
  return {
    id: `category-tab-${index}`,
    'aria-controls': `category-tabpanel-${index}`,
  };
};

const StyledTabs = withStyles({
  root: {
    borderRight: '1px solid #eeeeee',
  },
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'transparent',
    '& > span': {
      width: '100%',
      height: '100%',
      maxWidth: '48px',
      maxHeight: '32px',
      backgroundColor: 'var(--color-primary)',
    },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    fontSize: 'var(--font-size-base)',
    minWidth: 72,
    '&:focus': {
      color: 'var(--color-primary)',
      opacity: 1,
    },
  },
  selected: {
    color: 'var(--color-primary)',
  },
}))((props) => <Tab disableRipple {...props} />);

function FilterTabs(props) {
  const { tab = 0, data = [], onTabClick, locale } = props;

  const { isMobile } = useResponsive();
  const [tabValue, setTabValue] = useState(tab);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    localStorage.setItem('product_category_list', newValue);
  };

  useEffect(() => {
    const productCategory = JSON.parse(localStorage.getItem('product_category_list')) || 0;
    setTabValue(productCategory);
  }, []);

  return (
    <StyledTabs
      orientation={isMobile ? 'horizontal' : 'vertical'}
      variant="scrollable"
      scrollButtons={isMobile ? 'on' : 'auto'}
      value={tabValue}
      onChange={handleTabChange}
      aria-label="Category tabs">
      <StyledTab
        onClick={() => onTabClick('all', null)}
        label={locale === LOCALES.ENGLISH ? 'All' : 'Todos'}
        {...a11yProps('all')}
      />
      {data.map((item, index) => (
        <StyledTab
          key={item.id}
          onClick={() => onTabClick(item.id, item.category)}
          label={locale === LOCALES.ENGLISH ? item.category_en : item.category}
          {...a11yProps(item.id)}
        />
      ))}
    </StyledTabs>
  );
}

FilterTabs.propTypes = {
  tab: PropTypes.number,
  data: PropTypes.array,
  onTabClick: PropTypes.func,
};

export default FilterTabs;
