import configService from './config';
import fetchApi from './fetchApi';
import * as METHODS from './methods';

const baseUrl = configService.apiUrl;
const courseService = {};

courseService.list = async function () {
  const { response } = await fetchApi({
    url: baseUrl + '/courses',
    options: METHODS.GET(),
    dataType: [],
    message: {
      success: 'Lista de cursos obtenida con éxito.',
      error: 'No se pudo obtener la lista de cursos.',
    },
  });

  return { ...response };
};

courseService.show = async function (id) {
  const { response } = await fetchApi({
    url: baseUrl + '/courses/show/' + id,
    options: METHODS.GET(),
    dataType: {},
    message: {
      success: 'Curso obtenido con éxito.',
      error: 'No se pudo obtener el curso solicitado.',
    },
  });

  return { ...response };
};

export default courseService;
