import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { replacePathParams } from 'utils';
import { productService } from 'services';
import * as VARIABLES from 'constants/variables';
import { useResponsive } from 'hooks';
import PropTypes from 'prop-types';
import * as ROUTES from 'constants/routes';
import './banner.scss';
// Components
import { Button } from '@material-ui/core';
// Images

// import CafeteraUrl from 'assets/images/background/cafetera.png';
// import LecheraUrl from 'assets/images/background/choco.png';
// import CafeBolsaUrl from 'assets/images/background/cafe_bolsa.png';
import Simple from 'assets/images/background/simple.jpg';
import Traslate from 'i18n/traslate';
import ELEMENTS from 'constants/elementsForTranslate';

/*
const getSlugProductName = (name) => {
  const prodsFavs = [
    {
      name: 'Cafetera',
      slug: 'mando-play-+-café-bitácora-x-250-g',
    },
    {
      name: 'Lechera',
      slug: 'mando-play-+-caf%C3%A9-bit%C3%A1cora-x-250-g',
    },
    {
      name: 'CafeBolsa',
      slug: 'chocolate-granulado-de-cacao-al-100',
    },
  ];

  const filteredProdFav = prodsFavs.find((prodFav) => prodFav.name === name);
  //const filteredProdFav = prodsFavs.filter(person => prodsFavs.name = name);

  return filteredProdFav;
};
*/

// useEffect(()=>{console.log(getSlugProductName('Cafetera'))},[])
//console.log('vx',getSlugProductName('Cafetera').slug)

const ImageItem = ({ imageClass, image, slug }) => {
  console.log(imageClass);
  if (slug === '') {
    return (
      <div
        key={'div_ban_' + imageClass}
        className={`cate-home-banner__ilustration ${imageClass}`}>
        <div className="cate-home-banner__ilustration--animation-bounce-in">
          <div className="cate-home-banner__ilustration--animation-move-up-down">
            <div className="img-action"></div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div
        key={'div_ban_' + imageClass}
        className={`cate-home-banner__ilustration ${imageClass}`}>
        <RouterLink
          to={replacePathParams(ROUTES.PRODUCTS_BY_SLUG, {
            slug: slug,
          })}
          className="nui-no-decoration nui-block">
          <div className="cate-home-banner__ilustration--animation-bounce-in">
            <div className="cate-home-banner__ilustration--animation-move-up-down">
              <div className="img-action">
                <img className="img-root" src={image} alt="banner" />
              </div>
            </div>
          </div>
        </RouterLink>
      </div>
    );
  }
};

const Banner = (props) => {
  const { id } = props;
  const { isDesktop } = useResponsive();
  /* eslint-disable */
  const [data, setData] = useState([]);
  const [key1, setKey1] = useState(2);
  const [key2, setKey2] = useState(0);
  const [key3, setKey3] = useState(1);

  const [image1, setImage1] = useState(VARIABLES.IMAGE_FALLBACK);
  const [slug1, setSlug1] = useState('');
  const [image2, setImage2] = useState(VARIABLES.IMAGE_FALLBACK);
  const [slug2, setSlug2] = useState('');
  const [image3, setImage3] = useState(VARIABLES.IMAGE_FALLBACK);
  const [slug3, setSlug3] = useState('');

  const fetchShowProductsFav = async () => {
    //setLoading(true);
    const res = await productService.getFavorites();
    if (!res.info.error) {
      setData(res.data);
      res.data.map((item, index) => {
        if (
          item.image_url_fav === undefined ||
          item.image_url_fav === null ||
          item.image_url_fav === ''
        ) {
          if (index === 0) {
            setImage1(VARIABLES.IMAGE_FALLBACK);
          }
          if (index === 1) {
            setImage2(VARIABLES.IMAGE_FALLBACK);
          }

          if (index === 2) {
            setImage3(VARIABLES.IMAGE_FALLBACK);
          }
        } else {
          if (item.posic_image_fav === 1) {
            setImage1(VARIABLES.IMAGE_PATH + item.image_url_fav);
            setSlug1(item.slug);
            setKey1(item.posic_image_fav);
          }

          if (item.posic_image_fav === 2) {
            setImage2(VARIABLES.IMAGE_PATH + item.image_url_fav);
            setSlug2(item.slug);
            setKey2(item.posic_image_fav);
          }

          if (item.posic_image_fav === 3) {
            setImage3(VARIABLES.IMAGE_PATH + item.image_url_fav);
            setSlug3(item.slug);
            setKey3(item.posic_image_fav);
          }
        }
      });

      // console.log('Test : ' + res.data);
    } else {
      console.log(res.info.message);
    }
  };

  useEffect(() => {
    fetchShowProductsFav();
  }, []);

  return (
    <section id={id} className="cate-home-banner" style={{ backgroundImage: `url(${Simple})` }}>
      {isDesktop && (
        <>
          <ImageItem
            key={90 + key1}
            imageClass={'ilustration-' + key1}
            image={image1}
            slug={slug1}
          />
          <ImageItem
            key={91 + key2}
            imageClass={'ilustration-' + key2}
            image={image2}
            slug={slug2}
          />
          <ImageItem
            key={92 + key3}
            imageClass={'ilustration-' + key3}
            image={image3}
            slug={slug3}
          />
        </>
      )}
      <div className="cate-home-banner__header">
        <div className="header-title">
          <h1>{Traslate(ELEMENTS.BANNER.SLOGAN, { component: <br /> })}</h1>
        </div>
        <div className="header-action">
          <RouterLink className="nui-no-decoration" to={ROUTES.PRODUCTS}>
            <Button variant="contained" color="primary" disableElevation>
              {Traslate(ELEMENTS.BANNER.SHOP)}
            </Button>
          </RouterLink>
        </div>
      </div>
    </section>
  );
};

Banner.propTypes = {
  id: PropTypes.string,
};

export default Banner;
