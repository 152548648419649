import { useState } from 'react';

const useNotification = () => {
  const [notification, setNotification] = useState({
    message: '',
    variant: 'info',
    state: false,
  });

  const createMessage = {};

  createMessage.getDisplayError = async (response) => {
    let displayMessage = `Sucedió un error inesperado. Contacte con Soporte técnico`;

    let bodyJSON = null;
    try {
      const text = await response.text();
      bodyJSON = JSON.parse(text);
    } catch (error) {
      console.log(error);
    }

    if (bodyJSON && bodyJSON.title) {
      let msgGlobal = '';
      if (bodyJSON.errors) {
        const errorKeys = Object.keys(bodyJSON.errors);
        if (errorKeys.length > 0) {
          errorKeys.forEach((errorKey) => {
            const totalErrores = bodyJSON.errors[errorKey].length;
            for (let i = 0; i < totalErrores; i++) {
              const msgError = bodyJSON.errors[errorKey][i];
              msgGlobal = msgGlobal + '- ' + msgError + '\n';
            }
          });
        }
        displayMessage = bodyJSON.title + '\n' + msgGlobal;
      } else {
        displayMessage = bodyJSON.title;
      }
    }

    return displayMessage;
  };

  createMessage.errorServidor = async (response) => {
    const text = await createMessage.getDisplayError(response);
    setNotification({ message: text, variant: 'error', state: true });
  };

  createMessage.error = function (text) {
    setNotification({ message: text, variant: 'error', state: true });
  };

  createMessage.info = function (text) {
    setNotification({ message: text, variant: 'info', state: true });
  };

  createMessage.success = function (text) {
    setNotification({ message: text, variant: 'success', state: true });
  };

  createMessage.warning = function (text) {
    setNotification({ message: text, variant: 'warning', state: true });
  };

  const closeMessage = () => {
    setNotification({ ...notification, state: false });
  };

  return { notification, createMessage, closeMessage };
};

export default useNotification;
