import { intervalToDuration } from 'date-fns';

const isNewProduct = (date) => {
  const intervalDate = intervalToDuration({
    start: new Date(date),
    end: new Date(),
  });

  if (intervalDate.days <= 15 && intervalDate.months === 0 && intervalDate.years === 0) {
    return true;
  } else {
    return false;
  }
};

export default isNewProduct;
