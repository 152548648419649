import React, { Fragment, useContext } from 'react';
import { IntlProvider } from 'react-intl';
import messages from './messages';
import { Context } from 'context';

const Provider = ({ children }) => {
  const { locale } = useContext(Context);
  return (
    <IntlProvider locale={locale} textComponent={Fragment} messages={messages[locale]}>
      {children}
    </IntlProvider>
  );
};

export default Provider;
