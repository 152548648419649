import MuiBadge from './MuiBadge';
import MuiButton from './MuiButton';
import MuiChip from './MuiChip';
import MuiFormLabel from './MuiFormLabel';
import MuiIconButton from './MuiIconButton';
import MuiInputBase from './MuiInputBase';
import MuiMenuItem from './MuiMenuItem';
import MuiPaper from './MuiPaper';
import MuiSvgIcon from './MuiSvgIcon';
import MuiTableCell from './MuiTableCell';
import MuiTextField from './MuiTextField';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  MuiBadge,
  MuiButton,
  MuiChip,
  MuiFormLabel,
  MuiIconButton,
  MuiInputBase,
  MuiMenuItem,
  MuiPaper,
  MuiSvgIcon,
  MuiTableCell,
  MuiTextField,
};
