const ELEMENTS = {
  NAV: {
    HOME: 'NAV_HOME',
    PRODUCTS: 'NAV_PRODUCTS',
    SHOP: 'NAV_SHOP',
    ABOUT: 'NAV_ABOUT',
    MEMBERSHIPS: 'NAV_MEMBERSHIPS',
    EXPERIENCES: 'NAV_EXPERIENCES',
    SUCURSALES: 'NAV_BRANCHES',
    HELLO: 'NAV_HELLO',
    OTHERS: 'NAV_OTHERS',
    COURSES: 'NAV_COURSES',
    LOGIN: 'NAV_LOGIN',
    MANAGE: 'NAV_MANAGE',
    CHANGE_PASSWORD: 'NAV_CHANGE_PASSWORD',
    MY_SHOPPING: 'NAV_MY_SHOPPING',
    MY_EXPERIENCES: 'NAV_MY_EXPERIENCES',
    MY_MEMBERSHIPS: 'NAV_MY_MEMBERSHIPS',
    LOGOUT: 'NAV_LOGOUT',
  },
  BANNER: {
    SLOGAN: 'BANNER_SLOGAN',
    SHOP: 'BANNER_SHOP',
  },
  RECOMMEDED_PRODUCTS: {
    TITLE: 'RECOMMENDED_PRODUCTS_TITLE',
  },
  CAROUSEL: {
    TITLE: 'CAROUSEL_TITLE',
    SUBTITLE: 'CAROUSEL_SUBTITLE',
  },

  SUBSCRIBE: {
    TITLE: 'SUBSCRIBE_TITLE',
    SUBTITLE: 'SUBSCRIBE_SUBTITLE',
  },

  FOOTER: {
    CONTACT: 'FOOTER_CONTACT',
    STREET: 'FOOTER_STREET',
    INFORMATION: 'FOOTER_INFORMATION',
    SHIPPING: 'FOOTER_SHIPPING',
    TERMS: 'FOOTER_TERMS',
    PRIVACY: 'FOOTER_PRIVACY',
    FOLLOW: 'FOOTER_FOLLOW',
    REGISTER: 'FOOTER_REGISTER',
  },

  PRODUCTS: {
    CATEGORIES: 'PRODUCTS_CATEGORIES',
    ALL: 'PRODUCTS_ALL',
    LOOK: 'PRODUCTS_LOOK',
    UNIT: 'PRODUCTS_UNIT',
    NEW: 'PRODUCTS_NEW',
    PRODUCT: 'PRODUCTS_PRODUCT',
    SOLD_OUT: 'PRODUCTS_SOLD_OUT',
  },

  PRODUCT_DETAIL: {
    BACK: 'PRODUCT_DETAIL_BACK',
    UNITS_AVAILABLE: 'PRODUCT_DETAIL_UNITS_AVAILABLE',
    AMOUNT: 'PRODUCT_DETAIL_AMOUNT',
    WHOLESALE: 'PRODUCT_DETAIL_WHOLESALE',
    TYPE_GROUND: 'PRODUCT_DETAIL_TYPE_GROUND',
    ADD_CART: 'PRODUCT_DETAIL_ADD_CART',
    USER_RATING: 'PRODUCT_DETAIL_USER_RATING',
    GLOBAL_RATING: 'PRODUCT_DETAIL_GLOBAL_RATING',
    SPECIFICATIONS: 'PRODUCT_DETAIL_SPECIFICATIONS',
    HEIGHT: 'PRODUCT_DETAIL_HEIGHT',
    WIDTH: 'PRODUCT_DETAIL_WIDTH',
    DEPTH: 'PRODUCT_DETAIL_DEPTH',
    WEIGHT: 'PRODUCT_DETAIL_WEIGHT',
    RATINGS_COMMENTS: 'PRODUCT_DETAIL_RATINGS_COMMENTS',
    LET_RATING: 'PRODUCT_DETAIL_LET_RATING',
    RATING: 'PRODUCT_DETAIL_RATING',
    COMMENT: 'PRODUCT_DETAIL_COMMENT',
    SEND_COMMENT: 'PRODUCT_DETAIL_SEND_COMMENT',
    LIST_COMMENTS: 'PRODUCT_DETAIL_LIST_COMMENTS',
    ENTER_REVIEW: 'PRODUCT_DETAIL_ENTER_REVIEW',
  },

  MEMBERSHIPS: {
    TITLE: 'MEMBERSHIPS_TITLE',
    SUBTITLE: 'MEMBERSHIPS_SUBTITLE',
    SUBSCRIBE: 'MEMBERSHIPS_SUBSCRIBE',
    FOOTER_MESSAGE: 'MEMBERSHIPS_FOOTER_MESSAGE',
    BUTTON: 'MEMBERSHIPS_BUTTON',
    MODAL: {
      SELECT: 'MEMBERSHIPS_MODAL_SELECT',
      CATEGORY: 'MEMBERHIPS_MODAL_CATEGORY',
      PRODUCT: 'MEMBERSHIPS_MODAL_PRODUCT',
      PRODUCT_SELECT: 'MEMBERSHIPS_MODAL_PRODUCT_SELECT',
      DEPARTMENT: 'MEMBERSHIPS_MODAL_DEPARTMENT',
      PROVINCIA: 'MEMBERSHIPS_MODAL_PROVINCIA',
      PROVINCIA_SELECT: 'MEMBERSHIPS_MODAL_PROVINCIA_SELECT',
      DISTRICT: 'MEMBERSHIPS_MODAL_DISTRICT',
      DISTRICT_SELECT: 'MEMBERSHIPS_MODAL_DISTRICTS_SELECT',
      LOADING: 'MEMBERSHIPS_LOADING',
    },
  },

  // SUCURSALES
  BRANCHES: {
    TITLE: 'BRANCHES_TITLE',
    ADDRESS: 'BRANCHES_ADDRESS',
    REFERENCE: 'BRANCHES_REFERENCE',
    PHONE: 'BRANCHES_PHONE',
    LOOK_UBICATION: 'BRANCHES_LOOK_UBICATION',
  },

  ABOUT: {
    TITLE: 'ABOUT_TITLE',
    PARAGRAPH: 'ABOUT_PARAGRAPH',
    PARAGRAPH1_L1: 'ABOUT_PARAGRAPH1_L1',
    PARAGRAPH1_L2: 'ABOUT_PARAGRAPH1_L2',
    PARAGRAPH1_L3: 'ABOUT_PARAGRAPH1_L3',
    PARAGRAPH1_L4: 'ABOUT_PARAGRAPH1_L4',
    PARAGRAPH1_L5: 'ABOUT_PARAGRAPH1_L5',
    PARAGRAPH2: 'ABOUT_PARAGRAPH2',
    PARAGRAPH3: 'ABOUT_PARAGRAPH3',
    PARAGRAPH4: 'ABOUT_PARAGRAPH4',
    PARAGRAPH5_TITLE: 'ABOUT_PARAGRAPH5_TITLE',
    PARAGRAPH5_L1: 'ABOUT_PARAGRAPH5_L1',
    PARAGRAPH5_L2: 'ABOUT_PARAGRAPH5_L2',
    PARAGRAPH5_L3: 'ABOUT_PARAGRAPH5_L3',
    FIND_US: 'ABOUT_FIND_US',
  },

  EXPERIENCES: {
    TITLE: 'EXPERIENCES_TITLE',
    EXPIRED: 'EXPERIENCES_EXPIRED',
    FROM: 'EXPERIENCES_FROM',
  },

  EXPERIENCES_DETAIL: {
    DURATION: 'EXPERIENCES_DETAIL_DURATION',
    DAY: 'EXPERIENCES_DETAIL_DAY',
    IT_INCLUDES: 'EXPERIENCES_DETAIL_IT_INCLUDES',
    PRODUCTS: 'EXPERIENCES_DETAIL_PRODUCTS',
    AVAILABLE: 'EXPERIENCES_DETAIL_AVAILABLE',
    PRICE: 'EXPERIENCES_DETAIL_PRICE',
    RESERVE: 'EXPERIENCES_DETAIL_RESERVE',
  },

  SHOPCART: {
    SHOPCART_COUNT: 'SHOPCART_SHOPCART_COUNT',
    SHOPCART_EMPTY: 'SHOPCART_SHOPCART_EMPTY',
    SUMMARY: 'SHOPCART_SUMMARY',
    LIMITED: 'SHOPCART_LIMITED',
    CONTINUED: 'SHOPCART_CONTINUED',
    SHIPPING: 'SHOPCART_SHIPPING',
    ARRIVE: 'SHOPCART_ARRIVE',
    DELIVERY_TIME: 'SHOPCART_DELIVERY_TIME',
  },

  SHIPPING: {
    CHOOSE: 'SHIPPING_CHOOSE',
    DISPATCH: 'SHIPPPING_DISPATCH',
    RETIRE: 'SHIPPING_RETIRE',
    CHOOSE_DISTRICT: 'SHIPPING_CHOOSE_DISTRICT',
    DEPARTMENT: 'SHIPPPING_DEPARTMENT',
    PROVINCE: 'SHIPPING_PROVINCE',
    DISTRICT: 'SHIPPING_DISTRICT',
    ADDRESS: 'SHIPPING_ADDRESS',

    PAYMENT_RECEIPT: ' SHIPPING_PAYMENT_RECEIPT',
    CONFIRM_DATE: 'SHIPPING_CONFIRM_DATE',
    DATE: 'SHIPPING_DATE',
    HOUR: 'SHIPPING_HOUR',

    PAY: 'SHIPPING_PAY',
  },

  PAYMENT: {
    INFORMATION: 'PAYMENT_INFORMATION',
    DETAILS: 'PAYMENT_DETAILS',
    TERMNS: 'PAYMENT_TERMNS',
    TERMNS_CONDITIONS: 'PAYMENT_TERMNS_CONDITIONS',
    SECURITY: 'PAYMENT_SECURITY',
  },

  CHECKOUT: {
    NAME: 'CHECKOUT_NAME',
    LAST_NAME: 'CHECKOUT_LAST_NAME',
    DOCUMENT_NUMBER: 'CHECKOUT_DOCUMENT_NUMBER',
    SHIPPING_DIRECTION: 'CHECKOUT_SHIPPPIN_DIRECTION',
    TYPE_INVOICE: 'CHECKOUT_TYPE_INVOICE',
  },

  // LOGIN

  LOGIN: {
    WELCOME: 'LOGIN_WELCOME',
    PASSWORD: 'LOGIN_PASSWORD',
    REMEMBER: 'LOGIN_REMEMBER',
    HAVE_ACCOUNT: 'LOGIN_HAVE_ACCOUNT',
    REGISTER: 'LOGIN_REGISTER',
    FORGOT_PASSWORD: 'LOGIN_FORGOT_PASSWORD',
  },

  REGISTER: {
    JOIN_UP: 'REGISTER_JOIN_UP',
    ACEPT: 'REGISTER_ACEPT',
    TERMNS: 'REGISTER_TERMNS',
    PRIVACY_POLICY: 'REGISTER_PRIVACY_POLICY',
    Y: 'REGISTER_Y',
  },

  USER_PROFILE: {
    ADMIN: 'USER_PROFILE_ADMIN',
    PROFILE: 'USER_PROFILE_PROFILE',
    BASIC_INFORMATION: 'USER_PROFILE_BASIC_INFORMATION',
    CHANGE: 'USER_PROFILE_CHANGE',
    SAVE: 'USER_PROFILE_SAVE',
    EXTRA: 'USER_PROFILE_EXTRA',
    CHANGE_UBICATION: 'USER_PROFILE_CHANGE_UBIVACIONT',
  },

  CHANGE_PASSWORD: {
    ENTER_EMAIL: 'CHANGE_PASSWORD_ENTER_EMAIL',
    SEND: 'CHANGE_PASSWORD_SEND',
  },
};

export default ELEMENTS;
