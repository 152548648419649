export const HOME = '/';

export const ABOUT = '/nosotros';

export const BLOG = '/blog';

export const BLOG_BY_SLUG = '/blog/:slug';

export const COURSES = '/cursos';

export const SUCURSALES = '/sucursales';

export const COURSES_BY_SLUG = '/cursos/:slug';

export const EXPERIENCES = '/experiencias';

export const EXPERIENCES_BY_ID = '/experiencias/:id';

export const MEMBERSHIPS = '/membresias';

export const MEMBERSHIPS_CHECKOUT = '/membresias/checkout';

export const MEMBERSHIPS_DETAIL = '/membresias/detail';

export const PRODUCTS = '/productos';

export const PRODUCTS_BY_SLUG = '/productos/:slug';

export const PRODUCTSB2B = '/productosb2b';

export const PRODUCTSB2B_BY_SLUG = '/productosb2b/:slug';

export const SHOPCART = '/carrito-de-compras';

export const SHIPPING = '/carrito-de-compras/despacho';

export const PAYMENT = '/carrito-de-compras/pago';

export const LOGIN = '/entrar';

export const REGISTER = '/registro';

export const USER_PROFILE = '/perfil';

export const USER_PURCHASES = '/pedidos';

export const USER_PURCHASES_BY_ID = '/pedidos/:id';

export const USER_EXPERIENCES = '/mis-experiencias';

export const USER_MEMBERSHIPS = '/mis-membresias';

export const USER_EXPERIENCES_BY_ID = '/mis-experiencias/:id';

export const CHANGE_USER_PASSWORD = '/cambiar-contraseña';

export const SHIPPING_RETURNS = '/envios-y-devoluciones';

export const TERMS_CONDITIONS = '/terminos-y-condiciones';

export const PRIVACY_POLICY = '/politica-de-privacidad';

export const BOOK_VIEW = '/libro-de-reclamaciones';

export const NOT_FOUND = '/404';

export const SUBSCRIPTION_STATUS = '/estado-subscripcion';

export const CONFIRM_EMAIL = '/confirm-email';
