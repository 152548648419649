import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import * as VARIABLES from 'constants/variables';
import PropTypes from 'prop-types';
import './cardGrid.scss';
// components
import { CatePaper } from 'components';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Button } from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import Skeleton from '@material-ui/lab/Skeleton';
import Traslate from 'i18n/traslate';
import ELEMENTS from 'constants/elementsForTranslate';

const layout = { xs: 12, sm: 6, md: 6, lg: 6 };

const ColorButton = withStyles((theme) => ({
  root: {
    color: red[500],
    border: '1px solid' + red[500],
    '&:hover': {
      backgroundColor: red[50],
    },
  },
}))(Button);

function CardGrid(props) {
  const { loading = true, data } = props;

  return (
    <div className="products-list-grid">
      <Grid container spacing={2}>
        {loading ? (
          <>
            {Array.from(new Array(15)).map((data, index) => (
              <Grid item key={index} {...layout}>
                <CatePaper disableElevation className="product-card">
                  <Skeleton animation="wave" variant="rect" className="product-card__image" />
                  <div className="product-card__body">
                    <div className="product-card__body__wrap">
                      <div className="card-body-top">
                        <div className="body-brand">
                          <Skeleton animation="wave" width="60%" height={20} />
                        </div>
                        <div className="body-title">
                          <Skeleton animation="wave" height={20} />
                        </div>
                      </div>
                      <div className="card-body-bottom">
                        <div className="body-price"></div>
                        <div className="body-action">
                          <Skeleton animation="wave" width={32} height={24} />
                        </div>
                      </div>
                    </div>
                  </div>
                </CatePaper>
              </Grid>
            ))}
          </>
        ) : (
          <>
            {data.map((item, index) => {
              return (
                <React.Fragment key={index}>
                  <Grid item {...layout}>
                    <CatePaper disableElevation className="product-card">
                      <div className="nui-no-decoration nui-block">
                        <div className={`product-card__image`} style={{ 'cursor': 'auto'}}>
                          <img
                            src={
                              item.foto?.length > 0
                                ? VARIABLES.IMAGE_PATH + item.foto
                                : VARIABLES.IMAGE_FALLBACK
                            }
                            alt={item.referencia}
                          />
                        </div>
                      </div>
                      <div className="product-card__body">
                        <div className="product-card__body__wrap">
                          <div className="card-body-top">
                            <div className="body-title">
                              <b>{Traslate(ELEMENTS.BRANCHES.ADDRESS)}:</b> {item.direccion}
                            </div>
                            <div className="body-price-normal">
                              <p>
                                <b>{Traslate(ELEMENTS.BRANCHES.REFERENCE)}:</b>{' '}
                                {item.referencia}
                              </p>
                            </div>
                            <div className="body-price-normal">
                              <p>
                                <b>{Traslate(ELEMENTS.BRANCHES.PHONE)}:</b> {item.telefono}
                              </p>
                            </div>
                          </div>
                          <div className="card-body-bottom">
                            <div className="body-price">
                              <span></span>
                            </div>
                            <div className="body-action">
                              <RouterLink
                                className="nui-no-decoration"
                                target="_blank"
                                to={{
                                  pathname: item.map_url,
                                }}>
                                <ColorButton disableElevation>
                                  {Traslate(ELEMENTS.BRANCHES.LOOK_UBICATION)}
                                </ColorButton>
                              </RouterLink>
                            </div>
                          </div>
                        </div>
                      </div>
                    </CatePaper>
                  </Grid>
                </React.Fragment>
              );
            })}
          </>
        )}
      </Grid>
    </div>
  );
}

CardGrid.propTypes = {
  loading: PropTypes.bool,
  data: PropTypes.array,
};

export default CardGrid;
