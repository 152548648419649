import ELEMENTS from 'constants/elementsForTranslate';
import { LOCALES } from '../locales';

const enUs = {
  [LOCALES.ENGLISH]: {
    [ELEMENTS.NAV.HOME]: 'Home',
    [ELEMENTS.NAV.PRODUCTS]: 'Products',
    [ELEMENTS.NAV.SHOP]: 'Shop',
    [ELEMENTS.NAV.ABOUT]: 'About us',
    [ELEMENTS.NAV.MEMBERSHIPS]: 'Subscriptions',
    [ELEMENTS.NAV.EXPERIENCES]: 'Tasting experiences',
    [ELEMENTS.NAV.SUCURSALES]: 'Branches',
    [ELEMENTS.NAV.HELLO]: 'Hello!',
    [ELEMENTS.NAV.OTHERS]: 'Others',
    [ELEMENTS.NAV.COURSES]: 'Courses',
    [ELEMENTS.NAV.LOGIN]: 'Login',

    [ELEMENTS.NAV.MANAGE]: 'Manage your account',
    [ELEMENTS.NAV.CHANGE_PASSWORD]: 'Change your password',
    [ELEMENTS.NAV.MY_SHOPPING]: 'My Shopping',
    [ELEMENTS.NAV.MY_EXPERIENCES]: 'My Experiences',
    [ELEMENTS.NAV.MY_MEMBERSHIPS]: 'My Memberships',
    [ELEMENTS.NAV.LOGOUT]: 'Logout',

    [ELEMENTS.BANNER.SLOGAN]: 'The flavors of Peru {component} in one place.',
    [ELEMENTS.BANNER.SHOP]: 'Shop',

    [ELEMENTS.RECOMMEDED_PRODUCTS.TITLE]: 'Our recommended products',

    // CAROUSEL
    [ELEMENTS.CAROUSEL.TITLE]: 'Our Products',
    [ELEMENTS.CAROUSEL.SUBTITLE]: 'Get what you need',

    // ABOUT
    [ELEMENTS.ABOUT.PARAGRAPH]:
      "Buying in our store is super easy, fast and safe. And the best thing is that you don't have to leave home, we take care of reaching your destination.",
    [ELEMENTS.ABOUT.TITLE]: 'Place your order and receive it at home',

    //SUBSCRIBE
    [ELEMENTS.SUBSCRIBE.TITLE]: 'Subscribe to Cate Tasting Room',
    [ELEMENTS.SUBSCRIBE.SUBTITLE]:
      'Get your subscription and access the benefits of Cate Tasting Room.',
    [ELEMENTS.SUBSCRIBE.BUTTON]: 'Subscribe',

    // FOOTER
    [ELEMENTS.FOOTER.CONTACT]: 'Contact',
    [ELEMENTS.FOOTER.STREET]: 'Independencia 269 Miraflores Street',
    [ELEMENTS.FOOTER.INFORMATION]: 'Information',
    [ELEMENTS.FOOTER.SHIPPING]: 'Shipping & Returns',
    [ELEMENTS.FOOTER.TERMS]: 'Terms and Conditions',
    [ELEMENTS.FOOTER.PRIVACY]: 'Privacy Policy',
    [ELEMENTS.FOOTER.FOLLOW]: 'Follow us on ',
    [ELEMENTS.FOOTER.REGISTER]: 'Sign up',

    // PRODUCTS
    [ELEMENTS.PRODUCTS.CATEGORIES]: 'Categories',
    [ELEMENTS.PRODUCTS.ALL]: 'All',
    [ELEMENTS.PRODUCTS.LOOK]: 'Look the product',
    [ELEMENTS.PRODUCTS.UNIT]: 'Unit',
    [ELEMENTS.PRODUCTS.NEW]: 'New',
    [ELEMENTS.PRODUCTS.PRODUCT]: 'PRODUCT',
    [ELEMENTS.PRODUCTS.SOLD_OUT]: 'SOLD OUT',

    // PRODUCTS DETAIL

    [ELEMENTS.PRODUCT_DETAIL.BACK]: 'Back',
    [ELEMENTS.PRODUCT_DETAIL.UNITS_AVAILABLE]: 'Units available',
    [ELEMENTS.PRODUCT_DETAIL.AMOUNT]: 'Amount',
    [ELEMENTS.PRODUCT_DETAIL.WHOLESALE]:
      'For the purchase of 6 units or more, the unit price is: ',
    [ELEMENTS.PRODUCT_DETAIL.TYPE_GROUND]:
      'Do you want to grind your coffee? Choose the type of grind you prefer: ',
    [ELEMENTS.PRODUCT_DETAIL.ADD_CART]: 'Add to cart',
    [ELEMENTS.PRODUCT_DETAIL.USER_RATING]: 'User ratings',
    [ELEMENTS.PRODUCT_DETAIL.GLOBAL_RATING]: 'Global ratings',
    [ELEMENTS.PRODUCT_DETAIL.SPECIFICATIONS]: 'Specifications',
    [ELEMENTS.PRODUCT_DETAIL.HEIGHT]: 'Height',
    [ELEMENTS.PRODUCT_DETAIL.WIDTH]: 'Width',
    [ELEMENTS.PRODUCT_DETAIL.DEPTH]: 'Depth',
    [ELEMENTS.PRODUCT_DETAIL.WEIGHT]: 'Weight',
    [ELEMENTS.PRODUCT_DETAIL.RATINGS_COMMENTS]: 'Ratings and comments about the product',
    [ELEMENTS.PRODUCT_DETAIL.LET_RATING]: 'Let comment',
    [ELEMENTS.PRODUCT_DETAIL.RATING]: 'Calificación',
    [ELEMENTS.PRODUCT_DETAIL.COMMENT]: 'Comment',
    [ELEMENTS.PRODUCT_DETAIL.SEND_COMMENT]: 'Send comment',
    [ELEMENTS.PRODUCT_DETAIL.LIST_COMMENTS]: 'List of comments',
    [ELEMENTS.PRODUCT_DETAIL.ENTER_REVIEW]: 'Enter review',

    // MEMBERSHIPS
    [ELEMENTS.MEMBERSHIPS.TITLE]: 'Select a plan and join the Cate Tasting Room family',
    [ELEMENTS.MEMBERSHIPS.SUBTITLE]:
      'Get your subscription and access the benefits of Cate Tasting Room.',
    [ELEMENTS.MEMBERSHIPS.FOOTER_MESSAGE]:
      '*Shipments will be made the third day after confirming the subscription',
    [ELEMENTS.MEMBERSHIPS.SUBSCRIBE]: 'Subscribe',
    [ELEMENTS.MEMBERSHIPS.BUTTON]: 'Subscribe',

    [ELEMENTS.MEMBERSHIPS.MODAL.SELECT]: 'Select category and product',
    [ELEMENTS.MEMBERSHIPS.MODAL.CATEGORY]: 'Category',
    [ELEMENTS.MEMBERSHIPS.MODAL.PRODUCT]: 'Products',
    [ELEMENTS.MEMBERSHIPS.MODAL.PRODUCT_SELECT]: 'Select a category to get the products',
    [ELEMENTS.MEMBERSHIPS.MODAL.DEPARTMENT]: 'Departments',
    [ELEMENTS.MEMBERSHIPS.MODAL.PROVINCIA]: 'Provinces',
    [ELEMENTS.MEMBERSHIPS.MODAL.PROVINCIA_SELECT]:
      'Select a department to obtain the provinces',
    [ELEMENTS.MEMBERSHIPS.MODAL.DISTRICT]: 'Distritcs',
    [ELEMENTS.MEMBERSHIPS.MODAL.DISTRICT_SELECT]: 'Select a province to get the districts',
    [ELEMENTS.MEMBERSHIPS.MODAL.LADING]: 'Loading...',

    // SUCURSALES
    [ELEMENTS.BRANCHES.TITLE]: 'Branches',
    [ELEMENTS.BRANCHES.ADDRESS]: 'Address',
    [ELEMENTS.BRANCHES.REFERENCE]: 'Reference',
    [ELEMENTS.BRANCHES.PHONE]: 'Phone',
    [ELEMENTS.BRANCHES.LOOK_UBICATION]: 'Look ubication',

    // About
    [ELEMENTS.ABOUT.PARAGRAPH1_L1]:
      'We, with the help of our taster and Q-Grader consultant, Henrry Guillén,',
    [ELEMENTS.ABOUT.PARAGRAPH1_L2]:
      'cwe rate each batch of coffee in reference to 10 attributes on a scale of 1',
    [ELEMENTS.ABOUT.PARAGRAPH1_L3]:
      'to 10, in this way the perfect coffee would have 100 points. We handle two brands',
    [ELEMENTS.ABOUT.PARAGRAPH1_L4]:
      'of specialty coffee: Log for coffees with more than 84 points and White Cup',
    [ELEMENTS.ABOUT.PARAGRAPH1_L5]: 'for those with the lowest score, which we call Clean Cup.',
    [ELEMENTS.ABOUT.PARAGRAPH2]:
      'Henry Guillén, Q-Grader taster, National Jury of the years 2017, 2018, 2019, and 2020 of the Peru Cup of Excellence contest.',
    [ELEMENTS.ABOUT.PARAGRAPH3]:
      '(There is a globally accepted evaluation system that is the SCAA system. Only a person certified as a Q-Grader by the Coffee Quality Institute has the authority to grant it)',
    [ELEMENTS.ABOUT.PARAGRAPH4]:
      'We seek to bring Peruvian chocolate from different parts of the country to the market and recreate symphonies with the spices of our environment, bringing flavored chocolates that prove their worth in a cup as our ancestors did in the past. Just as tea has its TEA TIME, or coffee its COFFEE SHOP, we believe that the time has come to unite chocolate with its peers in a CHOC TIME.',
    [ELEMENTS.ABOUT.PARAGRAPH5_TITLE]: 'Producer Partners',
    [ELEMENTS.ABOUT.PARAGRAPH5_L1]:
      'We provide training and verify the work of producers on how to',
    [ELEMENTS.ABOUT.PARAGRAPH5_L2]:
      'brew specialty coffee to establish strong practices and share',
    [ELEMENTS.ABOUT.PARAGRAPH5_L3]: 'information between all the actors.',
    [ELEMENTS.ABOUT.FIND_US]: 'Find us at',

    [ELEMENTS.EXPERIENCES.TITLE]: 'Experiences',
    [ELEMENTS.EXPERIENCES.EXPIRED]: 'Expired',
    [ELEMENTS.EXPERIENCES.FROM]: 'From',

    // EXPERIENCES DETAIL
    [ELEMENTS.EXPERIENCES_DETAIL.DURATION]: 'Duration: ',
    [ELEMENTS.EXPERIENCES_DETAIL.DAY]: 'Day: ',
    [ELEMENTS.EXPERIENCES_DETAIL.IT_INCLUDES]: 'Includes: ',
    [ELEMENTS.EXPERIENCES_DETAIL.PRODUCTS]: 'products',
    [ELEMENTS.EXPERIENCES_DETAIL.AVAILABLE]: 'Space available: ',
    [ELEMENTS.EXPERIENCES_DETAIL.PRICE]: 'Price: ',
    [ELEMENTS.EXPERIENCES_DETAIL.RESERVE]: 'Reserve',

    // SHOPCART
    [ELEMENTS.SHOPCART.SHOPCART_COUNT]: 'Limited quantity available',
    [ELEMENTS.SHOPCART.SHOPCART_EMPTY]: 'Your cart is empty',
    [ELEMENTS.SHOPCART.SUMMARY]: 'Order summary',
    [ELEMENTS.SHOPCART.LIMITED]: 'limited quantity available',
    [ELEMENTS.SHOPCART.CONTINUED]: 'Continue with my purchase',
    [ELEMENTS.SHOPCART.SHIPPING]: 'Shipment',
    [ELEMENTS.SHOPCART.ARRIVE]: 'The mobile Cate will arrive at your house',
    [ELEMENTS.SHOPCART.DELIVERY_TIME]: 'Delivery time: 2-5 days',

    // PAYMENT
    [ELEMENTS.SHIPPING.CHOOSE]: 'Choose your delivery options',
    [ELEMENTS.SHIPPING.DISPATCH]: 'Delivery',
    [ELEMENTS.SHIPPING.RETIRE]: 'Retire in store',
    [ELEMENTS.SHIPPING.CHOOSE_DISTRICT]:
      'Select the District where your products will be shipped.',
    [ELEMENTS.SHIPPING.DEPARTMENT]: 'Department',
    [ELEMENTS.SHIPPING.PROVINCE]: 'Province',
    [ELEMENTS.SHIPPING.DISTRICT]: 'District',
    [ELEMENTS.SHIPPING.ADDRESS]: 'Address',
    [ELEMENTS.SHIPPING.PAYMENT_RECEIPT]: 'Payment receipt',
    [ELEMENTS.SHIPPING.CONFIRM_DATE]:
      'Confirm the date and time you will come to pick up your order.',
    [ELEMENTS.SHIPPING.DATE]: 'Date: ',
    [ELEMENTS.SHIPPING.HOUR]: 'Hour: ',
    [ELEMENTS.SHIPPING.PAY]: 'PROCEED TO PAYMENT',

    // Payment
    [ELEMENTS.PAYMENT.INFORMATION]: 'Shipment information',
    [ELEMENTS.PAYMENT.DETAILS]: 'Order details',
    [ELEMENTS.PAYMENT.TERMNS]:
      'By clicking "Proceed to Payment", I confirm that I have read and accept the',
    [ELEMENTS.PAYMENT.TERMNS_CONDITIONS]: 'Terms and Conditions',
    [ELEMENTS.PAYMENT.SECURITY]: 'MercadoPago keeps your information and your payment safe',

    // MEMBERSHIP CHECKOUT
    [ELEMENTS.CHECKOUT.NAME]: 'Name',
    [ELEMENTS.CHECKOUT.LAST_NAME]: 'Last name',
    [ELEMENTS.CHECKOUT.DOCUMENT_NUMBER]: 'Document number',
    [ELEMENTS.CHECKOUT.SHIPPING_DIRECTION]: 'Address where the product will be sent.',
    [ELEMENTS.CHECKOUT.TYPE_INVOICE]: 'Invoice type',

    // LOGIN
    [ELEMENTS.LOGIN.WELCOME]: 'Welcome to',
    [ELEMENTS.LOGIN.PASSWORD]: 'Password',
    [ELEMENTS.LOGIN.REMEMBER]: 'Remember me',
    [ELEMENTS.LOGIN.HAVE_ACCOUNT]: 'You do not have an account? ',
    [ELEMENTS.LOGIN.REGISTER]: 'Sign up',
    [ELEMENTS.LOGIN.FORGOT_PASSWORD]: 'Did you forget your password? ',

    // REGISTER
    [ELEMENTS.REGISTER.JOIN_UP]: 'Join our family',
    [ELEMENTS.REGISTER.ACEPT]: 'I accept the terms ',
    [ELEMENTS.REGISTER.TERMNS]: 'Termns ',
    [ELEMENTS.REGISTER.PRIVACY_POLICY]: 'Privacy policies',
    [ELEMENTS.REGISTER.Y]: 'and ',

    // User Profile
    [ELEMENTS.USER_PROFILE.ADMIN]: 'Manage your account',
    [ELEMENTS.USER_PROFILE.PROFILE]: 'Profile',
    [ELEMENTS.USER_PROFILE.BASIC_INFORMATION]: 'Basic information',
    [ELEMENTS.USER_PROFILE.CHANGE]: 'Change the basic data of your account',
    [ELEMENTS.USER_PROFILE.SAVE]: 'Save Changes',
    [ELEMENTS.USER_PROFILE.EXTRA]: 'Extra information',
    [ELEMENTS.USER_PROFILE.CHANGE_UBICATION]:
      'Change the location and identification data of your account.',

    // CHANGE PASSWORD
    [ELEMENTS.CHANGE_PASSWORD.ENTER_EMAIL]: 'Enter your email address to reset your password.',
    [ELEMENTS.CHANGE_PASSWORD.SEND]: 'Send',
  },
};

export default enUs;
